import React from "react";
import { Avatar, Grid, Row, Col } from "rsuite";

export default props => {
  const avatars = [
    "images/device1.png",
    "images/device2.png",
    "images/device3.png",
    "images/device4.png",
    "images/device5.png",
    "images/device6.png",
    "images/device7.png",
    "images/device8.png",
    "images/device9.png",
    "images/device10.png",
    "images/device11.png",
    "images/device12.png",
    "images/device13.png",
    "images/device14.png",
    "images/device15.png",
    "images/device16.png"
  ];
  return (
    <Grid fluid>
      {[0, 1, 2, 3].map(row => (
        <Row key={"diprow-" + row}>
          {avatars.slice(row * 4, row * 4 + 4).map((src, index) => {
            return (
              <Col xs={6} key={"dipcol-" + row + "-" + index}>
                <Avatar
                  key={"dipavatar-" + row + "-" + index}
                  onClick={() => {
                    props.onClick && props.onClick(src);
                  }}
                  src={src}
                  size="md"
                  className="small-shadow"
                  style={{ cursor: "pointer" }}
                />
              </Col>
            );
          })}
        </Row>
      ))}
    </Grid>
  );
};
