import React, { useState, useEffect } from "react";
import {
  Avatar,
  Form,
  Table,
  Panel,
  Button,
  //Icon,
  Grid,
  Row,
  Col,
  Loader,
} from "rsuite";

import Icon from "../../components/Icon";
import { useQuery } from "@apollo/react-hooks";

import GQL from "./graphql";
import { useApp } from "../../AppStore";

import RolePicker from "./rolepicker";
import ToggleUser from "./toggleuser";
import Details from "./details";
import Actions from "./actions";
import AccessDenied from "../../components/accessdenied";

const { Column, HeaderCell, Cell } = Table;

let lang = null;

export default (props) => {
  const rowHeight = 75;
  /*
  const rowHeight =
    props.widget.dataGrid.h > 1
      ? parseInt((window.innerHeight - 232) * 0.11)
      : 75;
			*/
  const isMobile = window.innerWidth < 1024;
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [{ user, selectedAccount: globalSelectedAccount, cols }] = useApp();
  const [selectedAccount, setSelectedAccount] = useState(globalSelectedAccount);

  const { data: readAccess, loading: loadingReadAccess } = useQuery(
    GQL.Queries.readAccess,
    {
      onCompleted: (res) => console.log({ res }),
    }
  );
  const { data: writeAccess, loading: loadingWriteAccess } = useQuery(
    GQL.Queries.writeAccess
  );
  const { data: deleteAccess, loading: loadingDeleteAccess } = useQuery(
    GQL.Queries.deleteAccess
  );
  const { data: adminAccess, loading: loadingAdminAccess } = useQuery(
    GQL.Queries.adminAccess
  );
  const {
    data: users,
    loading: loadingUsers,
    refetch: reloadUsers,
  } = useQuery(GQL.Queries.users, {
    variables: {
      ...(selectedAccount ? { accountId: selectedAccount.id } : {}),
    },
    fetchPolicy: "network-only",
  });
  const widgetWidth =
    (window.innerWidth / cols) * props.widget.dataGrid.w -
    10 * props.widget.dataGrid.w;

  useEffect(() => {
    /** Language File */
    lang =
      user && user.language
        ? require("../../languages/" + user.language + ".js").default
        : lang;
  }, [user]);

  useEffect(() => {
    setSelectedAccount(globalSelectedAccount);
  }, [globalSelectedAccount]);

  const { loading: loadingRoles } = useQuery(GQL.Queries.roles, {
    variables: {
      accountId: selectedAccount ? selectedAccount.id : user.account.id,
    },
  });

  const renderContent = () => {
    return loadingUsers ||
      loadingRoles ||
      loadingReadAccess ||
      loadingWriteAccess ||
      loadingDeleteAccess ||
      loadingAdminAccess ? (
      <Loader content={lang !== null ? lang.loading : "Loading..."} />
    ) : readAccess.hasAccessToSection === true ? (
      <div
        style={{
          width: "100%",
          position: "relative",
        }}
      >
        <Grid
          fluid
          style={{
            fontSize: "1.1rem",
            fontWeight: "bold",
            width: "100%",
            textAlign: "left",
            padding: "15px",
            paddingBottom: "5px",
            borderBottom: "1px solid #eeeeee",
          }}
        >
          <Row>
            <Col xs={12} style={{ paddingTop: "7px" }}>
              <div>{lang !== null ? lang.users : "Users"}</div>
              <div style={{ fontSize: "0.75rem", color: "#aaa" }}>
                {selectedAccount ? (
                  <>
                    {
                      /* Logged in user Account */
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSelectedAccount(null);
                        }}
                      >
                        {user.account.name}
                      </span>
                    }
                    {
                      /* Account breadcrumb */
                      selectedAccount.parentBreadcrumb &&
                      selectedAccount.parentBreadcrumb.length > 0
                        ? selectedAccount.parentBreadcrumb.map((account) => (
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setSelectedAccount(account);
                              }}
                            >
                              {" "}
                              <Icon icon="faArrowRight" /> {account.name}
                            </span>
                          ))
                        : null
                    }
                    {
                      /* Selected Account */
                      <span>
                        {" "}
                        <Icon icon="faArrowRight" />{" "}
                        {selectedAccount && selectedAccount.name
                          ? selectedAccount.name
                          : "Company"}
                      </span>
                    }
                  </>
                ) : user && user.account && user.account.name ? (
                  user.account.name
                ) : lang !== null ? (
                  lang.company
                ) : (
                  "Company"
                )}
              </div>
            </Col>
            <Col xs={12} style={{ textAlign: "right" }}>
              <Button
                appearance="ghost"
                //className="small-shadow"
                onClick={() => {
                  setSelectedUser(null);
                  setShowDetailsModal(!showDetailsModal);
                }}
              >
                {showDetailsModal ? (
                  <span>
                    <Icon icon="faBan" width={15} />{" "}
                    {lang !== null ? lang.cancel : "Cancel"}
                  </span>
                ) : (
                  <span>
                    <Icon icon="faSquarePlus" />{" "}
                    {lang !== null ? lang.newUser : "New User"}
                  </span>
                )}
              </Button>
            </Col>
          </Row>
        </Grid>
        <Table
          virtualized={false}
          height={
            isMobile
              ? window.innerHeight - 230
              : (props.baseHeight + 10) * props.h - 125
          }
          data={users.users}
          affixHeader={true}
          //rowHeight={75}
          rowHeight={rowHeight}
          style={{
            //border: "1px solid black",
            borderTop: "2px solid #fafafa",
            borderBottom: "1px dashed #fafafa",
            filter: showDetailsModal && "blur(2px)",
            pointerEvents: showDetailsModal && "none",
          }}
          loading={loadingUsers}
          onRowClick={(rowData) => {
            //setSelectedUser(rowData);
            //setShowDetailsModal(true);
          }}
          //autoHeight={isMobile}
        >
          <Column
            /*
            width={Math.max(
              (widgetWidth / 12) * (isMobile ? 6 : 4),
              isMobile ? 200 : 250
            )}
						*/
            flexGrow={3}
          >
            <HeaderCell style={{ textAlign: "left" }}>
              {lang !== null ? lang.name : "Name"}/
              {lang !== null ? lang.email : "Email"}
            </HeaderCell>
            <Cell>
              {(rowData) => {
                return (
                  <Grid
                    style={{ cursor: "pointer" }}
                    fluid
                    onClick={() => {
                      if (writeAccess.hasAccessToSection === true) {
                        setSelectedUser(rowData);
                        setShowDetailsModal(true);
                      }
                    }}
                  >
                    <Row>
                      {widgetWidth > 400 && (
                        <Col xs={6}>
                          <Avatar
                            circle
                            className="small-shadow"
                            src={
                              rowData && rowData.avatar
                                ? rowData.avatar
                                : "images/avatar0.png"
                            }
                            size="md"
                          />
                        </Col>
                      )}
                      <Col
                        xs={18}
                        style={{ textAlign: "left", fontSize: "14px" }}
                      >
                        <div>
                          <big>
                            {rowData.firstname} {rowData.lastname}
                          </big>
                        </div>
                        <div>
                          <small>{rowData.email}</small>
                        </div>
                      </Col>
                    </Row>
                  </Grid>
                );
              }}
            </Cell>
          </Column>
          {!isMobile && widgetWidth > 500 && (
            <Column
              //width={Math.max((widgetWidth / 12) * 4, 250)}
              flexGrow={3}
            >
              <HeaderCell style={{ textAlign: "left" }}>
                {lang !== null ? lang.roles : "Roles"}
              </HeaderCell>
              <Cell style={{ position: "relative" }}>
                {(rowData) => (
                  <RolePicker
                    rowData={rowData}
                    disabled={adminAccess.hasAccessToSection !== true}
                  />
                )}
              </Cell>
            </Column>
          )}
          {widgetWidth > 300 && (
            <Column
            //width={Math.max((widgetWidth / 12) * 2, isMobile ? 50 : 150)}
            //flexGrow={1}
            >
              <HeaderCell>
                {!isMobile && (lang !== null ? lang.active : "Active")}
              </HeaderCell>
              <Cell style={{ position: "relative" }}>
                {(rowData) => (
                  <ToggleUser
                    active={rowData.active}
                    id={rowData.id}
                    disabled={writeAccess.hasAccessToSection !== true}
                  />
                )}
              </Cell>
            </Column>
          )}
          <Column
            //width={Math.max((widgetWidth / 12) * 2, isMobile ? 100 : 150)}
            flexGrow={2}
          >
            <HeaderCell>{lang !== null ? lang.actions : "Actions"}</HeaderCell>
            <Cell style={{ position: "relative" }}>
              {(rowData) => (
                <Actions
                  data={rowData}
                  setShowDetailsModal={setShowDetailsModal}
                  setSelectedUser={setSelectedUser}
                  refetch={reloadUsers}
                  access={{
                    adminAccess: adminAccess.hasAccessToSection,
                    deleteAccess: deleteAccess.hasAccessToSection,
                    writeAccess: writeAccess.hasAccessToSection,
                    readAccess: readAccess.hasAccessToSection,
                  }}
                />
              )}
            </Cell>
          </Column>
        </Table>
        {showDetailsModal && (
          <Details
            selectedUser={selectedUser}
            selectedAccount={selectedAccount}
            refetch={reloadUsers}
            close={() => {
              setShowDetailsModal(false);
            }}
            access={{
              adminAccess: adminAccess.hasAccessToSection,
              deleteAccess: deleteAccess.hasAccessToSection,
              writeAccess: writeAccess.hasAccessToSection,
              readAccess: readAccess.hasAccessToSection,
            }}
          />
        )}
      </div>
    ) : (
      <AccessDenied section="USERS" />
    );
  };

  //console.log({ widgetWidth });

  return (
    <div>
      <Panel
        expanded={props.expanded}
        collapsible
        bodyFill
        className="widget-config"
        style={{ borderRadius: 0 }}
      >
        <Form
          style={{ padding: "5px", paddingTop: "15px", paddingBottom: "15px" }}
        ></Form>
      </Panel>
      {renderContent()}
    </div>
  );
};
