export default {
  stat: {
    fontWeight: "bold",
    fontSize: "12rem",
    color: "skyblue"
  },
  label: {
    fontSize: "2.5rem",
    color: "#777777"
  }
};
