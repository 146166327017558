import gql from "graphql-tag";

export default {
  Queries: {
    templateScreens: gql`
      {
        templateScreens {
          id
          icon
          title
          widgets {
            id
            dataGrid
          }
        }
      }
    `
  },
  Mutations: {}
};
