import gql from "graphql-tag";

export default {
  Queries: {
    alerts: gql`
      {
        alerts {
          id
          account {
            name
          }
          alertTypeId
          alertType {
            name
            icon
          }
          users {
            id
            email
            avatar
            firstname
            lastname
          }
          alertSpeeds {
            id
            speed
          }
          alertLocations {
            id
            trigger
            alertId
            location {
              id
              name
              type
              radius
              coordinates
              address
            }
          }
          alertOffhour {
            id
            locationIds
            days
            startHour
            startMinute
            endHour
            endMinute
          }
          devices {
            id
            name
            icon
            color
            serial
            imei
            simnumber
            conduitDeviceId
            lastEvent
            accountId
            deviceTypeId
            deviceType {
              name
            }
          }
        }
      }
    `,
    alertTypes: gql`
      {
        alertTypes {
          id
          name
        }
      }
    `,
    devices: gql`
      query devices($accountId: Int, $color: String) {
        devices(accountId: $accountId, color: $color) {
          id
          name
          icon
          color
          serial
          imei
          deviceType {
            name
          }
        }
      }
    `,
    locations: gql`
      {
        locations {
          id
          name
          type
          radius
          coordinates
          address
        }
      }
    `,
    users: gql`
      {
        users {
          id
          email
          firstname
          lastname
        }
      }
    `,
  },
  Mutations: {
    createAlert: gql`
      mutation {
        createAlert {
          id
        }
      }
    `,
    createAlertDevice: gql`
      mutation createAlertDevice($alertId: Int!, $deviceId: Int!) {
        createAlertDevice(alertId: $alertId, deviceId: $deviceId) {
          id
        }
      }
    `,
    createAlertLocation: gql`
      mutation createAlertLocation(
        $alertId: Int!
        $locationId: Int!
        $trigger: String!
      ) {
        createAlertLocation(
          alertId: $alertId
          locationId: $locationId
          trigger: $trigger
        ) {
          id
        }
      }
    `,
    createAlertSpeed: gql`
      mutation createAlertSpeed($alertId: Int!, $speed: Int!) {
        createAlertSpeed(alertId: $alertId, speed: $speed) {
          id
        }
      }
    `,
    createAlertUser: gql`
      mutation createAlertUser($alertId: Int!, $userId: Int!) {
        createAlertUser(alertId: $alertId, userId: $userId) {
          id
        }
      }
    `,
    createAlertOffhour: gql`
      mutation createAlertOffhour(
        $alertId: Int!
        $locationIds: [Int!]
        $days: [Int!]
        $startHour: Int
        $startMinute: Int
        $endHour: Int
        $endMinute: Int
      ) {
        createAlertOffhour(
          alertId: $alertId
          locationIds: $locationIds
          days: $days
          startHour: $startHour
          startMinute: $startMinute
          endHour: $endHour
          endMinute: $endMinute
        ) {
          id
        }
      }
    `,
    deleteAlert: gql`
      mutation deleteAlert($id: Int!) {
        deleteAlert(id: $id)
      }
    `,
  },
};
