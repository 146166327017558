import React, { useState, useEffect } from "react";
import { Map, Marker, Popup, Circle } from "react-leaflet";
import {
  Form,
  //FormGroup,
  Panel,
  Toggle,
  Checkbox,
  CheckPicker,
  InputGroup,
  //Icon,
  Button,
  Grid,
  Row,
  Col,
} from "rsuite";

import Icon from "../../components/Icon";
import moment from "moment";
import { useQuery } from "@apollo/react-hooks";
import GQL from "./graphql";
import { useApp } from "../../AppStore";
import SaveCancel from "../../components/savecancel";
import AccountTileLayer from "../../components/accounttilelayer";

import L from "leaflet";
import Control from "@skyeer/react-leaflet-custom-control";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

let lang = null;
const { Group: FormGroup } = Form;

export default function (props) {
  const isMobile = window.innerWidth < 1024;
  const [mapRef, setMapRef] = useState(null);
  const [
    {
      user,
      selectedDevice: globalSelectedDevice,
      selectedLocation: globalSelectedLocation,
      cols,
    },
    actions,
  ] = useApp();
  const widgetWidth =
    (window.innerWidth / cols) * props.widget.dataGrid.w -
    20 * props.widget.dataGrid.w -
    20;

  useEffect(() => {
    /** Language File */
    lang =
      user && user.language
        ? require("../../languages/" + user.language + ".js").default
        : lang;
  }, [user]);

  const [bounds, setBounds] = useState([
    [49.3457868, -124.7844079],
    [24.7433195, -66.9513812],
  ]);
  /*
  const [synced, setSynced] = useState(
    props.widget.config && props.widget.config.synced
      ? props.widget.config.synced
      : false
  );
  */

  const [selectedDevice, setSelectedDevice] = useState(globalSelectedDevice);
  const [selectedDevices, setSelectedDevices] = useState(
    props.widget.config && props.widget.config.selectedDevices
      ? props.widget.config.selectedDevices
      : []
  );
  const [showAllDevices, setShowAllDevices] = useState(
    props.widget.config && props.widget.config.showAllDevices
      ? props.widget.config.showAllDevices
      : false
  );
  const [disableSelectDevice, setDisableSelectDevice] = useState(
    props.widget.config && props.widget.config.showAllDevices ? true : false
  );

  const [selectedLocation, setSelectedLocation] = useState(
    globalSelectedLocation
  );

  const { data: devices } = useQuery(GQL.Queries.devices);
  const {
    data: lastEvents,
    startPolling,
    stopPolling,
    refetch: refetchLastEvents,
  } = useQuery(GQL.Queries.devicesWithLastEvent, {
    pollInterval:
      props.widget.config && props.widget.config.autoRefresh ? 30000 : 0,
    variables:
      selectedDevice && showAllDevices
        ? { ids: [selectedDevice.id] }
        : selectedDevices && selectedDevices.length > 0
        ? { ids: selectedDevices }
        : selectedLocation
        ? { ids: [] }
        : showAllDevices
        ? {}
        : { ids: [] },
  });

  const [selectedLocations, setSelectedLocations] = useState(
    props.widget.config && props.widget.config.selectedLocations
      ? props.widget.config.selectedLocations
      : []
  );
  const [showAllLocations, setShowAllLocations] = useState(
    props.widget.config && props.widget.config.showAllLocations
      ? props.widget.config.showAllLocations
      : false
  );
  const [disableSelectLocation, setDisableSelectLocation] = useState(
    props.widget.config && props.widget.config.showAllLocations ? true : false
  );

  const { data: allLocations } = useQuery(GQL.Queries.locations);
  const { data: locations, refetch: refetchLocations } = useQuery(
    GQL.Queries.locations,
    {
      pollInterval:
        props.widget.config && props.widget.config.autoRefresh ? 30000 : 0,
      variables:
        selectedLocation && showAllLocations
          ? { ids: [selectedLocation.id] }
          : selectedLocations && selectedLocations.length > 0
          ? { ids: selectedLocations }
          : selectedDevice
          ? { ids: [] }
          : showAllLocations
          ? {}
          : { ids: [] },
      /*
    variables: {
      ids:
        selectedLocation && synced
          ? [selectedLocation.id]
          : props.widget.config &&
            props.widget.config.selectedLocations &&
            (!selectedDevice || !synced)
            ? props.widget.config.selectedLocations
            : []
    }
    */
    }
  );

  const [autoRefresh, setAutoRefresh] = useState(
    props.widget.config && props.widget.config.autoRefresh
      ? props.widget.config.autoRefresh
      : false
  );
  const saveAndReload = () => {
    const config = JSON.stringify({
      selectedDevices,
      selectedLocations,
      autoRefresh,
      //synced,
      showAllDevices,
      showAllLocations,
    });
    props.updateWidget({
      variables: {
        id: props.widget.id,
        config,
      },
    });
    props.collapse && props.collapse();

    if (autoRefresh) {
      startPolling(30000);
    } else {
      stopPolling();
    }

    refetchLastEvents();
    refetchLocations();
  };

  const validBounds = (bounds) => {
    //console.log("Valid Bounds Check:", bounds);

    return bounds &&
      bounds.length > 1 &&
      bounds[0] &&
      bounds[0].length > 1 &&
      !isNaN(bounds[0][0]) &&
      (bounds[0][0] !== Infinity || bounds[0][0] !== -Infinity) &&
      !isNaN(bounds[0][1]) &&
      (bounds[0][1] !== Infinity || bounds[0][1] !== -Infinity) &&
      bounds[1] &&
      bounds[1].length > 1 &&
      !isNaN(bounds[1][0]) &&
      (bounds[1][0] !== Infinity || bounds[1][0] !== -Infinity) &&
      !isNaN(bounds[1][1]) &&
      (bounds[1][1] !== Infinity || bounds[1][1] !== -Infinity)
      ? true
      : false;
  };

  useEffect(() => {
    //L && L.map && L.map.invalidateSize && L.map.invalidateSize();
    mapRef && mapRef.leafletElement && mapRef.leafletElement.invalidateSize();
  });

  useEffect(() => {
    let events = [];
    let locs = [];

    if (lastEvents && lastEvents.devices) {
      events = lastEvents.devices.map((device) => {
        const lastEvent = JSON.parse(device.lastEvent);

        return (
          lastEvent &&
          lastEvent.event_data &&
          lastEvent.event_data.latitude &&
          lastEvent.event_data.longitude && [
            lastEvent.event_data.latitude,
            lastEvent.event_data.longitude,
          ]
        );
      });

      events = events.filter((e) => e && e[0] != null && e[1] != null);
    }

    if (locations && locations.locations) {
      locs = locations.locations.map((l) => [...l.coordinates[0], l.radius]);
    }

    const coordinates = [...events, ...locs];

    let minLat = 90;
    let maxLat = -90;
    let minLon = 80;
    let maxLon = -180;

    for (let i = 0; i < coordinates.length; i++) {
      const c = coordinates[i];

      if (c && c.length > 1) {
        const [latitude, longitude] = c;

        if (latitude < minLat) {
          minLat = c.length > 2 ? latitude - c[2] * 0.00001 : latitude;
        }
        if (latitude > maxLat) {
          maxLat = c.length > 2 ? latitude + c[2] * 0.00001 : latitude;
        }
        if (longitude < minLon) {
          minLon = c.length > 2 ? longitude - c[2] * 0.00001 : longitude;
        }
        if (longitude > maxLon) {
          maxLon = c.length > 2 ? longitude + c[2] * 0.00001 : longitude;
        }

        setBounds([
          [minLat - 0.001, minLon - 0.001],
          [maxLat + 0.001, maxLon + 0.001],
        ]);
      }
    }
  }, [lastEvents, locations]);

  useEffect(() => {
    setSelectedDevice(globalSelectedDevice);
  }, [globalSelectedDevice]);

  useEffect(() => {
    setSelectedLocation(globalSelectedLocation);
  }, [globalSelectedLocation]);

  const degreesToCardinal = (degrees) => {
    if (degrees > 337.5 && degrees < 22.5) {
      return "N";
    } else if (degrees >= 22.5 && degrees < 67.5) {
      return "NE";
    } else if (degrees >= 67.5 && degrees < 112.5) {
      return "E";
    } else if (degrees >= 112.5 && degrees < 157.5) {
      return "SE";
    } else if (degrees >= 157.5 && degrees < 202.5) {
      return "S";
    } else if (degrees >= 202.5 && degrees < 247.5) {
      return "SW";
    } else if (degrees >= 247.5 && degrees < 292.5) {
      return "W";
    } else {
      return "NW";
    }
  };

  return (
    <div>
      <Panel
        expanded={props.expanded}
        collapsible
        bodyFill
        className="widget-config"
        style={{ borderRadius: 0 }}
      >
        <Form
          style={{
            width: "100%",
            padding: "5px",
            paddingTop: "15px",
            paddingBottom: "15px",
          }}
        >
          <FormGroup style={{ width: "100%" }}>
            <InputGroup style={{ width: "100%" }}>
              <InputGroup.Addon style={{ width: "100%" }}>
                <Icon icon="faLocationDot" width={15} />
                <InputGroup.Addon
                  style={{
                    padding: "0px 5px 0px 5px",
                    margin: 0,
                  }}
                >
                  <Checkbox
                    checked={showAllDevices}
                    onChange={(value, checked) => {
                      setSelectedDevices([]);
                      if (checked) {
                        setShowAllDevices(true);
                        setDisableSelectDevice(true);
                      } else {
                        setShowAllDevices(false);
                        setDisableSelectDevice(false);
                      }
                    }}
                  >
                    Show All Devices
                  </Checkbox>
                </InputGroup.Addon>
                <CheckPicker
                  style={{
                    width: "100%",
                  }}
                  disabled={disableSelectDevice}
                  value={selectedDevices}
                  onChange={(value, checked) => {
                    console.log({ value, checked });
                    setSelectedDevices(value);
                    setShowAllDevices(false);
                  }}
                  placeholder={
                    lang !== null
                      ? lang.selectDevicesPlaceholder
                      : "Select devices"
                  }
                  data={
                    devices && devices.devices
                      ? devices.devices.map((device) => ({
                          value: device.id,
                          label: device.name,
                        }))
                      : []
                  }
                />
              </InputGroup.Addon>
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup style={{ width: "100%" }}>
              <InputGroup.Addon style={{ width: "100%" }}>
                <Icon icon="faShapes" width={15} />
                <InputGroup.Addon
                  style={{
                    padding: "0px 5px 0px 5px",
                    margin: 0,
                  }}
                >
                  <Checkbox
                    checked={showAllLocations}
                    onChange={(value, checked) => {
                      setSelectedLocations([]);
                      if (checked) {
                        setShowAllLocations(true);
                        setDisableSelectLocation(true);
                      } else {
                        setShowAllLocations(false);
                        setDisableSelectLocation(false);
                      }
                    }}
                  >
                    Show All Locations
                  </Checkbox>
                </InputGroup.Addon>
                <CheckPicker
                  size="sm"
                  style={{
                    width: "100%",
                  }}
                  disabled={disableSelectLocation}
                  value={selectedLocations}
                  onChange={(value, checked) => {
                    console.log({ value, checked });
                    setSelectedLocations(value);
                    setShowAllLocations(false);
                  }}
                  placeholder={
                    lang !== null
                      ? lang.selectLocationsPlaceholder
                      : "Select locations"
                  }
                  data={
                    allLocations && allLocations.locations
                      ? allLocations.locations.map((locations) => ({
                          value: locations.id,
                          label: locations.name,
                        }))
                      : []
                  }
                />
              </InputGroup.Addon>
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <Grid style={{ width: "50%" }}>
              <Row>
                <Col xs={24}>
                  <Toggle
                    checked={autoRefresh}
                    onChange={setAutoRefresh}
                    checkedChildren={
                      <span style={{ color: "white", fontWeight: "bold" }}>
                        {lang !== null ? lang.live : "Live"}
                      </span>
                    }
                    unCheckedChildren={lang !== null ? lang.static : "Static"}
                    //className="inside-shadow"
                  />
                </Col>
                {/*
                <Col xs={12}>
                  <Toggle
                    checked={synced}
                    onChange={setSynced}
                    checkedChildren={
                      <span style={{ color: "white", fontWeight: "bold" }}>
                        {lang !== null ? lang.synced : "Synced"}
                      </span>
                    }
                    unCheckedChildren={
                      lang !== null ? lang.unsynced : "Unsynced"
                    }
                    className="inside-shadow"
                  />
                </Col>
                  */}
              </Row>
            </Grid>
          </FormGroup>
          <FormGroup>
            <SaveCancel
              onSave={() => {
                saveAndReload();
              }}
              onCancel={() => {
                props.collapse && props.collapse();
              }}
            />
          </FormGroup>
        </Form>
      </Panel>
      <Map
        ref={(ref) => setMapRef(ref)}
        bounds={
          validBounds(bounds)
            ? bounds
            : [
                [49.3457868, -124.7844079],
                [24.7433195, -66.9513812],
              ]
        }
        style={{
          height: isMobile
            ? window.innerHeight - 150
            : (props.baseHeight + 10) * props.h - 55 + "px",
          borderTop: "2px solid #f0f0f0",
        }}
        attributionControl={false}
        animate={true}
        dragging={!isMobile}
      >
        <AccountTileLayer />
        {/* MAPBOX
        <TileLayer
          url={
            "https://api.mapbox.com/styles/v1/mapbox/streets-v9/tiles/{z}/{x}/{y}?access_token=" +
            config.MAPBOX_TOKEN
          }
        />
        */}
        {/* OSM
        <TileLayer attribution="" url="//{s}.tile.osm.org/{z}/{x}/{y}.png" />
         */}
        {((selectedDevice && showAllDevices) ||
          (selectedLocation && showAllLocations)) && (
          <Control position="topright">
            <Button
              appearance="primary"
              color="blue"
              onClick={() => {
                actions.setSelectedDevice(null);
                actions.setSelectedLocation(null);
              }}
              className="shadow"
            >
              Show All
            </Button>
          </Control>
        )}
        {locations &&
          locations.locations &&
          locations.locations.length > 0 &&
          locations.locations.map((location, index) => (
            <Circle
              key={"location-" + index}
              center={location.coordinates[0]}
              radius={location.radius}
            />
          ))}
        {lastEvents &&
          lastEvents.devices &&
          lastEvents.devices.map((device) => {
            if (device.lastEvent) {
              const lastEvent = JSON.parse(device.lastEvent);
              //console.log({ lastEvent });
              if (
                lastEvent.event_data &&
                lastEvent.event_data.latitude &&
                lastEvent.event_data.longitude
              ) {
                const position = [
                  lastEvent.event_data.latitude,
                  lastEvent.event_data.longitude,
                ];
                const secondsAgo = Math.abs(
                  moment
                    .unix(lastEvent.event_data.device_time)
                    .diff(moment(), "seconds")
                );
                const deviceIcon = new L.Icon({
                  iconUrl: device.icon ? device.icon : "images/device1.png",
                  iconRetinaUrl: device.icon
                    ? device.icon
                    : "images/device1.png",
                  iconAnchor: new L.Point(16, 16),
                  popupAnchor: new L.Point(0, -8),
                  shadowUrl: "images/deviceshadow.png",
                  shadowSize: new L.Point(32, 32),
                  shadowAnchor: new L.point(16, 16),
                  iconSize: new L.Point(32, 32),
                });

                const timeString =
                  moment.unix(lastEvent.event_data.device_time).fromNow(true) +
                  " " +
                  (lang !== null ? lang.ago : "Ago");
                //console.log({ timeString });

                return (
                  <Marker
                    key={"marker-" + device.id}
                    position={position}
                    icon={deviceIcon}
                  >
                    <Popup>
                      <p>
                        <span>
                          <Icon
                            icon="faUser"
                            style={{
                              color:
                                user &&
                                user.account &&
                                user.account.primaryColor
                                  ? user.account.primaryColor
                                  : "skyblue",
                            }}
                          />{" "}
                          {device.name}
                        </span>
                      </p>
                      {/*
                      <p>
                        <span>
                          <Icon
                            icon="tag"
                            style={{
                              color:
                                user &&
                                user.account &&
                                user.account.primaryColor
                                  ? user.account.primaryColor
                                  : "skyblue"
                            }}
                          />{" "}
                          {device.serial}
                        </span>
                      </p>
                          */}
                      {widgetWidth > 300 && (
                        <>
                          <p>
                            <span>
                              <Icon
                                icon="faClock"
                                style={{
                                  color:
                                    user &&
                                    user.account &&
                                    user.account.primaryColor
                                      ? user.account.primaryColor
                                      : "skyblue",
                                }}
                              />{" "}
                              {lang !== null ? lang.reported : "Reported"}{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                  color:
                                    "#" +
                                    lastEvent.event_data.conduit_color_code,
                                }}
                              >
                                {lastEvent.event_data.conduit_event_description}
                              </span>{" "}
                              {timeString}
                            </span>
                          </p>
                          <p>
                            <span>
                              <Icon
                                icon="faCalendarDays"
                                style={{
                                  color:
                                    user &&
                                    user.account &&
                                    user.account.primaryColor
                                      ? user.account.primaryColor
                                      : "skyblue",
                                }}
                              />{" "}
                              {moment
                                .unix(lastEvent.event_data.device_time)
                                .format("ddd, MMMM Do, YYYY")}
                            </span>
                          </p>
                          <p>
                            <span>
                              <Icon
                                icon="faSignsPost"
                                style={{
                                  color:
                                    user &&
                                    user.account &&
                                    user.account.primaryColor
                                      ? user.account.primaryColor
                                      : "skyblue",
                                }}
                              />{" "}
                              {lastEvent.event_data.speed
                                ? parseInt(lastEvent.event_data.speed) +
                                  " " +
                                  (lang !== null ? lang.mph : "Mph")
                                : lang !== null
                                ? lang.nA
                                : "N/A"}{" "}
                              <Icon
                                icon="faCompass"
                                style={{
                                  color:
                                    user &&
                                    user.account &&
                                    user.account.primaryColor
                                      ? user.account.primaryColor
                                      : "skyblue",
                                }}
                              />{" "}
                              {degreesToCardinal(lastEvent.event_data.heading)}
                            </span>
                          </p>
                          <p>
                            <span>
                              <Icon
                                icon="faHome"
                                style={{
                                  color:
                                    user &&
                                    user.account &&
                                    user.account.primaryColor
                                      ? user.account.primaryColor
                                      : "skyblue",
                                }}
                              />{" "}
                              {lastEvent.event_data.address
                                ? lastEvent.event_data.address
                                : lang !== null
                                ? lang.nA
                                : "N/A"}
                            </span>
                          </p>
                        </>
                      )}
                    </Popup>
                  </Marker>
                );
              }
            }
            return null;
          })}
      </Map>
    </div>
  );
}
