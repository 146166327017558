import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import {
  Form,
  //FormGroup,
  Panel,
  SelectPicker,
  InputGroup,
  //Icon,
  Message,
  Loader,
  Progress,
} from "rsuite";

import Icon from "../../components/Icon";
//import { VictoryPie } from "victory";

import { useApp } from "../../AppStore";
import GQL from "./graphql";
import styles from "./styles";
import SaveCancel from "../../components/savecancel";
import moment from "moment";

const { Group: FormGroup } = Form;

let lang = null;

export default function (props) {
  const isMobile = window.innerWidth < 1024;
  const [{ user, minH, system, rowHeight }] = useApp();
  /*
  const widgetWidth =
    (window.innerWidth / cols) * props.widget.dataGrid.w -
    10 * props.widget.dataGrid.w;*/

  useEffect(() => {
    /** Language File */
    lang =
      user && user.language
        ? require("../../languages/" + user.language + ".js").default
        : lang;
  }, [user]);

  // Count queries
  const [
    getDevicesReportedTodayCount,
    { data: devicesReportedTodayQuery, called: devicesReportedTodayRequested },
  ] = useLazyQuery(GQL.Queries.reportedDevicesCount);
  const [
    getDeviceCount,
    { data: deviceCountQuery, called: deviceCountRequested },
  ] = useLazyQuery(GQL.Queries.deviceCount);
  const [getUserCount, { data: userCountQuery, called: userCountRequested }] =
    useLazyQuery(GQL.Queries.userCount, {
      onCompleted: (res) => {
        //console.info("getUserCount done:", res);
      },
      onError: (err) => {
        console.error("getUserCount error:", err);
      },
    });
  const [
    getAccountCount,
    { data: accountCountQuery, called: accountCountRequested },
  ] = useLazyQuery(GQL.Queries.accountCount);

  const [statType, setStatType] = useState(
    props.widget.config && props.widget.config.statType
      ? props.widget.config.statType
      : null
  );
  const [start] = useState(
    props.widget.config && props.widget.config.start
      ? props.widget.config.start
      : null
  );

  const setConfigAndSave = () => {
    let configObject = { statType };

    if (statType === "devicesReportedSince") {
      configObject.start = start;
    }

    const config = JSON.stringify({ statType });
    props.updateWidget({
      variables: {
        id: props.widget.id,
        config,
      },
    });
  };

  const renderContent = () => {
    let count = (
      <Loader content={lang !== null ? lang.loading : "Loading..."} />
    );
    let label = "";

    if (props.widget && props.widget.config && props.widget.config.statType) {
      const { statType } = props.widget.config;

      if (statType === "accounts" && system && system === "sm") {
        !accountCountRequested && getAccountCount();
        if (accountCountQuery && accountCountQuery.accountCount !== null) {
          count = accountCountQuery.accountCount;
          label = <span>Accounts</span>;
        }
      } else if (statType === "devices") {
        !deviceCountRequested && getDeviceCount();
        if (deviceCountQuery && deviceCountQuery.deviceCount !== null) {
          count = deviceCountQuery.deviceCount;
          label = <span>Devices</span>;
        }
      } else if (statType === "users") {
        !userCountRequested && getUserCount();
        if (userCountQuery && userCountQuery.userCount !== null) {
          count = userCountQuery.userCount;
          label = <span>Users</span>;
        }
      } else if (statType === "devicesReportedToday") {
        !devicesReportedTodayQuery &&
          !devicesReportedTodayRequested &&
          getDevicesReportedTodayCount({
            variables: {
              start: moment()
                .startOf("day")
                .utc()
                .format("YYYY-MM-DD HH:mm:ss"),
            },
          });
        !deviceCountRequested && getDeviceCount();
        const reportedCount =
          devicesReportedTodayQuery &&
          devicesReportedTodayQuery.reportedDevicesCount
            ? devicesReportedTodayQuery.reportedDevicesCount
            : 0;
        const deviceCount =
          deviceCountQuery && deviceCountQuery.deviceCount
            ? deviceCountQuery.deviceCount
            : 0;
        /*
        let size = parseInt(rowHeight * 0.55);
        if (widgetWidth < rowHeight) {
          size = parseInt(widgetWidth * 0.55);
        }*/

        //console.log({size});

        count = (
          <div>
            <div>
              {reportedCount}
              {"/"}
              {deviceCount}
            </div>
            <Progress.Line
              percent={parseInt((reportedCount / deviceCount) * 100)}
              showInfo={false}
            />
          </div>
        );

        label = (
          <div
            style={{
              position: "relative",
              top:
                "-" +
                parseInt(rowHeight * 0.01 * (props.widget.dataGrid.h / minH)) +
                "pt",
              color:
                user && user.account && user.account.secondaryColor
                  ? user.account.secondaryColor
                  : "#777777",
              fontSize:
                (isMobile
                  ? 32
                  : (20 * (props.widget.dataGrid.h / minH) * rowHeight) / 300) +
                "pt",
            }}
          >
            {lang !== null
              ? lang.devicesReportedTodayPlaceholder
              : "Reported Today"}
          </div>
        );
      }
    } else {
      console.error("Invalid config");
      return (
        <Message
          type="error"
          title={
            lang !== null ? lang.invalidConfigPlaceholder : "Invalid config"
          }
          description={
            lang !== null
              ? lang.contactAdminPlaceholder
              : "Please contact the administrator"
          }
        />
      );
    }
    //console.log({ isMobile });
    //console.log(window.innerWidth);

    return (
      <div>
        <div
          style={{
            ...styles.stat,
            color:
              user &&
              user.account &&
              user.account.primaryColor &&
              user.account.primaryColor,
            fontSize:
              (isMobile
                ? 120
                : 60 * (((props.widget.dataGrid.h / minH) * rowHeight) / 300)) +
              "pt",
          }}
        >
          {count}
        </div>
        <div
          style={{
            ...styles.label,
            color:
              user &&
              user.account &&
              user.account.secondaryColor &&
              user.account.secondaryColor,
            fontSize:
              (isMobile
                ? 32
                : (24 * (props.widget.dataGrid.h / minH) * rowHeight) / 300) +
              "pt",
            fontWeight: "bold",
            position: "relative",
            //top: "-" + rowHeight / 10 + "pt"
          }}
        >
          {label}
        </div>
      </div>
    );
  };

  //console.log({ devicesReportedTodayQuery });

  return (
    <div>
      <Panel
        expanded={props.expanded || props.widget.config == null}
        collapsible
        bodyFill
        className="widget-config"
        style={{ borderRadius: 0 }}
      >
        <Form
          style={{ padding: "5px", paddingTop: "15px", paddingBottom: "15px" }}
        >
          <FormGroup>
            <SelectPicker
              size="sm"
              value={statType}
              onChange={setStatType}
              placeholder={
                lang !== null ? lang.selectStatPlaceholder : "Select a stat"
              }
              searchable={false}
              cleanable={false}
              style={{ width: "100%", fontSize: 7 }}
              data={[
                ...(system && system === "sm"
                  ? [
                      {
                        label: lang !== null ? lang.accounts : "Accounts",
                        value: "accounts",
                      },
                    ]
                  : []),
                {
                  label: lang !== null ? lang.devices : "Devices",
                  value: "devices",
                },
                {
                  label: lang !== null ? lang.users : "Users",
                  value: "users",
                },
                {
                  label:
                    lang !== null
                      ? lang.devicesReportedTodayPlaceholder
                      : "Reported Today",
                  value: "devicesReportedToday",
                },
              ]}
            />
          </FormGroup>
          <FormGroup>
            <SaveCancel
              saveDisabled={!statType || !statType.length > 0}
              onSave={() => {
                setConfigAndSave(statType);
                props.collapse && props.collapse();
              }}
              onCancel={() => props.collapse && props.collapse()}
              size="sm"
            />
          </FormGroup>
        </Form>
      </Panel>
      {props.widget.config === null ? (
        <Message
          type="info"
          title={
            lang !== null
              ? lang.configureWidgetPlaceholder
              : "Widget needs to be configured"
          }
        />
      ) : (
        renderContent()
      )}
    </div>
  );
}
