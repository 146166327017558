import React from "react";
import { Avatar, Grid, Row, Col } from "rsuite";

import Icon from "../../components/Icon";

export default (props) => {
  const icons = [
    "faDashboard",
    "faHome",
    "faFileText",
    "faTablet",
    "faGlobe",
    "faMap",
    "faBarChart",
    "faSitemap",
    "faMapMarkerAlt",
    "faLocationArrow",
    "faMapSigns",
    //"user-analysis",
    "faChartGantt",
    //"user-circle",
    //"user-secret",
  ];

  return (
    <Grid fluid>
      {[0, 1, 2, 3].map((row) => (
        <Row key={"siprow-" + row}>
          {icons.slice(row * 4, row * 4 + 4).map((icon, index) => {
            return (
              <Col
                xs={6}
                key={"sipcol-" + row + "-" + index}
                style={{ padding: "5px" }}
              >
                <Avatar
                  circle
                  key={"sipavatar-" + row + "-" + index}
                  onClick={() => {
                    props.onClick && props.onClick(icon);
                  }}
                  size="sm"
                  className="small-shadow"
                  style={{ cursor: "pointer" }}
                >
                  <Icon icon={icon} />
                </Avatar>
              </Col>
            );
          })}
        </Row>
      ))}
    </Grid>
  );
};
