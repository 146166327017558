import React, { useState, useEffect } from "react";

import L from "leaflet";
import { Map, Marker } from "react-leaflet";

import AccountTileLayer from "../../components/accounttilelayer";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

export default props => {
  const { rowData, width, height, editable, icon } = props;
  const [mapRef, setMapRef] = useState(null);

  const lastEvent =
    rowData && rowData.lastEvent ? JSON.parse(rowData.lastEvent) : null;
  const coordinates =
    lastEvent &&
    lastEvent.event_data &&
    lastEvent.event_data.latitude &&
    lastEvent.event_data.longitude
      ? [lastEvent.event_data.latitude, lastEvent.event_data.longitude]
      : null;

  useEffect(() => {
    lastEvent &&
      lastEvent.event_data &&
      lastEvent.event_data.latitude &&
      lastEvent.event_data.longitude &&
      mapRef &&
      mapRef.leafletElement &&
      mapRef.leafletElement.invalidateSize();
  });

  const deviceIcon = new L.Icon({
    iconUrl: icon ? icon : "images/device1.png",
    iconRetinaUrl: icon ? icon : "images/device1.png",
    iconAnchor: new L.Point(16, 16),
    popupAnchor: null,
    shadowUrl: "images/deviceshadow.png",
    shadowSize: null,
    shadowAnchor: new L.point(16, 16),
    iconSize: new L.Point(32, 32)
  });

  return rowData &&
    lastEvent &&
    coordinates &&
    coordinates.length > 1 &&
    coordinates[0] &&
    coordinates[1] ? (
    <Map
      ref={ref => setMapRef(ref)}
      className="small-shadow"
      zoomControl={editable ? editable : false}
      doubleClickZoom={editable ? editable : false}
      scrollWheelZoom={editable ? editable : false}
      dragging={editable ? editable : false}
      style={{
        width,
        height,
        borderRadius: "5px",
        ...props.style
      }}
      bounds={[
        [coordinates[0] - 0.0001, coordinates[1] - 0.0001],
        [coordinates[0] + 0.0001, coordinates[1] + 0.0001]
      ]}
      attributionControl={false}
      animated={true}
    >
      <AccountTileLayer />
      {/*<TileLayer url="//{s}.tile.osm.org/{z}/{x}/{y}.png" />*/}
      <Marker position={coordinates} icon={deviceIcon} />
    </Map>
  ) : (
    <div
      style={{
        width,
        height,
        borderRadius: "5px",
        backgroundColor: "#eeeeee",
        position: "relative",
        ...props.style
      }}
      className="small-shadow"
    >
      <img
        src={rowData.icon ? rowData.icon : "images/device1.png"}
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          margin: "auto"
        }}
        alt="device"
      />
    </div>
  );
};
