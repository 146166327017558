import React, { useState, useEffect } from "react";
import {
  Avatar,
  Form,
  //FormGroup,
  InputGroup,
  Input,
  Button,
  //Icon,
  Grid,
  Row,
  Col,
  ButtonGroup,
  Popover,
  Whisper,
  Dropdown,
  toaster,
  Message,
} from "rsuite";

import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import Icon from "../../components/Icon";
import GQL from "./graphql";
import { useApp } from "../../AppStore";
import Map from "./map";

let lang = null;
const { Group: FormGroup } = Form;

export default (props) => {
  const { selectedLocation } = props;
  const [{ user }] = useApp();
  const [error, setError] = useState("");

  useEffect(() => {
    /** Language File */
    lang =
      user && user.language
        ? require("../../languages/" + user.language + ".js").default
        : lang;
  }, [user]);

  const [createLocation] = useMutation(GQL.Mutations.createLocation);
  const [updateLocation] = useMutation(GQL.Mutations.updateLocation);
  const [address, setAddress] = useState(
    selectedLocation && selectedLocation.address ? selectedLocation.address : ""
  );
  const [name, setName] = useState(
    selectedLocation && selectedLocation.name ? selectedLocation.name : ""
  );
  const [type, setType] = useState("circle");

  const [radius, setRadius] = useState(
    selectedLocation && selectedLocation.radius ? selectedLocation.radius : 100
  );
  const [coordinates, setCoordinates] = useState(
    selectedLocation && selectedLocation.coordinates
      ? selectedLocation.coordinates
      : [[33.487007, -117.143784]]
  );
  const [geocode] = useLazyQuery(GQL.Queries.geocode, {
    onCompleted: (res) => {
      //console.log({ res });
      if (res.geocode) {
        setCoordinates([res.geocode]);
      } else {
        toaster.push(
          <Message type="warning" closable>
            Could not locate address.
          </Message>
        );
        setError("Could not locate address.");
      }
    },
  });

  const metersToLabel = (meters) => {
    switch (meters) {
      case 50:
        return lang !== null ? lang.fiftyMeters : "50 Meters";
      case 100:
        return lang !== null ? lang.oneHundredMeters : "100 Meters";
      case 0.5 * 1609.34:
        return lang !== null ? lang.halfMile : "1/2 Mile";
      case 1609.34:
        return lang !== null ? lang.oneMile : "1 Mile";
      case 5 * 1609.34:
        return lang !== null ? lang.fiveMiles : "5 Miles";
      case 10 * 1609.34:
        return lang !== null ? lang.tenMiles : "10 Miles";
      default:
        return meters;
    }
  };

  return (
    <div
      className="shadow"
      style={{
        animation: "0.15s ease-out 0s 1 fadeAndSlideIn",
        borderRadius: "1px",
        position: "absolute",
        padding: 0,
        backgroundColor: "white",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 9999,
        width: "95%",
        height: "100%",
        margin: "auto",
        overflowY: "scroll",
      }}
    >
      <div
        style={{
          padding: "10px",
          color: "white",
          position: "relative",
          width: "100%",
        }}
        className="shadow primary-gradient"
      >
        <div style={{ width: "100%", textAlign: "left" }}>
          <Grid fluid>
            <Row>
              <Col xs={12} style={{ textAlign: "left" }}>
                <big>
                  {lang !== null ? lang.locationDetails : "Location Details"}
                </big>
              </Col>
              <Col xs={12} style={{ textAlign: "right" }}>
                <Avatar
                  size="xs"
                  style={{ backgroundColor: "#D64545" }}
                  className="action-button"
                  onClick={() => {
                    props.close && props.close();
                  }}
                >
                  <Icon icon="faCircleXmark" />
                </Avatar>
              </Col>
            </Row>
          </Grid>
        </div>
        <div
          style={{
            width: "100%",
            margin: "auto",
          }}
        >
          <Map
            style={{ margin: "auto" }}
            rowData={
              selectedLocation
                ? { ...selectedLocation, type, name, radius }
                : { coordinates, type, name, radius }
            }
            width="75%"
            height={125}
            editable={true}
            coordinates={coordinates}
            setCoordinates={setCoordinates}
            type={type}
            name={name}
            radius={radius}
          />
        </div>
        <div>
          <big>
            {name && name.length > 0 ? name : lang !== null ? lang.new : "New"}
          </big>
        </div>
        <div>
          <small>
            {lang !== null
              ? lang.clickMapPlaceholder
              : "Please click map to set location"}
            .
          </small>
        </div>
      </div>
      <div style={{ padding: "15px" }}>
        <Form fluid>
          <FormGroup>
            <InputGroup style={{ width: "100%" }}>
              <InputGroup.Addon>
                <Icon icon="faHome" />
              </InputGroup.Addon>
              <Input
                value={address}
                onChange={setAddress}
                onKeyDown={(e) => {
                  if (
                    (e.charCode === 13 || e.keyCode === 13) &&
                    address &&
                    address.length > 0
                  ) {
                    setError("");
                    geocode({
                      variables: {
                        address,
                      },
                    });
                  }
                }}
              />
              <InputGroup.Button
                color="blue"
                disabled={!address || address.length === 0}
                onClick={() => {
                  if (address && address.length > 0) {
                    setError("");
                    geocode({
                      variables: {
                        address,
                      },
                    });
                  }
                }}
              >
                <Icon icon="faBullseye" />
              </InputGroup.Button>
            </InputGroup>
            {error && error.length > 0 && (
              <Form.HelpText style={{ color: "red", textAlign: "left" }}>
                {error}
              </Form.HelpText>
            )}
          </FormGroup>
          <FormGroup>
            <InputGroup style={{ width: "100%" }}>
              <InputGroup.Addon>
                <Icon icon="faSignsPost" />
              </InputGroup.Addon>
              <Whisper
                disabled={true}
                trigger="click"
                speaker={
                  <Popover>
                    <Dropdown.Menu
                      disabled={true}
                      onSelect={(type) => {
                        setType(type);
                      }}
                    >
                      <Dropdown.Item eventKey="circle">
                        <Icon icon="faCircle" />{" "}
                        {lang !== null ? lang.circle : "Circle"}
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="polygon">
                        <Icon icon="faShapes" />{" "}
                        {lang !== null ? lang.polygon : "Polygon"}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Popover>
                }
                placement="bottom"
              >
                <InputGroup.Button>
                  {type
                    ? type
                    : lang !== null
                    ? lang.selectTypePlaceholder
                    : "Select Type"}
                </InputGroup.Button>
              </Whisper>
              {type && type === "circle" && (
                <>
                  <InputGroup.Addon>
                    <Icon icon="faCircle" />
                  </InputGroup.Addon>
                  <Whisper
                    trigger="click"
                    speaker={
                      <Popover>
                        <Dropdown.Menu
                          onSelect={(radius) => {
                            setRadius(radius);
                          }}
                        >
                          <Dropdown.Item eventKey={50}>
                            {lang !== null ? lang.fiftyMeters : "50 Meters"}
                          </Dropdown.Item>
                          <Dropdown.Item eventKey={100}>
                            {lang !== null
                              ? lang.oneHundredMeters
                              : "100 Meters"}
                          </Dropdown.Item>
                          <Dropdown.Item eventKey={0.5 * 1609.34}>
                            {lang !== null ? lang.halfMile : "1/2 Mile"}
                          </Dropdown.Item>
                          <Dropdown.Item eventKey={1609.34}>
                            {lang !== null ? lang.oneMile : "1 Mile"}
                          </Dropdown.Item>
                          <Dropdown.Item eventKey={5 * 1609.34}>
                            {lang !== null ? lang.fiveMiles : "5 Miles"}
                          </Dropdown.Item>
                          <Dropdown.Item eventKey={10 * 1609.34}>
                            {lang !== null ? lang.tenMiles : "10 Miles"}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Popover>
                    }
                    placement="bottom"
                  >
                    <InputGroup.Button>
                      {radius
                        ? metersToLabel(radius)
                        : lang !== null
                        ? lang.radius
                        : "Radius"}
                    </InputGroup.Button>
                  </Whisper>
                </>
              )}
              <InputGroup.Addon>
                <Icon icon="faTag" width={15} />
              </InputGroup.Addon>
              <Input
                placeholder={lang !== null ? lang.name : "Name"}
                value={name}
                onChange={setName}
                style={{ width: "75%" }}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <ButtonGroup>
              <Button
                appearance="primary"
                //className="small-shadow"
                color="green"
                onClick={() => {
                  if (selectedLocation) {
                    updateLocation({
                      variables: {
                        id: selectedLocation.id,
                        name,
                        type,
                        radius,
                        coordinates,
                      },
                    }).then((res) => {
                      props.close();
                      props.refetch();
                    });
                  } else {
                    //console.log("creating:", coordinates);
                    createLocation({
                      variables: {
                        name,
                        type,
                        radius,
                        coordinates,
                      },
                    }).then((res) => {
                      //console.log("Create User Result:", res);
                      if (
                        res &&
                        res.data &&
                        res.data.createLocation &&
                        res.data.createLocation.id
                      ) {
                        props.close();
                        props.refetch();
                      }
                    });
                  }
                }}
              >
                {selectedLocation ? (
                  <span>
                    <Icon icon="faFloppyDisk" />{" "}
                    {lang !== null ? lang.save : "Save"}
                  </span>
                ) : (
                  <span>
                    <Icon icon="faFloppyDisk" />{" "}
                    {lang !== null ? lang.create : "Create"}
                  </span>
                )}
              </Button>
              <Button
                appearance="primary"
                //className="small-shadow"
                color="orange"
                onClick={() => {
                  props.close && props.close();
                }}
              >
                <Icon icon="faBan" width={15} />{" "}
                {lang !== null ? lang.cancel : "Cancel"}
              </Button>
            </ButtonGroup>
          </FormGroup>
        </Form>
      </div>
    </div>
  );
};
