import React, { useEffect } from "react";
import {
  Form,
  Table,
  Panel,
  //Icon,
  Grid,
  Row,
  Col,
  Loader,
  Container,
} from "rsuite";

import Icon from "../../components/Icon";

import { useQuery, useMutation, useApolloClient } from "@apollo/react-hooks";

import GQL from "./graphql";
import { useApp } from "../../AppStore";

/*
import RolePicker from "./rolepicker";
import ToggleUser from "./toggleuser";
import Actions from "./actions";
import Details from "./details";
import StatusChanger from "./statuschanger";
*/
import AccessDenied from "../../components/accessdenied";
import moment from "moment";

const { Column, HeaderCell, Cell } = Table;

let lang = null;

export default (props) => {
  /** GLOBAL APP STATE */
  const [
    {
      user,
      //screens,
      //selectedScreen,
      selectedAccount: globalSelectedAccount,
      cols,
    },
    actions,
  ] = useApp();

  /** LOCAL COMPONENT STATE */
  //const [showDetailsModal, setShowDetailsModal] = useState(false);
  //const [selectedAccount, setSelectedAccount] = useState(null);
  const widgetWidth =
    (window.innerWidth / cols) * props.widget.dataGrid.w -
    10 * props.widget.dataGrid.w;

  /** GRAPHQL */
  const { data: readAccess, loading: loadingReadAccess } = useQuery(
    GQL.Queries.readAccess
  );
  const { data: writeAccess, loading: loadingWriteAccess } = useQuery(
    GQL.Queries.writeAccess
  );
  const { data: deleteAccess, loading: loadingDeleteAccess } = useQuery(
    GQL.Queries.deleteAccess
  );
  const {
    data: accounts,
    loading: loadingAccounts,
    refetch: reloadAccounts,
  } = useQuery(GQL.Queries.accounts, {
    variables: {
      ...(globalSelectedAccount ? { parentId: globalSelectedAccount.id } : {}),
    },
    fetchPolicy: "network-only",
  });
  const [createWidget] = useMutation(GQL.Mutations.createWidget);
  const apolloClient = useApolloClient();

  useEffect(() => {
    /** Language File */
    lang =
      user && user.language
        ? require("../../languages/" + user.language + ".js").default
        : lang;
  }, [user]);

  const renderContent = () => {
    return loadingAccounts ||
      loadingReadAccess ||
      loadingWriteAccess ||
      loadingDeleteAccess ? (
      <Loader content={lang !== null ? lang.loading : "Loading..."} />
    ) : readAccess.hasAccessToSection === true ? (
      <div
        style={{
          width: "100%",
          position: "relative",
        }}
      >
        <Grid
          fluid
          style={{
            fontSize: "1.1rem",
            fontWeight: "bold",
            width: "100%",
            textAlign: "left",
            padding: "15px",
            paddingBottom: "5px",
            borderBottom: "1px solid #eeeeee",
          }}
        >
          <Row>
            <Col xs={12} style={{ paddingTop: "7px" }}>
              <div>{lang !== null ? lang.leads : "Leads"}</div>

              <div style={{ fontSize: "0.75rem", color: "#aaa" }}>
                <div>
                  {globalSelectedAccount ? (
                    <>
                      {
                        /* Logged in user Account */
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            actions.setSelectedAccount(null);
                          }}
                        >
                          {user.account.name}
                        </span>
                      }
                      {
                        /* Account breadcrumb */
                        globalSelectedAccount.parentBreadcrumb &&
                        globalSelectedAccount.parentBreadcrumb.length > 0
                          ? globalSelectedAccount.parentBreadcrumb.map(
                              (account) => (
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    actions.setSelectedAccount(account);
                                  }}
                                >
                                  {" "}
                                  <Icon icon="faArrowRight" /> {account.name}
                                </span>
                              )
                            )
                          : null
                      }
                      {
                        /* Selected Account */
                        <span>
                          {" "}
                          <Icon icon="faArrowRight" />{" "}
                          {globalSelectedAccount && globalSelectedAccount.name
                            ? globalSelectedAccount.name
                            : "Company"}
                        </span>
                      }
                    </>
                  ) : user && user.account && user.account.name ? (
                    user.account.name
                  ) : lang !== null ? (
                    lang.company
                  ) : (
                    "Company"
                  )}
                </div>
              </div>
            </Col>
            <Col xs={12} style={{ textAlign: "right" }}>
              {/*
              <Button
                className="small-shadow"
                onClick={() => {
                  setSelectedAccount(null);
                  setShowDetailsModal(!showDetailsModal);
                }}
              >
                {showDetailsModal ? (
                  <span>
                    <Icon icon="faBan" width={15} /> {lang !== null ? lang.cancel : "Cancel"}
                  </span>
                ) : (
                  <span>
                    <Icon icon="faSquarePlus" />{" "}
                    {lang !== null ? lang.newAccount : "New Lead"}
                  </span>
                )}
              </Button>
								*/}
            </Col>
          </Row>
        </Grid>
        <Table
          virtualized={true}
          height={(props.baseHeight + 10) * props.h - 125}
          data={accounts.accounts}
          affixHeader={true}
          {...(widgetWidth > 500 && { rowHeight: 125 })}
          style={{
            borderTop: "2px solid #fafafa",
            borderBottom: "1px dashed #fafafa",
            //filter: showDetailsModal && "blur(2px)",
            //pointerEvents: showDetailsModal && "none",
          }}
          loading={loadingAccounts}
          onRowClick={(rowData) => {
            //setSelectedUser(rowData);
            //setShowDetailsModal(true);
          }}
        >
          <Column
            //width={Math.max((widgetWidth / 12) * 2, 150)}
            flexGrow={1}
          >
            <HeaderCell style={{ textAlign: "left" }}>
              {lang !== null ? lang.lead : "Lead"}
            </HeaderCell>
            <Cell style={{ textAlign: "left" }}>
              {(rowData) => {
                return rowData.name;
              }}
            </Cell>
          </Column>
          {widgetWidth > 500 && (
            <Column
              //width={Math.max((widgetWidth / 12) * 8, 150)}
              flexGrow={2}
            >
              <HeaderCell style={{ textAlign: "left" }}>
                <div style={{ textAlign: "left" }}>
                  {lang !== null ? lang.notes : "Notes"}
                </div>
              </HeaderCell>
              <Cell style={{ textAlign: "left" }}>
                {(rowData) => {
                  const notesArray = rowData.notes.split("\n");
                  return (
                    <Container style={{ textAlign: "left", fontSize: "14px" }}>
                      {notesArray.map((note) => (
                        <div>{note}</div>
                      ))}
                    </Container>
                  );
                  return rowData.notes.replaceAll("\n", ", ");
                }}
              </Cell>
            </Column>
          )}
          {/*
          <Column width={300}>
            <HeaderCell style={{ textAlign: "left" }}>Roles</HeaderCell>
            <Cell style={{ position: "relative" }}>
              {rowData => <RolePicker rowData={rowData} />}
            </Cell>
          </Column>
          <Column width={75}>
            <HeaderCell>Active</HeaderCell>
            <Cell style={{ position: "relative" }}>
              {rowData => (
                <ToggleUser active={rowData.active} id={rowData.id} />
              )}
            </Cell>
          </Column>
              */}
          {widgetWidth > 200 && (
            <Column
              //width={Math.max((widgetWidth / 12) * 2, 150)}
              flexGrow={1}
            >
              <HeaderCell>{lang !== null ? lang.age : "Age"}</HeaderCell>
              <Cell style={{ position: "relative" }}>
                {(rowData) =>
                  moment.unix(parseInt(rowData.createdAt / 1000)).fromNow()
                }
              </Cell>
            </Column>
          )}
        </Table>
        {/*showDetailsModal && (
          <Details
            selectedAccount={selectedAccount}
            refetch={reloadAccounts}
            close={() => {
              setShowDetailsModal(false);
            }}
          />
					)*/}
      </div>
    ) : (
      <AccessDenied section="ACCOUNTS" />
    );
  };

  //console.log({ widgetWidth });

  return (
    <div>
      <Panel
        expanded={props.expanded}
        collapsible
        bodyFill
        className="widget-config"
        style={{ borderRadius: 0 }}
      >
        <Form
          style={{ padding: "5px", paddingTop: "15px", paddingBottom: "15px" }}
        ></Form>
      </Panel>
      {renderContent()}
    </div>
  );
};
