import React, { useEffect } from "react";
import { Loader } from "rsuite";
import Cookie from "js-cookie";

export default (props) => {
  const {
    match: {
      params: { token },
    },
  } = props;

  useEffect(() => {
    Cookie.set("atles-geofleetos-token", token);
    Cookie.set("atles-geofleetos-impersonationstate", "true");
    window.location = "/";
  }, [token]);

  return <Loader />;
};
