import gql from "graphql-tag";

export default {
  Queries: {
    widget: gql`
      query widget($id: Int!) {
        widget(id: $id) {
          id
          title
          type
          config
          dataGrid
        }
      }
    `,
    user: gql`
      {
        user {
          id
          email
          language
          account {
            id
            name
            smAccess
            mapSystemId
            mapSystemApiKey
            screens {
              id
              title
              widgets {
                id
                title
                icon
                type
                config
              }
            }
          }
        }
      }
    `
  },
  Mutations: {
    updateWidget: gql`
      mutation updateWidget($id: Int!, $config: String) {
        updateWidget(id: $id, config: $config)
      }
    `,
    deleteWidget: gql`
      mutation deleteWidget($id: Int!) {
        deleteWidget(id: $id)
      }
    `
  }
};
