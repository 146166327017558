import React, { useState, useEffect } from "react";
import {
  Avatar,
  Button,
  Modal,
  Form,
  //FormGroup,
  //Icon,
  Grid,
  Row,
  Col,
  Header,
  Nav,
  Navbar,
  Dropdown,
  Panel,
  InputGroup,
  Input,
  Placeholder,
} from "rsuite";

import Icon from "../../components/Icon";
import ReactGridLayout from "react-grid-layout";
import { useApolloClient, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { useApp } from "../../AppStore";
import TemplateScreen from "./templatescreen";
import SaveCancel from "../../components/savecancel";

import GQL from "./graphql";

let lang = null;
const icons = [
  "faDashboard",
  "faHome",
  "faFileText",
  "faTablet",
  "faGlobe",
  "faMap",
  "faBarChart",
  "faSitemap",
  "faMapMarkerAlt",
  "faLocationArrow",
  "faMapSigns",
  //"user-analysis",
  "faChartGantt",
  //"user-circle",
  //"user-secret",
];
const { Group: FormGroup } = Form;

export default (props) => {
  const { show, account, widgetTypes, loadingWidgetTypes, refetchAccount } =
    props;
  const [{ user, screens: myScreens, cols, rows }] = useApp();

  //const [language, setLanguage] = useState("");
  const [primaryColor, setPrimaryColor] = useState("skyblue");
  const [screens, setScreens] = useState([]);
  const [newScreenName, setNewScreenName] = useState("");
  const [newScreenIcon, setNewScreenIcon] = useState("");
  const [selectedScreen, setSelectedScreen] = useState(0);

  const apolloClient = useApolloClient();
  const [createTemplateScreen] = useMutation(
    GQL.Mutations.createTemplateScreen
  );
  const [createWidget] = useMutation(GQL.Mutations.createWidget);
  const [updateWidgetDataGrid] = useMutation(
    GQL.Mutations.updateWidgetDataGrid
  );
  const [deleteWidget] = useMutation(GQL.Mutations.deleteWidget);

  useEffect(() => {
    //user && user.language && setLanguage(user.language);
    user &&
      user.account &&
      user.account.primaryColor &&
      setPrimaryColor(user.account.primaryColor);

    /** Language File */
    lang =
      user && user.language
        ? require("../../languages/" + user.language + ".js").default
        : lang;
  }, [user]);

  useEffect(() => {
    /** Map Screens to local state */
    account &&
      account.account &&
      account.account.templateScreens &&
      setScreens(account.account.templateScreens);
  }, [account]);

  const nextOpenSpot = (widgets, w, h, maxX, maxY) => {
    for (let y = 0; y <= maxY - h; y++) {
      for (let x = 0; x <= maxX - w; x++) {
        if (
          !widgets.reduce((accum, w) => {
            const widget = {
              ...w,
              dataGrid: JSON.parse(w.dataGrid),
            };
            if (
              x < widget.dataGrid.x + widget.dataGrid.w &&
              x + w > widget.dataGrid.x &&
              y < widget.dataGrid.y + widget.dataGrid.h &&
              y + h > widget.dataGrid.y
            ) {
              return true;
            } else {
              return accum;
            }
          }, false)
        ) {
          return { x, y };
        }
      }
    }

    console.log("No more room.");
    return null;
  };

  const renderIcons = () => {
    const cols = 4;
    let iconRows = [];

    for (let row = 0; row < parseInt(icons.length / cols); row++) {
      iconRows.push(row);
    }

    return (
      <Grid fluid>
        {iconRows.map((row) => {
          return (
            <Row key={"iconRow-" + row}>
              {icons.slice(row * cols, row * cols + cols).map((icon, index) => {
                return (
                  <Col
                    xs={24 / cols}
                    key={"iconCol-" + row + "-" + index}
                    style={{
                      //border: "1px solid gray",
                      textAlign: "center",
                      padding: "5px",
                      backgroundColor: newScreenIcon === icon && "#eee",
                    }}
                  >
                    <Icon
                      icon={icon}
                      key={"icon-" + row + "-" + index}
                      size="2x"
                      onClick={() => setNewScreenIcon(icon)}
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  </Col>
                );
              })}
            </Row>
          );
        })}
      </Grid>
    );
  };

  const swapScreens = (fromIndex, toIndex) => {
    const screens = account.account.templateScreens.concat();
    const toScreen = Object.assign({}, screens[toIndex]);
    screens.splice(toIndex, 1, screens[fromIndex]);
    screens.splice(fromIndex, 1, toScreen);

    screens.forEach(({ id }, order) => {
      const mutation = gql`
        mutation updateScreen($id: Int!, $order: Int!) {
          updateScreen(id: $id, order: $order)
        }
      `;
      const variables = { id, order };
      apolloClient
        .mutate({ mutation, variables })
        .then((res) => {
          props.refetchAccount && props.refetchAccount();
        })
        .catch((err) => console.error({ err }));
    });
  };

  const onLayoutChange = (layout) => {
    let promises = [];
    layout.forEach((dataGrid) => {
      promises.push(
        updateWidgetDataGrid({
          variables: {
            id: parseInt(dataGrid.i),
            dataGrid: JSON.stringify(dataGrid),
          },
        })
      );
    });

    Promise.all(promises).then(() => {
      //props.refetchAccount && props.refetchAccount();
    });
  };

  const copyScreen = async (screen) => {
    let promises = [];

    const newScreen = await createTemplateScreen({
      variables: {
        title: screen.title,
        icon: screen.icon,
        order: account.account.templateScreens.length,
      },
    });

    if (screen.widgets && screen.widgets.length > 0) {
      screen.widgets.forEach((widget) => {
        const dataGrid = widget.dataGrid;

        promises.push(
          createWidget({
            variables: {
              screenId: newScreen.data.createTemplateScreen.id,
              widgetTypeId: widget.widgetTypeId,
              title: widget.title,
              dataGrid: JSON.stringify(dataGrid),
            },
          }).then(async (res) => {
            /** We need to update the dataGrid with the new widget ID */
            const dataGrid = JSON.parse(res.data.createWidget.dataGrid);

            await updateWidgetDataGrid({
              variables: {
                id: res.data.createWidget.id,
                dataGrid: JSON.stringify({
                  ...dataGrid,
                  i: res.data.createWidget.id,
                }),
              },
            });
          })
        );
      });
      Promise.all(promises).then(() => {
        props.refetchAccount && props.refetchAccount();
      });
    } else {
      props.refetchAccount && props.refetchAccount();
    }
  };

  return (
    <Modal open={show} backdrop="static" size="lg" full>
      <Modal.Header closeButton={false}>
        <Modal.Title>
          <Icon icon="faDashboard" width={15} />{" "}
          {lang !== null ? lang.screenTemplates : "Screen Templates"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ width: "100%" }}>
          <FormGroup
            style={{ width: "100%", textAlign: "center", marginBottom: "10px" }}
          >
            <Button
              className="small-shadow"
              onClick={() => {
                props.setShow && props.setShow(false);
                refetchAccount && refetchAccount();
              }}
            >
              <Icon icon="faBan" width={15} /> Close
            </Button>
            {/*
            <SaveCancel
              onCancel={() => {
                props.setShow && props.setShow(false);
              }}
            />
            */}
          </FormGroup>
          <FormGroup style={{ width: "100%", textAlign: "center" }}>
            <div
              style={{
                margin: "auto",
                width: parseInt(window.innerWidth * 0.75) + "px",
                height: parseInt(window.innerHeight * 0.75) + "px",
                border: "1px solid lightgray",
                backgroundColor: "#f0f0f0",
              }}
            >
              <Header className="header">
                <Navbar appearance="default" className="main-menu">
                  <Navbar.Header style={{ padding: "5px" }}>
                    <img
                      src="images/generic_logo.png"
                      style={{ width: "50px" }}
                      alt="Company Logo"
                    />
                  </Navbar.Header>
                  <Navbar.Body>
                    <Nav activeKey={selectedScreen}>
                      {
                        /*account &&
                        account.account &&
                        account.account.templateScreens &&
                        account.account.templateScreens.length > 0 &&
                        account.account.templateScreens.map*/
                        screens.map((screen, index) => (
                          <TemplateScreen
                            key={"screen-" + index}
                            screen={screen}
                            index={index}
                            setSelectedScreen={setSelectedScreen}
                            swapScreens={swapScreens}
                            refetchAccount={props.refetchAccount}
                            selectedScreen={selectedScreen}
                          />
                        ))
                      }
                      <Dropdown
                        icon={
                          <Icon
                            icon={
                              newScreenName ? newScreenIcon : "faSquarePlus"
                            }
                          />
                        }
                        trigger={["hover", "click"]}
                      >
                        <Panel
                          className="menu-dropdown-header"
                          style={{ position: "relative", top: "-15pt" }}
                        >
                          <Grid style={{ width: "200px" }}>
                            <Row>
                              <Col xs={24} style={{ padding: "5px" }}>
                                <big>
                                  <Icon icon="faDashboard" width={15} />{" "}
                                  {lang !== null ? lang.newScreen : "New"}
                                </big>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={24}>
                                <small>
                                  {lang !== null
                                    ? lang.newTemplateScreenHeading
                                    : "Choose a screen or create a new one"}
                                </small>
                              </Col>
                            </Row>
                          </Grid>
                        </Panel>
                        <Panel>
                          <Form>
                            <FormGroup>
                              {myScreens &&
                                myScreens.map((screen) => (
                                  <InputGroup
                                    key={`screen-button-${screen.id}`}
                                    onClick={() => {
                                      copyScreen(screen);
                                    }}
                                  >
                                    <Button style={{ width: "100%" }}>
                                      <Icon icon={screen.icon} /> {screen.title}
                                    </Button>
                                  </InputGroup>
                                ))}
                            </FormGroup>
                            <FormGroup>
                              <InputGroup>
                                <InputGroup.Addon>
                                  <Icon icon="faTag" width={15} />
                                </InputGroup.Addon>
                                <Input
                                  placeholder={
                                    lang !== null
                                      ? lang.newScreenNamePlaceholder
                                      : "Name"
                                  }
                                  value={newScreenName}
                                  onChange={setNewScreenName}
                                />
                              </InputGroup>
                              <fieldset
                                style={{
                                  border: "1px solid #ddd",
                                  borderRadius: "5px",
                                }}
                              >
                                <legend>
                                  {lang !== null
                                    ? lang.newScreenIconHeading
                                    : "Select an Icon"}
                                </legend>
                                {renderIcons()}
                              </fieldset>
                            </FormGroup>
                            <FormGroup>
                              <SaveCancel
                                saveDisabled={!newScreenName}
                                onSave={() => {
                                  createTemplateScreen({
                                    variables: {
                                      title: newScreenName,
                                      icon: newScreenIcon,
                                      order:
                                        account.account.templateScreens.length,
                                    },
                                  }).then((res) => {
                                    props.refetchAccount &&
                                      props.refetchAccount();
                                  });
                                  setNewScreenName("");
                                  setNewScreenIcon("faDashboard");
                                }}
                                onCancel={() => {
                                  setNewScreenName("");
                                  setNewScreenIcon("faDashboard");
                                }}
                              />
                            </FormGroup>
                          </Form>
                        </Panel>
                      </Dropdown>
                    </Nav>
                    <Nav pullRight>
                      <Dropdown
                        trigger={["hover", "click"]}
                        icon={
                          <Avatar
                            circle
                            size="sm"
                            className="small-shadow menu-button-background"
                            style={{ position: "relative", top: "-5pt" }}
                          >
                            <Icon icon="faBars" width={15} />
                          </Avatar>
                        }
                        placement="bottomEnd"
                      >
                        <Panel
                          className="menu-dropdown-header"
                          style={{ position: "relative", top: "-15pt" }}
                        >
                          <Grid style={{ width: "200px" }}>
                            <Row>
                              <Col xs={24} style={{ padding: "5px" }}>
                                <big>
                                  {lang !== null
                                    ? lang.widgetsTitle
                                    : "Widgets"}
                                </big>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={24}>
                                <small>
                                  {lang !== null
                                    ? lang.widgetsHeading
                                    : "Select one to add"}
                                </small>
                              </Col>
                            </Row>
                          </Grid>
                        </Panel>
                        {!loadingWidgetTypes &&
                          widgetTypes &&
                          widgetTypes.widgetTypes &&
                          widgetTypes.widgetTypes.length > 0 &&
                          widgetTypes.widgetTypes.map((widgetType) => (
                            <Dropdown.Item
                              key={`widgetType-${widgetType.id}`}
                              eventKey={widgetType.id}
                              onClick={() => {
                                const screen =
                                  account.account.templateScreens[
                                    selectedScreen
                                  ];
                                const openSpot = nextOpenSpot(
                                  screen.widgets,
                                  1,
                                  1,
                                  cols,
                                  rows
                                );

                                createWidget({
                                  variables: {
                                    widgetTypeId: widgetType.id,
                                    screenId:
                                      account.account.templateScreens[
                                        selectedScreen
                                      ].id,
                                    title: widgetType.name,
                                  },
                                }).then((res) => {
                                  //console.log({ res });
                                  updateWidgetDataGrid({
                                    variables: {
                                      id: res.data.createWidget.id,
                                      dataGrid: JSON.stringify({
                                        ...openSpot,
                                        w: 1,
                                        h: 1,
                                        i: "" + res.data.createWidget.id,
                                      }),
                                    },
                                  });
                                  props.refetchAccount &&
                                    props.refetchAccount();
                                });
                              }}
                            >
                              <Grid fluid>
                                <Row>
                                  <Col xs={12}>
                                    <Icon icon={widgetType.icon} />{" "}
                                    {widgetType.name.charAt(0).toUpperCase() +
                                      widgetType.name.slice(1)}
                                  </Col>
                                  <Col xs={12} style={{ textAlign: "right" }}>
                                    {widgetType.section === "both" && (
                                      <Icon icon="faShareNodes" width={15} />
                                    )}
                                  </Col>
                                </Row>
                              </Grid>
                            </Dropdown.Item>
                          ))}
                      </Dropdown>
                      <Dropdown
                        trigger={["hover", "click"]}
                        icon={
                          <Avatar
                            circle
                            size="sm"
                            className="small-shadow"
                            src="images/avatar0.png"
                            style={{ position: "relative", top: "-5pt" }}
                          />
                        }
                        placement="bottomEnd"
                      />
                    </Nav>
                  </Navbar.Body>
                </Navbar>
              </Header>
              <ReactGridLayout
                preventCollision={true}
                width={parseInt(window.innerWidth * 0.75)}
                height={parseInt(window.innerHeight * 0.75)}
                cols={cols}
                rowHeight={(parseInt(window.innerHeight * 0.75) - 110) / 4}
                layout={
                  account &&
                  account.account &&
                  account.account.templateScreens &&
                  account.account.templateScreens[selectedScreen] &&
                  account.account.templateScreens[selectedScreen].widgets
                    ? account.account.templateScreens[
                        selectedScreen
                      ].widgets.map((widget) => {
                        const dataGrid = JSON.parse(widget.dataGrid);
                        return {
                          ...dataGrid,
                          i: "" + dataGrid.i || 0,
                        };
                      })
                    : []
                }
                maxRows={4}
                verticalCompact={false}
                style={{ backgroundColor: "#f0f0f0" }}
                onLayoutChange={(layout) => {
                  setTimeout(() => {
                    onLayoutChange(layout);
                  }, 0);
                }}
              >
                {account &&
                account.account &&
                account.account.templateScreens &&
                account.account.templateScreens[selectedScreen] &&
                account.account.templateScreens[selectedScreen].widgets
                  ? account.account.templateScreens[selectedScreen].widgets.map(
                      (widget) => {
                        const dataGrid = JSON.parse(widget.dataGrid);
                        return (
                          <div
                            key={widget.id}
                            style={{
                              border: "1px solid #efefef",
                              borderRadius: "3px",
                              borderTop: "3px solid " + primaryColor,
                              backgroundColor: "white",
                            }}
                            className="widget"
                          >
                            <Grid
                              fluid
                              style={{
                                width: "100%",
                                textAlign: "right",
                                padding: "2px",
                                paddingRight: "5px",
                                paddingLeft: "5px",
                              }}
                            >
                              <Row>
                                <Col xs={12} style={{ textAlign: "left" }}>
                                  <Icon icon={widget.widgetType.icon} />{" "}
                                  {widget.widgetType.name}
                                </Col>
                                <Col xs={12} style={{ textAlign: "right" }}>
                                  <Icon
                                    icon="faWindowClose"
                                    style={{
                                      color: "#fa9387",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      deleteWidget({
                                        variables: {
                                          id: widget.id,
                                        },
                                      }).then((res) => {
                                        props.refetchAccount &&
                                          props.refetchAccount();
                                      });
                                    }}
                                  />
                                </Col>
                              </Row>
                            </Grid>
                            <div>
                              <Placeholder.Grid
                                rows={10 * (dataGrid.h || 1)}
                                columns={4}
                                style={{ height: "100%", margin: "15px" }}
                              />
                            </div>
                          </div>
                        );
                      }
                    )
                  : null}
              </ReactGridLayout>
            </div>
          </FormGroup>
        </div>
      </Modal.Body>
    </Modal>
  );
};
