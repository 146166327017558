import gql from "graphql-tag";

export default {
  Queries: {
    adminAccess: gql`
      query hasAccessToSection {
        hasAccessToSection(section: "Devices", access: "adminAccess")
      }
    `,
    deleteAccess: gql`
      query hasAccessToSection {
        hasAccessToSection(section: "Devices", access: "deleteAccess")
      }
    `,
    writeAccess: gql`
      query hasAccessToSection {
        hasAccessToSection(section: "Devices", access: "writeAccess")
      }
    `,
    readAccess: gql`
      query hasAccessToSection {
        hasAccessToSection(section: "Devices", access: "readAccess")
      }
    `,
    accounts: gql`
      query accounts($parentId: Int) {
        accounts(parentId: $parentId) {
          id
          logo
          name
          domain
          status
          smAccess
          childrenTree {
            value
            label
            children {
              value
              label
              children {
                value
                label
                children {
                  value
                  label
                }
              }
            }
          }
        }
      }
    `,
    devices: gql`
      query devices($accountId: Int, $color: String) {
        devices(accountId: $accountId, color: $color) {
          id
          name
          icon
          color
          serial
          imei
          simnumber
          simCarrierId
          conduitDeviceId
          lastEvent
          accountId
          deviceTypeId
          deviceType {
            name
          }
        }
      }
    `,
    simCarriers: gql`
      {
        simCarriers {
          id
          name
        }
      }
    `,
    deviceTypes: gql`
      {
        deviceTypes {
          id
          name
        }
      }
    `,
  },
  Mutations: {
    createDevice: gql`
      mutation createDevice(
        $accountId: Int
        $name: String!
        $serial: String!
        $imei: String
        $icon: String
        $color: String
        $deviceTypeId: Int!
        $simnumber: String
        $simCarrierId: Int
      ) {
        createDevice(
          accountId: $accountId
          name: $name
          serial: $serial
          imei: $imei
          icon: $icon
          color: $color
          deviceTypeId: $deviceTypeId
          simnumber: $simnumber
          simCarrierId: $simCarrierId
        ) {
          id
        }
      }
    `,
    updateDevice: gql`
      mutation updateDevice(
        $id: Int!
        $accountId: Int
        $name: String
        $icon: String
        $color: String
        $imei: String
        $serial: String
        $deviceTypeId: Int
        $simnumber: String
        $simCarrierId: Int
      ) {
        updateDevice(
          id: $id
          accountId: $accountId
          name: $name
          icon: $icon
          color: $color
          imei: $imei
          serial: $serial
          deviceTypeId: $deviceTypeId
          simnumber: $simnumber
          simCarrierId: $simCarrierId
        )
      }
    `,
    deleteDevice: gql`
      mutation deviceDevice($id: Int!) {
        deleteDevice(id: $id)
      }
    `,
    queueOta: gql`
      mutation queueOta($id: Int!, $command: String!) {
        queueOta(id: $id, command: $command)
      }
    `,
  },
};
