import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useApolloClient } from "@apollo/react-hooks";
import { Avatar, Col, Grid, Row, Tooltip, Whisper, Loader } from "rsuite";

import Icon from "../components/Icon";

// Widgets
import Map from "./map";
import Report from "./report";
import Statistics from "./statistics";
import List from "./list";
import Users from "./users";
import Locations from "./locations";
import Devices from "./devices";
import Roles from "./roles";
import Alerts from "./alerts";
import Leads from "./leads";

// SyMon Widgets
import Accounts from "./accounts";

import { useApp } from "../AppStore";
import GQL from "./graphql";

let lang = null;

const noConfigWidgetTypes = [
  "devices",
  "locations",
  "accounts",
  "users",
  "roles",
  "alerts",
  "leads",
];

export default (props) => {
  const isMobile = window.innerWidth < 1024;
  const apolloClient = useApolloClient();
  const [{ user }] = useApp();
  const { data, loading, refetch } = useQuery(GQL.Queries.widget, {
    variables: {
      id: props.widget.id,
    },
    onCompleted: () => {
      //console.log("widget query completed");
    },
  });
  const [updateWidget] = useMutation(GQL.Mutations.updateWidget, {
    onCompleted: (res) => {
      !loading && refetch && refetch();
    },
  });
  const [expanded, setExpanded] = useState(false);
  const { widget, index, disableConfig } = props;
  const [{ rowHeight, rows }, actions] = useApp();

  useEffect(() => {
    /** Language File */
    lang =
      user && user.language
        ? require("../languages/" + user.language + ".js").default
        : lang;
  }, [user]);

  const renderWidgetContent = (widgetData) => {
    const widget = {
      ...widgetData,
      config: JSON.parse(widgetData.config),
      dataGrid: props.widget.dataGrid,
    };
    //console.log("Widget to render:", { widget });
    if (widget.type) {
      switch (widget.type) {
        case "map":
          return (
            <Map
              widget={widget}
              baseHeight={rowHeight}
              h={props.fullscreen ? rows : widget.dataGrid.h}
              expanded={expanded}
              collapse={() => {
                setExpanded(false);
              }}
              updateWidget={updateWidget}
            />
          );
        case "statistics":
          return (
            <Statistics
              expanded={expanded}
              widget={widget}
              baseHeight={rowHeight}
              h={props.fullscreen ? rows : widget.dataGrid.h}
              refetch={refetch}
              collapse={() => {
                setExpanded(false);
              }}
              updateWidget={updateWidget}
            />
          );
        case "report":
          return (
            <Report
              expanded={expanded}
              widget={widget}
              baseHeight={rowHeight}
              h={props.fullscreen ? rows : widget.dataGrid.h}
              refetch={refetch}
              collapse={() => {
                setExpanded(false);
              }}
              updateWidget={updateWidget}
            />
          );
        case "list":
          return (
            <List
              expanded={expanded}
              widget={widget}
              baseHeight={rowHeight}
              h={props.fullscreen ? rows : widget.dataGrid.h}
              refetch={refetch}
              collapse={() => {
                setExpanded(false);
              }}
              updateWidget={updateWidget}
            />
          );
        case "users":
          return (
            <Users
              expanded={expanded}
              widget={widget}
              baseHeight={rowHeight}
              h={props.fullscreen ? rows : widget.dataGrid.h}
              refetch={refetch}
              collapse={() => {
                setExpanded(false);
              }}
              updateWidget={updateWidget}
            />
          );
        case "locations":
          return (
            <Locations
              expanded={expanded}
              widget={widget}
              baseHeight={rowHeight}
              h={props.fullscreen ? rows : widget.dataGrid.h}
              refetch={refetch}
              collapse={() => {
                setExpanded(false);
              }}
              updateWidget={updateWidget}
            />
          );
        case "devices":
          return (
            <Devices
              expanded={expanded}
              widget={widget}
              baseHeight={rowHeight}
              h={props.fullscreen ? rows : widget.dataGrid.h}
              refetch={refetch}
              collapse={() => {
                setExpanded(false);
              }}
              updateWidget={updateWidget}
            />
          );
        case "accounts":
          return (
            <Accounts
              expanded={expanded}
              widget={widget}
              baseHeight={rowHeight}
              h={props.fullscreen ? rows : widget.dataGrid.h}
              refetch={refetch}
              collapse={() => {
                setExpanded(false);
              }}
              updateWidget={updateWidget}
            />
          );
        case "roles":
          return (
            <Roles
              expanded={expanded}
              widget={widget}
              baseHeight={rowHeight}
              h={props.fullscreen ? rows : widget.dataGrid.h}
              refetch={refetch}
              collapse={() => {
                setExpanded(false);
              }}
              updateWidget={updateWidget}
            />
          );
        case "alerts":
          return (
            <Alerts
              expanded={expanded}
              widget={widget}
              baseHeight={rowHeight}
              h={props.fullscreen ? rows : widget.dataGrid.h}
              refetch={refetch}
              collapse={() => {
                setExpanded(false);
              }}
              updateWidget={updateWidget}
            />
          );
        case "leads":
          return (
            <Leads
              expanded={expanded}
              widget={widget}
              baseHeight={rowHeight}
              h={props.fullscreen ? rows : widget.dataGrid.h}
              refetch={refetch}
              collapse={() => {
                setExpanded(false);
              }}
              updateWidget={updateWidget}
            />
          );
        default:
          return <div>NO CONTENT</div>;
      }
    } else {
      return widget.content && widget.content;
    }
  };

  return (
    <>
      <div
        className="widget-title drag-me"
        style={{
          overflow: "auto",
          padding: 0,
        }}
      >
        {props.fullscreen ? (
          <Grid fluid>
            <Row>
              <Col
                xxs={8}
                xs={8}
                sm={8}
                md={8}
                lg={8}
                style={{ textAlign: "left", color: "#67737a" }}
              >
                <Icon icon={widget.icon} /> {widget.title}
              </Col>
              <Col
                xxs={8}
                xs={8}
                sm={8}
                md={8}
                lg={8}
                style={{ textAlign: "center" }}
              >
                <span style={{ color: "lightgray" }}>
                  {lang !== null ? lang.fullscreen : "Fullscreen"}
                </span>
              </Col>
              <Col
                xxs={8}
                xs={8}
                sm={8}
                md={8}
                lg={8}
                style={{ textAlign: "right" }}
              >
                <Whisper
                  speaker={
                    <Tooltip>
                      {lang !== null ? lang.exitFullscreen : "Exit fullscreen"}
                    </Tooltip>
                  }
                  placement="auto"
                >
                  <Icon
                    icon="faWindowRestore"
                    style={{ color: "#facc87" }}
                    onClick={() => {
                      //actions.minimizeWidget();
                      //console.log("min clicked");
                      props.setCurrentScreen &&
                        props.currentScreen &&
                        props.setCurrentScreen({
                          ...props.currentScreen,
                          fullscreenWidget: null,
                        });
                    }}
                  />
                </Whisper>
              </Col>
            </Row>
          </Grid>
        ) : (
          <Grid fluid>
            <Row>
              <Col
                xxs={8}
                xs={8}
                sm={8}
                md={8}
                lg={8}
                style={{ textAlign: "left" }}
              >
                {!disableConfig &&
                  (noConfigWidgetTypes.includes(widget.type) ? (
                    <Icon
                      icon="faGear"
                      style={{ color: "#dddddd" }}
                      //width={10}
                    />
                  ) : (
                    <Icon
                      icon={expanded ? "faChevronCircleUp" : "faGear"}
                      onClick={() => {
                        setExpanded(!expanded);
                        //actions.toggleCardFlipped(index);
                      }}
                      style={{ color: "#67737a" }}
                      //width={10}
                    />
                  ))}
              </Col>
              <Col xxs={8} xs={8} sm={8} md={8} lg={8}>
                {/*widget.locked ? (
                  <Icon
                    icon="lock"
                    style={{ color: "#70C044" }}
                    onClick={() => {
                      actions.toggleLockedWidget(index);
                    }}
                  />
                ) : (
                  <Icon
                    icon="unlock"
                    style={{ color: "#ddd" }}
                    onClick={() => {
                      actions.toggleLockedWidget(index);
                    }}
                  />
                  )*/}
              </Col>
              <Col
                xxs={8}
                xs={8}
                sm={8}
                md={8}
                lg={8}
                style={{ textAlign: "right" }}
              >
                &nbsp;
                {!isMobile && (
                  <Icon
                    icon="faWindowMaximize"
                    //width={10}
                    style={{ color: "#facc87" }}
                    onClick={() => {
                      //actions.maximizeWidget(index);
                      //console.log(props.widget);

                      props.setCurrentScreen &&
                        props.currentScreen &&
                        props.setCurrentScreen({
                          ...props.currentScreen,
                          fullscreenWidget: props.widget,
                        });
                    }}
                  />
                )}
                &nbsp;
                {!widget.locked ? (
                  <Icon
                    icon="faWindowClose"
                    //width={15}
                    style={{ color: "#fa9387" }}
                    onClick={() => {
                      actions.removeWidget(index, apolloClient);
                    }}
                  />
                ) : (
                  <Icon icon="faWindowClose" style={{ color: "#ddd" }} />
                )}
              </Col>
            </Row>
          </Grid>
        )}
      </div>
      <div className="widget-content">
        {data && data.widget ? (
          renderWidgetContent(data.widget)
        ) : (
          <Loader content={lang !== null ? lang.loading : "Loading..."} />
        )}
      </div>
    </>
  );
};
