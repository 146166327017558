import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { Loader, Whisper, Tooltip } from "rsuite";

import Icon from "../../components/Icon";
import GQL from "./graphql";

export default (props) => {
  const { data, loading } = useQuery(GQL.Queries.location, {
    variables: {
      id: props.locationId,
    },
  });

  return loading ? (
    <Loader />
  ) : (
    <Whisper
      speaker={<Tooltip>{data.location.name}</Tooltip>}
      trigger={["hover"]}
    >
      <Icon icon="faCircle" style={{ color: "#fa9387" }} />
    </Whisper>
  );
  /*
  <Whisper speaker={<Tooltip>{data.location.name}</Tooltip>} trigger={["hover"]}>
    <Map
    className="small-shadow"
    zoomControl={false}
    doubleClickZoom={false}
    scrollWheelZoom={false}
    dragging={false}
    style={{
      width: "45px",
      height: "45px",
      borderRadius: "5px",
    }}
    bounds={[
      [
        Math.min.apply(
          null,
          data.location.coordinates.map(c => c[0])
        ) -
          (data.location.radius ? data.location.radius : 10000) * 0.00001,
        Math.min.apply(
          null,
          data.location.coordinates.map(c => c[1])
        ) -
          (data.location.radius ? data.location.radius : 10000) * 0.00001
      ],
      [
        Math.max.apply(
          null,
          data.location.coordinates.map(c => c[0])
        ) +
          (data.location.radius ? data.location.radius : 10000) * 0.00001,
        Math.max.apply(
          null,
          data.location.coordinates.map(c => c[1])
        ) +
          (data.location.radius ? data.location.radius : 10000) * 0.00001
      ]
    ]}
    attributionControl={false}
  >
    <AccountTileLayer />
    <Circle center={data.location.coordinates[0]} radius={data.location.radius} />
  </Map>  
  </Whisper>
  */
};
