import gql from "graphql-tag";

export default {
  Queries: {
    adminAccess: gql`
      query hasAccessToSection {
        hasAccessToSection(section: "Roles", access: "adminAccess")
      }
    `,
    deleteAccess: gql`
      query hasAccessToSection {
        hasAccessToSection(section: "Roles", access: "deleteAccess")
      }
    `,
    writeAccess: gql`
      query hasAccessToSection {
        hasAccessToSection(section: "Roles", access: "writeAccess")
      }
    `,
    readAccess: gql`
      query hasAccessToSection {
        hasAccessToSection(section: "Roles", access: "readAccess")
      }
    `,
    roles: gql`
      query roles($accountId: Int!) {
        roles(accountId: $accountId) {
          id
          name
          roleSections {
            section {
              id
              name
              icon
            }
            readAccess
            writeAccess
            deleteAccess
            adminAccess
          }
          users {
            id
            firstname
            lastname
            avatar
          }
        }
      }
    `,
    sections: gql`
      {
        sections {
          id
          name
        }
      }
    `
  },
  Mutations: {
    createRole: gql`
      mutation createRole(
        $name: String!
        $accountId: Int!
        $sectionIds: [Int!]
        $sectionAccess: [[Boolean!]!]
      ) {
        createRole(
          name: $name
          accountId: $accountId
          sectionIds: $sectionIds
          sectionAccess: $sectionAccess
        ) {
          id
        }
      }
    `,
    updateRole: gql`
      mutation updateRole(
        $id: Int!
        $name: String!
        $sectionIds: [Int!]
        $sectionAccess: [[Boolean!]!]
      ) {
        updateRole(
          id: $id
          name: $name
          sectionIds: $sectionIds
          sectionAccess: $sectionAccess
        )
      }
    `,
    deleteRole: gql`
      mutation deleteRole($id: Int!) {
        deleteRole(id: $id)
      }
    `
  }
};
