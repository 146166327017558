import React, { useState, useEffect, useCallback } from "react";

import L from "leaflet";
import { Map, Marker, Circle, Polygon } from "react-leaflet";

import AccountTileLayer from "../../components/accounttilelayer";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

export default props => {
  const {
    rowData,
    width,
    height,
    editable,
    type,
    coordinates: incomingCoordinates
  } = props;
  const [mapRef, setMapRef] = useState(null);
  const [coordinates, setCoordinates] = useState(
    incomingCoordinates ? incomingCoordinates : [[33.487007, -117.143784]]
  );
  const [radius, setRadius] = useState(
    rowData && rowData.radius ? rowData.radius : 0
  );
  const [, updateState] = React.useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const handleOnClick = clickCoordinates => {
    if (editable) {
      if (type === "circle") {
        setCoordinates([
          [clickCoordinates.latlng.lat, clickCoordinates.latlng.lng]
        ]);
        props.setCoordinates &&
          props.setCoordinates([
            [clickCoordinates.latlng.lat, clickCoordinates.latlng.lng]
          ]);
      } else {
        setCoordinates([
          ...coordinates,
          [clickCoordinates.latlng.lat, clickCoordinates.latlng.lng]
        ]);
        props.setCoordinates &&
          props.setCoordinates([
            ...coordinates,
            [clickCoordinates.latlng.lat, clickCoordinates.latlng.lng]
          ]);
      }
    }
  };

  useEffect(() => {
    mapRef && mapRef.leafletElement && mapRef.leafletElement.invalidateSize();
  });

  useEffect(() => {
    rowData && rowData.radius && setRadius(rowData.radius);
  }, [rowData]);

  useEffect(() => {
    if (incomingCoordinates) {
      //console.log("map - setcoords");
      setCoordinates(incomingCoordinates);
    }
  }, [incomingCoordinates]);

  //console.log({ coordinates });

  return (
    <Map
      ref={ref => setMapRef(ref)}
      className="small-shadow"
      zoomControl={editable ? editable : false}
      doubleClickZoom={editable ? editable : false}
      scrollWheelZoom={editable ? editable : false}
      dragging={editable ? editable : false}
      style={{
        width,
        height,
        borderRadius: "5px",
        ...props.style
      }}
      bounds={[
        [
          Math.min.apply(
            null,
            coordinates.map(c => c[0])
          ) -
            (rowData.radius ? rowData.radius : 10000) * 0.00001,
          Math.min.apply(
            null,
            coordinates.map(c => c[1])
          ) -
            (rowData.radius ? rowData.radius : 10000) * 0.00001
        ],
        [
          Math.max.apply(
            null,
            coordinates.map(c => c[0])
          ) +
            (rowData.radius ? rowData.radius : 10000) * 0.00001,
          Math.max.apply(
            null,
            coordinates.map(c => c[1])
          ) +
            (rowData.radius ? rowData.radius : 10000) * 0.00001
        ]
      ]}
      attributionControl={false}
      animated={true}
      onClick={handleOnClick}
    >
      <AccountTileLayer />
      {/* <TileLayer url="//{s}.tile.osm.org/{z}/{x}/{y}.png" /> */}
      {rowData.type && rowData.type === "circle" && (
        <>
          <Circle center={coordinates[0]} radius={radius} />
        </>
      )}
      {rowData.type && rowData.type === "polygon" && (
        <>
          <Polygon positions={rowData.coordinates} />
          {rowData.coordinates.map((position, index) => (
            <Marker
              position={position}
              draggable={true}
              onDragend={e => {
                //console.log("Dragend:", index);
                let newCoordinates = coordinates;

                newCoordinates.splice(index, 1, [
                  e.target._latlng.lat,
                  e.target._latlng.lng
                ]);

                setCoordinates(newCoordinates);
                props.setCoordinates && props.setCoordinates(newCoordinates);
                forceUpdate();
                mapRef &&
                  mapRef.leafletElement &&
                  mapRef.leafletElement.invalidateSize(true);
              }}
            />
          ))}
        </>
      )}
      {/*<Polyline color="green" positions={rowData.coordinates} />*/}
    </Map>
  );
};
