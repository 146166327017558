import gql from "graphql-tag";

export default {
  Queries: {
    checkToken: gql`
      query validPasswordResetToken($token: String!) {
        validPasswordResetToken(token: $token)
      }
    `
  },
  Mutations: {
    resetPassword: gql`
      mutation resetPassword($token: String!, $password: String!) {
        resetPassword(token: $token, password: $password)
      }
    `
  }
};