import gql from "graphql-tag";

export default {
  Queries: {
    emailExists: gql`
      query emailExists($email: String!) {
        emailExists(email: $email)
      }
    `,
    deleteAccess: gql`
      query hasAccessToSection {
        hasAccessToSection(section: "Accounts", access: "deleteAccess")
      }
    `,
    writeAccess: gql`
      query hasAccessToSection {
        hasAccessToSection(section: "Accounts", access: "writeAccess")
      }
    `,
    readAccess: gql`
      query hasAccessToSection {
        hasAccessToSection(section: "Accounts", access: "readAccess")
      }
    `,
    accounts: gql`
      query accounts($parentId: Int) {
        accounts(parentId: $parentId, accountTypeId: 3) {
          id
          logo
          name
          accountTypeId
          domain
          status
          mapping
          mapSystemId
          geocode
          geocoderId
          smAccess
          notes
          createdAt
          parentBreadcrumb {
            id
            logo
            name
            accountTypeId
            domain
            status
            mapping
            mapSystemId
            geocode
            geocoderId
            smAccess
            parentBreadcrumb {
              id
              logo
              name
              accountTypeId
              domain
              status
              mapping
              mapSystemId
              geocode
              geocoderId
              smAccess
            }
          }
        }
      }
    `,
    accountTypes: gql`
      {
        accountTypes {
          id
          name
        }
      }
    `,
    mapSystems: gql`
      {
        mapSystems {
          id
          name
          icon
        }
      }
    `,
    geocoders: gql`
      {
        geocoders {
          id
          name
          icon
        }
      }
    `,
  },
  Mutations: {
    createAccount: gql`
      mutation createAccount(
        $parentId: Int
        $name: String!
        $domain: String
        $status: String
        $mapSystemId: Int
        $mapSystemApiKey: String
        $firstname: String
        $lastname: String
        $email: String
        $password: String
        $templateScreenIds: [Int!]
        $smAccess: Boolean
      ) {
        createAccount(
          parentId: $parentId
          name: $name
          domain: $domain
          status: $status
          mapSystemId: $mapSystemId
          mapSystemApiKey: $mapSystemApiKey
          firstname: $firstname
          lastname: $lastname
          email: $email
          password: $password
          templateScreenIds: $templateScreenIds
          smAccess: $smAccess
        ) {
          id
        }
      }
    `,
    updateAccount: gql`
      mutation updateAccount(
        $id: Int!
        $name: String
        $domain: String
        $status: String
        $mapSystemId: Int
        $mapSystemApiKey: String
        $accountTypeId: Int
        $smAccess: Boolean
      ) {
        updateAccount(
          id: $id
          name: $name
          domain: $domain
          status: $status
          mapSystemId: $mapSystemId
          mapSystemApiKey: $mapSystemApiKey
          accountTypeId: $accountTypeId
          smAccess: $smAccess
        )
      }
    `,
    deleteAccount: gql`
      mutation deleteAccount($id: Int!) {
        deleteAccount(id: $id)
      }
    `,
    createWidget: gql`
      mutation createWidget(
        $widgetTypeId: Int!
        $screenId: Int!
        $title: String!
      ) {
        createWidget(
          widgetTypeId: $widgetTypeId
          screenId: $screenId
          title: $title
        ) {
          id
          widgetType {
            id
            icon
            name
          }
        }
      }
    `,
  },
};
