import gql from "graphql-tag";

export default {
  Queries: {
    devices: gql`
      {
        devices {
          id
          name
          serial
          imei
          simnumber
          conduitDeviceId
        }
      }
    `,
    users: gql`
      {
        users {
          id
          email
          firstname
          lastname
          avatar
        }
      }
    `
  },
  Mutations: {
    updateWidget: gql`
      mutation updateWidget($id: Int!, $config: String) {
        updateWidget(id: $id, config: $config)
      }
    `
  }
};
