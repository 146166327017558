import React, { useState } from "react";
import {
  Button,
  Container,
  Form,
  //FormGroup,
  //Icon,
  Input,
  InputGroup,
  Message,
} from "rsuite";

import Icon from "../../components/Icon";
import { useQuery, useMutation } from "@apollo/react-hooks";
import sha1 from "sha1";

import GQL from "./graphql";

const { Group: FormGroup } = Form;

export default (props) => {
  const token = props.match.params.token;
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [hiddenPassword, setHiddenPassword] = useState(true);
  const [password, setPassword] = useState("");
  useQuery(GQL.Queries.checkToken, {
    variables: {
      token,
    },
    onCompleted: (data) => {
      if (data.validPasswordResetToken === true) {
        setShowPassword(true);
      } else {
        setShowPassword(false);
        setError("Invalid session.");
      }
    },
  });
  const [resetPassword] = useMutation(GQL.Mutations.resetPassword, {
    onCompleted: (res) => {
      console.log({ res });
      if (res.resetPassword === true) {
        setShowPassword(false);
        setMessage("Your password has been reset!");
      } else {
        setShowPassword(false);
        setError("An error occured while trying to reset your password.");
      }
    },
  });

  return (
    <Container
      style={{
        margin: "auto",
        marginTop: "100px",
        width: "400px",
        backgroundColor: "transparent",
      }}
    >
      <Container
        className="widget"
        style={{
          padding: "15px",
          margin: "10px",
        }}
      >
        <Form fluid layout="vertical">
          <FormGroup style={{ width: "100%", textAlign: "center" }}>
            <Container style={{ color: "#E57A45" }}>
              <Icon icon="faLock" />
            </Container>
            {/*<Container style={{fontSize: "30px", fontWeight: "bold"}}>RESET PASSWORD</Container>*/}
          </FormGroup>
          {showPassword && (
            <>
              <FormGroup>
                <InputGroup style={{ width: "100%" }}>
                  <InputGroup.Addon>
                    <Icon icon="faKey" width={15} />
                  </InputGroup.Addon>
                  <Input
                    name="password"
                    placeholder="New Password"
                    onChange={setPassword}
                    value={password}
                    onKeyDown={(event) => {
                      event.keyCode === 13 &&
                        resetPassword({
                          variables: {
                            token,
                            password: sha1(password),
                          },
                        });
                    }}
                    type={hiddenPassword ? "password" : "text"}
                  />
                  <InputGroup.Addon>
                    <Icon
                      icon={hiddenPassword ? "faEye" : "faEyeSlash"}
                      onClick={() => setHiddenPassword(!hiddenPassword)}
                      style={{ cursor: "pointer" }}
                    />
                  </InputGroup.Addon>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <Button
                  style={{
                    width: "100%",
                    alignContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                  size="lg"
                  appearance="primary"
                  onClick={(event) => {
                    resetPassword({
                      variables: {
                        token,
                        password: sha1(password),
                      },
                    });
                  }}
                  className="shadow-lightblue button-primary"
                >
                  <span
                    style={{
                      width: "100%",
                      alignContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <Icon icon="faRightToBracket" /> Submit
                  </span>
                </Button>
              </FormGroup>
            </>
          )}
          {error.length > 0 && (
            <FormGroup>
              <Message
                showIcon
                type="error"
                title={error}
                description="Request a new token to try again."
              />
            </FormGroup>
          )}
          {message.length > 0 && (
            <FormGroup>
              <Message description={message} />
              <Button
                onClick={() => {
                  window.location = "/login";
                }}
                style={{
                  width: "100%",
                  alignContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                Go to Login
              </Button>
            </FormGroup>
          )}
        </Form>
      </Container>
    </Container>
  );
};
