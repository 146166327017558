import React, { useState, useEffect } from "react";
import {
  Avatar,
  Form,
  //FormGroup,
  Input,
  Button,
  //Icon,
  Grid,
  Row,
  Col,
  ButtonGroup,
  Panel,
  SelectPicker,
} from "rsuite";

import Icon from "../../components/Icon";
import { useMutation, useQuery } from "@apollo/react-hooks";

import GQL from "./graphql";
import { useApp } from "../../AppStore";

let lang = null;
const { Group: FormGroup } = Form;

export default (props) => {
  const { selectedAccount } = props;
  const [{ user }] = useApp();
  const [imeis, setImeis] = useState("");
  const [deviceTypeId, setDeviceTypeId] = useState(null);
  const [createDevice] = useMutation(GQL.Mutations.createDevice);
  const { data: deviceTypes } = useQuery(GQL.Queries.deviceTypes);

  useEffect(() => {
    /** Language File */
    lang =
      user && user.language
        ? require("../../languages/" + user.language + ".js").default
        : lang;
  }, [user]);

  return (
    <div
      className="shadow"
      style={{
        animation: "0.15s ease-out 0s 1 fadeAndSlideIn",
        borderRadius: "1px",
        position: "absolute",
        padding: 0,
        backgroundColor: "white",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 9999,
        width: "95%",
        height: "95%",
        margin: "auto",
        overflowY: "scroll",
      }}
    >
      <div
        style={{
          padding: "10px",
          color: "white",
          position: "relative",
          width: "100%",
        }}
        className="small-shadow primary-gradient"
      >
        <div style={{ width: "100%", textAlign: "left" }}>
          <Grid fluid>
            <Row>
              <Col xs={12} style={{ textAlign: "left" }}>
                <big>{lang !== null ? lang.bulkImport : "Bulk Import"}</big>
              </Col>
              <Col xs={12} style={{ textAlign: "right" }}>
                <Avatar
                  size="xs"
                  style={{ backgroundColor: "#D64545" }}
                  className="action-button"
                  onClick={() => {
                    props.close && props.close();
                  }}
                >
                  <Icon icon="faCircleXmark" />
                </Avatar>
              </Col>
            </Row>
            <Row>
              <Col xs={24} style={{ textAlign: "center" }}>
                <div>
                  <img
                    style={{ borderRadius: "5px" }}
                    width={100}
                    src={
                      selectedAccount ? selectedAccount.logo : user.account.logo
                    }
                    alt="logo"
                  />
                </div>
                <big>
                  {selectedAccount ? selectedAccount.name : user.account.name}
                </big>
              </Col>
            </Row>
          </Grid>
        </div>
      </div>
      <Panel>
        <Form>
          <FormGroup>
            <SelectPicker
              cleanable={false}
              searchable={false}
              data={
                deviceTypes && deviceTypes.deviceTypes
                  ? deviceTypes.deviceTypes.map((dt) => ({
                      value: dt.id,
                      label: dt.name,
                    }))
                  : []
              }
              onChange={setDeviceTypeId}
              value={deviceTypeId}
              placeholder="Device Type"
            />
          </FormGroup>
          <FormGroup>
            <Input
              disabled={deviceTypeId === null}
              componentClass="textarea"
              rows={10}
              placeholder="One IMEI per line."
              value={imeis}
              onChange={setImeis}
            />
          </FormGroup>
          <FormGroup>
            <ButtonGroup>
              <Button
                appearance="primary"
                className="small-shadow"
                color="green"
                onClick={() => {
                  // SAVE IMEIS
                  const data = imeis
                    .trim()
                    .split("\n")
                    .map((imei) => imei.trim());

                  if (data && data.length > 0) {
                    let promises = [];
                    data.forEach((imei) => {
                      promises.push(
                        createDevice({
                          variables: {
                            accountId: selectedAccount
                              ? selectedAccount.id
                              : user.account.id,
                            name: imei,
                            imei,
                            serial: imei,
                            deviceTypeId,
                          },
                        })
                      );
                    });
                    Promise.all(promises).then((res) => {
                      props.close && props.close();
                      props.refetch && props.refetch();
                    });
                  }
                }}
              >
                <span>
                  <Icon icon="faFileUpload" /> Import
                </span>
              </Button>
              <Button
                appearance="primary"
                className="small-shadow"
                color="orange"
                onClick={() => {
                  setImeis("");
                  props.close && props.close();
                }}
              >
                <Icon icon="faBan" width={15} />{" "}
                {lang !== null ? lang.cancel : "Cancel"}
              </Button>
            </ButtonGroup>
          </FormGroup>
        </Form>
      </Panel>
    </div>
  );
};
