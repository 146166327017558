import React, { useState, useEffect } from "react";
import { SelectPicker } from "rsuite";

import { useMutation } from "@apollo/react-hooks";

import GQL from "./graphql";
import { useApp } from "../../AppStore";

let lang = null;

export default props => {
  const [{ user }] = useApp();
  const { data } = props;
  const { id, status: incomingStatus } = data;
  const [status, setStatus] = useState(incomingStatus);
  const [updateAccount] = useMutation(GQL.Mutations.updateAccount);
  const options = [
    {
      value: "active",
      label: lang !== null ? lang.active : "Active"
    },
    {
      value: "suspended",
      label: lang !== null ? lang.suspended : "Suspended"
    },
    {
      value: "disabled",
      label: lang !== null ? lang.disabled : "Disabled"
    }
  ];

  useEffect(() => {
    /** Language File */
    lang =
      user && user.language
        ? require("../../languages/" + user.language + ".js").default
        : lang;
  }, [user]);

  const setAndSaveStatus = status => {
    setStatus(status);
    updateAccount({
      variables: {
        id,
        status
      }
    });
    props.refetch && props.refetch();
  };

  return (
    <SelectPicker
      searchable={false}
      cleanable={false}
      data={options}
      style={{
        width: "100%",
        margin: 0,
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%) translateX(-50%)"
      }}
      value={status}
      onChange={status => {
        setAndSaveStatus(status);
      }}
    />
  );
};
