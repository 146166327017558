import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import Login from "./layout/login";
import Impersonate from "./layout/impersonate";
import ForgotPassword from "./layout/forgotpassword";
import Cookie from "js-cookie";
import * as serviceWorker from "./serviceWorker";
//import "rsuite/styles/index.less";
import "./App.less";
//import "./custom-theme.less";
//import "rsuite/dist/styles/rsuite-default.css";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import { ApolloProvider } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import config from "./config";

const httpLink = createHttpLink({
  uri: config.APIURL,
});

const authLink = setContext(async (_, { headers }) => {
  const token = Cookie.get("atles-geofleetos-token");
  return {
    headers: {
      ...headers,
      Authorization: token ? token : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const SystemManager = () => <App system="sm" />;

ReactDOM.render(
  <ApolloProvider client={client}>
    <Router>
      <Switch>
        <Route exact path={["/"]} component={App} />
        <Route exact path={["/sm"]} component={SystemManager} />
        <Route exact path={["/login"]} component={Login} />
        <Route exact path={["/session/:token"]} component={Impersonate} />
        <Route
          exact
          path={["/forgotpassword/:token"]}
          component={ForgotPassword}
        />
      </Switch>
    </Router>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
