import React, { useState, useEffect } from "react";
import {
  Avatar,
  CheckPicker,
  Form,
  //FormGroup,
  InputGroup,
  Button,
  //Icon,
  Grid,
  Row,
  Col,
  ButtonGroup,
  Nav,
  Checkbox,
  Badge,
} from "rsuite";

import Icon from "../../components/Icon";
import { useQuery, useMutation } from "@apollo/react-hooks";

import GQL from "./graphql";
import { useApp } from "../../AppStore";

import TriggerSelector from "./triggerselector";
import { SelectedElement } from "rsuite/esm/Picker";

let lang = null;
const { Group: FormGroup } = Form;

export default (props) => {
  /** Incoming Props */
  const { selectedAlert } = props;

  /** Global State */
  const [{ user }] = useApp();

  /** Local State */
  const [alertTypeId, setAlertTypeId] = useState(1);
  const [enabledAlertTypeIds, setEnabledAlertTypeIds] = useState(
    selectedAlert
      ? [
          ...(selectedAlert.alertLocations &&
          selectedAlert.alertLocations.length > 0
            ? [1]
            : []),
          ...(selectedAlert.alertSpeeds && selectedAlert.alertSpeeds.length > 0
            ? [2]
            : []),
          ...(selectedAlert.alertOffhour ? [3] : []),
        ]
      : []
  );
  const [allDevices, setAllDevices] = useState(false);
  const [selectedDeviceIds, setSelectedDeviceIds] = useState(
    selectedAlert && selectedAlert.devices && selectedAlert.devices.length > 0
      ? selectedAlert.devices.map((device) => device.id)
      : []
  );
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedSpeeds, setSelectedSpeeds] = useState(
    selectedAlert &&
      selectedAlert.alertSpeeds &&
      selectedAlert.alertSpeeds.length > 0
      ? selectedAlert.alertSpeeds.map((alertSpeed) => alertSpeed.speed)
      : []
  );
  const [selectedUserIds, setSelectedUserIds] = useState(
    selectedAlert && selectedAlert.users && selectedAlert.users.length > 0
      ? selectedAlert.users.map((user) => user.id)
      : []
  );
  const [offhourStartHour, setOffhourStartHour] = useState(
    selectedAlert &&
      selectedAlert.alertOffhour &&
      selectedAlert.alertOffhour.startHour !== null
      ? selectedAlert.alertOffhour.startHour
      : 17
  );
  const [offhourStartMinute, setOffhourStartMinute] = useState(
    selectedAlert &&
      selectedAlert.alertOffhour &&
      selectedAlert.alertOffhour.startMinute !== null
      ? selectedAlert.alertOffhour.startMinute
      : 0
  );
  const [offhourEndHour, setOffhourEndHour] = useState(
    selectedAlert &&
      selectedAlert.alertOffhour &&
      selectedAlert.alertOffhour.endHour !== null
      ? selectedAlert.alertOffhour.endHour
      : 9
  );
  const [offhourEndMinute, setOffhourEndMinute] = useState(
    selectedAlert &&
      selectedAlert.alertOffhour &&
      selectedAlert.alertOffhour.endMinute !== null
      ? selectedAlert.alertOffhour.endMinute
      : 0
  );
  const [offhourLocations, setOffhourLocations] = useState(
    selectedAlert &&
      selectedAlert.alertOffhour &&
      selectedAlert.alertOffhour.locationIds
      ? selectedAlert.alertOffhour.locationIds
      : []
  );
  const [days, setDays] = useState(
    selectedAlert &&
      selectedAlert.alertOffhour &&
      selectedAlert.alertOffhour.days
      ? selectedAlert.alertOffhour.days
      : [1, 2, 3, 4, 5]
  );

  /** GraphQL */
  const { data: alertTypes } = useQuery(GQL.Queries.alertTypes);
  const { data: devices } = useQuery(GQL.Queries.devices, {
    onCompleted: (res) => {
      if (res && res.devices && res.devices.length > 0) {
        // Select all devices if new alert
        if (!selectedAlert) {
          setAllDevices(true);
          setSelectedDeviceIds(res.devices.map((device) => device.id));
        }
      }
    },
  });
  const { data: locations } = useQuery(GQL.Queries.locations, {
    onCompleted: (res) => {
      // Set default Locations for Offhours
      if (
        selectedAlert &&
        selectedAlert.alertOffhour &&
        selectedAlert.alertOffhour.locationIds
      ) {
        setOffhourLocations(selectedAlert.alertOffhour.locationIds);
      } else {
        setOffhourLocations(res.locations.map((location) => location.id));
      }

      // Set selected Locations
      setSelectedLocations(
        selectedAlert &&
          selectedAlert.alertTypeId === 1 &&
          selectedAlert.alertLocations &&
          selectedAlert.alertLocations.length > 0
          ? res.locations.map((location) => {
              const alertLocation = selectedAlert.alertLocations.filter(
                (alertLocation) => alertLocation.location.id === location.id
              );

              if (alertLocation && alertLocation.length > 0) {
                return {
                  id: location.id,
                  in: ["in", "both"].includes(alertLocation[0].trigger),
                  out: ["out", "both"].includes(alertLocation[0].trigger),
                };
              } else {
                return { id: location.id, in: false, out: false };
              }
            })
          : res.locations.map((location) => ({
              id: location.id,
              in: false,
              out: false,
            }))
      );
    },
  });
  const { data: users } = useQuery(GQL.Queries.users, {
    onCompleted: (res) => {
      // Select current user if new alert
      if (user && user.id && !selectedAlert) {
        setSelectedUserIds([user.id]);
      }
    },
  });
  const [createAlert] = useMutation(GQL.Mutations.createAlert);
  const [createAlertDevice] = useMutation(GQL.Mutations.createAlertDevice);
  const [createAlertLocation] = useMutation(GQL.Mutations.createAlertLocation);
  const [createAlertSpeed] = useMutation(GQL.Mutations.createAlertSpeed);
  const [createAlertUser] = useMutation(GQL.Mutations.createAlertUser);
  const [createAlertOffhour] = useMutation(GQL.Mutations.createAlertOffhour);
  const [deleteAlert] = useMutation(GQL.Mutations.deleteAlert);

  useEffect(() => {
    /** Language File */
    lang =
      user && user.language
        ? require("../../languages/" + user.language + ".js").default
        : lang;
  }, [user]);

  return (
    <div
      className="shadow"
      style={{
        animation: "0.15s ease-out 0s 1 fadeAndSlideIn",
        borderRadius: "1px",
        position: "absolute",
        padding: 0,
        backgroundColor: "white",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 9999,
        width: "95%",
        height: "95%",
        margin: "auto",
        overflowY: "scroll",
      }}
    >
      <div
        style={{
          padding: "10px",
          color: "white",
          position: "relative",
          width: "100%",
        }}
        className="primary-gradient"
      >
        <div style={{ width: "100%", textAlign: "left" }}>
          <Grid fluid>
            <Row>
              <Col xs={12} style={{ textAlign: "left" }}>
                <big>{lang !== null ? lang.alertDetails : "Alert Details"}</big>
              </Col>
              <Col xs={12} style={{ textAlign: "right" }}>
                <Avatar
                  size="xs"
                  style={{ backgroundColor: "#D64545" }}
                  className="action-button"
                  onClick={() => {
                    props.close && props.close();
                  }}
                >
                  <Icon icon="faCircleXmark" />
                </Avatar>
              </Col>
            </Row>
          </Grid>
        </div>
      </div>
      <div style={{ padding: "15px" }}>
        <Form fluid>
          <FormGroup>
            <InputGroup style={{ width: "100%" }}>
              <InputGroup.Addon style={{ width: "100%" }}>
                <Icon icon="faTablet" />
                <InputGroup.Addon
                  style={{
                    padding: "0px 5px 0px 5px",
                    margin: 0,
                  }}
                >
                  <Checkbox
                    checked={allDevices}
                    onChange={(value, checked) => {
                      setAllDevices(checked);
                      if (checked) {
                        if (
                          devices &&
                          devices.devices &&
                          devices.devices.length > 0
                        ) {
                          setSelectedDeviceIds(
                            devices.devices.map((device) => device.id)
                          );
                        }
                      } else {
                        setSelectedDeviceIds([]);
                      }
                    }}
                  >
                    All Devices
                  </Checkbox>
                </InputGroup.Addon>
                <CheckPicker
                  cleanable={false}
                  data={
                    devices && devices.devices && devices.devices.length > 0
                      ? devices.devices.map((device) => ({
                          value: device.id,
                          label: device.name,
                        }))
                      : []
                  }
                  value={selectedDeviceIds}
                  onChange={(deviceIds) => {
                    setSelectedDeviceIds(deviceIds);
                    setAllDevices(
                      devices &&
                        devices.devices &&
                        deviceIds.length == devices.devices.length
                    );
                  }}
                  style={{ width: "100%" }}
                />
              </InputGroup.Addon>
            </InputGroup>
            <InputGroup style={{ width: "100%" }}>
              <InputGroup.Addon style={{ width: "100%" }}>
                <Icon icon="faUser" style={{ marginRight: 10 }} />
                <CheckPicker
                  cleanable={false}
                  data={
                    users && users.users && users.users.length > 0
                      ? users.users.map((user) => ({
                          value: user.id,
                          label: user.firstname + " " + user.lastname,
                        }))
                      : []
                  }
                  value={selectedUserIds}
                  onChange={setSelectedUserIds}
                  style={{ width: "100%" }}
                />
              </InputGroup.Addon>
            </InputGroup>
            <Nav
              activeKey={alertTypeId}
              onSelect={(id) => {
                // If Offhour is selected and it's not enabled, enable that trigger
                if (id == 3) {
                  if (!enabledAlertTypeIds.includes(3)) {
                    setEnabledAlertTypeIds([...enabledAlertTypeIds, 3]);
                  }

                  // Only disable if current tab is offhour alerts
                  if (enabledAlertTypeIds.includes(3) && id == alertTypeId) {
                    setEnabledAlertTypeIds(
                      enabledAlertTypeIds.filter((id) => id != 3)
                    );
                  }
                }

                setAlertTypeId(id);
              }}
              appearance="tabs"
              style={{ textAlign: "left", marginTop: "10px" }}
            >
              {alertTypes &&
                alertTypes.alertTypes &&
                alertTypes.alertTypes.map((alertType) => (
                  <Nav.Item
                    key={`alert-type-${alertType.id}`}
                    eventKey={alertType.id}
                  >
                    <Badge
                      color={
                        enabledAlertTypeIds.includes(alertType.id)
                          ? "green"
                          : "red"
                      }
                    >
                      {alertType.name}
                    </Badge>
                  </Nav.Item>
                ))}
            </Nav>
          </FormGroup>
          <div>
            {alertTypeId > 0 ? (
              <TriggerSelector
                selectedAlert={selectedAlert}
                alertTypeId={alertTypeId}
                selectedLocations={selectedLocations}
                onSelectedLocationsChange={setSelectedLocations}
                selectedSpeeds={selectedSpeeds}
                onSelectedSpeedsChange={setSelectedSpeeds}
                locations={locations}
                offhourStartHour={offhourStartHour}
                setOffhourStartHour={setOffhourStartHour}
                offhourStartMinute={offhourStartMinute}
                setOffhourStartMinute={setOffhourStartMinute}
                offhourEndHour={offhourEndHour}
                setOffhourEndHour={setOffhourEndHour}
                offhourEndMinute={offhourEndMinute}
                setOffhourEndMinute={setOffhourEndMinute}
                offhourLocations={offhourLocations}
                setOffhourLocations={setOffhourLocations}
                days={days}
                setDays={setDays}
                widget={props.widget}
              />
            ) : null}
          </div>
          <div>
            <FormGroup>
              <ButtonGroup>
                <Button
                  appearance="primary"
                  className="small-shadow"
                  color="green"
                  onClick={async () => {
                    if (selectedAlert) {
                      // Delete old
                      console.log({
                        deleteOldAlert: { id: selectedAlert.id },
                      });
                      await deleteAlert({
                        variables: { id: selectedAlert.id },
                      });
                    }
                    const alert = await createAlert();
                    let promises = [];

                    if (
                      alert &&
                      alert.data &&
                      alert.data.createAlert &&
                      alert.data.createAlert.id
                    ) {
                      const alertId = alert.data.createAlert.id;

                      // Devices
                      if (selectedDeviceIds && selectedDeviceIds.length > 0) {
                        selectedDeviceIds.forEach((deviceId) => {
                          promises.push(
                            createAlertDevice({
                              variables: { alertId, deviceId },
                            })
                          );
                        });
                      }

                      // Locations
                      if (selectedLocations && selectedLocations.length > 0) {
                        selectedLocations.forEach((location) => {
                          if (location.in === true || location.out === true) {
                            promises.push(
                              createAlertLocation({
                                variables: {
                                  alertId,
                                  locationId: location.id,
                                  trigger:
                                    location.in === true &&
                                    location.out === true
                                      ? "both"
                                      : location.in === true
                                      ? "in"
                                      : "out",
                                },
                              })
                            );
                          }
                        });
                      }

                      // Speeds
                      if (selectedSpeeds && selectedSpeeds.length > 0) {
                        selectedSpeeds.forEach((speed) => {
                          promises.push(
                            createAlertSpeed({
                              variables: {
                                alertId,
                                speed,
                              },
                            })
                          );
                        });
                      }

                      // Save Offhour
                      if (enabledAlertTypeIds.includes(3)) {
                        if (
                          selectedDeviceIds &&
                          selectedDeviceIds.length > 0 &&
                          offhourLocations &&
                          offhourLocations.length > 0 &&
                          days &&
                          days.length > 0
                        ) {
                          promises.push(
                            createAlertOffhour({
                              variables: {
                                alertId,
                                deviceIds: selectedDeviceIds,
                                locationIds: offhourLocations,
                                days,
                                startHour: offhourStartHour,
                                startMinute: offhourStartMinute,
                                endHour: offhourEndHour,
                                endMinute: offhourEndMinute,
                              },
                            })
                          );
                        }
                      }

                      // Users
                      if (selectedUserIds && selectedUserIds.length > 0) {
                        selectedUserIds.forEach((userId) => {
                          promises.push(
                            createAlertUser({
                              variables: {
                                alertId,
                                userId,
                              },
                            })
                          );
                        });
                      }

                      Promise.all(promises).then(() => {
                        props.refetch && props.refetch();
                      });
                    } else {
                      console.error("Could not create alert");
                    }

                    props.close && props.close();
                  }}
                >
                  <span>
                    <Icon icon="faFloppyDisk" />{" "}
                    {selectedAlert ? "Save" : "Create"}
                  </span>
                </Button>
                <Button
                  appearance="primary"
                  className="small-shadow"
                  color="orange"
                  onClick={() => {
                    props.close && props.close();
                  }}
                >
                  <Icon icon="faBan" width={15} />{" "}
                  {lang !== null ? lang.cancel : "Cancel"}
                </Button>
              </ButtonGroup>
            </FormGroup>
          </div>
        </Form>
      </div>
    </div>
  );
};
