import React, { useState, useEffect } from "react";
import {
  Avatar,
  Form,
  //FormGroup,
  InputGroup,
  Input,
  Button,
  //Icon,
  Grid,
  Row,
  Col,
  ButtonGroup,
  InputPicker,
  Checkbox,
  //HelpBlock,
} from "rsuite";

import Icon from "../../components/Icon";
import { useMutation, useQuery, useLazyQuery } from "@apollo/react-hooks";
import sha1 from "sha1";
import moment from "moment";
import GQL from "./graphql";
import { useApp } from "../../AppStore";

import TemplateScreenSelector from "../../components/templatescreenselector";

let lang = null;
const { Group: FormGroup, HelpText: HelpBlock } = Form;

export default (props) => {
  /** PROPS */
  const { selectedAccount } = props;

  /** GLOBAL APP STATE */
  const [{ user, selectedAccount: globalSelectedAccount }] = useApp();

  /** LOCAL COMPONENT STATE */
  const [validEmail, setValidEmail] = useState(false);
  const [showEmailMessage, setShowEmailMessage] = useState(false);
  const [name, setName] = useState(
    selectedAccount && selectedAccount.name ? selectedAccount.name : ""
  );
  const [accountTypeId, setAccountTypeId] = useState(
    selectedAccount && selectedAccount.accountTypeId
      ? selectedAccount.accountTypeId
      : 1
  );
  const [domain, setDomain] = useState(
    selectedAccount && selectedAccount.domain ? selectedAccount.domain : ""
  );
  const [mapping, setMapping] = useState(
    selectedAccount && selectedAccount.mapping ? selectedAccount.mapping : false
  );
  const [geocode, setGeocode] = useState(
    selectedAccount && selectedAccount.geocode ? selectedAccount.geocode : false
  );
  const [mapSystemId, setMapSystemId] = useState(
    selectedAccount && selectedAccount.mapSystemId
      ? selectedAccount.mapSystemId
      : 1
  );
  const [geocoderId, setGeocoderId] = useState(
    selectedAccount && selectedAccount.geocoderId
      ? selectedAccount.geocoderId
      : 1
  );
  const [templateScreenIds, setTemplateScreenIds] = useState([]);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [smAccess, setSmAccess] = useState(
    selectedAccount && selectedAccount.smAccess ? true : false
  );

  /** GRAPHQL */
  const [createAccount] = useMutation(GQL.Mutations.createAccount);
  const [updateAccount] = useMutation(GQL.Mutations.updateAccount);
  const { data: mapSystems } = useQuery(GQL.Queries.mapSystems);
  const { data: accountTypes } = useQuery(GQL.Queries.accountTypes);
  const { data: geocoders } = useQuery(GQL.Queries.geocoders);
  const [emailExists] = useLazyQuery(GQL.Queries.emailExists, {
    variables: {
      email,
    },
    onCompleted: (res) => {
      //console.log({res});
      if (res.emailExists === false) {
        setValidEmail(true);
        setShowEmailMessage(false);
      } else {
        setValidEmail(false);
        setShowEmailMessage(true);
      }
    },
  });

  useEffect(() => {
    /** Language File */
    lang =
      user && user.language
        ? require("../../languages/" + user.language + ".js").default
        : lang;
  }, [user]);

  return (
    <div
      className="shadow"
      style={{
        animation: "0.15s ease-out 0s 1 fadeAndSlideIn",
        borderRadius: "1px",
        position: "absolute",
        padding: 0,
        backgroundColor: "white",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 9999,
        width: "95%",
        height: "95%",
        margin: "auto",
        overflowY: "scroll",
      }}
    >
      <div
        style={{
          /*
          backgroundColor:
            user && user.account && user.account.primaryColor
              ? user.account.primaryColor
              : "skyblue",
          */
          backgroundColor: "white",
          backgroundImage:
            "-webkit-gradient(linear, left top, right bottom, from(#45B0E5), to(#71D0FF))",
          padding: "10px",
          color: "white",
          position: "relative",
          width: "100%",
        }}
        className="small-shadow"
      >
        <div style={{ width: "100%", textAlign: "left" }}>
          <Grid fluid>
            <Row>
              <Col xs={12} style={{ textAlign: "left" }}>
                <big>
                  {lang !== null ? lang.accountDetails : "Account Details"}
                </big>
              </Col>
              <Col xs={12} style={{ textAlign: "right" }}>
                <Avatar
                  size="xs"
                  style={{ backgroundColor: "#D64545" }}
                  className="action-button"
                  onClick={() => {
                    props.close && props.close();
                  }}
                >
                  <Icon icon="faCircleXmark" />
                </Avatar>
              </Col>
            </Row>
          </Grid>
        </div>
        {/*
        <div>
          <Whisper
            trigger="click"
            placement="bottom"
            speaker={
              <Popover>
                <AvatarPicker
                  onClick={avatar => {
                    setAvatar(avatar);
                    props.refetch();
                  }}
                />
              </Popover>
            }
          >
            <Avatar
              onClick={() => {
                //console.log("avatar")
              }}
              circle
              className="small-shadow"
              src={avatar && avatar.length > 0 ? avatar : "images/256_1.png"}
              size="lg"
            />
          </Whisper>
        </div>
            */}
        <div>
          <big>
            {name && name.length > 0
              ? name
              : lang !== null
              ? lang.account
              : "Account"}
          </big>
        </div>
        <div>
          <small>
            {domain && domain.length > 0
              ? domain
              : lang !== null
              ? lang.accountComPlaceholder
              : "account.com"}
          </small>
        </div>
      </div>
      <div style={{ padding: "15px" }}>
        <Form fluid>
          <FormGroup>
            {/** Account Name */}
            <InputGroup style={{ width: "100%" }}>
              <InputGroup.Addon>
                <Icon icon="faHome" />
              </InputGroup.Addon>
              <Input
                style={{ width: "100%" }}
                placeholder={lang !== null ? lang.name : "Name"}
                value={name}
                onChange={setName}
              />
            </InputGroup>
            {/** Account Type */}
            <InputGroup style={{ width: "100%" }}>
              <InputGroup.Addon>
                <Icon icon="faBuilding" />
              </InputGroup.Addon>
              <InputPicker
                cleanable={false}
                style={{ width: "100%", textAlign: "left" }}
                value={accountTypeId}
                onChange={(value) => {
                  setAccountTypeId(value);
                  if (value === 2) {
                    // DEALER
                    setSmAccess(true);
                  } else {
                    setSmAccess(false);
                  }
                }}
                data={
                  accountTypes &&
                  accountTypes.accountTypes &&
                  accountTypes.accountTypes.length > 0
                    ? accountTypes.accountTypes.map(
                        ({ id: value, name: label }) => ({ value, label })
                      )
                    : []
                }
              />
            </InputGroup>
            {accountTypeId !== 1 ? (
              <>
                <InputGroup style={{ width: "100%" }}>
                  <InputGroup.Addon>
                    <Icon icon="faBuilding" />
                  </InputGroup.Addon>
                  <Input
                    style={{ width: "100%" }}
                    placeholder={lang !== null ? lang.domain : "Domain"}
                    value={domain}
                    onChange={setDomain}
                  />
                </InputGroup>
                <Grid fluid>
                  <Row>
                    <Col xs={12} style={{ textAlign: "left", padding: 5 }}>
                      <Checkbox
                        checked={mapping}
                        onChange={(value, checked) => {
                          setMapping(checked);
                        }}
                      >
                        Use {mapping ? "customer's" : "my"} mapping
                      </Checkbox>
                    </Col>
                    <Col xs={12} style={{ textAlign: "left" }}>
                      {mapping === true ? (
                        <InputGroup>
                          <InputGroup.Addon>
                            <Icon icon="faMap" />
                          </InputGroup.Addon>
                          <InputPicker
                            cleanable={false}
                            style={{ width: "100%", textAlign: "left" }}
                            data={
                              mapSystems &&
                              mapSystems.mapSystems &&
                              mapSystems.mapSystems.length > 0
                                ? mapSystems.mapSystems.map(
                                    ({ id: value, name: label }) => ({
                                      value,
                                      label,
                                    })
                                  )
                                : []
                            }
                            value={mapSystemId}
                            onChange={setMapSystemId}
                          />
                        </InputGroup>
                      ) : null}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} style={{ textAlign: "left", padding: 5 }}>
                      <Checkbox
                        checked={geocode}
                        onChange={(value, checked) => {
                          setGeocode(checked);
                        }}
                      >
                        Use {geocode ? "customer's" : "my"} geocoder
                      </Checkbox>
                    </Col>
                    <Col xs={12} style={{ textAlign: "left" }}>
                      {geocode === true ? (
                        <InputGroup>
                          <InputGroup.Addon>
                            <Icon icon="faMap" />
                          </InputGroup.Addon>
                          <InputPicker
                            cleanable={false}
                            style={{ width: "100%", textAlign: "left" }}
                            data={
                              geocoders &&
                              geocoders.geocoders &&
                              geocoders.geocoders.length > 0
                                ? geocoders.geocoders.map(
                                    ({ id: value, name: label }) => ({
                                      value,
                                      label,
                                    })
                                  )
                                : []
                            }
                            value={geocoderId}
                            onChange={setGeocoderId}
                          />
                        </InputGroup>
                      ) : null}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} style={{ textAlign: "left", padding: 5 }}>
                      <Checkbox
                        checked={smAccess}
                        onChange={(value, checked) => {
                          setSmAccess(checked);
                        }}
                      >
                        SM Access
                      </Checkbox>
                    </Col>
                  </Row>
                </Grid>
              </>
            ) : null}
          </FormGroup>
          {selectedAccount === null ? (
            <FormGroup>
              <InputGroup style={{ width: "100%" }}>
                <InputGroup.Addon>
                  <Icon icon="faTag" width={15} />
                </InputGroup.Addon>
                <Input
                  style={{ width: "50%" }}
                  placeholder={
                    lang !== null ? lang.firstNamePlaceholder : "Firstname"
                  }
                  value={firstname}
                  onChange={setFirstname}
                />
                <Input
                  style={{ width: "50%" }}
                  placeholder={
                    lang !== null ? lang.lastNamePlaceholder : "Lastname"
                  }
                  value={lastname}
                  onChange={setLastname}
                />
              </InputGroup>
              <InputGroup style={{ width: "100%" }}>
                <InputGroup.Addon>
                  <Icon icon="faAt" width={15} />
                </InputGroup.Addon>
                <Input
                  placeholder={lang !== null ? lang.emailPlaceholder : "Email"}
                  value={email}
                  onChange={setEmail}
                  onBlur={() => {
                    const emailTest =
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    if (
                      email.length > 0 &&
                      emailTest.test(String(email.toLowerCase()))
                    ) {
                      emailExists();
                    } else {
                      setValidEmail(false);
                      setShowEmailMessage(false);
                    }
                  }}
                />
                <InputGroup.Addon>
                  <Icon
                    icon={validEmail === true ? "faCheck" : "faExclamation"}
                    style={{ color: validEmail === true ? "green" : "red" }}
                  />
                </InputGroup.Addon>
              </InputGroup>
              {showEmailMessage && (
                <HelpBlock>Email already exists in the system!</HelpBlock>
              )}
              {selectedAccount && (
                <InputGroup style={{ width: "100%" }}>
                  <InputGroup.Addon>
                    <Icon icon="faKey" width={15} />
                  </InputGroup.Addon>
                  <Input
                    placeholder="Password"
                    value={password}
                    onChange={setPassword}
                  />
                </InputGroup>
              )}
              <TemplateScreenSelector
                setTemplateScreenIds={setTemplateScreenIds}
                onChange={setTemplateScreenIds}
                selectAll={true}
              />
            </FormGroup>
          ) : null}
          <FormGroup>
            <ButtonGroup>
              <Button
                className="small-shadow"
                appearance="primary"
                color="green"
                disabled={!selectedAccount && validEmail === false}
                onClick={() => {
                  if (selectedAccount) {
                    updateAccount({
                      variables: {
                        id: selectedAccount.id,
                        name,
                        domain,
                        mapSystemId,
                        geocoderId,
                        accountTypeId,
                        smAccess,
                      },
                    }).then((res) => {
                      props.close();
                      props.refetch();
                    });
                  } else {
                    console.log({ templateScreenIds });
                    createAccount({
                      variables: {
                        parentId: globalSelectedAccount
                          ? globalSelectedAccount.id
                          : user.account.id,
                        name,
                        domain,
                        mapSystemId,
                        geocoderId,
                        firstname,
                        lastname,
                        email,
                        password: sha1(
                          email + firstname + lastname + moment().valueOf()
                        ),
                        templateScreenIds,
                        smAccess,
                      },
                    }).then((res) => {
                      if (
                        res &&
                        res.data &&
                        res.data.createAccount &&
                        res.data.createAccount.id
                      ) {
                        props.close();
                        props.refetch();
                      }
                    });
                  }
                }}
              >
                {selectedAccount ? (
                  <span>
                    <Icon icon="faFloppyDisk" />{" "}
                    {lang !== null ? lang.save : "Save"}
                  </span>
                ) : (
                  <span>
                    <Icon icon="faFloppyDisk" />{" "}
                    {lang !== null ? lang.create : "Create"}
                  </span>
                )}
              </Button>
              <Button
                className="small-shadow"
                appearance="primary"
                color="orange"
                onClick={() => {
                  props.close && props.close();
                }}
              >
                <Icon icon="faBan" width={15} />{" "}
                {lang !== null ? lang.cancel : "Cancel"}
              </Button>
            </ButtonGroup>
          </FormGroup>
        </Form>
      </div>
    </div>
  );
};
