export default {
  fiftyMeters: "50 Meters",
  oneHundredMeters: "100 Meters",
  halfMile: "1/2 Mile",
  oneMile: "1 Mile",
  fiveMiles: "5 Miles",
  tenMiles: "10 Miles",
  account: "Account",
  accountComPlaceholder: "account.com",
  accountDetails: "Account Details",
  accounts: "Accounts",
  actions: "Actions",
  active: "Active",
  address: "Address",
  addScreenHerePlaceholder: "Add screen here",
  addWidgetsHerePlaceholder: "Add widgets here",
  age: "Age",
  ago: "ago",
  alert: "Alert",
  alertDetails: "Alert Details",
  alerts: "Alerts",
  alertType: "Alert Type",
  altitude: "Altitude",
  average: "Average",
  battery: "Battery",
  bulkAction: "Bulk Action",
  bulkDelete: "Bulk Delete",
  bulkImport: "Bulk Import",
  bulkOta: "Bulk OTA",
  bulkTransfer: "Bulk Transfer",
  cancel: "Cancel",
  circle: "Circle",
  clickHereForEventsReport: "Click here for events Report",
  clickMapPlaceholder: "Please click map to set location",
  close: "Close",
  company: "Company",
  configureWidgetPlaceholder: "Widget needs to be configured",
  confirm: "Confirm",
  contactAdminPlaceholder: "Please contact the administrator",
  create: "Create",
  createScreenError: "Could not create screen",
  customers: "Customers",
  date: "Date",
  day: "Day(s)",
  daysAgo: "Day(s) Ago",
  delete: "Delete",
  deleteConfirm: ["Are you sure you want to delete", "This CANNOT be undone!"],
  deleteScreen: "Delete Screen",
  deleteScreenError: "could not delete screen",
  details: "Details",
  device: "Device",
  deviceBattery: "Device Battery",
  deviceDetails: "Device Details",
  deviceName: "Device Name",
  deviceVoltage: "Device Voltage",
  devices: "Devices",
  devicesReportedTodayPlaceholder: "Reported Today",
  disabled: "Disabled",
  domain: "Domain",
  duration: "Duration",
  email: "Email",
  emailPlaceholder: "email@domain.com",
  end: "End",
  endEvent: "End Event",
  endLocation: "End Location",
  endMap: "End Map",
  endTime: "End Time",
  event: "Event",
  eventLocations: "Locations",
  events: "Events",
  eventTime: "Event Time",
  exitFullscreen: "Exit fullscreen",
  export: "Export",
  firstNamePlaceholder: "First Name",
  fullscreen: "Fullscreen",
  heading: "Heading",
  hour: "Hour(s)",
  hoursAgo: "Hour(s) Ago",
  id: "ID",
  idle: "Idle",
  imei: "IMEI",
  invalidConfigPlaceholder: "Invalid config",
  lastNamePlaceholder: "Last Name",
  lastReported: "Last Reported",
  latLong: "Lat/Long",
  lead: "Lead",
  leads: "Leads",
  live: "Live",
  loadingPlaceholder: "Loading...",
  location: "Location",
  locations: "Locations",
  locationDetails: "Location Details",
  logout: "Logout",
  miles: "Miles",
  minimap: "Minimap",
  minute: "Minute(s)",
  minutesAgo: "Minute(s) Ago",
  mph: "Mph",
  mphTopSpeed: "Mph Top Speed",
  nA: "N/A",
  name: "Name",
  new: "New",
  newAccount: "New Account",
  newAlert: "New Alert",
  newLocation: "New Location",
  newRole: "New Role",
  newScreen: "New",
  newScreenHeading: "Please fill out the form below.",
  newScreenIconHeading: "Select an Icon",
  newScreenNamePlaceholder: "Name",
  newTemplateScreenHeading: "Choose a screen or create a new one",
  newUser: "New User",
  noRoomPlaceholder: "No more room",
  notes: "Notes",
  password: "Password",
  passwordPlaceholder: "Fill in to change password",
  polygon: "",
  profile: "Profile",
  radius: "Radius",
  remove: "Remove",
  reported: "Reported",
  reportFor: "Report for",
  role: "Role",
  roles: "Roles",
  roleSections: "Role Sections",
  save: "Save",
  screenTemplates: "Screen Templates",
  search: "Search",
  second: "Second(s)",
  secondsAgo: "Second(s) Ago",
  sections: "Sections",
  selectDevice: "Select a device",
  selectDevicesPlaceholder: "Select devices",
  selectLocationsPlaceholder: "Select locations",
  selectReport: "Select a report",
  selectReportFields: "Select report fields",
  selectStatPlaceholder: "Select a stat",
  selectTypePlaceholder: "Select Type",
  serial: "Serial",
  settings: "Settings",
  simnumber: "Sim Number",
  speed: "Speed",
  static: "Static",
  start: "Start",
  startEvent: "Start Event",
  startLocation: "Start Location",
  startMap: "Start Map",
  startTime: "Start Time",
  status: "Status",
  suspended: "Suspended",
  synced: "Synced",
  this_month: "This Month",
  this_week: "This Week",
  today: "Today",
  triggers: "Triggers",
  tripDetails: "Trip Details",
  tripMap: "Trip Map",
  trips: "Trips",
  userDetailsPlaceholder: "User Details",
  users: "Users",
  unsynced: "Unsynced",
  widgetAddedPlaceholder: "Widget added",
  widgetDeletedPlaceholder: "Widget deleted",
  widgetsHeading: "Select one to add",
  widgetsTitle: "Widgets",
  yes: "Yes",
  yesterday: "Yesterday",
};
