import React, { useState, useEffect } from "react";
import {
  Avatar,
  Form,
  //FormGroup,
  InputGroup,
  Input,
  Button,
  //Icon,
  Grid,
  Row,
  Col,
  ButtonGroup,
  Popover,
  Whisper,
  SelectPicker,
} from "rsuite";

import Icon from "../../components/Icon";
import { useMutation, useQuery } from "@apollo/react-hooks";

import GQL from "./graphql";
import { useApp } from "../../AppStore";

import Map from "./map";
import DeviceIconPicker from "../../components/deviceiconpicker";
import DeviceColorPicker from "../../components/devicecolorpicker";

let lang = null;
const { Group: FormGroup } = Form;

export default (props) => {
  const { selectedDevice, selectedAccount } = props;
  const [{ system, user }] = useApp();
  const [name, setName] = useState(
    selectedDevice && selectedDevice.name ? selectedDevice.name : ""
  );
  const [deviceTypeId, setDeviceTypeId] = useState(
    selectedDevice && selectedDevice.deviceTypeId
      ? selectedDevice.deviceTypeId
      : 0
  );
  const [serial, setSerial] = useState(
    selectedDevice && selectedDevice.serial ? selectedDevice.serial : ""
  );
  const [imei, setImei] = useState(
    selectedDevice && selectedDevice.imei ? selectedDevice.imei : ""
  );
  const [simCarrierId, setSimCarrierId] = useState(
    selectedDevice && selectedDevice.simCarrierId
      ? selectedDevice.simCarrierId
      : 0
  );
  const [simnumber, setSimnumber] = useState(
    selectedDevice && selectedDevice.simnumber ? selectedDevice.simnumber : ""
  );
  const [icon, setIcon] = useState(
    selectedDevice && selectedDevice.icon ? selectedDevice.icon : ""
  );
  const [color, setColor] = useState(
    selectedDevice && selectedDevice.color ? selectedDevice.color : null
  );
  const [createDevice] = useMutation(GQL.Mutations.createDevice);
  const [updateDevice] = useMutation(GQL.Mutations.updateDevice);
  const { data: deviceTypes } = useQuery(GQL.Queries.deviceTypes, {
    onCompleted: (res) => {
      //console.log({ res });
    },
  });
  const { data: simCarriers } = useQuery(GQL.Queries.simCarriers, {
    onCompleted: (simCarriers) => {
      //console.log({ simCarriers });
    },
  });

  useEffect(() => {
    /** Language File */
    lang =
      user && user.language
        ? require("../../languages/" + user.language + ".js").default
        : lang;
  }, [user]);

  return (
    <div
      className="shadow"
      style={{
        animation: "0.15s ease-out 0s 1 fadeAndSlideIn",
        borderRadius: "1px",
        position: "absolute",
        padding: 0,
        backgroundColor: "white",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 9999,
        width: "95%",
        height: "95%",
        margin: "auto",
        overflowY: "scroll",
      }}
    >
      <div
        style={{
          padding: "10px",
          color: "white",
          position: "relative",
          width: "100%",
        }}
        className="small-shadow primary-gradient"
      >
        <div style={{ width: "100%", textAlign: "left" }}>
          <Grid fluid>
            <Row>
              <Col xs={12} style={{ textAlign: "left" }}>
                <big>
                  {lang !== null ? lang.deviceDetails : "Device Details"}
                </big>
              </Col>
              <Col xs={12} style={{ textAlign: "right" }}>
                <Avatar
                  size="xs"
                  style={{ backgroundColor: "#D64545" }}
                  className="action-button"
                  onClick={() => {
                    props.close && props.close();
                  }}
                >
                  <Icon icon="faCircleXmark" />
                </Avatar>
              </Col>
            </Row>
          </Grid>
        </div>
        <Whisper
          trigger="click"
          placement="bottom"
          speaker={
            <Popover>
              <DeviceIconPicker
                onClick={(icon) => {
                  setIcon(icon);
                  //props.refetch();
                }}
              />
            </Popover>
          }
        >
          {selectedDevice && selectedDevice.lastEvent ? (
            <div
              style={{
                width: "100%",
                margin: "auto",
              }}
            >
              <Map
                rowData={selectedDevice}
                width="150px"
                height="150px"
                style={{
                  margin: "auto",
                  borderTop: "6px solid " + (color ? color : "white"),
                }}
                icon={icon}
              />
            </div>
          ) : (
            <div
              style={{
                width: "150px",
                height: "150px",
                borderRadius: "5px",
                backgroundColor: "#eeeeee",
                position: "relative",
                margin: "auto",
                borderTop: "6px solid " + (color ? color : "white"),
              }}
              className="small-shadow"
            >
              <img
                src={icon ? icon : "images/device1.png"}
                style={{
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  margin: "auto",
                }}
                alt={lang !== null ? lang.device : "Device"}
              />
            </div>
          )}
        </Whisper>
        <div>
          <big>
            {name && name.length > 0
              ? name
              : lang !== null
              ? lang.device
              : "Device"}
          </big>
        </div>
        <div>
          <small>
            {selectedDevice &&
            selectedDevice.deviceType &&
            selectedDevice.deviceType.name
              ? selectedDevice.deviceType.name
              : "Device"}
          </small>
        </div>
      </div>
      <div style={{ padding: "15px" }}>
        <Form fluid>
          <FormGroup>
            <InputGroup
              className="small-shadow"
              style={{ borderRadius: "6px", width: "100%" }}
            >
              <InputGroup.Addon>
                <Icon icon="faTag" width={15} />
              </InputGroup.Addon>
              <Input
                placeholder={lang !== null ? lang.name : "Name"}
                value={name}
                onChange={setName}
              />
              <Whisper
                trigger="click"
                placement="topEnd"
                speaker={
                  <Popover>
                    <DeviceColorPicker
                      color={color}
                      onClick={(color) => {
                        setColor(color);
                      }}
                    />
                  </Popover>
                }
              >
                <InputGroup.Button
                  style={{
                    color: color ? color : "white",
                    backgroundColor: !color && "lightgray",
                  }}
                >
                  <Icon icon="faStar" />
                </InputGroup.Button>
              </Whisper>
            </InputGroup>
          </FormGroup>
          {system && system === "sm" ? (
            <>
              <FormGroup>
                <SelectPicker
                  style={{ width: "100%" }}
                  placeholder="Device Type"
                  cleanable={false}
                  searchable={false}
                  value={deviceTypeId}
                  data={
                    deviceTypes && deviceTypes.deviceTypes
                      ? deviceTypes.deviceTypes.map((dt) => ({
                          value: dt.id,
                          label: dt.name,
                        }))
                      : []
                  }
                  onChange={setDeviceTypeId}
                />
              </FormGroup>
              <FormGroup>
                <InputGroup style={{ width: "100%" }}>
                  <InputGroup.Addon>
                    <Icon icon="faTag" width={15} />
                  </InputGroup.Addon>
                  <Input
                    placeholder="Serial"
                    value={serial}
                    onChange={setSerial}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup style={{ width: "100%" }}>
                  <InputGroup.Addon>
                    <Icon icon="faTag" width={15} />
                  </InputGroup.Addon>
                  <Input placeholder="IMEI" value={imei} onChange={setImei} />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup style={{ width: "100%" }}>
                  <InputGroup.Addon>
                    <Icon icon="faHashtag" />
                  </InputGroup.Addon>
                  <Input
                    placeholder="Sim Number"
                    value={simnumber}
                    onChange={setSimnumber}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <SelectPicker
                  style={{ width: "100%" }}
                  placeholder="Sim Carrier"
                  cleanable={false}
                  searchable={false}
                  value={simCarrierId}
                  data={
                    simCarriers && simCarriers.simCarriers
                      ? simCarriers.simCarriers.map((sc) => ({
                          value: sc.id,
                          label: sc.name,
                        }))
                      : []
                  }
                  onChange={setSimCarrierId}
                />
              </FormGroup>
            </>
          ) : null}
          <FormGroup>
            <ButtonGroup>
              <Button
                appearance="primary"
                className="small-shadow"
                color="green"
                onClick={() => {
                  if (selectedDevice) {
                    updateDevice({
                      variables: {
                        id: selectedDevice.id,
                        name,
                        icon,
                        color,
                        imei,
                        serial,
                        deviceTypeId,
                        simnumber,
                        simCarrierId,
                      },
                    }).then((res) => {
                      props.close();
                      props.refetch();
                    });
                  } else {
                    createDevice({
                      variables: {
                        ...(selectedAccount
                          ? { accountId: selectedAccount.id }
                          : null),
                        name,
                        icon,
                        color,
                        imei,
                        serial,
                        deviceTypeId,
                        simnumber,
                        simCarrierId,
                      },
                    }).then((res) => {
                      props.close();
                      props.refetch();
                    });
                  }
                }}
              >
                <span>
                  <Icon icon="faFloppyDisk" />{" "}
                  {selectedDevice ? "Save" : "Create"}
                </span>
              </Button>
              <Button
                appearance="primary"
                className="small-shadow"
                color="orange"
                onClick={() => {
                  props.close && props.close();
                }}
              >
                <Icon icon="faBan" width={15} />{" "}
                {lang !== null ? lang.cancel : "Cancel"}
              </Button>
            </ButtonGroup>
          </FormGroup>
        </Form>
      </div>
    </div>
  );
};
