import gql from "graphql-tag";

export default {
  Queries: {
    devices: gql`
      {
        devices {
          id
          name
        }
      }
    `,
    devicesWithLastEvent: gql`
      query devices($ids: [Int]) {
        devices(ids: $ids) {
          id
          name
          imei
          serial
          simnumber
          lastEvent
          icon
        }
      }
    `,
    locations: gql`
      query locations($ids: [Int]) {
        locations(ids: $ids) {
          id
          name
          type
          radius
          coordinates
          address
        }
      }
    `
  },
  Mutations: {}
};
