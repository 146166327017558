import React from "react";
import { Avatar, Grid, Row, Col } from "rsuite";
export default props => {
  const avatars = [
    "images/256_1.png",
    "images/256_2.png",
    "images/256_3.png",
    "images/256_4.png",
    "images/256_5.png",
    "images/256_6.png",
    "images/256_7.png",
    "images/256_8.png",
    "images/256_9.png",
    "images/256_10.png",
    "images/256_11.png",
    "images/256_12.png",
    "images/256_13.png",
    "images/256_14.png",
    "images/256_15.png",
    "images/256_16.png"
  ];
  return (
    <Grid fluid>
      {[0, 1, 2, 3].map(row => (
        <Row key={"aprow-" + row}>
          {avatars.slice(row * 4, row * 4 + 4).map((src, index) => {
            return (
              <Col xs={6} key={"apcol-" + row + "-" + index}>
                <Avatar
                  key={"apavatar-" + row + "-" + index}
                  onClick={() => {
                    props.onClick && props.onClick(src);
                  }}
                  circle
                  src={src}
                  size="lg"
                  className="small-shadow"
                  style={{ cursor: "pointer" }}
                />
              </Col>
            );
          })}
        </Row>
      ))}
    </Grid>
  );
};
