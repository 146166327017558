import React, { useState } from "react";
import { Avatar } from "rsuite";

import Icon from "../../components/Icon";
import { useApp } from "../../AppStore";
import { useMutation } from "@apollo/react-hooks";

import GQL from "./graphql";

import TransferPopup from "./transferpopup";

export default (props) => {
  const { setSelectedDevice, setShowDetailsModal, data, refetch, access } =
    props;
  const [{ system }] = useApp();
  const [showTransferPopup, setShowTransferPopup] = useState(false);
  const [updateDevice] = useMutation(GQL.Mutations.updateDevice);

  return (
    <div
      style={{
        borderRadius: "6px",
        margin: 0,
        position: "relative",
        top: "50%",
        transform: "translateY(-50%)",
      }}
    >
      <div>
        {system && system === "sm" ? (
          <>
            <Avatar
              size="sm"
              circle={true}
              onClick={() => {
                if (access.adminAccess === true) {
                  setShowTransferPopup(true);
                }
              }}
              className={
                access.adminAccess === true
                  ? "action-button"
                  : "action-button-disabled"
              }
              style={{
                backgroundColor:
                  access.adminAccess === true ? "var(--base-color)" : "#CCCCCC",
              }}
            >
              <Icon icon="faArrowRightArrowLeft" />
            </Avatar>
            {showTransferPopup && (
              <div
                style={{
                  position: "absolute",
                  width: "80%",
                  height: "80%",
                  top: "10%",
                  left: "10%",
                }}
              >
                <TransferPopup
                  data={data}
                  setShowTransferPopup={setShowTransferPopup}
                  onChange={(accountId) => {
                    updateDevice({
                      variables: {
                        id: data.id,
                        accountId,
                      },
                    }).then((res) => {
                      setShowTransferPopup();
                      props.refetch && props.refetch();
                    });
                  }}
                  refetch={refetch}
                />
              </div>
            )}
          </>
        ) : null}{" "}
        <Avatar
          size="sm"
          circle={true}
          onClick={() => {
            if (access.writeAccess === true) {
              setSelectedDevice(data);
              setShowDetailsModal(true);
            }
          }}
          className={
            access.writeAccess === true
              ? "action-button"
              : "action-button-disabled"
          }
          style={{
            backgroundColor:
              access.writeAccess === true ? "#45B0E5" : "#CCCCCC",
          }}
        >
          <Icon icon="faPencil" />
        </Avatar>
      </div>
    </div>
  );
};
