import gql from "graphql-tag";

export default {
  Queries: {
    adminAccess: gql`
      query hasAccessToSection {
        hasAccessToSection(section: "Users", access: "adminAccess")
      }
    `,
    deleteAccess: gql`
      query hasAccessToSection {
        hasAccessToSection(section: "Users", access: "deleteAccess")
      }
    `,
    writeAccess: gql`
      query hasAccessToSection {
        hasAccessToSection(section: "Users", access: "writeAccess")
      }
    `,
    readAccess: gql`
      query hasAccessToSection {
        hasAccessToSection(section: "Users", access: "readAccess")
      }
    `,
    users: gql`
      query users($accountId: Int) {
        users(accountId: $accountId) {
          id
          email
          firstname
          lastname
          avatar
          active
          roles {
            id
          }
        }
      }
    `,
    roles: gql`
      query roles($accountId: Int!) {
        roles(accountId: $accountId) {
          id
          name
        }
      }
    `
  },
  Mutations: {
    impersonateUser: gql`
      mutation impersonateUser($id: Int!) {
        impersonateUser(id: $id) {
          token
        }
      }
    `,
    createUser: gql`
      mutation createUser(
        $firstname: String
        $lastname: String
        $email: String!
        $password: String!
        $roleIds: [Int!]
        $avatar: String
        $accountId: Int
        $templateScreenIds: [Int!]
      ) {
        createUser(
          firstname: $firstname
          lastname: $lastname
          email: $email
          password: $password
          roleIds: $roleIds
          avatar: $avatar
          accountId: $accountId
          templateScreenIds: $templateScreenIds
        ) {
          id
        }
      }
    `,
    updateUser: gql`
      mutation updateUser(
        $id: Int!
        $firstname: String
        $lastname: String
        $email: String
        $password: String
        $roleIds: [Int!]
        $active: Boolean
        $avatar: String
      ) {
        updateUser(
          id: $id
          active: $active
          roleIds: $roleIds
          firstname: $firstname
          lastname: $lastname
          email: $email
          password: $password
          avatar: $avatar
        )
      }
    `,
    deleteUser: gql`
      mutation deleteUser($id: Int!) {
        deleteUser(id: $id)
      }
    `
  }
};
