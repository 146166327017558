import React, { useState, useEffect } from "react";
import { Avatar, Button, ButtonGroup } from "rsuite";
import { useApp } from "../../AppStore";
import { useMutation } from "@apollo/react-hooks";

import Icon from "../../components/Icon";
import GQL from "./graphql";

let lang = null;

export default (props) => {
  const { setSelectedAlert, setShowDetailsModal, data } = props;
  const { id } = data;
  const [{ user }] = useApp();

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleteAlert] = useMutation(GQL.Mutations.deleteAlert, {
    onCompleted: (res) => {
      setShowDeleteConfirm(false);
      props.refetch();
    },
  });

  useEffect(() => {
    /** Language File */
    lang =
      user && user.language
        ? require("../../languages/" + user.language + ".js").default
        : lang;
  }, [user]);

  //console.log({ data });

  return (
    <div
      style={{
        borderRadius: "6px",
        margin: 0,
        position: "relative",
        top: "50%",
        transform: "translateY(-50%)",
      }}
    >
      <div>
        <Avatar
          size="sm"
          circle={true}
          onClick={() => {
            setSelectedAlert(data);
            setShowDetailsModal(true);
          }}
          className="action-button"
          style={{ backgroundColor: "#45B0E5" }}
        >
          <Icon icon="faPencil" />
        </Avatar>{" "}
        <Avatar
          size="sm"
          circle={true}
          onClick={() => {
            setShowDeleteConfirm(true);
          }}
          className="action-button"
          style={{ backgroundColor: "#EE4D4D" }}
        >
          <Icon icon="faTrash" />
        </Avatar>
      </div>
      {showDeleteConfirm && (
        <div
          style={{
            position: "absolute",
            width: "80%",
            height: "80%",
            top: "10%",
            left: "10%",
          }}
        >
          <ButtonGroup style={{ borderRadius: "6px" }} className="small-shadow">
            <Button
              appearance="primary"
              color="red"
              size="xs"
              onClick={() =>
                deleteAlert({
                  variables: { id },
                })
              }
            >
              <Icon icon="faCheck" />
            </Button>
            <Button
              appearance="primary"
              color="orange"
              size="xs"
              onClick={() => setShowDeleteConfirm(false)}
            >
              <Icon icon="faBan" width={15} />
            </Button>
          </ButtonGroup>
        </div>
      )}
    </div>
  );
};
