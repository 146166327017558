import React /*, { useState, useEffect, useCallback, updateState }*/ from "react";
//import { useQuery } from "@apollo/react-hooks";
import {
  Avatar,
  Grid,
  Col,
  Row,
  Loader,
  Slider,
  RangeSlider,
  SelectPicker,
  InputGroup,
  Form,
  Toggle,
  Divider,
} from "rsuite";

import Icon from "../../components/Icon";

//import GQL from "./graphql";
import { useApp } from "../../AppStore";

import LocationMap from "./locationmap";
import InputGroupAddon from "rsuite/esm/InputGroup/InputGroupAddon";

const hours = [
  {
    label: "12 AM",
    value: 0,
  },
  {
    label: "1 AM",
    value: 1,
  },
  {
    label: "2 AM",
    value: 2,
  },
  {
    label: "3 AM",
    value: 3,
  },
  {
    label: "4 AM",
    value: 4,
  },
  {
    label: "5 AM",
    value: 5,
  },
  {
    label: "6 AM",
    value: 6,
  },
  {
    label: "7 AM",
    value: 7,
  },
  {
    label: "8 AM",
    value: 8,
  },
  {
    label: "9 AM",
    value: 9,
  },
  {
    label: "10 AM",
    value: 10,
  },
  {
    label: "11 AM",
    value: 11,
  },
  {
    label: "12 PM",
    value: 12,
  },
  {
    label: "1 PM",
    value: 13,
  },
  {
    label: "2 PM",
    value: 14,
  },
  {
    label: "3 PM",
    value: 15,
  },
  {
    label: "4 PM",
    value: 16,
  },
  {
    label: "5 PM",
    value: 17,
  },
  {
    label: "6 PM",
    value: 18,
  },
  {
    label: "7 PM",
    value: 19,
  },
  {
    label: "8 PM",
    value: 20,
  },
  {
    label: "9 PM",
    value: 21,
  },
  {
    label: "10 PM",
    value: 22,
  },
  {
    label: "11 PM",
    value: 23,
  },
];

const minutes = [
  {
    label: "00",
    value: 0,
  },
  {
    label: "15",
    value: 15,
  },
  {
    label: "30",
    value: 30,
  },
  {
    label: "45",
    value: 45,
  },
];

const daysOfTheWeek = [
  {
    label: "S",
    value: 0,
  },
  {
    label: "M",
    value: 1,
  },
  {
    label: "T",
    value: 2,
  },
  {
    label: "W",
    value: 3,
  },
  {
    label: "T",
    value: 4,
  },
  {
    label: "F",
    value: 5,
  },
  {
    label: "S",
    value: 6,
  },
];

export default (props) => {
  const isMobile = window.innerWidth < 1024;
  /** Incomping props */
  const {
    //    selectedAlert,
    alertTypeId,
    onSelectedLocationsChange,
    selectedLocations,
    selectedSpeeds,
    onSelectedSpeedsChange,
    offhourStartHour,
    setOffhourStartHour,
    offhourStartMinute,
    setOffhourStartMinute,
    offhourEndHour,
    setOffhourEndHour,
    offhourEndMinute,
    setOffhourEndMinute,
    offhourLocations,
    setOffhourLocations,
    days,
    setDays,
    locations,
  } = props;

  /** Global State */
  const [{ cols }] = useApp();

  /** Local State */
  //const [selectedLocations, setSelectedLocations] = useState([]);

  /** GraphQL */
  /*
  const { data: locations } = useQuery(GQL.Queries.locations, {
    onCompleted: (res) => {
      if (res && res.locations) {
        setSelectedLocations(
          res.locations.map((location) => ({ in: false, out: false }))
        );
      }
    },
  });
  */

  /** Constants */
  const speeds = [55, 60, 65, 70, 75, 80, 85, 90, 95, 100];
  const widgetWidth = isMobile
    ? window.innerWidth - 40
    : (window.innerWidth / cols) * props.widget.dataGrid.w -
      10 * props.widget.dataGrid.w;

  const toggleLocationTrigger = (index, direction) => {
    const newSelectedLocations = selectedLocations;
    newSelectedLocations.splice(index, 1, {
      ...selectedLocations[index],
      ...(direction === "in"
        ? { in: !selectedLocations[index].in }
        : { out: !selectedLocations[index].out }),
    });
    onSelectedLocationsChange([...newSelectedLocations]);
  };

  const renderLocationSelector = () => {
    if (locations && locations.locations) {
      const boxWidth = 65;
      const detailWidth = parseInt(widgetWidth * 0.95);
      const colWidth = parseInt(detailWidth / boxWidth);
      const xs =
        colWidth > boxWidth ? parseInt(24 / colWidth) : parseInt(48 / colWidth);
      const cols = 24 / xs;
      const rows = Math.ceil(locations.locations.length / cols);

      return (
        <div>
          <Grid
            fluid
            style={
              {
                //border: "1px solid red"
              }
            }
          >
            {locations && locations.locations
              ? Array.from(Array(rows), (x, i) => i).map((row) => {
                  const rowLocations = locations.locations.slice(
                    row * cols,
                    row * cols + cols
                  );
                  return (
                    <Row key={"row-" + row * cols}>
                      {rowLocations.map((location, col) => {
                        return (
                          <Col
                            xs={xs}
                            key={"row-" + row * cols + "-col-" + col}
                            style={{
                              //border: "1px solid blue", // TEMP
                              padding: "5px",
                              paddingBottom: "20px",
                              position: "relative",
                              /*
                            backgroundColor:
                              selectedLocations &&
                              selectedLocations[row * cols + col] &&
                              (selectedLocations[row * cols + col].in ||
                                selectedLocations[row * cols + col].out) &&
                              "#EEE",
                            */
                            }}
                          >
                            <div>
                              <LocationMap
                                rowData={location}
                                coordinates={location.coordinates}
                                width="65px"
                                height="65px"
                                className="small-shadow"
                                style={{
                                  display: "block",
                                  margin: "auto",
                                }}
                              />
                            </div>
                            <div>
                              <small>{location.name}</small>
                            </div>
                            <Grid
                              fluid
                              style={{
                                position: "absolute",
                                top: "-10px",
                                left: 0,
                                width: "100%",
                                zIndex: 99999,
                              }}
                            >
                              <Row>
                                <Col xs={12}>
                                  <Avatar
                                    size="xs"
                                    circle
                                    className="small-shadow"
                                    style={{
                                      backgroundColor: "#777",
                                      opacity:
                                        selectedLocations &&
                                        selectedLocations[row * cols + col] &&
                                        selectedLocations[row * cols + col].in
                                          ? 1.0
                                          : 0.5,
                                    }}
                                    onClick={() => {
                                      toggleLocationTrigger(
                                        row * cols + col,
                                        "in"
                                      );
                                    }}
                                  >
                                    <Icon icon="faChevronDown" />
                                  </Avatar>
                                </Col>
                                <Col xs={12}>
                                  <Avatar
                                    size="xs"
                                    circle
                                    className="small-shadow"
                                    style={{
                                      backgroundColor: "#777",
                                      opacity:
                                        selectedLocations &&
                                        selectedLocations[row * cols + col] &&
                                        selectedLocations[row * cols + col].out
                                          ? 1.0
                                          : 0.5,
                                    }}
                                    onClick={() => {
                                      toggleLocationTrigger(
                                        row * cols + col,
                                        "out"
                                      );
                                    }}
                                  >
                                    <Icon icon="faChevronUp" />
                                  </Avatar>
                                </Col>
                              </Row>
                            </Grid>
                            {selectedLocations &&
                              selectedLocations[row * cols + col] &&
                              (selectedLocations[row * cols + col].in ||
                                selectedLocations[row * cols + col].out) && (
                                <Avatar
                                  circle
                                  size="sm"
                                  style={{
                                    backgroundColor: "#8AC054",
                                    position: "absolute",
                                    left: 0,
                                    top: "50px",
                                    zIndex: 99999,
                                  }}
                                  className="small-shadow"
                                >
                                  <Icon icon="faCheck" />
                                </Avatar>
                              )}
                          </Col>
                        );
                      })}
                    </Row>
                  );
                })
              : null}
          </Grid>
        </div>
      );
    } else {
      return <Loader />;
    }
  };

  const renderSpeedSelector = () => {
    if (speeds && speeds.length > 0) {
      const boxWidth = 50;
      const detailWidth = parseInt(widgetWidth * 0.95);
      const colWidth = parseInt(detailWidth / boxWidth);
      const xs =
        colWidth > boxWidth ? parseInt(24 / colWidth) : parseInt(48 / colWidth);
      const cols = 24 / xs;
      const rows = Math.ceil(speeds.length / cols);

      return (
        <div>
          <Grid
            fluid
            style={{
              //border: "1px solid red"
              width: "100%",
            }}
          >
            {speeds
              ? Array.from(Array(rows), (x, i) => i).map((row) => {
                  const rowSpeeds = speeds.slice(row * cols, row * cols + cols);
                  return (
                    <Row key={"row-" + row * cols}>
                      {rowSpeeds.map((speed, col) => {
                        return (
                          <div>
                            <Avatar
                              key={"speed-" + speed}
                              style={{
                                backgroundColor: "white",
                                position: "relative",
                                fontWeight: "bold",
                                color: "black",
                                border: "1px solid black",
                                margin: 0,
                                padding: "2pt",
                                width: "65px",
                                height: "65px",
                                float: "left",
                                marginRight: 10,
                                marginBottom: 10,
                                display: "inline-block",
                                opacity: selectedSpeeds.includes(speed)
                                  ? 1.0
                                  : 0.3,
                                cursor: "pointer",
                                //transform: "rotate(90deg)",
                                //transformOrigin: "right top",
                              }}
                              className="shadow"
                              onClick={() => {
                                if (selectedSpeeds.includes(speed)) {
                                  const newSelectedSpeeds = selectedSpeeds;
                                  newSelectedSpeeds.splice(
                                    selectedSpeeds.indexOf(speed),
                                    1
                                  );
                                  onSelectedSpeedsChange([
                                    ...newSelectedSpeeds,
                                  ]);
                                } else {
                                  onSelectedSpeedsChange([
                                    ...selectedSpeeds,
                                    speed,
                                  ]);
                                }
                              }}
                            >
                              <div style={{ padding: "2px" }}>
                                <div style={{ fontSize: "8px", padding: 0 }}>
                                  SPEED
                                </div>
                                <div style={{ fontSize: "8px", padding: 0 }}>
                                  LIMIT
                                </div>
                                <div style={{ padding: 0 }}>{speed}</div>
                              </div>
                            </Avatar>
                          </div>
                        );
                      })}
                    </Row>
                  );
                })
              : null}
          </Grid>
        </div>
      );
    } else {
      return <Loader />;
    }

    return (
      <div style={{ padding: 10, paddingTop: 0, position: "relative" }}>
        {speeds.map((speed) => (
          <div>
            <Avatar
              key={"speed-" + speed}
              style={{
                backgroundColor: "white",
                position: "relative",
                fontWeight: "bold",
                color: "black",
                border: "1px solid black",
                margin: 0,
                padding: "2pt",
                width: "65px",
                height: "65px",
                float: "left",
                marginRight: 10,
                marginBottom: 10,
                display: "inline-block",
                opacity: selectedSpeeds.includes(speed) ? 1.0 : 0.3,
                cursor: "pointer",
                //transform: "rotate(90deg)",
                //transformOrigin: "right top",
              }}
              className="shadow"
              onClick={() => {
                if (selectedSpeeds.includes(speed)) {
                  const newSelectedSpeeds = selectedSpeeds;
                  newSelectedSpeeds.splice(selectedSpeeds.indexOf(speed), 1);
                  onSelectedSpeedsChange([...newSelectedSpeeds]);
                } else {
                  onSelectedSpeedsChange([...selectedSpeeds, speed]);
                }
              }}
            >
              <div style={{ padding: "2px" }}>
                <div style={{ fontSize: "8px", padding: 0 }}>SPEED</div>
                <div style={{ fontSize: "8px", padding: 0 }}>LIMIT</div>
                <div style={{ padding: 0 }}>{speed}</div>
              </div>
            </Avatar>
          </div>
        ))}
      </div>
    );
  };

  const renderOffhourLocations = () => {
    if (locations && locations.locations) {
      const boxWidth = 65;
      const detailWidth = parseInt(widgetWidth * 0.95);
      const colWidth = parseInt(detailWidth / boxWidth);
      const xs =
        colWidth > boxWidth ? parseInt(24 / colWidth) : parseInt(48 / colWidth);
      const cols = 24 / xs;
      const rows = Math.ceil(locations.locations.length / cols);
      const widthPercentage =
        100 / Math.min(cols, locations.locations.length) + "%";

      console.log({ widthPercentage });

      return (
        <div
          style={{
            //border: "1px solid red",
            alignContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Grid
            fluid
            style={{
              width: "100%",
              //border: "1px solid red"
            }}
          >
            {locations && locations.locations
              ? Array.from(Array(rows), (x, i) => i).map((row) => {
                  const rowLocations = locations.locations.slice(
                    row * cols,
                    row * cols + cols
                  );
                  return (
                    <Row key={"row-" + row * cols} style={{ width: "100%" }}>
                      {rowLocations.map((location, col) => {
                        return (
                          <Col
                            //xs={xs}
                            key={"row-" + row * cols + "-col-" + col}
                            style={{
                              width: widthPercentage,
                              //border: "1px solid blue", // TEMP
                              padding: "5px",
                              //paddingBottom: "20px",
                              position: "relative",
                              /*
															backgroundColor:
																selectedLocations &&
																selectedLocations[row * cols + col] &&
																(selectedLocations[row * cols + col].in ||
																	selectedLocations[row * cols + col].out) &&
																"#EEE",
															*/
                            }}
                          >
                            <div
                              onClick={() => {
                                if (offhourLocations.includes(location.id)) {
                                  setOffhourLocations(
                                    offhourLocations.filter(
                                      (id) => id != location.id
                                    )
                                  );
                                } else {
                                  setOffhourLocations([
                                    ...offhourLocations,
                                    location.id,
                                  ]);
                                }
                              }}
                            >
                              <LocationMap
                                rowData={location}
                                coordinates={location.coordinates}
                                width="65px"
                                height="65px"
                                className={
                                  offhourLocations.includes(location.id)
                                    ? "big-shadow"
                                    : "small-shadow"
                                }
                                style={{
                                  display: "block",
                                  margin: "auto",
                                  border:
                                    offhourLocations.includes(location.id) &&
                                    "4px solid #8AC054",
                                }}
                              />
                            </div>
                            <div>
                              <small
                                style={{
                                  color:
                                    !offhourLocations.includes(location.id) &&
                                    "#AAAAAA",
                                }}
                              >
                                {location.name}
                              </small>
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  );
                })
              : null}
          </Grid>
        </div>
      );
    } else {
      return <Loader />;
    }
  };

  const renderOffHourSelector = () => {
    return (
      <Form>
        <label>Monitored Locations</label>
        <Form.Group>{renderOffhourLocations()}</Form.Group>
        <label>Selected Days</label>
        <Form.Group>
          {daysOfTheWeek.map((day, index) => (
            <>
              <Avatar
                size="lg"
                style={{
                  backgroundColor: days.includes(day.value) && "#404040",
                  cursor: "pointer",
                }}
                circle
                onClick={() => {
                  if (days.includes(day.value)) {
                    setDays(days.filter((value) => value != day.value));
                  } else {
                    setDays([...days, day.value].sort());
                  }
                }}
              >
                {day.label}
              </Avatar>
              {index < daysOfTheWeek.length && " "}
            </>
          ))}
        </Form.Group>
        <Form.Group>
          <InputGroup style={{ width: "100%" }}>
            <InputGroupAddon style={{ width: "50%" }}>
              <SelectPicker
                data={hours}
                style={{ width: "100%" }}
                searchable={false}
                cleanable={false}
                placeholder="Hour"
                onChange={setOffhourStartHour}
                value={offhourStartHour}
              />
            </InputGroupAddon>
            <InputGroupAddon style={{ width: "50%" }}>
              <SelectPicker
                data={minutes}
                style={{ width: "100%" }}
                searchable={false}
                cleanable={false}
                placeholder="Minute"
                onChange={setOffhourStartMinute}
                value={offhourStartMinute}
              />
            </InputGroupAddon>
          </InputGroup>
          <Divider>to</Divider>
          <InputGroup style={{ width: "100%" }}>
            <InputGroupAddon style={{ width: "50%" }}>
              <SelectPicker
                data={hours}
                style={{ width: "100%" }}
                searchable={false}
                cleanable={false}
                placeholder="Hour"
                onChange={setOffhourEndHour}
                value={offhourEndHour}
              />
            </InputGroupAddon>
            <InputGroupAddon style={{ width: "50%" }}>
              <SelectPicker
                data={minutes}
                style={{ width: "100%" }}
                searchable={false}
                cleanable={false}
                placeholder="Minute"
                onChange={setOffhourEndMinute}
                value={offhourEndMinute}
              />
            </InputGroupAddon>
          </InputGroup>
        </Form.Group>
      </Form>
    );
  };

  return (
    <div style={{ paddingBottom: "15px" }}>
      {alertTypeId === 1 ? renderLocationSelector() : null}
      {alertTypeId === 2 ? renderSpeedSelector() : null}
      {alertTypeId === 3 ? renderOffHourSelector() : null}
    </div>
  );
};
