import React from "react";
import { Avatar, Grid, Row, Col } from "rsuite";
import Icon from "../Icon";
export default (props) => {
  const colors = [
    "#D64545",
    "#E57A45",
    "#E5B045",
    "#7DB545",
    "#45C5B0",
    "#45B0E5",
    "#5485E5",
    "#9065E5",
    "#E54594",
    "#7D8995",
    "#333333",
    "",
  ];
  const cols = 3;
  return (
    <Grid fluid>
      {[0, 1, 2, 3].map((row) => (
        <Row key={"dcp-" + row}>
          {colors
            .slice(row * cols, row * cols + cols)
            .map((backgroundColor, index) => {
              return (
                <Col xs={24 / cols} key={"dcp-" + row + "-" + index}>
                  <Avatar
                    key={"dcp-avatar-" + row + "-" + index}
                    onClick={() => {
                      props.onClick && props.onClick(backgroundColor);
                    }}
                    size="md"
                    className="small-shadow"
                    style={{
                      cursor: "pointer",
                      backgroundColor:
                        backgroundColor.length > 0
                          ? backgroundColor
                          : "#DDDDDD",
                    }}
                  >
                    {backgroundColor.length === 0 && (
                      <Icon icon="faBan" width={15} />
                    )}
                    {props.color === backgroundColor &&
                      backgroundColor.length > 1 && <Icon icon="faCheck" />}
                  </Avatar>
                </Col>
              );
            })}
        </Row>
      ))}
    </Grid>
  );
};
