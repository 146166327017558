import React, { useState, useRef } from "react";
import {
  Avatar,
  Button,
  Grid,
  Row,
  Col,
  InputGroup,
  CheckPicker,
  Checkbox,
  Toggle,
} from "rsuite";
import { useQuery } from "@apollo/react-hooks";
import Icon from "../Icon";
import GQL from "./graphql";

const footerStyles = {
  padding: "10px 2px",
  borderTop: "1px solid #e5e5e5",
};

const footerButtonStyle = {
  float: "right",
  marginRight: 10,
  marginTop: 2,
};

export default (props) => {
  const {
    useLocationFilter,
    setUseLocationFilter,
    filteredLocationIds,
    setFilteredLocationIds,
    filterLocations,
    setFilterLocations,
  } = props;
  const picker = useRef();
  const {
    data: locations,
    loading: loadingLocations,
    refetch: reloadLocations,
  } = useQuery(GQL.Queries.locations, {
    fetchPolicy: "network-only",
  });

  const handleChange = (value) => {
    setFilteredLocationIds(value);
    setUseLocationFilter(true);
  };
  const handleCheckAll = (value, checked) => {
    setFilteredLocationIds(
      checked ? locations.locations.map((location) => location.id) : []
    );
    setUseLocationFilter(true);
  };

  return (
    <>
      <Checkbox
        checked={useLocationFilter}
        onChange={(value, checked) => setUseLocationFilter(checked)}
      />{" "}
      <CheckPicker
        ref={picker}
        value={filteredLocationIds}
        onChange={handleChange}
        data={
          loadingLocations
            ? [{ value: 0, label: "Loading..." }]
            : locations.locations.map((location) => ({
                value: location.id,
                label: location.name,
              }))
        }
        size="lg"
        placement="autoVerticalEnd"
        renderExtraFooter={() => (
          <div style={footerStyles}>
            <div>
              <Checkbox
                indeterminate={
                  filteredLocationIds.length > 0 &&
                  filteredLocationIds.length < locations.locations.length
                }
                checked={
                  filteredLocationIds.length === locations.locations.length
                }
                onChange={handleCheckAll}
              >
                All Locations
              </Checkbox>
            </div>
            <div
              style={{
                alignContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Toggle
                checked={filterLocations}
                onChange={setFilterLocations}
                checkedChildren={<span>Inside</span>}
                unCheckedChildren={<span>Outside</span>}
              />
            </div>
            {/*
					<Button
            style={footerButtonStyle}
            appearance="primary"
            size="sm"
            onClick={() => {
              picker.current.close();
            }}
          >
            Ok
          </Button>
					*/}
          </div>
        )}
      />
    </>
  );
};
