import React, { useState } from "react";
import { Toggle } from "rsuite";

import Icon from "../../components/Icon";
import { useMutation } from "@apollo/react-hooks";

import GQL from "./graphql";

export default (props) => {
  const [active, setActive] = useState(props.active);
  const [updateUser] = useMutation(GQL.Mutations.updateUser);
  const { id, disabled } = props;

  const setActiveAndSave = (active) => {
    setActive(active);
    updateUser({
      variables: {
        id,
        active,
      },
    });
  };

  return (
    <Toggle
      //className="inside-shadow"
      checked={active}
      onChange={(active) => {
        setActiveAndSave(active);
      }}
      checkedChildren={<Icon icon="faCheck" />}
      unCheckedChildren={<Icon icon="faBan" />}
      style={{
        margin: 0,
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%) translateX(-50%)",
        //borderRadius: "50%",
      }}
      disabled={disabled}
    />
  );
};
