import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import sha1 from "sha1";
import Cookie from "js-cookie";
import less from "less";
import {
  Animation,
  Button,
  Container,
  Form,
  //FormGroup,
  //Icon,
  Input,
  InputGroup,
  Message,
  Loader,
} from "rsuite";
import Icon from "../../components/Icon";

import { useApp } from "../../AppStore";
import GQL from "./graphql";

const FaSvgIcon = ({ faIcon, ...rest }) => {
  const { width, height, svgPathData } = faIcon;
  return (
    <svg
      {...rest}
      viewBox={`0 0 ${width} ${height}`}
      width="2em"
      height="2em"
      fill="currentColor"
    >
      <path d={svgPathData}></path>
    </svg>
  );
};
const { Group: FormGroup } = Form;
const hexToHSL = (H) => {
  // Convert hex to RGB first
  let r = 0,
    g = 0,
    b = 0;
  if (H.length === 4) {
    r = "0x" + H[1] + H[1];
    g = "0x" + H[2] + H[2];
    b = "0x" + H[3] + H[3];
  } else if (H.length === 7) {
    r = "0x" + H[1] + H[2];
    g = "0x" + H[3] + H[4];
    b = "0x" + H[5] + H[6];
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  let cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0;

  if (delta === 0) h = 0;
  else if (cmax === r) h = ((g - b) / delta) % 6;
  else if (cmax === g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return {
    h,
    s,
    l,
  }; //"hsl(" + h + "," + s + "%," + l + "%)";
};

export default () => {
  const isMobile = window.innerWidth < 1024;
  const [{ isLoggedIn }] = useApp();
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const { data, loading } = useQuery(GQL.Queries.accountFromDomain, {
    variables: {
      domain: window.location.hostname,
    },
    onCompleted: (res) => {
      if (res && res.accountFromDomain && res.accountFromDomain.primaryColor) {
        less.modifyVars({
          "@base-color": res.accountFromDomain.primaryColor,
        });
        less.refresh();
        const { h, s, l } = hexToHSL(res.accountFromDomain.primaryColor);

        document.documentElement.style.setProperty("--base-color-h", h);
        document.documentElement.style.setProperty("--base-color-s", s + "%");
        document.documentElement.style.setProperty("--base-color-l", l + "%");
      } else {
        less.modifyVars({
          "@base-color": "skyblue",
        });
        less.refresh();
      }
    },
  });
  const [login] = useMutation(GQL.Mutations.login, {
    onCompleted: async ({ login }) => {
      if (login && login.token && login.token.length > 0) {
        const { token } = login;
        if (token !== null && token.length > 0) {
          Cookie.set("atles-geofleetos-token", token);
          window.location = "/";
        }
      } else {
        setError("Invalid Credentials.");
      }
    },
  });
  const [requestPasswordReset] = useMutation(
    GQL.Mutations.requestResetPassword,
    {
      onCompleted: (res) => {
        /*
      setMessage("An email should arrive shortly to the email provided below if it is associated with a valid user.");
      setShowForgotPassword(false);
      setTimeout(() => setMessage(""), 5000);
      */
      },
    }
  );

  return !loading ? (
    <Container
      style={{
        margin: "auto",
        marginTop: !isMobile && "100px",
        width: isMobile ? "100%" : "400px",
        backgroundColor: "transparent",
      }}
    >
      <div style={{ padding: "10px", textAlign: "center" }}>
        <Animation.Slide
          in={!isLoggedIn}
          transitionAppear={true}
          unmountOnExit={true}
          timeout={0}
          placement="top"
        >
          <img
            src={
              data && data.accountFromDomain && data.accountFromDomain.logo
                ? data.accountFromDomain.logo
                : "images/geofleetos.png"
            }
            style={{ width: "200px" }}
            alt="logo"
          />
        </Animation.Slide>
      </div>
      {message.length > 0 && <Message description={message} />}
      <Animation.Slide
        in={!isLoggedIn}
        transitionAppear={true}
        unmountOnExit={true}
        timeout={0}
        placement="bottom"
      >
        <div style={{ textAlign: "center" }}>
          <Container
            className="widget"
            style={{
              padding: "15px",
              margin: "10px",
            }}
          >
            <Form fluid layout="vertical">
              {error.length > 0 && (
                <FormGroup>
                  <Message
                    showIcon
                    type="error"
                    title={error}
                    description="Please try again."
                  />
                </FormGroup>
              )}
              {showForgotPassword ? (
                <>
                  <FormGroup>
                    <InputGroup style={{ width: isMobile ? "95%" : "100%" }}>
                      <InputGroup.Addon>
                        <Icon icon="faAt" width={15} />
                      </InputGroup.Addon>
                      <Input
                        name="email"
                        placeholder="Email"
                        onChange={setEmail}
                        value={email}
                        onKeyDown={(event) => {
                          event.keyCode === 13 &&
                            requestPasswordReset({
                              variables: {
                                email,
                              },
                            });
                          setMessage(
                            "An email should arrive shortly to the email provided below if it is associated with a valid user."
                          );
                          setShowForgotPassword(false);
                          setTimeout(() => setMessage(""), 5000);
                        }}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <Button
                      style={{ width: isMobile ? "95%" : "100%" }}
                      size="lg"
                      appearance="primary"
                      onClick={() => {
                        requestPasswordReset({
                          variables: {
                            email,
                          },
                        });
                        setMessage(
                          "An email should arrive shortly to the email provided below if it is associated with a valid user."
                        );
                        setShowForgotPassword(false);
                        setTimeout(() => setMessage(""), 5000);
                      }}
                      className="shadow-lightblue button-primary"
                    >
                      <span>
                        <Icon icon="faEnvelope" width={15} /> Submit
                      </span>
                    </Button>
                    <Button
                      style={{ width: "100%" }}
                      size="lg"
                      appearance="subtle"
                      onClick={() => setShowForgotPassword(false)}
                    >
                      <span>
                        <Icon icon="faSignIn" width={15} /> Back to Login
                      </span>
                    </Button>
                  </FormGroup>
                </>
              ) : (
                <>
                  <FormGroup>
                    <InputGroup style={{ width: "100%" }}>
                      <InputGroup.Addon>
                        <Icon icon="faAt" width={15} />
                      </InputGroup.Addon>
                      <Input
                        name="email"
                        placeholder="Email"
                        onChange={setEmail}
                        value={email}
                        onKeyDown={(event) =>
                          event.keyCode === 13 &&
                          login({
                            variables: {
                              email,
                              password: sha1(password),
                            },
                          })
                        }
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup style={{ width: "100%" }}>
                      <InputGroup.Addon>
                        <Icon icon="faKey" width={15} />
                      </InputGroup.Addon>
                      <Input
                        name="password"
                        placeholder="Password"
                        type="password"
                        onChange={setPassword}
                        value={password}
                        onKeyDown={(event) =>
                          event.keyCode === 13 &&
                          login({
                            variables: {
                              email,
                              password: sha1(password),
                            },
                          })
                        }
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <Button
                      style={{
                        width: "100%",
                        alignContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                      size="lg"
                      appearance="primary"
                      onClick={() => {
                        login({
                          variables: {
                            email,
                            password: sha1(password),
                          },
                        });
                      }}
                      className="shadow-lightblue button-primary"
                    >
                      <span
                        style={{
                          width: "100%",
                          alignContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        <Icon icon="faSignIn" width={15} /> Login
                      </span>
                    </Button>
                    <Button
                      style={{
                        width: "100%",
                        alignContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                      size="lg"
                      appearance="subtle"
                      onClick={() => setShowForgotPassword(true)}
                    >
                      <span
                        style={{
                          width: "100%",
                          alignContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        <Icon icon="faEnvelope" width={15} /> Forgot Password
                      </span>
                    </Button>
                  </FormGroup>
                </>
              )}
            </Form>
          </Container>
          <i>
            <small>Powered by Conduit™</small>
          </i>
        </div>
      </Animation.Slide>
    </Container>
  ) : (
    <Loader content="Loading..." />
  );
};
