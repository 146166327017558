import gql from "graphql-tag";

export default {
  Queries: {
    location: gql`
      query location($id: Int!) {
        location(id: $id) {
          id
          name
          radius
          coordinates
        }
      }
    `,
    events: gql`
      query events($deviceId: Int!, $start: String!, $end: String!) {
        events(deviceId: $deviceId, start: $start, end: $end, asc: true)
      }
    `,
    trips: gql`
      query trips($deviceId: Int!, $start: String!, $end: String!) {
        trips(deviceId: $deviceId, start: $start, end: $end) {
          deviceId
          durationInSeconds
          distanceInMeters
          topSpeed
          averageSpeed
          startTime
          startLocation {
            latitude
            longitude
          }
          endTime
          endLocation {
            latitude
            longitude
          }
          events
        }
      }
    `,
    idleReport: gql`
      query idleReport($deviceId: Int!, $start: String!, $end: String!) {
        idleReport(deviceId: $deviceId, start: $start, end: $end) {
          deviceId
          durationInSeconds
          startTime
          location {
            latitude
            longitude
          }
          endTime
          address
        }
      }
    `,
    locationsReport: gql`
      query locationsReport($deviceId: Int!, $start: String!, $end: String!) {
        locationsReport(deviceId: $deviceId, start: $start, end: $end) {
          id
          deviceId
          device {
            name
          }
          locationId
          location {
            name
          }
          createdAt
          exitedAt
        }
      }
    `,
    devices: gql`
      {
        devices {
          id
          name
          serial
          imei
          simnumber
          conduitDeviceId
          lastEvent
        }
      }
    `,
    users: gql`
      {
        users {
          id
          email
          firstname
          lastname
          avatar
        }
      }
    `,
  },
  Mutations: {},
};
