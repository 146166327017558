import React, { useState, useEffect } from "react";
import {
  Avatar,
  Form,
  //FormGroup,
  InputGroup,
  Input,
  Button,
  //Icon,
  Grid,
  Row,
  Col,
  ButtonGroup,
  Panel,
  Whisper,
  Tooltip,
  TreePicker,
  Loader,
} from "rsuite";

import { useMutation, useQuery } from "@apollo/react-hooks";
import Icon from "../../components/Icon";
import GQL from "./graphql";
import { useApp } from "../../AppStore";

import Map from "./map";

let lang = null;
const { Group: FormGroup } = Form;

export default (props) => {
  const { selectedAccount, bulkActionType, selectedDevices } = props;
  const [{ user }] = useApp();
  const [accountId, setAccountId] = useState(null);
  const [command, setCommand] = useState("");
  const { data: childrenAccounts } = useQuery(GQL.Queries.accounts, {
    variables: {
      parentId: user.account.id,
    },
  });
  const [updateDevice] = useMutation(GQL.Mutations.updateDevice);
  const [queueOta] = useMutation(GQL.Mutations.queueOta);
  const [deleteDevice] = useMutation(GQL.Mutations.deleteDevice);

  useEffect(() => {
    /** Language File */
    lang =
      user && user.language
        ? require("../../languages/" + user.language + ".js").default
        : lang;
  }, [user]);

  return (
    <div
      className="shadow"
      style={{
        animation: "0.15s ease-out 0s 1 fadeAndSlideIn",
        borderRadius: "1px",
        position: "absolute",
        padding: 0,
        backgroundColor: "white",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 9999,
        width: "95%",
        height: "95%",
        margin: "auto",
        overflowY: "scroll",
      }}
    >
      <div
        style={{
          padding: "10px",
          color: "white",
          position: "relative",
          width: "100%",
        }}
        className="small-shadow primary-gradient"
      >
        <div style={{ width: "100%", textAlign: "left" }}>
          <Grid fluid>
            <Row>
              <Col xs={12} style={{ textAlign: "left" }}>
                <big>
                  {lang !== null
                    ? bulkActionType === "transfer"
                      ? lang.bulkTransfer
                      : bulkActionType === "ota"
                      ? lang.bulkOta
                      : bulkActionType === "delete"
                      ? lang.bulkDelete
                      : "Bulk Action"
                    : "Bulk Action"}
                </big>
              </Col>
              <Col xs={12} style={{ textAlign: "right" }}>
                <Avatar
                  size="xs"
                  style={{ backgroundColor: "#D64545" }}
                  className="action-button"
                  onClick={() => {
                    props.close && props.close();
                  }}
                >
                  <Icon icon="faCircleXmark" />
                </Avatar>
              </Col>
            </Row>
            <Row>
              <Col xs={24} style={{ textAlign: "center" }}>
                <div>
                  <img
                    style={{ borderRadius: "5px" }}
                    width={100}
                    src={
                      selectedAccount ? selectedAccount.logo : user.account.logo
                    }
                    alt="logo"
                  />
                </div>
                <big>
                  {selectedAccount ? selectedAccount.name : user.account.name}
                </big>
              </Col>
            </Row>
          </Grid>
        </div>
      </div>
      <Panel>
        <Form>
          <FormGroup>
            <fieldset
              style={{
                borderRadius: "5px",
                border: "1px solid #e7e7e7",
                padding: "5px",
              }}
            >
              <legend> {selectedDevices.length} Device(s) Selected </legend>
              {selectedDevices.map((rowData) => {
                const borderStyle =
                  "6px solid " + (rowData.color ? rowData.color : "white");
                return (
                  <Whisper
                    trigger="hover"
                    placement="top"
                    speaker={<Tooltip>{rowData.name}</Tooltip>}
                  >
                    <div style={{ float: "left", margin: "5px" }}>
                      <Map
                        rowData={rowData}
                        width="45px"
                        height="45px"
                        icon={rowData.icon}
                        style={{
                          borderTop: borderStyle,
                        }}
                      />
                    </div>
                  </Whisper>
                );
              })}
            </fieldset>
          </FormGroup>
          {bulkActionType === "transfer" ? (
            <FormGroup>
              {childrenAccounts && childrenAccounts.accounts ? (
                <TreePicker
                  defaultExpandAll
                  placeholder="Choose an account"
                  data={[
                    {
                      value: user.account.id,
                      label: (
                        <span>
                          <Icon icon="faStar" /> {user.account.name}
                        </span>
                      ),
                      children: childrenAccounts.accounts.map(
                        ({
                          id: value,
                          name: label,
                          childrenTree: children,
                        }) => ({
                          value,
                          label,
                          children,
                        })
                      ),
                    },
                  ]}
                  value={accountId}
                  onChange={setAccountId}
                />
              ) : (
                <Loader />
              )}
            </FormGroup>
          ) : null}
          {bulkActionType === "ota" ? (
            <FormGroup>
              <InputGroup>
                <InputGroup.Addon>
                  <Icon icon="faTerminal" />
                </InputGroup.Addon>
                <Input
                  placeholder="OTA Command"
                  value={command}
                  onChange={setCommand}
                />
              </InputGroup>
            </FormGroup>
          ) : null}
          {bulkActionType === "delete" ? (
            <FormGroup>
              <big>Bulk deletion CANNOT be undone!</big>
            </FormGroup>
          ) : null}
          <FormGroup>
            <ButtonGroup>
              <Button
                appearance="primary"
                className="small-shadow"
                color="green"
                onClick={() => {
                  if (bulkActionType === "transfer") {
                    let promises = [];
                    selectedDevices.forEach(({ id }) =>
                      promises.push(
                        updateDevice({
                          variables: {
                            id,
                            accountId,
                          },
                        })
                      )
                    );
                    Promise.all(promises).then(() => {
                      setAccountId(null);
                      props.close && props.close();
                      props.refetch && props.refetch();
                    });
                  } else if (bulkActionType === "ota") {
                    let promises = [];
                    selectedDevices.forEach(({ id }) => {
                      promises.push(queueOta({ variables: { id, command } }));
                    });
                    Promise.all(promises).then(() => {
                      setCommand("");
                      props.close && props.close();
                      props.refetch && props.refetch();
                    });
                  } else if (bulkActionType === "delete") {
                    let promises = [];
                    selectedDevices.forEach(({ id }) =>
                      promises.push(deleteDevice({ variables: { id } }))
                    );
                    Promise.all(promises).then(() => {
                      props.close && props.close();
                      props.refetch && props.refetch();
                    });
                  }
                  /*
                  // SAVE IMEIS
                  const data = imeis
                    .trim()
                    .split("\n")
                    .map(imei => imei.trim());

                  if (data && data.length > 0) {
                    let promises = [];
                    data.forEach(imei => {
                      promises.push(
                        createDevice({
                          variables: {
                            accountId: selectedAccount
                              ? selectedAccount.id
                              : user.account.id,
                            name: imei,
                            imei,
                            serial: imei,
                            deviceTypeId
                          }
                        })
                      );
                    });
                    Promise.all(promises).then(res => {
                      props.close && props.close();
                      props.refetch && props.refetch();
                    });
                  }
                  */
                }}
              >
                <span>
                  <Icon icon="faCheck" /> Confirm
                </span>
              </Button>
              <Button
                appearance="primary"
                className="small-shadow"
                color="orange"
                onClick={() => {
                  props.close && props.close();
                }}
              >
                <Icon icon="faBan" width={15} />{" "}
                {lang !== null ? lang.cancel : "Cancel"}
              </Button>
            </ButtonGroup>
          </FormGroup>
        </Form>
      </Panel>
    </div>
  );
};
