import React, { useState, useCallback } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Loader } from "rsuite";

import Icon from "../../components/Icon";
import GQL from "./graphql";

export default (props) => {
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [, updateState] = React.useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const { data, loading } = useQuery(GQL.Queries.templateScreens, {
    onCompleted: (res) => {
      if (props.selectAll === true) {
        setSelectedTemplates(res.templateScreens.map(({ id }) => id));
        props.setTemplateScreenIds(res.templateScreens.map(({ id }) => id));
      }
    },
  });

  const toggleTemplateSelected = (id) => {
    const index = selectedTemplates.indexOf(id);

    if (index > -1) {
      let newSelectedTemplates = selectedTemplates;
      newSelectedTemplates.splice(index, 1);
      setSelectedTemplates(newSelectedTemplates);
      forceUpdate();
      props.onChange && props.onChange(newSelectedTemplates);
    } else {
      setSelectedTemplates([...selectedTemplates, id]);
      props.onChange && props.onChange([...selectedTemplates, id]);
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <div className="template-screen-selector-container">
      {data &&
        data.templateScreens &&
        data.templateScreens.map((templateScreen) => {
          const borderColor =
            selectedTemplates.indexOf(templateScreen.id) > -1
              ? "skyblue"
              : "white";

          return (
            <div
              key={"template-" + templateScreen.id}
              className="template-screen-small small-shadow"
              onClick={() => {
                toggleTemplateSelected(templateScreen.id);
              }}
              style={{
                border: "3px solid " + borderColor,
              }}
            >
              <div className="template-screen-small-header">
                <small>
                  <Icon icon={templateScreen.icon} /> {templateScreen.title}
                </small>
              </div>
              <div className="template-screen-small-content">
                {templateScreen.widgets.map((widget) => {
                  const dataGrid = JSON.parse(widget.dataGrid);
                  const { x, y, w, h } = dataGrid;
                  const rowHeight = 20;
                  const colWidth = 18;
                  return (
                    <div
                      key={"template-widget-" + widget.id}
                      style={{
                        position: "absolute",
                        left: parseInt(x * colWidth),
                        top: parseInt(y * rowHeight),
                        width: parseInt(w * colWidth),
                        height: parseInt(h * rowHeight),
                        padding: "2px",
                        backgroundColor: "#e0e0e0",
                        border: "2px solid white",
                      }}
                    ></div>
                  );
                })}
              </div>
            </div>
          );
        })}
    </div>
  );
};
