import gql from "graphql-tag";

export default {
  Queries: {
    session: gql`
      query session($id: Int!) {
        session(id: $id) {
          id
          token
          userId
          user {
            id
            email
            language
            avatar
            accountId
            account {
              id
              name
              smAccess
              mapSystemId
              mapSystemApiKey
            }
          }
        }
      }
    `,
    accountFromDomain: gql`
      query accountFromDomain($domain: String!) {
        accountFromDomain(domain: $domain) {
          name
          logo
          domain
          primaryColor
          secondaryColor
        }
      }
    `
  },
  Mutations: {
    login: gql`
      mutation login($email: String!, $password: String!) {
        login(email: $email, password: $password) {
          token
        }
      }
    `,
    requestResetPassword: gql`
      mutation requestResetPassword($email: String!) {
        requestResetPassword(email: $email)
      }
    `,
  }
};
