import gql from "graphql-tag";

export default {
  Queries: {
    adminAccess: gql`
      query hasAccessToSection {
        hasAccessToSection(section: "Locations", access: "adminAccess")
      }
    `,
    deleteAccess: gql`
      query hasAccessToSection {
        hasAccessToSection(section: "Locations", access: "deleteAccess")
      }
    `,
    writeAccess: gql`
      query hasAccessToSection {
        hasAccessToSection(section: "Locations", access: "writeAccess")
      }
    `,
    readAccess: gql`
      query hasAccessToSection {
        hasAccessToSection(section: "Locations", access: "readAccess")
      }
    `,
    locations: gql`
      {
        locations {
          id
          name
          type
          radius
          coordinates
          address
        }
      }
    `,
    geocode: gql`
      query geocode($address: String!) {
        geocode(address: $address)
      }
    `,
    reverseGeocode: gql`
      query reverseGeocode($latitude: Float!, $longitude: Float!) {
        reverseGeocode(latitude: $latitude, longitude: $longitude)
      }
    `
  },
  Mutations: {
    createLocation: gql`
      mutation createLocation(
        $name: String!
        $type: String!
        $radius: Float!
        $coordinates: [[Float!]]
      ) {
        createLocation(
          name: $name
          type: $type
          radius: $radius
          coordinates: $coordinates
        ) {
          id
        }
      }
    `,
    updateLocation: gql`
      mutation updateLocation(
        $id: Int!
        $name: String
        $type: String
        $radius: Float
        $coordinates: [[Float!]]
      ) {
        updateLocation(
          id: $id
          name: $name
          type: $type
          radius: $radius
          coordinates: $coordinates
        )
      }
    `,
    deleteLocation: gql`
      mutation deleteLocation($id: Int!) {
        deleteLocation(id: $id)
      }
    `
  }
};
