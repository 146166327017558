import React, { useState, useEffect } from "react";
import {
  Form,
  Table,
  Panel,
  Button,
  //Icon,
  Grid,
  Row,
  Col,
  Loader,
} from "rsuite";

import Icon from "../../components/Icon";

import { useQuery } from "@apollo/react-hooks";

import Details from "./details";
import GQL from "./graphql";
import { useApp } from "../../AppStore";

import Map from "./map";
import Actions from "./actions";
import AccessDenied from "../../components/accessdenied";

const { Column, HeaderCell, Cell } = Table;
let lang = null;

export default (props) => {
  const isMobile = window.innerWidth < 1024;
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [{ user, cols }, actions] = useApp();
  const { data: readAccess, loading: loadingReadAccess } = useQuery(
    GQL.Queries.readAccess
  );
  const { data: writeAccess, loading: loadingWriteAccess } = useQuery(
    GQL.Queries.writeAccess
  );
  const { data: deleteAccess, loading: loadingDeleteAccess } = useQuery(
    GQL.Queries.deleteAccess
  );
  const { data: adminAccess, loading: loadingAdminAccess } = useQuery(
    GQL.Queries.adminAccess
  );
  const {
    data: locations,
    loading: loadingLocations,
    refetch: reloadLocations,
  } = useQuery(GQL.Queries.locations, {
    fetchPolicy: "network-only",
  });
  const widgetWidth =
    (window.innerWidth / cols) * props.widget.dataGrid.w -
    10 * props.widget.dataGrid.w;

  useEffect(() => {
    /** Language File */
    lang =
      user && user.language
        ? require("../../languages/" + user.language + ".js").default
        : lang;
  }, [user]);

  //console.log({ locations });
  const metersToLabel = (meters) => {
    switch (meters) {
      case 50:
        return lang !== null ? lang.fiftyMeters : "50 Meters";
      case 100:
        return lang !== null ? lang.oneHundredMeters : "100 Meters";
      case 0.5 * 1609.34:
        return lang !== null ? lang.halfMile : "1/2 Mile";
      case 1609.34:
        return lang !== null ? lang.oneMile : "1 Mile";
      case 5 * 1609.34:
        return lang !== null ? lang.fiveMiles : "5 Miles";
      case 10 * 1609.34:
        return lang !== null ? lang.tenMiles : "10 Miles";
      default:
        return meters;
    }
  };

  const renderContent = () => {
    return loadingLocations ||
      loadingAdminAccess ||
      loadingDeleteAccess ||
      loadingWriteAccess ||
      loadingReadAccess ? (
      <Loader content={lang !== null ? lang.loading : "Loading..."} />
    ) : readAccess.hasAccessToSection === true ? (
      <div
        style={{
          width: "100%",
          position: "relative",
        }}
      >
        <Grid
          fluid
          style={{
            fontSize: "1.1rem",
            fontWeight: "bold",
            width: "100%",
            textAlign: "left",
            padding: "15px",
            paddingBottom: "5px",
            borderBottom: "1px solid #eeeeee",
          }}
        >
          <Row>
            <Col xs={12} style={{ paddingTop: "7px" }}>
              <div>
                {lang !== null ? lang.location : "Location"}
                {locations &&
                locations.locations &&
                locations.locations.length !== 1
                  ? "s"
                  : null}
              </div>
              <div style={{ fontSize: "0.75rem", color: "#aaa" }}>
                {locations && locations.locations
                  ? locations.locations.length
                  : 0}{" "}
                {user && user.account && user.account.name
                  ? user.account.name
                  : lang !== null
                  ? lang.company
                  : "Company"}
              </div>
            </Col>
            <Col xs={12} style={{ textAlign: "right" }}>
              <Button
                //className="small-shadow"
                appearance="ghost"
                onClick={() => {
                  if (writeAccess.hasAccessToSection === true) {
                    setSelectedLocation(null);
                    setShowDetailsModal(!showDetailsModal);
                  }
                }}
                disabled={
                  writeAccess.hasAccessToSection !== true ||
                  loadingLocations ||
                  locations.locations.length >= 20
                }
              >
                {showDetailsModal ? (
                  <span>
                    <Icon icon="faBan" width={15} />{" "}
                    {lang !== null ? lang.cancel : "Cancel"}
                  </span>
                ) : (
                  <span>
                    <Icon icon="faSquarePlus" />{" "}
                    {lang !== null ? lang.newLocation : "New Location"}
                  </span>
                )}
              </Button>
            </Col>
          </Row>
        </Grid>
        <Table
          virtualized={false}
          height={
            isMobile
              ? window.innerHeight - 230
              : (props.baseHeight + 10) * props.h - 125
          }
          data={locations && locations.locations ? locations.locations : []}
          affixHeader={true}
          rowHeight={75}
          style={{
            borderTop: "2px solid #fafafa",
            borderBottom: "1px dashed #fafafa",
            filter: showDetailsModal && "blur(2px)",
            pointerEvents: showDetailsModal && "none",
          }}
          loading={loadingLocations}
        >
          <Column
            /*
            width={Math.max(
              (widgetWidth / 12) * (isMobile ? 8 : 4),
              isMobile ? 200 : 250
            )}
						*/
            flexGrow={3}
          >
            <HeaderCell style={{ textAlign: "left" }}>
              {lang !== null ? lang.location : "Location"}
            </HeaderCell>
            <Cell>
              {(rowData) => {
                return (
                  <Grid
                    style={{
                      cursor: "pointer",
                    }}
                    fluid
                    onClick={() => {
                      //setSelectedDevice(rowData);
                      //setShowDetailsModal(true);

                      actions.setSelectedLocation(rowData);
                      actions.setSelectedDevice(null);
                    }}
                  >
                    <Row>
                      {widgetWidth > 300 && (
                        <Col xs={6}>
                          <div
                            onClick={() => {
                              //setSelectedLocation(rowData);
                              //setShowDetailsModal(true);
                              actions.setSelectedDevice(null);
                              actions.setSelectedLocation(rowData);
                            }}
                          >
                            <Map
                              rowData={rowData}
                              coordinates={rowData.coordinates}
                              width="45px"
                              height="45px"
                            />
                          </div>
                        </Col>
                      )}
                      <Col
                        xs={18}
                        style={{ textAlign: "left", fontSize: "14px" }}
                      >
                        <div>
                          <big>{rowData.name}</big>
                        </div>
                        <div>
                          <small>
                            {rowData.type === "circle" &&
                              metersToLabel(rowData.radius)}
                          </small>
                        </div>
                      </Col>
                    </Row>
                  </Grid>
                );
              }}
            </Cell>
          </Column>
          {!isMobile && widgetWidth > 500 && (
            <Column
              //width={Math.max((widgetWidth / 12) * 6, 300)}
              flexGrow={3}
            >
              <HeaderCell style={{ textAlign: "left" }}>
                {lang !== null ? lang.address : "Address"}
              </HeaderCell>
              <Cell
                style={{
                  position: "relative",
                  textAlign: "left",
                  fontSize: "14px",
                }}
              >
                {(rowData) => {
                  if (rowData.address) {
                    const address = rowData.address.split(",");
                    return (
                      <div
                        onClick={() => {
                          setSelectedLocation(rowData);
                          setShowDetailsModal(true);
                        }}
                      >
                        <div>{address[0]}</div>
                        <div>{address.slice(1).join(", ")}</div>
                      </div>
                    );
                  } else {
                    return <div>{lang !== null ? lang.nA : "N/A"}</div>;
                  }
                }}
              </Cell>
            </Column>
          )}
          <Column
            //width={Math.max((widgetWidth / 12) * 2, 150)}
            flexGrow={2}
          >
            <HeaderCell>{lang !== null ? lang.actions : "Actions"}</HeaderCell>
            <Cell style={{ position: "relative" }}>
              {(rowData) => (
                <Actions
                  data={rowData}
                  showDetailsModal={showDetailsModal}
                  setShowDetailsModal={setShowDetailsModal}
                  setSelectedLocation={setSelectedLocation}
                  refetch={reloadLocations}
                  details={
                    <Details
                      selectedLocation={selectedLocation}
                      refetch={reloadLocations}
                      close={() => {
                        setShowDetailsModal(false);
                      }}
                    />
                  }
                  access={{
                    adminAccess: adminAccess.hasAccessToSection,
                    deleteAccess: deleteAccess.hasAccessToSection,
                    writeAccess: writeAccess.hasAccessToSection,
                    readAccess: readAccess.hasAccessToSection,
                  }}
                />
              )}
            </Cell>
          </Column>
        </Table>
        {showDetailsModal && (
          <Details
            selectedLocation={selectedLocation}
            refetch={reloadLocations}
            close={() => {
              setShowDetailsModal(false);
            }}
          />
        )}
      </div>
    ) : (
      <AccessDenied section="LOCATIONS" />
    );
  };

  return (
    <div>
      <Panel
        //expanded={props.expanded}
        expanded={false}
        collapsible
        bodyFill
        className="widget-config"
        style={{ borderRadius: 0 }}
      >
        <Form
          style={{ padding: "5px", paddingTop: "15px", paddingBottom: "15px" }}
        ></Form>
      </Panel>
      {renderContent()}
    </div>
  );
};
