import React, { useState } from "react";
import { CheckPicker } from "rsuite";

import { useMutation, useQuery } from "@apollo/react-hooks";

import { useApp } from "../../AppStore";

import GQL from "./graphql";

export default props => {
  const { rowData, disabled } = props;
  const { id } = rowData;
  const [{ user, selectedAccount }] = useApp();
  const { data: roles } = useQuery(GQL.Queries.roles, {
    variables: {
      accountId: selectedAccount ? selectedAccount.id : user.account.id
    }
  });
  const [assignedRoles, setAssignedRoles] = useState(
    rowData && rowData.roles ? rowData.roles.map(role => role.id) : []
  );
  const [updateUser] = useMutation(GQL.Mutations.updateUser);

  const setAssignedRolesAndSave = roleIds => {
    setAssignedRoles(roleIds);
    updateUser({
      variables: {
        id,
        roleIds
      }
    });
  };

  return (
    <CheckPicker
      data={
        roles && roles.roles
          ? roles.roles.map(role => ({
              value: role.id,
              label: role.name
            }))
          : []
      }
      style={{
        width: "100%",
        margin: 0,
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%) translateX(-50%)"
      }}
      value={assignedRoles}
      onChange={assignedRoles => {
        setAssignedRolesAndSave(assignedRoles);
      }}
      disabled={disabled}
    />
  );
};
