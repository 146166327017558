import React, { useState, useEffect } from "react";
import {
  Avatar,
  Form,
  Table,
  Panel,
  Button,
  //Icon,
  Grid,
  Row,
  Col,
  Loader,
  Tooltip,
  Whisper,
} from "rsuite";

import Icon from "../../components/Icon";
import { useQuery } from "@apollo/react-hooks";

import GQL from "./graphql";
import { useApp } from "../../AppStore";

import Details from "./details";
import Actions from "./actions";
import AccessDenied from "../../components/accessdenied";

const { Column, HeaderCell, Cell } = Table;

let lang = null;

export default (props) => {
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [{ user, selectedAccount: globalSelectedAccount, cols }] = useApp();
  const [selectedAccount, setSelectedAccount] = useState(globalSelectedAccount);
  const { data: readAccess, loading: loadingReadAccess } = useQuery(
    GQL.Queries.readAccess,
    {
      onCompleted: (res) => console.log({ res }),
    }
  );
  const { data: writeAccess, loading: loadingWriteAccess } = useQuery(
    GQL.Queries.writeAccess
  );
  const { data: deleteAccess, loading: loadingDeleteAccess } = useQuery(
    GQL.Queries.deleteAccess
  );
  const { data: adminAccess, loading: loadingAdminAccess } = useQuery(
    GQL.Queries.adminAccess
  );
  const {
    data: roles,
    loading: loadingRoles,
    refetch: reloadRoles,
  } = useQuery(GQL.Queries.roles, {
    variables: {
      accountId: selectedAccount ? selectedAccount.id : user.account.id,
    },
    fetchPolicy: "network-only",
  });
  const widgetWidth =
    (window.innerWidth / cols) * props.widget.dataGrid.w -
    10 * props.widget.dataGrid.w;

  useEffect(() => {
    /** Language File */
    lang =
      user && user.language
        ? require("../../languages/" + user.language + ".js").default
        : lang;
  }, [user]);

  useEffect(() => {
    setSelectedAccount(globalSelectedAccount);
  }, [globalSelectedAccount]);

  const renderContent = () => {
    return loadingRoles ||
      loadingReadAccess ||
      loadingWriteAccess ||
      loadingDeleteAccess ||
      loadingAdminAccess ? (
      <Loader content={lang !== null ? lang.loading : "Loading..."} />
    ) : readAccess.hasAccessToSection === true ? (
      <div
        style={{
          width: "100%",
          position: "relative",
        }}
      >
        <Grid
          fluid
          style={{
            fontSize: "1.1rem",
            fontWeight: "bold",
            width: "100%",
            textAlign: "left",
            padding: "15px",
            paddingBottom: "5px",
            borderBottom: "1px solid #eeeeee",
          }}
        >
          <Row>
            <Col xs={12} style={{ paddingTop: "7px" }}>
              <div>{lang !== null ? lang.roles : "Roles"}</div>

              <div style={{ fontSize: "0.75rem", color: "#aaa" }}>
                <div>
                  {selectedAccount ? (
                    <>
                      {
                        /* Logged in user Account */
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setSelectedAccount(null);
                          }}
                        >
                          {user.account.name}
                        </span>
                      }
                      {
                        /* Account breadcrumb */
                        selectedAccount.parentBreadcrumb &&
                        selectedAccount.parentBreadcrumb.length > 0
                          ? selectedAccount.parentBreadcrumb.map((account) => (
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setSelectedAccount(account);
                                }}
                              >
                                {" "}
                                <Icon icon="faArrowRight" /> {account.name}
                              </span>
                            ))
                          : null
                      }
                      {
                        /* Selected Account */
                        <span>
                          {" "}
                          <Icon icon="faArrowRight" />{" "}
                          {selectedAccount && selectedAccount.name
                            ? selectedAccount.name
                            : "Company"}
                        </span>
                      }
                    </>
                  ) : user && user.account && user.account.name ? (
                    user.account.name
                  ) : lang !== null ? (
                    lang.company
                  ) : (
                    "Company"
                  )}
                </div>
              </div>
            </Col>
            <Col xs={12} style={{ textAlign: "right" }}>
              <Button
                appearance="ghost"
                //className="small-shadow"
                onClick={() => {
                  setSelectedRole(null);
                  setShowDetailsModal(!showDetailsModal);
                }}
                disabled={writeAccess.hasAccessToSection !== true}
              >
                {showDetailsModal ? (
                  <span>
                    <Icon icon="faBan" width={15} />{" "}
                    {lang !== null ? lang.cancel : "Cancel"}
                  </span>
                ) : (
                  <span>
                    <Icon icon="faSquarePlus" />{" "}
                    {lang !== null ? lang.newRole : "New Role"}
                  </span>
                )}
              </Button>
            </Col>
          </Row>
        </Grid>
        <Table
          virtualized={false}
          height={(props.baseHeight + 10) * props.h - 125}
          data={roles && roles.roles ? roles.roles : []}
          affixHeader={true}
          rowHeight={75}
          style={{
            borderTop: "2px solid #fafafa",
            borderBottom: "1px dashed #fafafa",
            filter: showDetailsModal && "blur(2px)",
            pointerEvents: showDetailsModal && "none",
          }}
          loading={loadingRoles}
          onRowClick={(rowData) => {
            //setSelectedUser(rowData);
            //setShowDetailsModal(true);
          }}
        >
          <Column
            //</Table>width={Math.max((widgetWidth / 12) * 4, 250)}
            flexGrow={3}
          >
            <HeaderCell style={{ textAlign: "left" }}>
              {lang !== null ? lang.role : "Role"}
            </HeaderCell>
            <Cell style={{ textAlign: "left" }}>
              {(rowData) => (
                <div>
                  <div>
                    <big>{rowData.name}</big>
                  </div>
                  <div>
                    <div key={"role-row-div-" + rowData.id}>
                      {rowData.roleSections && rowData.roleSections.length > 0
                        ? rowData.roleSections.map(
                            (
                              {
                                section,
                                adminAccess,
                                writeAccess,
                                readAccess,
                                deleteAccess,
                              },
                              index
                            ) => {
                              let highestAccess = "No";
                              let backgroundColor = "#424953";

                              if (adminAccess) {
                                highestAccess = "Admin";
                                backgroundColor = "#967ADA";
                              } else if (deleteAccess) {
                                highestAccess = "Delete";
                                backgroundColor = "#D94452";
                              } else if (writeAccess) {
                                highestAccess = "Write";
                                backgroundColor = "#4B89DA";
                              } else if (readAccess) {
                                highestAccess = "Read";
                                backgroundColor = "#8AC054";
                              }

                              return (
                                <Whisper
                                  key={
                                    "role-row-div-" +
                                    rowData.id +
                                    "-section-icon-" +
                                    index
                                  }
                                  placement="bottom"
                                  trigger="hover"
                                  speaker={
                                    <Tooltip>
                                      {highestAccess} access : {section.name}
                                    </Tooltip>
                                  }
                                >
                                  <Avatar
                                    circle
                                    style={{
                                      marginRight: "2px",
                                      backgroundColor,
                                    }}
                                    size="xs"
                                  >
                                    <Icon icon={section.icon} />
                                  </Avatar>
                                </Whisper>
                              );
                            }
                          )
                        : null}
                    </div>
                  </div>
                </div>
              )}
            </Cell>
          </Column>
          {widgetWidth > 300 && (
            <Column
              //width={Math.max((widgetWidth / 12) * 6, 300)}
              flexGrow={4}
            >
              <HeaderCell style={{ textAlign: "left" }}>
                {lang !== null ? lang.users : "Users"}
              </HeaderCell>
              <Cell style={{ textAlign: "left" }}>
                {(rowData) => (
                  <div key={"role-row-div-" + rowData.id}>
                    {rowData.users && rowData.users.length > 0
                      ? rowData.users.map((user) => (
                          <Whisper
                            key={
                              "role-row-div-" +
                              rowData.id +
                              "-section-user-" +
                              user.id
                            }
                            placement="bottom"
                            trigger="hover"
                            speaker={
                              <Tooltip>
                                {user.firstname} {user.lastname}
                              </Tooltip>
                            }
                          >
                            <Avatar
                              circle
                              style={{
                                marginRight: "5px",
                              }}
                              size="md"
                              src={user.avatar}
                              className="small-shadow"
                            />
                          </Whisper>
                        ))
                      : null}
                  </div>
                )}
              </Cell>
            </Column>
          )}
          <Column
            //width={Math.max((widgetWidth / 12) * 2, 150)}
            flexGrow={2}
          >
            <HeaderCell>{lang !== null ? lang.actions : "Actions"}</HeaderCell>
            <Cell style={{ position: "relative" }}>
              {(rowData) => (
                <Actions
                  data={rowData}
                  setShowDetailsModal={setShowDetailsModal}
                  setSelectedRole={setSelectedRole}
                  refetch={reloadRoles}
                  access={{
                    adminAccess: adminAccess.hasAccessToSection,
                    deleteAccess: deleteAccess.hasAccessToSection,
                    writeAccess: writeAccess.hasAccessToSection,
                    readAccess: readAccess.hasAccessToSection,
                  }}
                />
              )}
            </Cell>
          </Column>
        </Table>
        {showDetailsModal && (
          <Details
            selectedRole={selectedRole}
            selectedAccount={selectedAccount}
            refetch={reloadRoles}
            close={() => {
              setShowDetailsModal(false);
            }}
            access={{
              adminAccess: adminAccess.hasAccessToSection,
              deleteAccess: deleteAccess.hasAccessToSection,
              writeAccess: writeAccess.hasAccessToSection,
              readAccess: readAccess.hasAccessToSection,
            }}
          />
        )}
      </div>
    ) : (
      <AccessDenied section="ROLES" />
    );
  };

  return (
    <div>
      <Panel
        expanded={false}
        collapsible
        bodyFill
        className="widget-config"
        style={{ borderRadius: 0 }}
      >
        <Form
          style={{ padding: "5px", paddingTop: "15px", paddingBottom: "15px" }}
        ></Form>
      </Panel>
      {renderContent()}
    </div>
  );
};
