import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useApolloClient } from "@apollo/react-hooks";
import sha1 from "sha1";
import {
  Animation,
  Avatar,
  Button,
  Dropdown,
  Form,
  //FormGroup,
  Header,
  //Icon,
  Modal,
  Nav,
  Navbar,
  Panel,
  Popover,
  Progress,
  Whisper,
  Grid,
  Row,
  Col,
  InputGroup,
  Input,
  SelectPicker,
  Tooltip,
} from "rsuite";
import Cookie from "js-cookie";
import { useApp } from "../../AppStore";
import GQL from "./graphql";
import SaveCancel from "../../components/savecancel";
import Screen from "./screen";
import AvatarPicker from "../../components/avatarpicker";
import TemplateEditor from "./templateeditor";

import ReactS3Uploader from "react-s3-uploader";

import Icon from "../../components/Icon";

const icons = [
  "faDashboard",
  "faHome",
  "faFileText",
  "faTablet",
  "faGlobe",
  "faMap",
  "faBarChart",
  "faSitemap",
  "faMapMarkerAlt",
  "faLocationArrow",
  "faMapSigns",
  //"user-analysis",
  "faChartGantt",
  //"user-circle",
  //"user-secret",
];

let lang = null;
const token = Cookie.get("atles-geofleetos-token");
const { Group: FormGroup } = Form;

export default (props) => {
  const isMobile = window.innerWidth < 1024;
  const [{ selectedScreen, user, screens, system }, actions] = useApp();
  const [showCompanySettings, setShowCompanySettings] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [email, setEmail] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [password, setPassword] = useState("");
  const [avatar, setAvatar] = useState("");
  const [language, setLanguage] = useState("");
  const [showUploadProgress, setShowUploadProgress] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showTemplateEditor, setShowTemplateEditor] = useState(false);

  // New Screen
  const [newScreenName, setNewScreenName] = useState("");
  const [newScreenDescription, setNewScreenDescription] = useState("");
  const [newScreenIcon, setNewScreenIcon] = useState("faDashboard");

  // Company Settings
  //const [logo, setLogo] = useState("");
  const [name, setName] = useState("");
  const [primaryColor, setPrimaryColor] = useState("");
  const [secondaryColor, setSecondaryColor] = useState("");
  const [mapSystemApiKey, setMapSystemApiKey] = useState("");
  const [geocoderApiKey, setGeocoderApiKey] = useState("");

  const apolloClient = useApolloClient();
  const { data: readAccess, loading: loadingReadAccess } = useQuery(
    GQL.Queries.readAccess
  );
  const { data: writeAccess, loading: loadingWriteAccess } = useQuery(
    GQL.Queries.writeAccess
  );
  /*
  const {
    data: deleteAccess,
    loading: loadingDeleteAccess
  } = useQuery(GQL.Queries.deleteAccess);
  */
  const { data: adminAccess, loading: loadingAdminAccess } = useQuery(
    GQL.Queries.adminAccess
  );
  const { data: account, refetch: refetchAccount } = useQuery(
    GQL.Queries.account,
    {
      variables: {
        id: user && user.account && user.account.id ? user.account.id : 0,
      },
      onCompleted: (res) => {
        if (res && res.account) {
          //setLogo(res.account.logo);
          setName(res.account.name);
          setPrimaryColor(res.account.primaryColor);
          setSecondaryColor(res.account.secondaryColor);
          setMapSystemApiKey(res.account.mapSystemApiKey);
          setGeocoderApiKey(res.account.geocoderApiKey);
        }
      },
      fetchPolicy: "network-only",
    }
  );
  const { data: session } = useQuery(GQL.Queries.sessionByToken, {
    variables: {
      token,
    },
  });
  const { data: widgetTypes, loading: loadingWidgetTypes } = useQuery(
    GQL.Queries.widgetTypes,
    {
      variables: {
        sections:
          system && system === "sm" ? ["sm", "both"] : ["atles", "both"],
      },
      fetchPolicy: "network-only",
    }
  );
  const [updateAccount] = useMutation(GQL.Mutations.updateAccount);
  const [updateUser] = useMutation(GQL.Mutations.updateUser, {
    onCompleted: (res) => {
      if (res) {
        setShowProfile(false);
      }
    },
  });
  const [changePassword] = useMutation(GQL.Mutations.changePassword, {
    onCompleted: (res) => {
      if (res) {
        setShowProfile(false);
        // TODO: Logout?
      }
    },
  });
  const [createWidget] = useMutation(GQL.Mutations.createWidget, {
    onCompleted: async ({ createWidget: widget }) => {
      const dataGrid = {
        id: widget.id,
        title: widget.widgetType.name,
        icon: widget.widgetType.icon,
        type: widget.widgetType.name,
        i: "widget-" + widget.id,
        w: 1,
        h: 1,
      };
      actions.addWidget(dataGrid, apolloClient);
    },
  });
  const [getPresignedUploadUrl] = useMutation(
    GQL.Mutations.getPresignedUploadUrl
  );

  const getSignedUrl = async (file, callback) => {
    const res = await getPresignedUploadUrl({
      variables: {
        filename: "images/" + file.name,
        filetype: file.type,
      },
    });

    //setNewLogo(user.account.id + "/images/" + file.name);
    updateAccount({
      variables: {
        id: user.account.id,
        logo: user.account.id + "/images/" + file.name,
      },
    });

    callback({ signedUrl: res.data.getPresignedUploadUrl });
  };
  const onUploadStart = (file, next) => {
    //console.log("Upload start:", data);
    setShowUploadProgress(true);
    setUploadProgress(0);
    next(file);
  };
  const onUploadProgress = (data) => {
    //console.log({ data });
    setUploadProgress(data);
  };

  const onUploadFinished = (data) => {
    refetchAccount();
    setShowUploadProgress(false);
  };

  useEffect(() => {
    user && user.email && setEmail(user.email);
    user && user.firstname && setFirstname(user.firstname);
    user && user.lastname && setLastname(user.lastname);
    user && user.avatar && setAvatar(user.avatar);
    user && user.language && setLanguage(user.language);

    /** Language File */
    lang =
      user && user.language
        ? require("../../languages/" + user.language + ".js").default
        : lang;
  }, [user]);

  const renderIcons = () => {
    const cols = 4;
    let iconRows = [];

    for (let row = 0; row < parseInt(icons.length / cols); row++) {
      iconRows.push(row);
    }

    return (
      <Grid fluid>
        {iconRows.map((row) => {
          return (
            <Row key={"iconRow-" + row}>
              {icons.slice(row * cols, row * cols + cols).map((icon, index) => {
                return (
                  <Col
                    xs={24 / cols}
                    key={"iconCol-" + row + "-" + index}
                    style={{
                      //border: "1px solid gray",
                      textAlign: "center",
                      padding: "5px",
                      backgroundColor: newScreenIcon === icon && "#eee",
                    }}
                  >
                    <Icon
                      icon={icon}
                      //key={"icon-" + row + "-" + index}
                      size="2x"
                      width={30}
                      onClick={() => setNewScreenIcon(icon)}
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  </Col>
                );
              })}
            </Row>
          );
        })}
      </Grid>
    );
  };

  //console.log({ account });

  return (
    <Header className={system && system === "sm" ? "sm-header" : "header"}>
      {session &&
      session.sessionByToken &&
      session.sessionByToken.impersonatorSessionId &&
      session.sessionByToken.impersonatorSessionId > 0 &&
      session.sessionByToken.impersonatorSession &&
      window.innerWidth > 1024 ? (
        <div style={{ color: "white", backgroundColor: "red", padding: "3px" }}>
          Impersonation session by:{" "}
          {session.sessionByToken.impersonatorSession.user.email}{" "}
          <Button
            size="xs"
            className="small-shadow"
            onClick={() => {
              if (
                session &&
                session.sessionByToken &&
                session.sessionByToken.impersonatorSessionId &&
                session.sessionByToken.impersonatorSessionId > 0 &&
                session.sessionByToken.impersonatorSession
              ) {
                Cookie.set(
                  "atles-geofleetos-token",
                  session.sessionByToken.impersonatorSession.token
                );
                Cookie.set("atles-geofleetos-impersonationstate", "false");
                window.location = "/";
              }
            }}
          >
            <Icon icon="faHand" width={15} /> Exit session
          </Button>
        </div>
      ) : null}
      <Animation.Collapse in={true} transitionAppear={true}>
        <Navbar
          appearance="default"
          className={system && system === "sm" ? "sm-main-menu" : "main-menu"}
          style={{
            backgroundColor: system && system === "sm" ? "#dadbdc" : "inherit",
            padding: 0,
          }}
        >
          <Navbar.Brand style={{ padding: 5 }}>
            <img
              src={
                user && user.account && user.account.logo
                  ? user.account.logo
                  : "images/geofleetos.png"
              }
              style={{ width: "50px" }}
              alt="Company Logo"
            />
          </Navbar.Brand>
          {window.innerWidth <= 1024 ? (
            <Nav>
              <Dropdown
                trigger="click"
                icon={
                  <Avatar
                    circle
                    size="sm"
                    className="small-shadow menu-button-background"
                    style={{
                      position: "relative",
                      //top: "-5pt",
                    }}
                  >
                    <Icon icon="faTv" width={15} />
                  </Avatar>
                }
                placement="bottomEnd"
                style={{ zIndex: 99999 }}
              >
                {screens && screens.length > 0
                  ? screens.map((screen, index) => {
                      console.log({ screen });
                      return (
                        <Dropdown.Item
                          key={`screen-dd-${index}`}
                          eventKey={index}
                          onClick={() => {
                            actions.setSelectedScreen(index);
                          }}
                          active={index === selectedScreen}
                        >
                          <Icon icon={screen.icon} /> {screen.title}
                        </Dropdown.Item>
                      );
                    })
                  : null}
              </Dropdown>
            </Nav>
          ) : (
            <Nav activeKey={selectedScreen} style={{ height: 50 }}>
              {screens &&
                screens.length > 0 &&
                screens.map((screen, index) => (
                  <Screen
                    key={"screen-" + index}
                    screen={screen}
                    index={index}
                  />
                ))}
              {screens && screens.length < 11 && (
                <Dropdown
                  icon={
                    <Icon
                      icon={newScreenName ? newScreenIcon : "faSquarePlus"}
                    />
                  }
                  trigger={["click", "hover"]}
                >
                  <Panel
                    className={
                      system && system === "sm"
                        ? "sm-menu-dropdown-header"
                        : "menu-dropdown-header"
                    }
                    style={{ position: "relative", top: "-15pt" }}
                  >
                    <Grid style={{ width: "200px" }}>
                      <Row>
                        <Col xs={24} style={{ padding: "5px" }}>
                          <big>
                            <Icon icon="faDashboard" width={15} />{" "}
                            {lang !== null ? lang.newScreen : "New"}
                          </big>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={24}>
                          <small>
                            {lang !== null
                              ? lang.newScreenHeading
                              : "Please fill out the form below."}
                          </small>
                        </Col>
                      </Row>
                    </Grid>
                  </Panel>
                  <Panel>
                    <Form>
                      <FormGroup>
                        <InputGroup>
                          <InputGroup.Addon>
                            <Icon icon="faTag" width={15} />
                          </InputGroup.Addon>
                          <Input
                            placeholder={
                              lang !== null
                                ? lang.newScreenNamePlaceholder
                                : "Name"
                            }
                            value={newScreenName}
                            onChange={setNewScreenName}
                          />
                        </InputGroup>
                        <fieldset
                          style={{
                            border: "1px solid #ddd",
                            borderRadius: "5px",
                          }}
                        >
                          <legend>
                            {lang !== null
                              ? lang.newScreenIconHeading
                              : "Select an Icon"}
                          </legend>
                          {renderIcons()}
                        </fieldset>
                      </FormGroup>
                      <FormGroup>
                        <SaveCancel
                          saveDisabled={!newScreenName}
                          onSave={() => {
                            actions.setShowOverlay(true);
                            actions.addScreen(
                              {
                                name: newScreenName,
                                description: newScreenDescription,
                                icon: newScreenIcon,
                                system,
                                order: screens.length,
                              },
                              apolloClient
                            );
                            setNewScreenName("");
                            setNewScreenDescription("");
                            setNewScreenIcon("faDashboard");
                          }}
                          onCancel={() => {
                            setNewScreenName("");
                            setNewScreenDescription("");
                            setNewScreenIcon("faDashboard");
                          }}
                        />
                      </FormGroup>
                    </Form>
                  </Panel>
                </Dropdown>
              )}
            </Nav>
          )}
          <Nav pullRight>
            <Dropdown
              trigger={["hover", "click"]}
              icon={
                <Avatar
                  circle
                  size="sm"
                  className="small-shadow menu-button-background"
                  style={{ position: "relative" }}
                >
                  <Icon icon="faBars" width={15} />
                </Avatar>
              }
              placement="bottomEnd"
            >
              <Panel
                className={
                  system && system === "sm"
                    ? "sm-menu-dropdown-header"
                    : "menu-dropdown-header"
                }
                style={{ position: "relative", top: "-15pt" }}
              >
                <Grid style={{ width: "200px" }}>
                  <Row>
                    <Col xs={24} style={{ padding: "5px" }}>
                      <big>{lang !== null ? lang.widgetsTitle : "Widgets"}</big>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24}>
                      <small>
                        {lang !== null
                          ? lang.widgetsHeading
                          : "Select one to add"}
                      </small>
                    </Col>
                  </Row>
                </Grid>
              </Panel>
              {!loadingWidgetTypes &&
                widgetTypes &&
                widgetTypes.widgetTypes &&
                widgetTypes.widgetTypes.length > 0 &&
                widgetTypes.widgetTypes.map((widgetType) => (
                  <Dropdown.Item
                    key={`widgetType-${widgetType.id}`}
                    eventKey={widgetType.id}
                    onClick={() => {
                      createWidget({
                        variables: {
                          widgetTypeId: widgetType.id,
                          screenId: screens[selectedScreen].id,
                          title: widgetType.name,
                          config:
                            widgetType.id === 1 // Map Defaults
                              ? '{"selectedDevices":null,"selectedLocations":null,"autoRefresh":true,"showAllDevices":true,"showAllLocations":true}'
                              : null,
                        },
                      });
                    }}
                  >
                    <Grid fluid>
                      <Row>
                        <Col xs={12}>
                          <Icon icon={widgetType.icon} />{" "}
                          {widgetType.name.charAt(0).toUpperCase() +
                            widgetType.name.slice(1)}
                        </Col>
                        <Col xs={12} style={{ textAlign: "right" }}>
                          {widgetType.section === "both" && (
                            <Icon icon="faShareNodes" width={15} />
                          )}
                        </Col>
                      </Row>
                    </Grid>
                  </Dropdown.Item>
                ))}
            </Dropdown>
            {user &&
            user.account &&
            user.account.smAccess === true &&
            !isMobile ? (
              <Nav.Item
                onClick={() => {
                  if (system && system === "sm") {
                    window.location = "/";
                  } else {
                    window.location = "/sm";
                  }
                }}
                icon={
                  <Whisper
                    speaker={
                      <Tooltip>
                        {system && system === "sm"
                          ? user && user.account && user.account.name
                            ? user.account.name
                            : "Application"
                          : "System Manager"}
                      </Tooltip>
                    }
                    placement="bottom"
                  >
                    <Avatar
                      circle
                      size="sm"
                      //className="small-shadow"
                      style={{
                        position: "relative",
                        //top: "-5pt",
                        backgroundColor:
                          system && system === "sm"
                            ? user && user.account && user.account.primaryColor
                              ? user.account.primaryColor
                              : "#4DC4FF"
                            : "#7D8995",
                      }}
                    >
                      <Icon
                        icon={
                          system && system === "sm" ? "faDashboard" : "faGear"
                        }
                      />
                    </Avatar>
                  </Whisper>
                }
              />
            ) : null}
            {!isMobile && (
              <Nav.Item
                onClick={() => setShowTemplateEditor(true)}
                icon={
                  <Whisper
                    speaker={<Tooltip>Screen Templates</Tooltip>}
                    placement="bottom"
                  >
                    <Avatar
                      circle
                      size="sm"
                      //className="small-shadow"
                      style={{
                        position: "relative",
                        //top: "-5pt",
                        backgroundColor:
                          user && user.account && user.account.primaryColor
                            ? user.account.primaryColor
                            : "#4DC4FF",
                      }}
                    >
                      <Icon icon="faCopy" width={15} />
                    </Avatar>
                  </Whisper>
                }
                //style={{ padding: 10, paddingTop: 20 }}
              />
            )}
            <Dropdown
              title={
                !isMobile
                  ? user && user.firstname && user.lastname
                    ? `${user.firstname} ${user.lastname}`
                    : "account"
                  : ""
              }
              trigger={["hover", "click"]}
              icon={
                <Avatar
                  circle
                  size="sm"
                  //style={{ background: "lightskyblue" }}
                  className="small-shadow"
                  src={user && user.avatar ? user.avatar : "images/avatar0.png"}
                  style={{ position: "relative", top: 0 }}
                />
              }
              placement="bottomEnd"
            >
              <Panel
                className={
                  system && system === "sm"
                    ? "sm-menu-dropdown-header"
                    : "menu-dropdown-header"
                }
                style={{ position: "relative", top: "-15pt" }}
              >
                <Grid style={{ width: "200px" }}>
                  <Row>
                    <Col xs={24} style={{ padding: "5px" }}>
                      <big>
                        {user && user.account && user.account.name
                          ? user.account.name
                          : "account"}
                      </big>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <Avatar
                        circle
                        className="shadow"
                        src={
                          avatar && avatar.length > 0
                            ? avatar
                            : "images/256_1.png"
                        }
                      />
                    </Col>
                    <Col xs={18}>
                      {user && user.firstname && user.lastname
                        ? `${user.firstname} ${user.lastname}`
                        : "account"}
                      <br />
                      <small>{user && user.email ? user.email : "user"}</small>
                    </Col>
                  </Row>
                </Grid>
              </Panel>
              {loadingAdminAccess || loadingReadAccess || loadingWriteAccess ? (
                <Dropdown.Item disabled={true}>Loading...</Dropdown.Item>
              ) : (
                <Dropdown.Item
                  eventKey="1"
                  onClick={() => {
                    if (readAccess.hasAccessToSection === true) {
                      setShowCompanySettings(true);
                    }
                  }}
                  disabled={readAccess.hasAccessToSection !== true}
                >
                  <Icon icon="faGears" width={15} /> Company Settings
                </Dropdown.Item>
              )}
              {/*
                <Dropdown.Item
                  eventKey="2"
                  onClick={() => setShowTemplateEditor(true)}
                >
                  <Icon icon="dashboard" />{" "}
                  {lang !== null ? lang.screenTemplates : "Screen Templates"}
                </Dropdown.Item>
                */}
              <Dropdown.Item eventKey="3" onClick={() => setShowProfile(true)}>
                <Icon icon="faSliders" width={15} />{" "}
                {lang !== null ? lang.profile : "Profile"}
              </Dropdown.Item>
              <Dropdown.Item divider />
              <Dropdown.Item
                eventKey="5"
                onClick={() => {
                  if (
                    session &&
                    session.sessionByToken &&
                    session.sessionByToken.impersonatorSessionId &&
                    session.sessionByToken.impersonatorSessionId > 0 &&
                    session.sessionByToken.impersonatorSession
                  ) {
                    Cookie.set(
                      "atles-geofleetos-token",
                      session.sessionByToken.impersonatorSession.token
                    );
                    Cookie.set("atles-geofleetos-impersonationstate", "false");
                    window.location = "/";
                  } else {
                    actions.logout();
                  }
                }}
              >
                <Icon icon="faRightFromBracket" width={15} />{" "}
                {lang !== null ? lang.logout : "Logout"}
              </Dropdown.Item>
            </Dropdown>
          </Nav>
        </Navbar>
      </Animation.Collapse>
      {!loadingAdminAccess && !loadingWriteAccess && !loadingReadAccess ? (
        <Modal
          open={showCompanySettings}
          backdrop={!isMobile && "static"}
          size={isMobile ? "xs" : "md"}
          style={{ width: isMobile && "100%" }}
        >
          <Modal.Header closeButton={false}>
            <Modal.Title>
              <Icon icon="faGears" width={15} />{" "}
              {lang !== null ? lang.company : "Company"}{" "}
              {lang !== null ? lang.settings : "Settings"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form fluid>
              <FormGroup
                style={{
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <label
                  className="logo-uploader"
                  style={{
                    display: showUploadProgress && "none",
                    backgroundImage:
                      account &&
                      account.account &&
                      account.account.logo &&
                      `url(${account.account.logo})`,
                  }}
                >
                  {!loadingAdminAccess &&
                    adminAccess.hasAccessToSection === true && (
                      <ReactS3Uploader
                        getSignedUrl={getSignedUrl}
                        accept="image/*"
                        uploadRequestHeaders={{}}
                        preprocess={onUploadStart}
                        onProgress={onUploadProgress}
                        onFinish={onUploadFinished}
                      />
                    )}
                </label>
                {showUploadProgress ? (
                  <Progress.Circle
                    percent={uploadProgress}
                    style={{ width: "64px", margin: "auto" }}
                    status={uploadProgress === 100 && "success"}
                  />
                ) : null}
                <div>
                  <big>
                    {account && account.account && account.account.name
                      ? account.account.name
                      : "Company Name"}
                  </big>
                </div>
              </FormGroup>
              <FormGroup>
                <InputGroup style={{ width: "100%" }}>
                  <InputGroup.Addon>
                    <Icon icon="faTag" width={15} />
                  </InputGroup.Addon>
                  <Input
                    placeholder="Company Name"
                    value={name}
                    onChange={setName}
                    disabled={
                      loadingAdminAccess ||
                      adminAccess.hasAccessToSection !== true
                    }
                  />
                </InputGroup>
                <InputGroup style={{ width: "100%" }}>
                  <InputGroup.Addon>
                    <Icon icon="faPencil" width={15} />
                  </InputGroup.Addon>
                  <Input
                    placeholder="Primary Color"
                    value={primaryColor}
                    onChange={setPrimaryColor}
                    disabled={
                      loadingWriteAccess ||
                      writeAccess.hasAccessToSection !== true
                    }
                  />
                  <InputGroup.Addon>
                    <Icon
                      icon="faSquare"
                      width={15}
                      style={{
                        color: primaryColor ? primaryColor : "#EFEFEF",
                      }}
                    />
                  </InputGroup.Addon>
                </InputGroup>
                <InputGroup style={{ width: "100%" }}>
                  <InputGroup.Addon>
                    <Icon icon="faPencil" width={15} />
                  </InputGroup.Addon>
                  <Input
                    placeholder="Secondary Color"
                    value={secondaryColor}
                    onChange={setSecondaryColor}
                    disabled={
                      loadingWriteAccess ||
                      writeAccess.hasAccessToSection !== true
                    }
                  />
                  <InputGroup.Addon>
                    <Icon
                      icon="faSquare"
                      width={15}
                      style={{
                        color: secondaryColor ? secondaryColor : "#EFEFEF",
                      }}
                    />
                  </InputGroup.Addon>
                </InputGroup>
                {account &&
                account.account &&
                account.account.mapping &&
                account.account.mapSystemId > 1 ? (
                  <InputGroup style={{ width: "100%" }}>
                    <InputGroup.Addon>
                      <Icon icon="faKey" width={15} />
                    </InputGroup.Addon>
                    <InputGroup.Addon>
                      {account.account.mapSystem.name} API Key
                    </InputGroup.Addon>
                    <Input
                      placeholder="API Key"
                      value={mapSystemApiKey}
                      onChange={setMapSystemApiKey}
                      disabled={
                        loadingAdminAccess ||
                        adminAccess.hasAccessToSection !== true
                      }
                    />
                  </InputGroup>
                ) : null}
                {account && account.account && account.account.geocode ? (
                  <InputGroup style={{ width: "100%" }}>
                    <InputGroup.Addon>
                      <Icon icon="faKey" width={15} />
                    </InputGroup.Addon>
                    <InputGroup.Addon>
                      {account.account.geocoder.name} API Key
                    </InputGroup.Addon>
                    <Input
                      placeholder="API Key"
                      value={geocoderApiKey}
                      onChange={setGeocoderApiKey}
                      disabled={
                        loadingAdminAccess ||
                        adminAccess.hasAccessToSection !== true
                      }
                    />
                  </InputGroup>
                ) : null}
              </FormGroup>
              <FormGroup>
                <SaveCancel
                  saveDisabled={
                    account &&
                    account.account &&
                    name === account.account.name &&
                    primaryColor === account.account.primaryColor &&
                    secondaryColor === account.account.secondaryColor &&
                    mapSystemApiKey === account.account.mapSystemApiKey &&
                    geocoderApiKey === account.account.geocoderApiKey
                  }
                  onSave={() => {
                    updateAccount({
                      variables: {
                        id: user.account.id,
                        name,
                        primaryColor,
                        secondaryColor,
                        mapSystemApiKey,
                        geocoderApiKey,
                      },
                    });

                    setShowCompanySettings(false);
                    refetchAccount();
                  }}
                  onCancel={() => {
                    setShowCompanySettings(false);
                  }}
                />
              </FormGroup>
            </Form>
          </Modal.Body>
        </Modal>
      ) : null}
      <Modal
        open={showProfile}
        backdrop="static"
        size={isMobile ? "xs" : "md"}
        style={{ width: isMobile && "100%" }}
      >
        <Modal.Header closeButton={false}>
          <Modal.Title>
            <Icon icon="faSliders" width={15} />{" "}
            {lang !== null ? lang.profile : "Profile"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form fluid>
            <FormGroup style={{ width: "100%", textAlign: "center" }}>
              <div>
                <Whisper
                  trigger="click"
                  placement="bottom"
                  speaker={
                    <Popover>
                      <AvatarPicker onClick={(avatar) => setAvatar(avatar)} />
                    </Popover>
                  }
                >
                  <Avatar
                    circle
                    className="small-shadow"
                    src={
                      avatar && avatar.length > 0 ? avatar : "images/256_1.png"
                    }
                    size="lg"
                    style={{ cursor: "pointer" }}
                  />
                </Whisper>
              </div>
              <div>
                <big>
                  {user && user.firstname && user.firstname.length > 0
                    ? user.firstname
                    : "New"}{" "}
                  {user && user.lastname && user.lastname.length > 0
                    ? user.lastname
                    : "User"}
                </big>
              </div>
              <div>
                <small>
                  {user && user.email && user.email.length > 0
                    ? user.email
                    : "email@domain.com"}
                </small>
              </div>
            </FormGroup>
            <FormGroup style={{ width: "100%", textAlign: "center" }}>
              <SelectPicker
                cleanable={false}
                data={[
                  {
                    value: "en",
                    label: "English",
                  },
                  {
                    value: "kl",
                    label: "Klingon",
                  },
                ]}
                value={language}
                onChange={setLanguage}
                style={{ width: "100%" }}
              />
            </FormGroup>
            <FormGroup>
              <InputGroup style={{ width: "100%" }}>
                <InputGroup.Addon>
                  <Icon icon="faAt" width={15} />
                </InputGroup.Addon>
                <Input
                  placeholder={lang !== null ? lang.email : "Email"}
                  value={email}
                  onChange={setEmail}
                  disabled
                />
              </InputGroup>
              <InputGroup style={{ width: "100%" }}>
                <InputGroup.Addon>
                  <Icon icon="faUser" width={15} />
                </InputGroup.Addon>
                <Input
                  placeholder={
                    lang !== null ? lang.firstNamePlaceholder : "First Name"
                  }
                  value={firstname}
                  onChange={setFirstname}
                  style={{ width: "50%" }}
                />
                <Input
                  placeholder={
                    lang !== null ? lang.lastNamePlaceholder : "Last Name"
                  }
                  value={lastname}
                  onChange={setLastname}
                  style={{ width: "50%" }}
                />
              </InputGroup>
              <InputGroup style={{ width: "100%" }}>
                <InputGroup.Addon>
                  <Icon icon="faKey" width={15} />
                </InputGroup.Addon>
                <Input
                  type="password"
                  placeholder={
                    lang !== null
                      ? lang.passwordPlaceholder
                      : "Fill in to change password"
                  }
                  value={password}
                  onChange={setPassword}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <SaveCancel
                saveDisabled={
                  user &&
                  user.email &&
                  user.email === email &&
                  user.firstname &&
                  user.firstname === firstname &&
                  user.lastname &&
                  user.lastname === lastname &&
                  user.avatar === avatar &&
                  user.language === language &&
                  password.length === 0
                }
                onSave={() => {
                  updateUser({
                    variables: {
                      id: user.id,
                      email,
                      firstname,
                      lastname,
                      avatar,
                      language,
                    },
                  });

                  if (password && password.length > 1) {
                    changePassword({
                      variables: {
                        id: user.id,
                        password: sha1(password),
                      },
                    });
                  }

                  setShowProfile(false);

                  actions.setUser({
                    firstname,
                    lastname,
                    email,
                    avatar,
                    language,
                  });
                }}
                onCancel={() => {
                  setShowProfile(false);
                }}
              />
            </FormGroup>
          </Form>
        </Modal.Body>
      </Modal>
      <TemplateEditor
        show={showTemplateEditor}
        setShow={setShowTemplateEditor}
        account={account}
        refetchAccount={refetchAccount}
        widgetTypes={widgetTypes}
        loadingWidgetTypes={loadingWidgetTypes}
      />
    </Header>
  );
};
