export default {
  fiftyMeters: "50  chuq",
  oneHundredMeters: "100  chuq",
  halfMile: "1/2 qelI'qam",
  oneMile: "1 qelI'qam",
  fiveMiles: "5 qelI'qam",
  tenMiles: "10 qelI'qam",
  account: "yuQ",
  accountComPlaceholder: "yuQ.com",
  accountDetails: "yuQ Doch",
  accounts: "yuQ",
  actions: "vIH",
  active: "pov",
  address: "SoQ",
  addScreenHerePlaceholder: "jIHDaq naDev chel",
  addWidgetsHerePlaceholder: "Doch naDev chel",
  age: "Qo'",
  ago: "ben law'",
  altitude: "'Iv",
  alert: "ghuH",
  alertDetails: "ghuH De'",
  alerts: "ghuH",
  alertType: "ghuH Segh",
  average: "motlhbogh",
  battery: "'ul 'aplo'mey",
  bulkAction: "MaQa' Qu'",
  bulkDelete: "MaQa' Qaw'",
  bulkImport: "'ej DughajmoH",
  bulkOta: "MaQa' QumpIn",
  bulkTransfer: "MaQa' Qay",
  cancel: "qIl",
  circle: "gho",
  clickHereForEventsReport: "wIv naDev wanI' ja'",
  clickMapPlaceholder: "DubelmoHchugh He 'angbogh Daq HIjmeH wItlha' yIqIp",
  close: "SoQmoH",
  company: "wey",
  configureWidgetPlaceholder: "cher nIS Qorwagh",
  confirm: "HIja'",
  contactAdminPlaceholder: "DubelmoHchugh yIjatlh ta'",
  create: "chenmoH",
  createScreenError: "laH wej jIHDaq chenmoH",
  customers: "gheD",
  date: "jaj",
  day: "jaj",
  daysAgo: "Hu'",
  delete: "Qaw'",
  deleteConfirm: ["Be Qaw' DaneH'a'", "Pagh ghotvam'e' Ha'!"],
  deleteScreen: "jIHDaq Qaw'",
  deleteScreenError: "laH wej jIHDaq teq",
  details: "lang",
  device: "jan",
  deviceBattery: "jan 'ul 'aplo'mey",
  deviceDetails: "jan lang",
  deviceName: "jan pong",
  deviceVoltage: "jan qoD",
  devices: "jan",
  devicesReportedTodayPlaceholder: "DaHjaj ja' jan",
  disabled: "Qotlh",
  domain: "yer",
  duration: "poH",
  email: "Va QIn",
  emailPlaceholder: "jabbI'ID@yer.com",
  end: "bertlham",
  endEvent: "'o'megh wanI'",
  endLocation: "'o'megh chaDo'maq",
  endMap: "'o'megh He 'angbogh wItlha'",
  endTime: "'o'megh poH",
  event: "wanI'",
  eventLocations: "Botjan",
  events: "wanI'",
  eventTime: "wanI' poH",
  exitFullscreen: "mej naQ jIHDaq",
  export: "tlhuD",
  firstNamePlaceholder: "wa'DIch pong",
  fullscreen: "jIHDaq naQ",
  heading: "nach",
  hour: "rep",
  hoursAgo: "rep ben law'",
  id: "mI'",
  idle: "ray'",
  imei: "IMEI",
  invalidConfigPlaceholder: "QIH Doch",
  lastNamePlaceholder: "wa'Hu' pong",
  lastReported: "wa'Hu' ja'",
  latLong: "lat ghap tIq",
  lead: "tuj",
  leads: "tuj",
  live: "yIn",
  loadingPlaceholder: "boq...",
  location: "lan",
  locations: "lan",
  locationDetails: "Daq Doch",
  logout: "mej",
  miles: "qelI'qam",
  minimap: "He 'angbogh wItlha' mach",
  minute: "tup",
  minutesAgo: "tup ben law'",
  mph: "Mph",
  mphTopSpeed: "mph yor vIH",
  nA: "N/A",
  name: "pong",
  new: "chu'",
  newAccount: "yuQ chu'",
  newAlert: "Chu ghuH",
  newLocation: "Chu Ian",
  newRole: "chu' patlh",
  newScreen: "Chu'",
  newScreenHeading: "DubelmoHchugh chaw'nIS chenmoH.",
  newScreenIconHeading: "mIllogh wIv",
  newScreenNamePlaceholder: "Pong",
  newUser: "chu ghot",
  noRoomPlaceholder: "jIQublaHtaHbe' puS",
  notes: "Chav",
  password: "mu'wIj",
  passwordPlaceholder: "chaw'nIS mu'wIj wIQaw'laH",
  polygon: "tu'lu'bej",
  profile: "QoghDu'DajDaq",
  radius: "gho",
  remove: "teq",
  reported: "ja'",
  reportFor: "ja'",
  role: "patlh",
  roles: "choHwI' wIlIng",
  roleSections: "pujchu'bogh Qu'",
  save: "choq",
  screenTemplates: "Screen Templates",
  search: "tu'",
  second: "cha'DIch",
  secondsAgo: "cha'DIch ben law'",
  sections: "'ay'",
  selectDevice: "jan wIv",
  selectDevicesPlaceholder: "jan wIv",
  selectLocationsPlaceholder: "jan Ian",
  selectReport: "ja' wIv",
  selectReportFields: "ja' yotlh wIv",
  selectStatPlaceholder: "lo'laHghach wIv",
  selectTypePlaceholder: "Segh wIv",
  serial: "mI'",
  settings: "pat",
  simnumber: "sim mI'",
  speed: "tlhoS",
  static: "rap",
  start: "bI'reS",
  startEvent: "wanI' tagh",
  startLocation: "chaDo'maq tagh",
  startMap: "He 'angbogh wItlha' tagh",
  startTime: "poH tagh",
  status: "Dotlh",
  suspended: "loS",
  synced: "nItebHa'",
  this_month: "'ochDaq",
  this_week: "Hogh",
  today: "DaHjaj",
  triggers: "chu'wI'",
  tripDetails: "leng Doch",
  tripMap: "leng He 'angbogh wItlha'",
  trips: "leng",
  userDetailsPlaceholder: "ghot lang",
  users: "ghot",
  unsynced: "wej tay'",
  widgetAddedPlaceholder: "Doch chel",
  widgetDeletedPlaceholder: "Doch teq",
  widgetsHeading: "wa' chel wIv",
  widgetsTitle: "Qorwagh",
  yes: "hIja'",
  yesterday: "wa'Hu'",
};
