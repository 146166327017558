import React, { useState, useEffect } from "react";
import { Avatar, Button, ButtonGroup } from "rsuite";
import { useMutation } from "@apollo/react-hooks";

import Icon from "../../components/Icon";

import GQL from "./graphql";
import { useApp } from "../../AppStore";

let lang = null;

export default (props) => {
  const { setSelectedRole, setShowDetailsModal, data, access } = props;
  const [{ user }] = useApp();
  const { id } = data;
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleteRole] = useMutation(GQL.Mutations.deleteRole, {
    onCompleted: (res) => {
      setShowDeleteConfirm(false);
      props.refetch();
    },
  });

  useEffect(() => {
    /** Language File */
    lang =
      user && user.language
        ? require("../../languages/" + user.language + ".js").default
        : lang;
  }, [user]);

  return (
    <div
      style={{
        position: "relative",
        top: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <div
        style={{
          filter: showDeleteConfirm && "blur(2px)",
          pointerEvents: showDeleteConfirm && "none",
        }}
      >
        <Avatar
          size="sm"
          circle={true}
          onClick={() => {
            if (access.writeAccess === true) {
              setSelectedRole(data);
              setShowDetailsModal(true);
            }
          }}
          className={
            access.writeAccess === true
              ? "action-button"
              : "action-button-disabled"
          }
          style={{
            backgroundColor:
              access.writeAccess === true ? "#45B0E5" : "#CCCCCC",
          }}
        >
          <Icon icon="faPencil" />
        </Avatar>{" "}
        <Avatar
          size="sm"
          circle={true}
          onClick={() => {
            if (access.deleteAccess === true) {
              setShowDeleteConfirm(true);
            }
          }}
          className={
            access.deleteAccess === true
              ? "action-button"
              : "action-button-disabled"
          }
          style={{
            backgroundColor:
              access.deleteAccess === true ? "#EE4D4D" : "#CCCCCC",
          }}
        >
          <Icon icon="faTrash" />
        </Avatar>
      </div>
      {showDeleteConfirm && (
        <div
          style={{
            position: "absolute",
            width: "80%",
            height: "80%",
            top: "10%",
            left: "10%",
          }}
        >
          <ButtonGroup style={{ borderRadius: "6px" }} className="small-shadow">
            <Button
              appearance="primary"
              color="red"
              size="xs"
              onClick={() =>
                deleteRole({
                  variables: { id },
                })
              }
            >
              <Icon icon="faTrash" /> {lang !== null ? lang.confirm : "Confirm"}
            </Button>
            <Button
              appearance="primary"
              color="orange"
              size="xs"
              onClick={() => setShowDeleteConfirm(false)}
            >
              <Icon icon="faBan" width={15} />{" "}
              {lang !== null ? lang.cancel : "Cancel"}
            </Button>
          </ButtonGroup>
        </div>
      )}
    </div>
  );
};
