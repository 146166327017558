import React, { useState, useEffect } from "react";
import {
  Form,
  //FormGroup,
  DatePicker,
  Table,
  Panel,
  SelectPicker,
  InputGroup,
  CheckPicker,
  Message,
  Button,
  //Icon,
  Grid,
  Row,
  Col,
  ButtonGroup,
} from "rsuite";

import Icon from "../../components/Icon";
import moment from "moment";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";

import GQL from "./graphql";
import { useApp } from "../../AppStore";
import { CSVLink } from "react-csv";

import AccountTileLayer from "../../components/accounttilelayer";
import EventLocation from "./eventlocation";

import L from "leaflet";
import { Map, Marker, Polyline } from "react-leaflet";
delete L.Icon.Default.prototype._getIconUrl;

let lang = null;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("../../images/marker-icon-2x.png"),
  iconUrl: require("../../images/marker-icon.png"),
  shadowUrl: require("../../images/marker-shadow.png"),
});

const startIcon = new L.Icon({
  //iconRetinaUrl: require("../../images/marker-icon-green-2x.png"),
  iconUrl: require("../../images/marker-icon-green.png"),
  shadowUrl: require("../../images/marker-shadow.png"),
  iconAnchor: [13, 41],
});

const endIcon = new L.Icon({
  //iconRetinaUrl: require("../../images/marker-icon-red-2x.png"),
  iconUrl: require("../../images/marker-icon-red.png"),
  shadowUrl: require("../../images/marker-shadow.png"),
  iconAnchor: [13, 41],
});

const { Column, HeaderCell, Cell } = Table;
const { Group: FormGroup } = Form;

const defaultReportFields = {
  events: [
    "event",
    "address",
    "speed",
    "heading",
    "battery",
    "device_time",
    "event_location",
  ],
  trips: ["tripMinimap", "tripDetails"],
  idle: ["startTime", "endTime", "address", "duration"],
  locations: ["location", "locationStart", "locationEnd", "locationDuration"],
  devices: ["id", "name", "serial"],
  users: ["id", "email", "firstname", "lastname"],
};

export default function (props) {
  const isMobile = window.innerWidth < 1024;
  const [{ user }] = useApp();
  const [reportType, setReportType] = useState(
    props.widget.config && props.widget.config.reportType
      ? props.widget.config.reportType
      : null
  );
  const [deviceId, setDeviceId] = useState(
    props.widget.config && props.widget.config.deviceId
      ? props.widget.config.deviceId
      : null
  );
  const [fieldOptions, setFieldOptions] = useState([]);
  const [fields, setFields] = useState(
    props.widget.config && props.widget.config.fields
      ? props.widget.config.fields
      : []
  );
  const { data } = useQuery(GQL.Queries.devices, {
    onCompleted: (res) => {
      //console.log("devices:", { res });
    },
  });
  const [reportRefreshRate, setReportRefreshRate] = useState(
    props.widget.config && props.widget.config.reportRefreshRate
      ? props.widget.config.reportRefreshRate
      : 0
  );
  const getStartDate = (config) => {
    let startDate = moment().format("YYYY-MM-DD");

    if (config.relativeDateType && config.relativeDateType.length > 0) {
      switch (config.relativeDateType) {
        case "yesterday":
          startDate = moment()
            .startOf("day")
            .add(-1, "day")
            .format("YYYY-MM-DD HH:mm:ss");
          break;
        case "today":
          startDate = moment().startOf("day").format("YYYY-MM-DD HH:mm:ss");
          break;
        case "week":
          startDate = moment().startOf("week").format("YYYY-MM-DD HH:mm:ss");
          break;
        case "month":
          startDate = moment().startOf("month").format("YYYY-MM-DD HH:mm:ss");
          break;
        default:
          break;
      }
    } else if (config.start.length > 0) {
      startDate = moment(props.widget.config.start).toDate();
    }

    return startDate;
  };
  const getEndDate = (config) => {
    let endDate = moment().add(1, "day").format("YYYY-MM-DD");

    if (config.relativeDateType && config.relativeDateType.length > 0) {
      switch (config.relativeDateType) {
        case "yesterday":
          endDate = moment().startOf("day").format("YYYY-MM-DD HH:mm:ss");
          break;
        case "today":
          endDate = moment()
            .startOf("day")
            .add(1, "day")
            .format("YYYY-MM-DD HH:mm:ss");
          break;
        case "week":
          endDate = moment()
            .startOf("day")
            .add(1, "day")
            .format("YYYY-MM-DD HH:mm:ss");
          break;
        case "month":
          endDate = moment()
            .startOf("day")
            .add(1, "day")
            .format("YYYY-MM-DD HH:mm:ss");
          break;
        default:
          break;
      }
    } else if (config.end.length > 0) {
      endDate = moment(props.widget.config.end).toDate();
    }

    return endDate;
  };
  const [start, setStart] = useState(
    props.widget.config &&
      (props.widget.config.start || props.widget.config.relativeDateType)
      ? getStartDate(props.widget.config)
      : moment().format("YYYY-MM-DD")
  );
  const [end, setEnd] = useState(
    props.widget.config &&
      (props.widget.config.end || props.widget.config.relativeDateType)
      ? getEndDate(props.widget.config)
      : moment().add(1, "day").format("YYYY-MM-DD")
  );
  const [relativeDateType, setRelativeDateType] = useState(
    props.widget.config && props.widget.config.relativeDateType
      ? props.widget.config.relativeDateType
      : null
  );
  const [
    getEvents,
    { data: events, called: eventsRequested, loading: loadingEvents },
  ] = useLazyQuery(GQL.Queries.events, {
    fetchPolicy: "network-only",
    pollInterval: reportRefreshRate,
    onCompleted: (data) => {
      //console.log({data})
    },
  });
  const [
    getTrips,
    { data: trips, called: tripsRequested, loading: loadingTrips },
  ] = useLazyQuery(GQL.Queries.trips, {
    fetchPolicy: "network-only",
    pollInterval: reportRefreshRate,
  });
  const [
    getIdleReport,
    {
      data: idleReport,
      called: idleReportRequested,
      //loading: loadingIdleReport,
    },
  ] = useLazyQuery(GQL.Queries.idleReport, {
    fetchPolicy: "network-only",
    pollInterval: reportRefreshRate,
  });
  const [
    getLocations,
    {
      data: locations,
      called: locationsRequested,
      //loading: loadingLocations
    },
  ] = useLazyQuery(GQL.Queries.locationsReport, {
    fetchPolicy: "network-only",
    pollInterval: reportRefreshRate,
  });
  const [
    getDevices,
    {
      data: devicesData,
      called: devicesDataRequested,
      refetch: refetchDevices,
      loading: loadingDevices,
    },
  ] = useLazyQuery(GQL.Queries.devices, {
    fetchPolicy: "network-only",
  });
  const [
    getUsers,
    {
      data: usersData,
      called: usersDataReqeusted,
      refetch: refetchUsers,
      loading: loadingUsers,
    },
  ] = useLazyQuery(GQL.Queries.users, {
    fetchPolicy: "network-only",
  });
  /*
  const widgetWidth =
    (window.innerWidth / cols) * props.widget.dataGrid.w -
    10 * props.widget.dataGrid.w;*/

  useEffect(() => {
    saveAndReload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportType, deviceId, start, end, relativeDateType]);

  useEffect(() => {
    /** Language File */
    lang =
      user && user.language
        ? require("../../languages/" + user.language + ".js").default
        : lang;
  }, [user]);

  const pullReport = () => {
    if (reportType === "events") {
      getEvents({
        variables: {
          deviceId,
          start: moment(start).utc().format("YYYY-MM-DD HH:mm:ss"),
          end: moment(end).utc().format("YYYY-MM-DD HH:mm:ss"),
        },
      });
    } else if (reportType === "trips") {
      getTrips({
        variables: {
          deviceId,
          start: moment(start).utc().format("YYYY-MM-DD HH:mm:ss"),
          end: moment(end).utc().format("YYYY-MM-DD HH:mm:ss"),
        },
      });
    } else if (reportType === "idle") {
      getIdleReport({
        variables: {
          deviceId,
          start: moment(start).utc().format("YYYY-MM-DD HH:mm:ss"),
          end: moment(end).utc().format("YYYY-MM-DD HH:mm:ss"),
        },
      });
    } else if (reportType === "locations") {
      //console.log("getLocations");
      getLocations({
        variables: {
          deviceId,
          start: moment(start).utc().format("YYYY-MM-DD HH:mm:ss"),
          end: moment(end).utc().format("YYYY-MM-DD HH:mm:ss"),
        },
      });
    } else if (reportType === "devices") {
      devicesData ? refetchDevices() : getDevices();
    } else if (reportType === "users") {
      usersData ? refetchUsers() : getUsers();
    }
  };

  const saveAndReload = () => {
    let configObject = { reportType, fields };

    switch (reportType) {
      case "events":
      case "trips":
      case "locations":
      case "idle":
        configObject = {
          ...configObject,
          ...(relativeDateType && relativeDateType.length > 0
            ? { relativeDateType }
            : { start, end }),
          deviceId,
          fields,
          reportRefreshRate,
        };
        break;
      default:
        break;
    }

    const config = JSON.stringify(configObject);
    props.updateWidget({
      variables: {
        id: props.widget.id,
        config,
      },
    });
    //props.collapse && props.collapse();

    if (reportType && deviceId && relativeDateType && start && end) {
      pullReport();
    }
  };

  const degreesToCardinal = (degrees) => {
    if (degrees > 337.5 && degrees < 22.5) {
      return "N";
    } else if (degrees >= 22.5 && degrees < 67.5) {
      return "NE";
    } else if (degrees >= 67.5 && degrees < 112.5) {
      return "E";
    } else if (degrees >= 112.5 && degrees < 157.5) {
      return "SE";
    } else if (degrees >= 157.5 && degrees < 202.5) {
      return "S";
    } else if (degrees >= 202.5 && degrees < 247.5) {
      return "SW";
    } else if (degrees >= 247.5 && degrees < 292.5) {
      return "W";
    } else {
      return "NW";
    }
  };

  const getColumnWidth = (field) => {
    switch (field) {
      case "minimap":
      case "startMinimap":
      case "endMinimap":
        return 125;
      case "tripMinimap":
        return 200;
      case "tripDetails":
        return 700;
      case "event":
        return 125;
      case "startLocation":
      case "endLocation":
        return 200;
      case "latlong":
      case "duration":
      case "address":
      case "location":
      case "locationDuration":
        return 150;
      case "device_time":
      case "startTime":
      case "endTime":
      case "locationStart":
      case "locationEnd":
        return 300;
      case "email":
        return 200;
      case "name":
        return 175;
      case "serial":
        return 350;
      case "lastReported":
        return 275;
      default:
        return 100;
    }
  };

  const cleanLabel = (field) => {
    switch (field) {
      case "address":
        return lang !== null ? lang.address : "Address";
      case "event_location":
        return lang !== null ? lang.eventLocations : "Locations";
      case "location":
        return lang !== null ? lang.locations : "Location";
      case "id":
        return lang !== null ? lang.id : "ID";
      case "latlong":
        return lang !== null ? lang.latLong : "Lat/Long";
      case "device_time":
        return lang !== null ? lang.eventTime : "Event Time";
      case "firstname":
        return lang !== null ? lang.firstNamePlaceholder : "First Name";
      case "lastname":
        return lang !== null ? lang.lastNamePlaceholder : "Last Name";
      case "email":
        return lang !== null ? lang.email : "Email";
      case "durationInSeconds":
      case "duration":
      case "locationDuration":
        return lang !== null ? lang.duration : "Duration";
      case "startEvent":
        return lang !== null ? lang.startEvent : "Start Event";
      case "startTime":
      case "locationStart":
        return lang !== null ? lang.startTime : "Start Time";
      case "startLocation":
        return lang !== null ? lang.startLocation : "Start Location";
      case "startMinimap":
        return lang !== null ? lang.startMap : "Start Map";
      case "tripDetails":
        return lang !== null ? lang.tripDetails : "Trip Details";
      case "endEvent":
        return lang !== null ? lang.endEvent : "End Event";
      case "endTime":
      case "locationEnd":
        return lang !== null ? lang.endTime : "End Time";
      case "endLocation":
        return lang !== null ? lang.endLocation : "End Location";
      case "endMinimap":
        return lang !== null ? lang.endMap : "End Map";
      case "tripMinimap":
        return lang !== null ? lang.tripMap : "Trip Map";
      case "lastReported":
        return lang !== null ? lang.lastReported : "Last Reported";

      default:
        return lang !== null
          ? lang[field]
          : field.charAt(0).toUpperCase() + field.slice(1);
    }
  };

  const secondsToString = (seconds) => {
    return seconds < 60
      ? seconds + " " + (lang !== null ? lang.second : "Second(s)")
      : seconds < 60 * 60
      ? parseInt(seconds / 60) +
        " " +
        (lang !== null ? lang.minute : "Minute(s)")
      : seconds < 60 * 60 * 24
      ? parseInt(seconds / 60 / 60) +
        " " +
        (lang !== null ? lang.hour : "Hour(s)")
      : parseInt(seconds / 60 / 60 / 24) +
        " " +
        (lang !== null ? lang.day : "Day(s)");
  };

  /*
  const secondsToString = seconds => {
    return seconds < 60
      ? seconds + " second" + (seconds > 1 ? "s" : "")
      : seconds < 60 * 60
      ? parseInt(seconds / 60) +
        " minute" +
        (parseInt(seconds / 60) > 1 ? "s" : "")
      : seconds < 60 * 60 * 24
      ? parseInt(seconds / 60 / 60) +
        " hour" +
        (parseInt(seconds / 60 / 60) > 1 ? "s" : "")
      : parseInt(seconds / 60 / 60 / 24) +
        " day" +
        (parseInt(seconds / 60 / 60 / 24) > 1 ? "s" : "");
  };
  */

  const formatReportField = (event, field) => {
    //console.log({ event });

    switch (field) {
      case "address":
        return event.address ? event.address : "N/A";
      case "event_location":
        return event.locationIds && event.locationIds.length > 0 ? (
          <>
            {event.locationIds.map((locationId) => (
              <EventLocation locationId={locationId} />
            ))}
          </>
        ) : null;
      case "minimap":
        return event.latitude && event.longitude ? (
          <Map
            className="shadow"
            zoomControl={false}
            scrollWheelZoom={false}
            doubleClickZoom={false}
            dragging={false}
            style={{
              width: "100px",
              height: "100px",
              borderRadius: "5px",
            }}
            bounds={[
              [event.latitude - 0.001, event.longitude - 0.001],
              [event.latitude + 0.001, event.longitude + 0.001],
            ]}
            attributionControl={false}
          >
            <AccountTileLayer />
            {/* <TileLayer
              attribution=""
              url="//{s}.tile.osm.org/{z}/{x}/{y}.png"
            /> */}
            <Marker position={[event.latitude, event.longitude]} />
          </Map>
        ) : (
          <span>N/A</span>
        );
      case "event":
        return (
          <span style={{ color: "#" + event.conduit_color_code }}>
            {event.conduit_event_description}
          </span>
        );
      case "latlong":
        return event.latitude && event.longitude
          ? parseFloat(event.latitude).toFixed(5) +
              "," +
              parseFloat(event.longitude).toFixed(5)
          : "N/A";
      case "location":
        return event.location && event.location.name
          ? event.location.name
          : "N/A";
      case "speed":
        return event.speed ? parseInt(event.speed) + " Mph" : "N/A";
      case "heading":
        return event.heading ? degreesToCardinal(event.heading) : "N/A";
      case "lastReported":
        if (event.lastEvent) {
          const lastEvent = JSON.parse(event.lastEvent);

          return moment
            .unix(lastEvent.event_data.device_time)
            .format("ddd. MMM. Do, YYYY - h:mm:ss a");
        } else {
          return "N/A";
        }
      case "device_time":
        return moment
          .unix(event.device_time)
          .format("ddd. MMM. Do, YYYY - h:mm:ss a");
      case "battery":
        return event.battery_percentage
          ? parseInt(event.battery_percentage) + "%"
          : "N/A";
      case "startTime":
        return moment
          .unix(event.startTime)
          .format("ddd. MMM. Do, YYYY - h:mm:ss a");
      case "locationStart":
        //console.log({ event });
        return moment
          .unix(parseInt(event.createdAt / 1000))
          .format("ddd. MMM. Do, YYYY - h:mm:ss a");
      case "locationEnd":
        //console.log({ event });
        return event.exitedAt
          ? moment
              .unix(parseInt(event.exitedAt / 1000))
              .format("ddd. MMM. Do, YYYY - h:mm:ss a")
          : "N/A";
      case "startEvent":
        return (
          <span
            style={{
              color:
                "#" + JSON.parse(event.events[0]).event_data.conduit_color_code,
            }}
          >
            {JSON.parse(event.events[0]).event_data.conduit_event_description}
          </span>
        );
      case "endEvent":
        return (
          <span
            style={{
              color:
                "#" +
                JSON.parse(event.events[event.events.length - 1]).event_data
                  .conduit_color_code,
            }}
          >
            {
              JSON.parse(event.events[event.events.length - 1]).event_data
                .conduit_event_description
            }
          </span>
        );
      case "startLocation":
        return (
          event.startLocation.latitude + "," + event.startLocation.longitude
        );
      case "tripMinimap":
        try {
          let tripEvents = event.events
            .map((e) => JSON.parse(e))
            .filter((e) => e.event_data.latitude && e.event_data.longitude);

          return (
            <div
              draggable={true}
              onDragStart={(e) => e.dataTransfer.setData("text/plain", "TEST")}
              x={7}
            >
              <Map
                className="small-shadow"
                zoomControl={false}
                doubleClickZoom={false}
                scrollWheelZoom={false}
                dragging={false}
                style={{
                  width: "180px",
                  height: "180px",
                  borderRadius: "5px",
                }}
                bounds={[
                  [
                    Math.min.apply(
                      null,
                      tripEvents.map((e) => e.event_data.latitude)
                    ) - 0.00001,
                    Math.min.apply(
                      null,
                      tripEvents.map((e) => e.event_data.longitude)
                    ) - 0.00001,
                  ],
                  [
                    Math.max.apply(
                      null,
                      tripEvents.map((e) => e.event_data.latitude)
                    ) + 0.00001,
                    Math.max.apply(
                      null,
                      tripEvents.map((e) => e.event_data.longitude)
                    ) + 0.00001,
                  ],
                ]}
                attributionControl={false}
              >
                <AccountTileLayer />
                {/* <TileLayer
                  attribution=""
                  url="//{s}.tile.osm.org/{z}/{x}/{y}.png"
                /> */}
                <Marker
                  position={[
                    event.startLocation.latitude,
                    event.startLocation.longitude,
                  ]}
                  icon={startIcon}
                />
                <Marker
                  position={[
                    event.endLocation.latitude,
                    event.endLocation.longitude,
                  ]}
                  icon={endIcon}
                />
                <Polyline
                  color="green"
                  positions={tripEvents.map((e) => [
                    e.event_data.latitude,
                    e.event_data.longitude,
                  ])}
                />
              </Map>
            </div>
          );
        } catch (e) {
          console.error(e);
          return null;
        }
      case "tripDetails":
        try {
          //console.log("trip events json:", event.events);
          let tripEvents = event.events
            .map((e) => JSON.parse(e))
            .filter((e) => e.event_data.latitude && e.event_data.longitude);
          return (
            <div style={{ widht: "100%", textAlign: "left" }}>
              <div style={{ fontWeight: "bold", fontSize: "9pt" }}>
                {tripEvents[0].event_data.address} <Icon icon="faArrowRight" />{" "}
                {tripEvents[tripEvents.length - 1].event_data.address}
              </div>
              <div style={{ color: "#888888", fontSize: "9pt" }}>
                <strong>{lang !== null ? lang.date : "Date"}:</strong>{" "}
                {moment.unix(event.startTime).format("ddd MMM. Do YYYY h:mm A")}
              </div>
              <hr style={{ marginTop: "5px", marginBottom: "5px" }} />
              <div style={{ color: "#888888", fontSize: "9pt" }}>
                <Icon icon="faClock" />{" "}
                {secondsToString(event.durationInSeconds)}
                {" | "}
                <Icon icon="faRoad" />{" "}
                {parseFloat(event.distanceInMeters * 0.000621371).toFixed(1)}{" "}
                {lang !== null ? lang.miles : "Miles"}
                {" | "}
                <Icon icon="faTrophy" /> {parseFloat(event.topSpeed).toFixed(1)}{" "}
                {lang !== null ? lang.mphTopSpeed : "Mph Top Speed"}
                {" | "}
                <Icon icon="faCar" />{" "}
                {parseFloat(event.averageSpeed).toFixed(1)}{" "}
                {lang !== null ? lang.average : "Average"}
                {lang !== null ? lang.mph : "Mph"}
                {" | "}
                <Icon icon="faList" /> {tripEvents.length}{" "}
                {lang !== null ? lang.events : "Events"}
              </div>
              <hr style={{ marginTop: "5px", marginBottom: "5px" }} />
              <div
                style={{
                  height: "105px",
                  overflow: "scroll",
                  borderRadius: "5px",
                  overflowX: "hidden",
                  fontSize: "9pt",
                }}
              >
                <Grid fluid>
                  {
                    //tripEvents.slice(0, 4).map((e, index) => (
                    tripEvents.map((e, index) => (
                      <Row
                        key={"trip-event-row-" + index}
                        style={{ borderBottom: "dashed 1px #eeeeee" }}
                      >
                        <Col xs={1} style={{ textAlign: "right" }}>
                          {index + 1}
                        </Col>
                        <Col xs={6} style={{ textAlign: "right" }}>
                          <span
                            style={{
                              //border: "1px solid blue",
                              textAlign: "right",
                              color: "#" + e.event_data.conduit_color_code,
                            }}
                          >
                            {e.event_data.conduit_event_description}
                          </span>
                        </Col>
                        <Col xs={6} style={{ textAlign: "center" }}>
                          <span>
                            <Icon icon="faClock" />{" "}
                            {moment
                              .unix(e.event_data.device_time)
                              .format("h:mm:ss A")}
                          </span>
                        </Col>
                        <Col xs={4} style={{ textAlign: "left" }}>
                          <span>
                            <Icon icon="faCar" /> {parseInt(e.event_data.speed)}{" "}
                            Mph
                          </span>
                        </Col>
                        <Col xs={1} style={{ textAlign: "left" }}>
                          <span>
                            <Icon icon="faCompass" />{" "}
                            {degreesToCardinal(e.event_data.heading)}
                          </span>
                        </Col>
                      </Row>
                    ))
                  }
                  {/*
                  <Row>
                    <Col xs={18} style={{ textAlign: "center" }}>
                      {lang !== null
                        ? lang.clickHereForEventsReport
                        : "Click here for events report"}
                    </Col>
                  </Row>
                      */}
                </Grid>
              </div>
            </div>
          );
        } catch (e) {
          console.error("trip error:", e);
          return null;
        }
      case "startMinimap":
        return (
          <Map
            className="small-shadow"
            zoomControl={false}
            doubleClickZoom={false}
            scrollWheelZoom={false}
            dragging={false}
            style={{
              width: "100px",
              height: "100px",
              borderRadius: "5px",
            }}
            bounds={[
              [
                event.startLocation.latitude - 0.001,
                event.startLocation.longitude - 0.001,
              ],
              [
                event.startLocation.latitude + 0.001,
                event.startLocation.longitude + 0.001,
              ],
            ]}
            attributionControl={false}
          >
            <AccountTileLayer />
            {/* <TileLayer
              attribution=""
              url="//{s}.tile.osm.org/{z}/{x}/{y}.png"
            /> */}
            <Marker
              position={[
                event.startLocation.latitude,
                event.startLocation.longitude,
              ]}
            />
          </Map>
        );
      case "endLocation":
        return event.endLocation.latitude + "," + event.endLocation.longitude;
      case "duration":
      case "durationInSeconds":
        return secondsToString(event.durationInSeconds);
      case "locationDuration":
        return event.exitedAt
          ? secondsToString(
              parseInt(event.exitedAt / 1000) - parseInt(event.createdAt / 1000)
            )
          : "On Going";
      case "endTime":
        return moment
          .unix(event.endTime)
          .format("ddd. MMM. Do, YYYY - h:mm:ss a");
      case "endMinimap":
        return (
          <Map
            className="small-shadow"
            zoomControl={false}
            doubleClickZoom={false}
            scrollWheelZoom={false}
            dragging={false}
            style={{
              width: "100px",
              height: "100px",
              borderRadius: "5px",
            }}
            bounds={[
              [
                event.endLocation.latitude - 0.001,
                event.endLocation.longitude - 0.001,
              ],
              [
                event.endLocation.latitude + 0.001,
                event.endLocation.longitude + 0.001,
              ],
            ]}
            attributionControl={false}
          >
            <AccountTileLayer />
            {/* <TileLayer
              attribution=""
              url="//{s}.tile.osm.org/{z}/{x}/{y}.png"
            /> */}
            <Marker
              position={[
                event.endLocation.latitude,
                event.endLocation.longitude,
              ]}
            />
          </Map>
        );

      default:
        return event[field];
    }
  };

  const formatCSVReportField = (event, field) => {
    switch (field) {
      case "event_location":
        return JSON.stringify(event.locationIds);
      case "minimap":
        return "N/A";
      case "event":
        return event.conduit_event_description;
      case "latlong":
        return event.latitude && event.longitude
          ? parseFloat(event.latitude).toFixed(5) +
              "," +
              parseFloat(event.longitude).toFixed(5)
          : "N/A";
      case "speed":
        return event.speed ? parseInt(event.speed) + " Mph" : "N/A";
      case "heading":
        return event.heading ? degreesToCardinal(event.heading) : "N/A";
      case "lastReported":
        if (event.lastEvent) {
          const lastEvent = JSON.parse(event.lastEvent);

          return moment
            .unix(lastEvent.event_data.device_time)
            .format("ddd. MMM. Do, YYYY - h:mm:ss a");
        } else {
          return "N/A";
        }
      case "device_time":
        return moment
          .unix(event.device_time)
          .format("ddd. MMM. Do, YYYY - h:mm:ss a");
      case "battery":
        return event.battery_percentage
          ? parseInt(event.battery_percentage) + "%"
          : "N/A";
      case "startTime":
        return moment
          .unix(event.startTime)
          .format("ddd. MMM. Do, YYYY - h:mm:ss a");
      case "startEvent":
        return JSON.parse(event.events[0]).event_data.conduit_event_description;
      case "endEvent":
        return JSON.parse(event.events[event.events.length - 1]).event_data
          .conduit_event_description;
      case "startLocation":
        return (
          event.startLocation.latitude + "," + event.startLocation.longitude
        );
      case "tripMinimap":
        return "N/A";
      case "tripDetails":
        return "Not Supported At This Time";
      case "startMinimap":
        return "N/A";
      case "endLocation":
        return event.endLocation.latitude + "," + event.endLocation.longitude;
      case "durationInSeconds":
        return secondsToString(event.durationInSeconds);
      case "endTime":
        return moment
          .unix(event.endTime)
          .format("ddd. MMM. Do, YYYY - h:mm:ss a");
      case "endMinimap":
        return "N/A";
      default:
        return event[field];
    }
  };

  const setReportTypeAndFields = (reportType) => {
    setReportType(reportType);

    switch (reportType) {
      case "events":
        setFieldOptions([
          "minimap",
          "altitude",
          "event",
          "latlong",
          "address",
          "speed",
          "heading",
          "battery",
          "device_time",
          "event_location",
        ]);
        break;
      case "trips":
        setFieldOptions([
          "tripMinimap",
          "tripDetails",
          "durationInSeconds",
          "startEvent",
          "startTime",
          "startLocation",
          "startMinimap",
          "endEvent",
          "endTime",
          "endLocation",
          "endMinimap",
        ]);
        break;
      case "idle":
        setFieldOptions(["startTime", "endTime", "address", "duration"]);
        break;
      case "locations":
        setFieldOptions([
          "location",
          "locationStart",
          "locationEnd",
          "locationDuration",
        ]);
        break;
      case "devices":
        setFieldOptions([
          "id",
          "name",
          "serial",
          "imei",
          "simnumber",
          "lastReported",
        ]);
        break;
      case "users":
        setFieldOptions(["id", "email", "firstname", "lastname"]);
        break;
      default:
        break;
    }
  };

  const renderContent = () => {
    let reportData = [];
    let headers = [];
    let csvData = [];
    let reportTitle = "";
    let reportDescription =
      user && user.account && user.account.name ? user.account.name : "";

    //console.log({ reportType });

    if (reportType === "events") {
      const device =
        data && data.devices
          ? data.devices.find((device) => device.id === deviceId)
          : null;

      //console.log(props.widget.dataGrid);
      reportTitle = (
        (lang !== null
          ? lang[reportType]
          : reportType.charAt(0).toUpperCase()) +
        (lang !== null ? " " + lang.reportFor + " " : " Report for ") +
        (device && device.name ? device.name : "device")
      ).slice(0, props.widget.dataGrid.w * 15);

      reportDescription =
        moment(start).format("M/D/YYYY") +
        " - " +
        moment(end).format("M/D/YYYY");

      !eventsRequested &&
        getEvents({
          variables: {
            deviceId,
            start: moment(start).utc().format("YYYY-MM-DD HH:mm:ss"),
            end: moment(end).utc().format("YYYY-MM-DD HH:mm:ss"),
          },
        });

      if (events) {
        reportData = events.events.map((event) => {
          const parsedEvent = JSON.parse(event);
          return {
            ...parsedEvent.event_data,
            locationIds: parsedEvent.locationIds,
          };
        });
      }
    } else if (reportType === "trips") {
      const device =
        data && data.devices
          ? data.devices.find((device) => device.id === deviceId)
          : null;

      reportTitle =
        (lang !== null
          ? lang[reportType]
          : reportType.charAt(0).toUpperCase()) +
        (lang !== null ? " " + lang.reportFor + " " : " Report for ") +
        (device && device.name ? device.name : "device");

      reportDescription =
        moment(start).format("M/D/YYYY") +
        " - " +
        moment(end).format("M/D/YYYY");

      !tripsRequested &&
        getTrips({
          variables: {
            deviceId,
            start: moment(start).utc().format("YYYY-MM-DD HH:mm:ss"),
            end: moment(end).utc().format("YYYY-MM-DD HH:mm:ss"),
          },
        });

      if (trips && trips.trips != null) {
        reportData = trips.trips.filter((trip) => trip !== null);
      } else {
        reportData = [];
      }
    } else if (reportType === "idle") {
      const device =
        data && data.devices
          ? data.devices.find((device) => device.id === deviceId)
          : null;

      reportTitle =
        (lang !== null
          ? lang[reportType]
          : reportType.charAt(0).toUpperCase()) +
        (lang !== null ? " " + lang.reportFor + " " : " Report for ") +
        (device && device.name ? device.name : "device");

      reportDescription =
        moment(start).format("M/D/YYYY") +
        " - " +
        moment(end).format("M/D/YYYY");

      !idleReportRequested &&
        getIdleReport({
          variables: {
            deviceId,
            start: moment(start).utc().format("YYYY-MM-DD HH:mm:ss"),
            end: moment(end).utc().format("YYYY-MM-DD HH:mm:ss"),
          },
        });

      if (idleReport && idleReport.idleReport != null) {
        reportData = idleReport.idleReport.filter((idle) => idle !== null);
      } else {
        reportData = [];
      }
    } else if (reportType === "locations") {
      //console.log("locations report");
      const device =
        data && data.devices
          ? data.devices.find((device) => device.id === deviceId)
          : null;

      reportTitle =
        (lang !== null
          ? lang[reportType]
          : reportType.charAt(0).toUpperCase()) +
        (lang !== null ? " " + lang.reportFor + " " : " Report for ") +
        (device && device.name ? device.name : "device");

      reportDescription =
        moment(start).format("M/D/YYYY") +
        " - " +
        moment(end).format("M/D/YYYY");

      !locationsRequested &&
        getLocations({
          variables: {
            deviceId,
            start: moment(start).utc().format("YYYY-MM-DD HH:mm:ss"),
            end: moment(end).utc().format("YYYY-MM-DD HH:mm:ss"),
          },
        });

      if (locations && locations.locationsReport != null) {
        reportData = locations.locationsReport
          .filter((location) => location !== null)
          .map((lt) => ({
            ...lt,
            duration: lt.exitedAt
              ? moment
                  .utc(lt.createdAt)
                  .diff(moment.utc(lt.exitedAt), "seconds")
              : null,
          }));
        //console.log({ reportData });
      } else {
        reportData = [];
      }
    } else if (reportType === "devices") {
      reportTitle = lang !== null ? lang.devices : "Devices";
      !devicesDataRequested && getDevices();

      reportData =
        devicesData && devicesData.devices ? devicesData.devices : [];
    } else if (reportType === "users") {
      reportTitle = lang !== null ? lang.users : "Users";
      !usersDataReqeusted && getUsers();
      reportData = usersData && usersData.users ? usersData.users : [];
    }

    if (reportData && reportData.length > 0 && fields && fields.length > 0) {
      headers = fields.map((field) => ({
        label: cleanLabel(field),
        key: field,
      }));

      csvData = reportData.map((rd) =>
        fields.reduce((csvRow, field) => {
          csvRow[field] = formatCSVReportField(rd, field);
          return csvRow;
        }, {})
      );
    }

    return (
      <div style={{ width: "100%" }}>
        <Grid
          fluid
          style={{
            fontSize: "1.1rem",
            fontWeight: "bold",
            width: "100%",
            textAlign: "left",
            padding: "15px",
            paddingBottom: "5px",
            borderBottom: "1px solid #eeeeee",
          }}
        >
          <Row>
            <Col xs={18} style={{ paddingTop: "7px" }}>
              <div>{reportTitle}</div>
              <div style={{ fontSize: "0.75rem", color: "#aaa" }}>
                {reportDescription}
              </div>
            </Col>
            <Col xs={6} style={{ textAlign: "right" }}>
              {reportData.length > 0 && (
                <CSVLink
                  data={csvData}
                  headers={headers}
                  filename={"geofleetos-export.csv"}
                  uFEFF={false}
                >
                  <Button appearance="primary">
                    <Icon icon="faTable" />{" "}
                    {lang !== null ? lang.export : "Export"}
                  </Button>
                </CSVLink>
              )}{" "}
              <Button
                appearance="ghost"
                onClick={() => {
                  saveAndReload();
                }}
              >
                <Icon
                  icon="faRefresh"
                  onClick={() => {
                    saveAndReload();
                  }}
                />
              </Button>
            </Col>
          </Row>
        </Grid>
        <Table
          virtualized={true}
          height={
            isMobile
              ? window.innerHeight - 230
              : (props.baseHeight + 10) * props.h - 125
          }
          data={reportData}
          affixHeader={true}
          rowHeight={
            fields.includes("tripMinimap")
              ? 210
              : fields.includes("startMinimap") ||
                fields.includes("endMinimap") ||
                fields.includes("minimap")
              ? 125
              : /*
              : fields.includes("event_location")
              ? 75
              */
                46
          }
          style={{
            borderTop: "2px solid #fafafa",
            borderBottom: "1px dashed #fafafa",
            fontSize: "9pt",
          }}
          loading={
            loadingEvents || loadingTrips || loadingDevices || loadingUsers
          }
        >
          {fields.map((field) => (
            <Column
              key={"report-" + props.widget.id + "-column-" + field}
              resizable={true}
              width={getColumnWidth(field)}
            >
              <HeaderCell
                key={"report-" + props.widget.id + "-headercell-" + field}
              >
                {cleanLabel(field)}
              </HeaderCell>
              <Cell key={"report-" + props.widget.id + "-cell-" + field}>
                {(event) => {
                  return formatReportField(event, field);
                }}
              </Cell>
            </Column>
          ))}
        </Table>
      </div>
    );
  };

  const validateForm = () => {
    switch (reportType) {
      case "events":
      case "trips":
      case "idle":
      case "locations":
        return deviceId && start && end && fields && fields.length > 0;
      case "devices":
      case "users":
        return fields && fields.length > 0;
      default:
        return false;
    }
  };

  useEffect(() => {
    setReportTypeAndFields(reportType);
    pullReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportType, start, end, deviceId]);

  return (
    <div>
      <Panel
        expanded={
          props.expanded ||
          props.widget.config === null ||
          props.widget.config.reportType === null ||
          !validateForm()
        }
        collapsible
        bodyFill
        className="widget-config"
        style={{ borderRadius: 0 }}
      >
        <Form
          style={{ padding: "5px", paddingTop: "15px", paddingBottom: "15px" }}
        >
          <FormGroup>
            <InputGroup style={{ width: "100%" }}>
              <InputGroup.Addon style={{ width: "100%" }}>
                <Icon icon="faFileText" style={{ marginRight: 10 }} />
                <SelectPicker
                  searchable={false}
                  style={{ width: "100%" }}
                  data={[
                    {
                      value: "events",
                      label: lang !== null ? lang.events : "Events",
                    },
                    {
                      value: "trips",
                      label: lang !== null ? lang.trips : "Trips",
                    },
                    {
                      value: "idle",
                      label: lang !== null ? lang.idle : "Idle",
                    },
                    {
                      value: "locations",
                      label: lang !== null ? lang.locations : "Locations",
                    },
                    {
                      value: "devices",
                      label: lang !== null ? lang.devices : "Devices",
                    },
                    {
                      value: "users",
                      label: lang !== null ? lang.users : "Users",
                    },
                  ]}
                  value={reportType}
                  onChange={(value) => {
                    setFields(
                      defaultReportFields[value]
                        ? defaultReportFields[value]
                        : []
                    );
                    setReportTypeAndFields(value);
                  }}
                  placeholder={
                    lang !== null ? lang.selectReport : "Select a report"
                  }
                />
              </InputGroup.Addon>
            </InputGroup>
            {reportType && reportType.length > 0 && (
              <InputGroup style={{ width: "100%" }}>
                <InputGroup.Addon style={{ width: "100%" }}>
                  <Icon icon="faTable" style={{ marginRight: 10 }} />
                  <CheckPicker
                    searchable={false}
                    style={{ width: "100%" }}
                    placeholder={
                      lang !== null
                        ? lang.selectReportFields
                        : "Select report fields"
                    }
                    value={fields}
                    onChange={setFields}
                    data={fieldOptions.map((f) => ({
                      value: f,
                      label: cleanLabel(f),
                    }))}
                  />
                </InputGroup.Addon>
              </InputGroup>
            )}
            {
              // Events and Trips Reports
              ["events", "trips", "idle", "locations"].includes(reportType) && (
                <>
                  <InputGroup style={{ width: "100%" }}>
                    <InputGroup.Addon style={{ width: "100%" }}>
                      <Icon icon="faTablet" style={{ marginRight: 10 }} />
                      <SelectPicker
                        style={{ width: "100%" }}
                        data={
                          data && data.devices
                            ? data.devices.map((device) => ({
                                value: device.id,
                                label: device.name,
                              }))
                            : []
                        }
                        value={deviceId}
                        onChange={setDeviceId}
                        placeholder={
                          lang !== null ? lang.selectDevice : "Select a device"
                        }
                      />
                    </InputGroup.Addon>
                  </InputGroup>
                  <InputGroup style={{ width: "100%" }}>
                    <ButtonGroup style={{ width: "100%" }}>
                      <Button
                        style={{
                          width: "25%",
                          ...(props.widget.config.relativeDateType ===
                          "yesterday"
                            ? {
                                backgroundColor: "var(--base-color)",
                                color: "white",
                              }
                            : {}),
                        }}
                        onClick={() => {
                          setStart(
                            moment()
                              .startOf("day")
                              .add(-1, "day")
                              .format("YYYY-MM-DD HH:mm:ss")
                          );
                          setEnd(
                            moment()
                              .startOf("day")
                              .format("YYYY-MM-DD HH:mm:ss")
                          );
                          setRelativeDateType("yesterday");
                          setReportRefreshRate(0);
                        }}
                      >
                        <Icon icon="faCalendarDays" />{" "}
                        {lang !== null ? lang.yesterday : "Yesterday"}
                      </Button>
                      <Button
                        style={{
                          width: "25%",
                          ...(props.widget.config.relativeDateType === "today"
                            ? {
                                backgroundColor: "var(--base-color)",
                                color: "white",
                              }
                            : {}),
                        }}
                        onClick={() => {
                          setStart(
                            moment()
                              .startOf("day")
                              .format("YYYY-MM-DD HH:mm:ss")
                          );
                          setEnd(
                            moment()
                              .startOf("day")
                              .add(1, "day")
                              .format("YYYY-MM-DD HH:mm:ss")
                          );
                          setRelativeDateType("today");
                          setReportRefreshRate(1800000); //refresh every 30 minutes.
                        }}
                      >
                        <Icon icon="faCalendarDays" />{" "}
                        {lang !== null ? lang.today : "Today"}
                      </Button>
                      <Button
                        style={{
                          width: "25%",
                          ...(props.widget.config.relativeDateType === "week"
                            ? {
                                backgroundColor: "var(--base-color)",
                                color: "white",
                              }
                            : {}),
                        }}
                        onClick={() => {
                          setStart(
                            moment()
                              .startOf("week")
                              .format("YYYY-MM-DD HH:mm:ss")
                          );
                          setEnd(
                            moment()
                              .startOf("day")
                              .add(1, "day")
                              .format("YYYY-MM-DD HH:mm:ss")
                          );
                          setRelativeDateType("week");
                          setReportRefreshRate(0);
                        }}
                      >
                        <Icon icon="faCalendarDays" />{" "}
                        {lang !== null ? lang.this_week : "This Week"}
                      </Button>
                      <Button
                        style={{
                          width: "25%",
                          ...(props.widget.config.relativeDateType === "month"
                            ? {
                                backgroundColor: "var(--base-color)",
                                color: "white",
                              }
                            : {}),
                        }}
                        onClick={() => {
                          setStart(
                            moment()
                              .startOf("month")
                              .format("YYYY-MM-DD HH:mm:ss")
                          );
                          setEnd(
                            moment()
                              .startOf("day")
                              .add(1, "day")
                              .format("YYYY-MM-DD HH:mm:ss")
                          );
                          setRelativeDateType("month");
                          setReportRefreshRate(0);
                        }}
                      >
                        <Icon icon="faCalendarDays" />{" "}
                        {lang !== null ? lang.this_month : "This Month"}
                      </Button>
                    </ButtonGroup>
                  </InputGroup>
                  <InputGroup style={{ width: "100%" }}>
                    <DatePicker
                      placeholder={lang !== null ? lang.start : "Start"}
                      size="lg"
                      value={moment(start).toDate()}
                      onChange={setStart}
                      /*
                    onClick={() => {
                      setRelativeDateType(null)
                    }}
                    */
                      onSelect={() => {
                        setRelativeDateType(null);
                        setReportRefreshRate(0);
                      }}
                      oneTap
                      style={{ width: "50%" }}
                    />
                    <DatePicker
                      placeholder={lang !== null ? lang.end : "End"}
                      size="lg"
                      value={moment(end).toDate()}
                      onChange={setEnd}
                      /*
                    onClick={() => {
                      setRelativeDateType(null)
                    }}
                    */
                      onSelect={() => {
                        setRelativeDateType(null);
                        setReportRefreshRate(0);
                      }}
                      oneTap
                      style={{ width: "50%" }}
                    />
                  </InputGroup>
                </>
              )
            }
          </FormGroup>
          <FormGroup>
            <Button
              //className="shadow"
              //color="blue"
              appearance="primary"
              style={{
                backgroundColor: "var(--base-color)",
                color: "white",
              }}
              onClick={() => {
                saveAndReload();
                props.collapse && props.collapse();
              }}
            >
              <Icon icon="faBan" width={15} />{" "}
              {lang !== null ? lang.close : "Close"}
            </Button>
            {/*
            <SaveCancel
              saveDisabled={!validateForm()}
              onSave={() => {
                saveAndReload();
              }}
              onCancel={() => props.collapse && props.collapse()}
            />
            */}
          </FormGroup>
        </Form>
      </Panel>
      {props.widget.config == null || !validateForm() ? (
        <Message
          type="info"
          title={
            lang !== null
              ? lang.configureWidgetPlaceholder
              : "Widget needs to be configured"
          }
        />
      ) : (
        renderContent()
      )}
    </div>
  );
}
