import React, { useState } from "react";
import { SelectPicker, Panel } from "rsuite";

import Icon from "../../components/Icon";
import { useQuery } from "@apollo/react-hooks";

import GQL from "./graphql";

import { useApp } from "../../AppStore";

export default (props) => {
  const { selectedAccount } = props;
  const [{ user }] = useApp();
  const { data } = props;

  const { data: accounts } = useQuery(GQL.Queries.accounts);

  const [accountId] = useState(data && data.accountId ? data.accountId : 0);

  return (
    <Panel>
      <SelectPicker
        cleanable={false}
        searchable={false}
        placeholder="Transfer to..."
        data={
          accounts && accounts.accounts
            ? [
                selectedAccount
                  ? {
                      value: user.account.id,
                      label: (
                        <span>
                          <Icon icon="faHome" /> {user.account.name}
                        </span>
                      ),
                    }
                  : null,
                ...accounts.accounts.map((account) => ({
                  value: account.id,
                  label: account.name,
                })),
              ].filter((account) => {
                return account && account.value !== data.accountId;
              })
            : []
        }
        style={{
          width: "100%",
          margin: 0,
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%) translateX(-50%)",
          zIndex: 9999,
        }}
        value={accountId}
        onChange={props.onChange}
        onClose={() =>
          props.setShowTransferPopup && props.setShowTransferPopup(false)
        }
      />
    </Panel>
  );
};
