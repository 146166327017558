import React, { useState, useEffect } from "react";
import { Container, Content } from "rsuite";
import Cookie from "js-cookie";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { Loader } from "rsuite";

/** Style Sheets */
//import "./App.less";
import "leaflet/dist/leaflet.css";

import { useApp } from "./AppStore";

import Header from "./layout/header";

/** Widgets */
import Widgets from "./widgets";

const token = Cookie.get("atles-geofleetos-token");
const QUERY = gql`
  query user {
    user {
      id
      email
      language
      firstname
      lastname
      avatar
      accountId
      screens {
        id
        title
        description
        icon
        widgets {
          id
          title
          type
          icon
          config
          dataGrid
          widgetTypeId
        }
      }
      smScreens {
        id
        title
        description
        icon
        widgets {
          id
          title
          type
          icon
          config
          dataGrid
          widgetTypeId
        }
      }
      account {
        id
        name
        logo
        smAccess
        primaryColor
        secondaryColor
        mapSystemId
        mapSystemApiKey
      }
    }
  }
`;

const CHECKTOKEN = gql`
  query isValidSession {
    isValidSession
  }
`;

const hexToHSL = (H) => {
  // Convert hex to RGB first
  let r = 0,
    g = 0,
    b = 0;
  if (H.length === 4) {
    r = "0x" + H[1] + H[1];
    g = "0x" + H[2] + H[2];
    b = "0x" + H[3] + H[3];
  } else if (H.length === 7) {
    r = "0x" + H[1] + H[2];
    g = "0x" + H[3] + H[4];
    b = "0x" + H[5] + H[6];
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  let cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0;

  if (delta === 0) h = 0;
  else if (cmax === r) h = ((g - b) / delta) % 6;
  else if (cmax === g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return {
    h,
    s,
    l,
  }; //"hsl(" + h + "," + s + "%," + l + "%)";
};

const App = (props) => {
  const windowSize = useWindowSize();
  const [{ user, system }, actions] = useApp();
  const { loading: checkingSession } = useQuery(CHECKTOKEN, {
    onCompleted: (res) => {
      //console.log("session check:", res);
      if (!res.isValidSession) {
        window.location = "/login";
      }
    },
  });
  const { data } = useQuery(QUERY, {
    variables: { token },
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      //console.log({ res });
      if (
        res &&
        res.user &&
        res.user.account &&
        res.user.account.primaryColor
      ) {
        const hex =
          props.system && props.system === "sm"
            ? "#A2ADB8"
            : res.user.account.primaryColor;
        const { h, s, l } = hexToHSL(hex);

        document.documentElement.style.setProperty("--base-color-h", h);
        document.documentElement.style.setProperty("--base-color-s", s + "%");
        document.documentElement.style.setProperty("--base-color-l", l + "%");
        /*
        document.documentElement.style.setProperty(
          "--base-color",
          props.system && props.system === "sm"
            ? "#A2ADB8"
            : res.user.account.primaryColor
        );
        */

        document.documentElement.style.setProperty(
          "background-color",
          props.system && props.system === "sm" ? "#e3e3e3" : "inherit"
        );
      }
    },
  });

  useEffect(() => {
    if (!token) {
      Cookie.set("atles-geofleetos-token", null);
      window.location = "/login";
    }
  });

  // Recalculate row height on window resize
  useEffect(() => {
    actions.windowResize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize]);

  if (!user && data && data.user) {
    //console.log("setUser:", data.user);
    actions.setUser(data.user);
  }

  useEffect(() => {
    props.system && actions.setSystem(props.system);

    if (props.system && props.system === "sm") {
      if (data && data.user && data.user.smScreens) {
        actions.setScreens(
          data.user.smScreens.map((screen) => ({
            ...screen,
            fullscreenWidget: null,
            widgets: screen.widgets.map((widget) => ({
              ...widget,
              dataGrid: JSON.parse(widget.dataGrid),
            })),
          }))
        );
      }

      //document.documentElement.style.setProperty("background-color", "#CDCECF");
    } else {
      if (data && data.user && data.user.screens) {
        actions.setScreens(
          data.user.screens.map((screen) => ({
            ...screen,
            fullscreenWidget: null,
            widgets: screen.widgets.map((widget) => ({
              ...widget,
              dataGrid: JSON.parse(widget.dataGrid),
            })),
          }))
        );
      }
    }
  }, [data, props, actions]);

  return (
    <Container
      className="App"
      style={{
        backgroundColor: system && system === "sm" ? "#e3e3e3" : "transparent",
      }}
    >
      {checkingSession ? (
        <Container
          style={{
            width: "100%",
            //height: "100vh",
            alignContent: "center",
            alignItems: "center",
            paddingTop: "20%",
          }}
        >
          {/*
					<img src="/images/geofleetos_small.png" />
          <br />
					*/}
          <Loader content="Loading..." size="md" />
        </Container>
      ) : (
        <>
          <Header />
          <Content
            className="content"
            style={{
              //padding: "10px",
              padding: 0,
              backgroundColor:
                system && system === "sm" ? "#e3e3e3" : "transparent",
            }}
          >
            <Widgets />
          </Content>
        </>
      )}
    </Container>
  );
};

function useWindowSize() {
  const isClient = typeof window === "object";

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return windowSize;
}

export default App;
