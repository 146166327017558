import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import {
  Form,
  //FormGroup,
  Table,
  Panel,
  CheckPicker,
  SelectPicker,
  //Icon,
  InputGroup,
  Message,
  Button,
  ButtonGroup,
} from "rsuite";

import Icon from "../../components/Icon";
import GQL from "./graphql";

const { Column, HeaderCell, Cell } = Table;
const { Group: FormGroup } = Form;

export default function (props) {
  const [getDevices, { data: devicesData, called: devicesDataRequested }] =
    useLazyQuery(GQL.Queries.devices);
  const [getUsers, { data: usersData, called: usersDataReqeusted }] =
    useLazyQuery(GQL.Queries.users);
  const [listType, setListType] = useState(
    props.widget.config && props.widget.config.listType
      ? props.widget.config.listType
      : null
  );
  const [fields, setFields] = useState([]);
  const [selectedFields, setSelectedFields] = useState(
    props.widget.config && props.widget.config.selectedFields
      ? props.widget.config.selectedFields
      : null
  );

  const saveAndReload = () => {
    const config = JSON.stringify({ listType, selectedFields });
    props.updateWidget({
      variables: {
        id: props.widget.id,
        config,
      },
    });
    props.collapse && props.collapse();
  };

  useEffect(() => {
    setListTypeAndFields(listType);
  }, [listType]);

  const setListTypeAndFields = (listType) => {
    setListType(listType);

    switch (listType) {
      case "devices":
        setFields([
          "id",
          "name",
          "serial",
          "imei",
          "simnumber",
          "lastReported",
        ]);
        break;
      case "users":
        setFields(["id", "email", "firstname", "lastname"]);
        break;
      default:
        break;
    }
  };

  const getHeaderLabel = (field) => {
    switch (field) {
      case "id":
        return "ID";
      case "name":
        return "Name";
      case "serial":
        return "Serial";
      case "imei":
        return "IMEI";
      case "simnumber":
        return "Sim Number";
      case "lastReported":
        return "Last Reported";
      case "email":
        return "E-Mail";
      default:
        return field;
    }
  };
  const getHeaderWidth = (field) => {
    switch (field) {
      case "id":
        return 75;
      case "name":
      case "imei":
      case "serial":
      case "email":
        return 200;
      default:
        return 100;
    }
  };

  const renderContent = () => {
    let data = [];

    if (props.widget && props.widget.config && props.widget.config.listType) {
      const { listType } = props.widget.config;

      if (listType === "devices") {
        !devicesDataRequested && getDevices();
        if (devicesData && devicesData.devices !== null) {
          data = devicesData.devices;
        }
      } else if (listType === "users") {
        !usersDataReqeusted && getUsers();
        if (usersData && usersData.users !== null) {
          data = usersData.users;
        }
      }
    } else {
      console.error("Invalid config");
      return (
        <Message
          type="error"
          title="Invalid config."
          description="Please contact the administrator"
        />
      );
    }

    return (
      <div style={{ width: "100%" }}>
        <Table
          height={(props.baseHeight + 10) * props.h - 55}
          data={data}
          affixHeader={true}
        >
          {selectedFields.map((field) => (
            <Column resizable={true} width={getHeaderWidth(field)}>
              <HeaderCell>{getHeaderLabel(field)}</HeaderCell>
              <Cell dataKey={field} />
            </Column>
          ))}
        </Table>
      </div>
    );
  };

  return (
    <div>
      <Panel
        expanded={props.expanded || props.widget.config === null}
        collapsible
        bodyFill
        className="widget-config"
        style={{ borderRadius: 0 }}
      >
        <Form
          style={{ padding: "5px", paddingTop: "15px", paddingBottom: "15px" }}
        >
          <FormGroup>
            <InputGroup>
              <InputGroup.Addon>
                <Icon icon="faList" />
              </InputGroup.Addon>
              <SelectPicker
                value={listType}
                onChange={(listType) => {
                  setListTypeAndFields(listType);
                }}
                placeholder="Select a list type"
                searchable={false}
                cleanable={false}
                style={{ width: "100%" }}
                data={[
                  {
                    label: "Device List",
                    value: "devices",
                  },
                  {
                    label: "User List",
                    value: "users",
                  },
                ]}
              />
            </InputGroup>
            <InputGroup>
              <InputGroup.Addon>
                <Icon icon="faColumns" />
              </InputGroup.Addon>
              <CheckPicker
                value={selectedFields}
                onChange={setSelectedFields}
                placeholder="Select fields"
                style={{ width: "100%" }}
                data={fields.map((field) => ({ value: field, label: field }))}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <ButtonGroup>
              <Button
                appearance="primary"
                color="green"
                onClick={() => {
                  saveAndReload();
                }}
              >
                <span>
                  <Icon icon="faFloppyDisk" /> Save
                </span>
              </Button>
              <Button
                appearance="primary"
                color="orange"
                onClick={() => {
                  props.collapse && props.collapse();
                }}
              >
                <span>
                  <Icon icon="faBan" width={15} /> Cancel
                </span>
              </Button>
            </ButtonGroup>
          </FormGroup>
        </Form>
      </Panel>
      {props.widget.config === null ? (
        <Message type="info" title="Widget needs to be configured" />
      ) : (
        renderContent()
      )}
      {/*
      <Table
        height={(props.baseHeight + 10) * props.h - 55}
        data={data}
        onRowClick={data => {
          console.log(data);
        }}
      >
        <Column width={50} align="center" fixed>
          <HeaderCell>Id</HeaderCell>
          <Cell dataKey="id" />
        </Column>

        <Column width={150} fixed>
          <HeaderCell>Name</HeaderCell>
          <Cell dataKey="name" />
        </Column>

        <Column width={150}>
          <HeaderCell>IMEI</HeaderCell>
          <Cell dataKey="imei" />
        </Column>

        <Column width={150}>
          <HeaderCell>Last Reported</HeaderCell>
          <Cell dataKey="lastReported" />
        </Column>

        <Column width={120} fixed="right">
          <HeaderCell>Action</HeaderCell>

          <Cell>
            {rowData => {
              function handleAction() {
                alert(`id:${rowData.id}`);
              }
              return (
                <span>
                  <a onClick={handleAction}> Edit </a>
                </span>
              );
            }}
          </Cell>
        </Column>
      </Table>
          */}
    </div>
  );
}
