import React, { useState, useEffect } from "react";
import {
  Avatar,
  Form,
  Table,
  Panel,
  Button,
  //Icon,
  Grid,
  Row,
  Col,
  Loader,
} from "rsuite";

import Icon from "../../components/Icon";
import { useQuery } from "@apollo/react-hooks";

import GQL from "./graphql";
import { useApp } from "../../AppStore";

import Map from "./map";
import LocationMap from "./locationmap";
import Actions from "./actions";
import Details from "./details";

const { Column, HeaderCell, Cell } = Table;
let lang = null;

export default (props) => {
  const isMobile = window.innerWidth < 1024;
  /** Global State */
  const [{ user, selectedAccount: globalSelectedAccount, cols }] = useApp();

  /** Local State */
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedAlert, setSelectedAlert] = useState(false);

  /** GraphQL */
  const {
    data: alerts,
    loading: loadingAlerts,
    refetch: reloadAlerts,
  } = useQuery(GQL.Queries.alerts);

  /** Local Variables */
  const widgetWidth =
    (window.innerWidth / cols) * props.widget.dataGrid.w -
    20 * props.widget.dataGrid.w -
    20;

  useEffect(() => {
    /** Language File */
    lang =
      user && user.language
        ? require("../../languages/" + user.language + ".js").default
        : lang;
  }, [user]);

  useEffect(() => {
    setSelectedAccount(globalSelectedAccount);
  }, [globalSelectedAccount]);

  const renderTriggers = (rowData) => {
    const location =
      rowData.alertLocations && rowData.alertLocations.length > 0
        ? rowData.alertLocations[0].location
        : null;

    return (
      <div>
        {location ? (
          <div
            key={"alert-locations-count-" + rowData.id}
            style={{
              position: "relative",
              backgroundColor: "white",
              float: "left",
              marginRight: "10px",
            }}
          >
            <LocationMap
              rowData={location}
              coordinates={location.coordinates}
              width="45px"
              height="45px"
              className="small-shadow"
            />
            <Avatar
              size="xs"
              circle
              className="small-shadow"
              style={{
                backgroundColor: "#777",
                position: "absolute",
                top: "-10px",
                left: "-4px",
                zIndex: 99999,
                fontWeight: "bold",
              }}
            >
              {isMobile
                ? rowData.alertLocations.length +
                  (rowData.alertSpeeds.length || 0)
                : rowData.alertLocations.length}
            </Avatar>
          </div>
        ) : null}
        {!isMobile && rowData.alertSpeeds && rowData.alertSpeeds.length > 0 && (
          <Avatar
            key={"speed-alert-count-" + rowData.id}
            style={{
              backgroundColor: "white",
              position: "relative",
              fontWeight: "bold",
              color: "black",
              border: "1px solid black",
              margin: 0,
              padding: "2pt",
              width: "45px",
              height: "45px",
              float: "left",
              marginRight: "10px",
              display: "inline-block",
              //transform: "rotate(90deg)",
              //transformOrigin: "right top",
            }}
            className="small-shadow"
          >
            <div style={{ padding: "2px" }}>
              <div style={{ fontSize: "5px", padding: 0 }}>SPEED</div>
              <div style={{ fontSize: "5px", padding: 0 }}>TRIGGERS</div>
              <div style={{ padding: 0 }}>
                <small>{rowData.alertSpeeds.length}</small>
              </div>
            </div>
          </Avatar>
        )}
        {!isMobile && rowData.alertOffhour && (
          <Avatar
            key={"offhour-alert-" + rowData.id}
            style={{
              backgroundColor: "white",
              position: "relative",
              fontWeight: "bold",
              color: "#EE4D4D",
              border: "1px solid gray",
              margin: 0,
              padding: "2pt",
              width: "45px",
              height: "45px",
              float: "left",
              marginRight: "10px",
              display: "inline-block",
              //transform: "rotate(90deg)",
              //transformOrigin: "right top",
            }}
            className="small-shadow"
          >
            <Icon icon="faClock" style={{ color: "#45B0E5" }} />{" "}
            <Icon icon="faMapMarkerAlt" />
          </Avatar>
        )}
      </div>
    );
  };

  const renderContent = () => {
    return loadingAlerts ? (
      <Loader content={lang !== null ? lang.loading : "Loading..."} />
    ) : (
      <div
        style={{
          width: "100%",
          position: "relative",
        }}
      >
        <Grid
          fluid
          style={{
            fontSize: "1.1rem",
            fontWeight: "bold",
            width: "100%",
            textAlign: "left",
            padding: "15px",
            paddingBottom: "5px",
            borderBottom: "1px solid #eeeeee",
          }}
        >
          <Row>
            <Col xs={12} style={{ paddingTop: "7px" }}>
              <div>{lang !== null ? lang.alerts : "Alerts"}</div>

              <div style={{ fontSize: "0.75rem", color: "#aaa" }}>
                <div>
                  {selectedAccount ? (
                    <>
                      {
                        /* Logged in user Account */
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setSelectedAccount(null);
                          }}
                        >
                          {user.account.name}
                        </span>
                      }
                      {
                        /* Account breadcrumb */
                        selectedAccount.parentBreadcrumb &&
                        selectedAccount.parentBreadcrumb.length > 0
                          ? selectedAccount.parentBreadcrumb.map((account) => (
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setSelectedAccount(account);
                                }}
                              >
                                {" "}
                                <Icon icon="faArrowRight" /> {account.name}
                              </span>
                            ))
                          : null
                      }
                      {
                        /* Selected Account */
                        <span>
                          {" "}
                          <Icon icon="faArrowRight" />{" "}
                          {selectedAccount && selectedAccount.name
                            ? selectedAccount.name
                            : "Company"}
                        </span>
                      }
                    </>
                  ) : user && user.account && user.account.name ? (
                    user.account.name
                  ) : lang !== null ? (
                    lang.company
                  ) : (
                    "Company"
                  )}
                </div>
              </div>
            </Col>
            <Col xs={12} style={{ textAlign: "right" }}>
              <Button
                appearance="ghost"
                //className="small-shadow"
                onClick={() => {
                  setSelectedAlert(null);
                  setShowDetailsModal(!showDetailsModal);
                }}
              >
                {showDetailsModal ? (
                  <span>
                    <Icon icon="faBan" width={15} />{" "}
                    {lang !== null ? lang.cancel : "Cancel"}
                  </span>
                ) : (
                  <span>
                    <Icon icon="faSquarePlus" />{" "}
                    {lang !== null ? lang.newAlert : "New Alert"}
                  </span>
                )}
              </Button>
            </Col>
          </Row>
        </Grid>
        <Table
          virtualized={false}
          height={
            isMobile
              ? window.innerHeight - 230
              : (props.baseHeight + 10) * props.h - 125
          }
          data={alerts.alerts}
          affixHeader={true}
          rowHeight={75}
          style={{
            borderTop: "2px solid #fafafa",
            borderBottom: "1px dashed #fafafa",
            //filter: showDetailsModal && "blur(2px)",
            //pointerEvents: showDetailsModal && "none",
          }}
          loading={loadingAlerts}
        >
          {/*
          <Column width={Math.max(widgetWidth / 12, 100)}>
            <HeaderCell style={{ textAlign: "left" }}>
              {lang !== null ? lang.alert : "Alert"}
            </HeaderCell>
            <Cell>
              {(rowData) => {
                return (
                  <div onClick={() => {}}>
                    <Avatar circle className="small-shadow">
                      <Icon icon={rowData.alertType.icon} />
                    </Avatar>
                  </div>
                );
              }}
            </Cell>
          </Column>
            */}
          <Column
            //width={Math.max((widgetWidth / 12) * 2, isMobile ? 75 : 150)}
            flexGrow={2}
          >
            <HeaderCell style={{ textAlign: "left" }}>
              {lang !== null ? lang.triggers : "Triggers"}
            </HeaderCell>
            <Cell
              style={{
                width: "100%",
                height: "100%",
                overflowX: "auto",
                whiteSpace: "nowrap",
                //transform: "rotate(-90deg) translateY(-100%)",
                //transformOrigin: "right top",
              }}
            >
              {(rowData) => renderTriggers(rowData)}
            </Cell>
          </Column>
          <Column
            //width={Math.max((widgetWidth / 12) * 1, 75)}
            flexGrow={1}
          >
            <HeaderCell style={{ textAlign: "left" }}>
              {lang !== null ? lang.devices : "Devices"}
            </HeaderCell>
            <Cell>
              {
                (rowData) => {
                  const device =
                    rowData.devices && rowData.devices.length > 0
                      ? rowData.devices[0]
                      : null;
                  return (
                    <div
                      key={"devices-count-" + rowData.id}
                      style={{
                        marginRight: "10px",
                        float: "left",
                        position: "relative",
                      }}
                    >
                      <Map
                        rowData={device}
                        width="45px"
                        height="45px"
                        icon={device.icon}
                      />
                      <Avatar
                        size="xs"
                        circle
                        className="small-shadow"
                        style={{
                          backgroundColor: "#777",
                          position: "absolute",
                          top: "-10px",
                          left: "-4px",
                          zIndex: 99999,
                          fontWeight: "bold",
                        }}
                      >
                        {rowData.devices.length}
                      </Avatar>
                    </div>
                  );
                }
                /*
                rowData.devices && rowData.devices.length > 0 ? (
                  rowData.devices.map((device) => {
                    const borderStyle =
                      "6px solid " + (device.color ? device.color : "white");
                    return (
                      <div
                        key={"devices-div-" + device.id}
                        onClick={() => {
                          //setSelectedDevice(rowData);
                          //setShowDetailsModal(true);
                        }}
                        style={{
                          marginRight: "10px",
                          float: "left",
                          position: "relative",
                        }}
                      >
                        <Map
                          rowData={device}
                          width="45px"
                          height="45px"
                          icon={device.icon}
                          style={{
                            borderTop: borderStyle,
                          }}
                        />
                      </div>
                    );
                  })                  
                ) : (
                  <span>No Locations Assigned</span>
                )*/
              }
            </Cell>
          </Column>
          {widgetWidth > 400 && (
            <Column
              //width={Math.max((widgetWidth / 12) * 7, 100)}
              flexGrow={3}
            >
              <HeaderCell style={{ textAlign: "left" }}>
                {lang !== null ? lang.users : "Users"}
              </HeaderCell>
              <Cell>
                {(rowData) =>
                  rowData.users && rowData.users.length > 0 ? (
                    isMobile ? (
                      <div
                        key="users-div"
                        onClick={() => {}}
                        style={{
                          marginRight: "10px",
                          float: "left",
                          position: "relative",
                        }}
                      >
                        <Avatar
                          circle
                          className="small-shadow"
                          src="images/avatar0.png"
                          size="md"
                        />
                      </div>
                    ) : (
                      rowData.users.map((user) => (
                        <div
                          key={"users-div-" + user.id}
                          onClick={() => {}}
                          style={{
                            marginRight: "10px",
                            float: "left",
                            position: "relative",
                          }}
                        >
                          <Avatar
                            circle
                            className="small-shadow"
                            src={
                              user && user.avatar
                                ? user.avatar
                                : "images/avatar0.png"
                            }
                            size="md"
                          />
                        </div>
                      ))
                    )
                  ) : (
                    <span>No Locations Assigned</span>
                  )
                }
              </Cell>
            </Column>
          )}
          <Column
            //width={Math.max(widgetWidth / 6, 100)}
            flexGrow={2}
          >
            <HeaderCell>{lang !== null ? lang.actions : "Actions"}</HeaderCell>
            <Cell style={{ position: "relative" }}>
              {(rowData) => (
                <Actions
                  data={rowData}
                  setShowDetailsModal={setShowDetailsModal}
                  setSelectedAlert={setSelectedAlert}
                  refetch={reloadAlerts}
                />
              )}
            </Cell>
          </Column>
        </Table>
        {showDetailsModal && (
          <Details
            selectedAlert={selectedAlert}
            refetch={reloadAlerts}
            close={() => {
              setShowDetailsModal(false);
            }}
            widget={props.widget}
          />
        )}
      </div>
    );
  };

  return (
    <div>
      <Panel
        expanded={props.expanded}
        collapsible
        bodyFill
        className="widget-config"
        style={{ borderRadius: 0 }}
      >
        <Form
          style={{ padding: "5px", paddingTop: "15px", paddingBottom: "15px" }}
        ></Form>
      </Panel>
      {renderContent()}
    </div>
  );
};
