import React, { useState, useEffect, useRef } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import { Responsive, WidthProvider } from "react-grid-layout";
import Widget from "./widget";
import Map from "./map";
import Report from "./report";
import Statistics from "./statistics";
import { useApp } from "../AppStore";
//import { Icon } from "rsuite";

import Icon from "../components/Icon";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

let lang = null;

const ResponsiveGridLayout = WidthProvider(Responsive);

export default () => {
  const ref = useRef(null);
  const [
    {
      cols,
      rows,
      rowHeight,
      widgetCount,
      selectedScreen,
      screens,
      minW,
      minH,
      showOverlay,
      showScreenOverlay,
      system,
      fullscreenToggle,
      user,
    },
    actions,
  ] = useApp();
  const apolloClient = useApolloClient();

  //const colWidth = window.innerWidth / cols;
  const [currentScreen, setCurrentScreen] = useState(null);
  const [toolboxPosition, setToolboxPosition] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const onLayoutChange = (layout) => {
    if (
      currentScreen != null &&
      currentScreen.widgets &&
      currentScreen.fullscreenWidget === null
    ) {
      //console.log("Setting current screen layout:", { layout, currentScreen });

      setCurrentScreen({
        ...currentScreen,
        widgets: currentScreen.widgets.map((widget, index) => ({
          ...widget,
          dataGrid: {
            ...widget.dataGrid,
            ...layout[index],
          },
        })),
      });
    }
  };

  useEffect(() => {
    if (screens && screens.length > 0 && selectedScreen != null) {
      setCurrentScreen(screens[selectedScreen]);
      //setCurrentSlide(0);
      //console.log({ currentScreen: screens[selectedScreen] });
    }
  }, [screens, selectedScreen, widgetCount, fullscreenToggle]);

  useEffect(() => {
    /** Language File */
    lang =
      user && user.language
        ? require("../languages/" + user.language + ".js").default
        : lang;
  }, [user]);

  useEffect(() => {
    // TODO
    setToolboxPosition(null);
    //console.log(ref);
    /*
    document.addEventListener('mousemove', (e) => {      
      const left = e.pageX;
      const top = e.pageY;
      const gridX = parseInt(left / colWidth);
      const gridY = parseInt((top - 60) / (rowHeight + 10));

      setToolboxPosition({
        left: gridX * colWidth,
        top: gridY * (rowHeight + 10) + 70
      });
    });
    */
  }, []);

  /*
  if (currentScreen && currentScreen.fullscreenWidget) {
    return (
      <div>
        <div
          className={system && system === "sm" ? "sm-widget" : "widget"}
          key={currentScreen.fullscreenWidget.id}
          style={{ height: window.innerHeight - 80 }}
        >
          <Widget
            widget={currentScreen.fullscreenWidget}
            setCurrentScreen={setCurrentScreen}
            currentScreen={currentScreen}
            index={currentScreen.fullscreenWidget.dataGrid.i}
            fullscreen={true}
          />
        </div>
      </div>
    );
  }
  */

  if (screens && screens.length > 0) {
    if (window.innerWidth < 1024) {
      // MOBILE
      return (
        <div>
          <Swiper
            spaceBetween={5}
            slidesPerView={1.09}
            centeredSlides={true}
            onSlideChange={(s) => {
              console.log({ s });
              setCurrentSlide(s.activeIndex);
            }}
            onSwiper={(swiper) => console.log({ swiper })}
          >
            {currentScreen &&
              currentScreen.widgets &&
              currentScreen.widgets.length > 0 &&
              currentScreen.widgets.map((widget, index) => {
                return (
                  <SwiperSlide>
                    <div
                      className={
                        system && system === "sm" ? "sm-widget" : "widget"
                      }
                      key={widget.id}
                      style={{
                        height: parseInt(window.innerHeight - 120) + "px",
                        marginBottom: "10px",
                      }}
                    >
                      <Widget
                        widget={{
                          ...widget,
                          dataGrid: {
                            ...widget.dataGrid,
                            w: 1,
                            h: 1,
                          },
                        }}
                        index={index}
                      />
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
          <div>
            {currentScreen &&
              currentScreen.widgets &&
              currentScreen.widgets.length > 0 &&
              currentScreen.widgets.map((widget, index) => {
                return (
                  <Icon
                    icon={widget.icon}
                    style={{
                      padding: 2,
                      color:
                        currentSlide === index
                          ? user.account.primaryColor
                          : "lightgray",
                    }}
                  />
                );
              })}
          </div>
        </div>
      );
      /*
      return (
        <div>
          {currentScreen && currentScreen.fullscreenWidget != null ? (
            <div
              className={system && system === "sm" ? "sm-widget" : "widget"}
              key={currentScreen.fullscreenWidget.id}
              data-grid={{
                ...currentScreen.fullscreenWidget.dataGrid,
                x: 0,
                y: 0,
                w: cols,
                h: rows,
                static: true,
                isDraggable: false,
                isResizable: false,
              }}
            >
              <Widget
                widget={currentScreen.fullscreenWidget}
                index={currentScreen.fullscreenWidget.dataGrid.i}
                fullscreen={true}
                setCurrentScreen={setCurrentScreen}
                currentScreen={currentScreen}
              />
            </div>
          ) : (
            currentScreen &&
            currentScreen.widgets &&
            currentScreen.widgets.length > 0 &&
            currentScreen.widgets.map((widget, index) => {
              return (
                <div
                  className={system && system === "sm" ? "sm-widget" : "widget"}
                  key={widget.id}
                  style={{
                    height: parseInt((window.innerHeight - 88) / 2) + "px",
                    marginBottom: "10px",
                  }}
                >
                  <Widget
                    widget={{
                      ...widget,
                      dataGrid: {
                        ...widget.dataGrid,
                        w: 1,
                        h: 1,
                      },
                    }}
                    index={index}
                  />
                </div>
              );
            })
          )}
        </div>
      );*/
    } else {
      // DESKTOP
      if (
        currentScreen &&
        ((currentScreen.widgets && currentScreen.widgets.length > 0) ||
          currentScreen.fullscreenWidget != null)
      ) {
        // HAS WIDGETS OR FULLSCREEN WIDGET

        return (
          <>
            <ResponsiveGridLayout
              ref={ref}
              preventCollision={true}
              verticalCompact={false}
              breakpoints={{ lg: 1920, md: 1024, sm: 800, xs: 480, xxs: 320 }}
              cols={{ lg: cols, md: cols, sm: cols, xs: cols, xxs: cols }}
              rowHeight={rowHeight}
              onLayoutChange={(layout, allLayouts) => {
                if (layout) {
                  setTimeout(() => {
                    // Only save if not going into fullscreen
                    if (currentScreen.fullscreenWidget === null) {
                      //console.log("Saving layout change");
                      actions.handleLayoutChange(layout, apolloClient);
                      onLayoutChange(layout);
                    }
                  }, 0);
                }
              }}
              draggableHandle=".drag-me"
              style={{
                backgroundColor: "transparent",
                //padding: 0,
                //border: "1px red solid",
              }}
              measureBeforeMount={true}
              //isDroppable={true}
              //onDrop={onDrop}
            >
              {currentScreen && currentScreen.fullscreenWidget != null ? (
                <div
                  className={system && system === "sm" ? "sm-widget" : "widget"}
                  key={currentScreen.fullscreenWidget.id * 1000}
                  data-grid={{
                    ...currentScreen.fullscreenWidget.dataGrid,
                    minW: cols,
                    minH: rows,
                    w: cols,
                    h: rows,
                    x: 0,
                    y: 0,
                  }}
                >
                  <Widget
                    widget={currentScreen.fullscreenWidget}
                    setCurrentScreen={setCurrentScreen}
                    currentScreen={currentScreen}
                    index={currentScreen.fullscreenWidget.dataGrid.i}
                    fullscreen={true}
                  />
                </div>
              ) : (
                currentScreen &&
                currentScreen.widgets &&
                currentScreen.widgets.length > 0 &&
                currentScreen.widgets.map((widget, index) => {
                  return (
                    <div
                      className={
                        system && system === "sm" ? "sm-widget" : "widget"
                      }
                      key={widget.id}
                      data-grid={{
                        ...widget.dataGrid,
                        minW,
                        minH,
                        w: Math.max(
                          minW,
                          widget.dataGrid && widget.dataGrid.w
                            ? widget.dataGrid.w
                            : 1
                        ),
                        h: Math.max(
                          minH,
                          widget.dataGrid && widget.dataGrid.h
                            ? widget.dataGrid.h
                            : 1
                        ),
                      }}
                    >
                      <Widget
                        widget={widget}
                        index={index}
                        setCurrentScreen={setCurrentScreen}
                        currentScreen={currentScreen}
                        disableConfig={[5, 6, 7, 8, 11].includes(
                          widget.widgetTypeId
                        )}
                      />
                    </div>
                  );
                })
              )}
            </ResponsiveGridLayout>
            <div
              style={{
                //backfaceVisibility: "hidden",
                display:
                  toolboxPosition &&
                  toolboxPosition.left != null &&
                  toolboxPosition.top != null
                    ? "block"
                    : "none",
                position: "absolute",
                top:
                  toolboxPosition && toolboxPosition.top != null
                    ? toolboxPosition.top
                    : 0,
                left:
                  toolboxPosition && toolboxPosition.left != null
                    ? toolboxPosition.left
                    : 0,
                border: "1px dashed gray",
                borderRadius: "5px",
                padding: "5px",
                width: "calc(100vw / " + cols + ")",
                height: rowHeight + "px",
                margin: "5px",
                textAlign: "center",
                //backgroundColor: "white",
              }}
            >
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  margin: 0,
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              >
                ADD WIDGET HERE
              </div>
            </div>
          </>
        );
      } else {
        // SHOW WIDGET OVERLAY
        if (showOverlay) {
          return (
            <div
              className="wizard-overlay"
              onClick={() => {
                actions.setShowOverlay(false);
              }}
            >
              <div className="widgets-wizard">
                <big>
                  {lang !== null
                    ? lang.addWidgetsHerePlaceholder
                    : "Add widgets here"}
                </big>
              </div>
            </div>
          );
        } else {
          return <div />;
        }
      }
    }
  } else {
    // SHOW SCREENS OVERLAY
    if (showScreenOverlay) {
      return (
        <div
          className="wizard-overlay"
          onClick={() => {
            actions.setShowScreenOverlay(false);
          }}
        >
          <div className="screen-wizard">
            <big>
              {lang !== null
                ? lang.addScreenHerePlaceholder
                : "Add screen here"}
            </big>
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }

  /*
  return currentScreen ? (
    window.innerWidth <= 1024 ? (
      <div>
        {currentScreen && currentScreen.fullscreenWidget != null ? (
          <div
            className={system && system === "sm" ? "sm-widget" : "widget"}
            key={currentScreen.fullscreenWidget.id}
            data-grid={{
              ...currentScreen.fullscreenWidget.dataGrid,
              x: 0,
              y: 0,
              w: cols,
              h: rows,
              static: true,
              isDraggable: false,
              isResizable: false,
            }}
          >
            <Widget
              widget={currentScreen.fullscreenWidget}
              index={currentScreen.fullscreenWidget.dataGrid.i}
              fullscreen={true}
              setCurrentScreen={setCurrentScreen}
              currentScreen={currentScreen}
            />
          </div>
        ) : (
          currentScreen &&
          currentScreen.widgets &&
          currentScreen.widgets.length > 0 &&
          currentScreen.widgets.map((widget, index) => {
            return (
              <div
                className={system && system === "sm" ? "sm-widget" : "widget"}
                key={widget.id}
                style={{
                  height: parseInt((window.innerHeight - 88) / 2) + "px",
                  marginBottom: "10px",
                }}
              >
                <Widget
                  widget={{
                    ...widget,
                    dataGrid: {
                      ...widget.dataGrid,
                      w: 1,
                      h: 1,
                    },
                  }}
                  index={index}
                />
              </div>
            );
          })
        )}
      </div>
    ) : (currentScreen &&
        currentScreen.widgets &&
        currentScreen.widgets.length > 0) ||
      currentScreen.fullscreenWidget != null ? (
      <ResponsiveGridLayout
        preventCollision={true}
        //verticalCompact={false}
        breakpoints={{ lg: 1920, md: 1024, sm: 800, xs: 480, xxs: 320 }}
        cols={{ lg: cols, md: cols, sm: cols, xs: cols, xxs: cols }}
        rowHeight={rowHeight}
        onLayoutChange={(layout, allLayouts) => {
          if (layout) {
            setTimeout(() => {
              actions.handleLayoutChange(layout, apolloClient);
              onLayoutChange(layout);
            }, 0);
          }
        }}
        draggableHandle=".drag-me"
        style={{
          backgroundColor: "transparent",
        }}
        measureBeforeMount={true}
        //isDroppable={true}
        //onDrop={onDrop}
      >
        {currentScreen && currentScreen.fullscreenWidget != null ? (
          <div
            className={system && system === "sm" ? "sm-widget" : "widget"}
            key={currentScreen.fullscreenWidget.id}
            data-grid={{
              //...currentScreen.fullscreenWidget.dataGrid,
              //x: 0,
              //y: 0,
              //w: cols,
              //h: rows,
              //minW: cols,
              //minH: rows,
              static: true,
              //isDraggable: false,
              //isResizable: false,
            }}
          >
            <Widget
              widget={currentScreen.fullscreenWidget}
              setCurrentScreen={setCurrentScreen}
              currentScreen={currentScreen}
              index={currentScreen.fullscreenWidget.dataGrid.i}
              fullscreen={true}
            />
          </div>
        ) : (
          currentScreen &&
          currentScreen.widgets &&
          currentScreen.widgets.length > 0 &&
          currentScreen.widgets.map((widget, index) => {
            return (
              <div
                className={system && system === "sm" ? "sm-widget" : "widget"}
                key={widget.id}
                data-grid={{
                  ...widget.dataGrid,
                  minW,
                  minH,
                  w: Math.max(
                    minW,
                    widget.dataGrid && widget.dataGrid.w ? widget.dataGrid.w : 1
                  ),
                  h: Math.max(
                    minH,
                    widget.dataGrid && widget.dataGrid.h ? widget.dataGrid.h : 1
                  ),
                }}
              >
                <Widget
                  widget={widget}
                  index={index}
                  setCurrentScreen={setCurrentScreen}
                  currentScreen={currentScreen}
                />
              </div>
            );
          })
        )}
      </ResponsiveGridLayout>
    ) : (
      showOverlay && (
        <div
          className="wizard-overlay"
          onClick={() => {
            actions.setShowOverlay(false);
          }}
        >
          <div className="widgets-wizard">
            <big>
              {lang !== null
                ? lang.addWidgetsHerePlaceholder
                : "Add widgets here"}
            </big>
          </div>
        </div>
      )
    )
  ) : screens && screens.length > 0 ? (
    <Loader content={lang !== null ? lang.loading : "Loading..."} size="lg" />
  ) : (
    showScreenOverlay && (
      <div
        className="wizard-overlay"
        onClick={() => {
          actions.setShowScreenOverlay(false);
        }}
      >
        <div className="screen-wizard">
          <big>
            {lang !== null ? lang.addScreenHerePlaceholder : "Add screen here"}
          </big>
        </div>
      </div>
    )
  );*/
};

export { Map, Report, Statistics };
