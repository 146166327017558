import gql from "graphql-tag";

export default {
  Queries: {
    adminAccess: gql`
      query hasAccessToSection {
        hasAccessToSection(section: "Account Profile", access: "adminAccess")
      }
    `,
    deleteAccess: gql`
      query hasAccessToSection {
        hasAccessToSection(section: "Account Profile", access: "deleteAccess")
      }
    `,
    writeAccess: gql`
      query hasAccessToSection {
        hasAccessToSection(section: "Account Profile", access: "writeAccess")
      }
    `,
    readAccess: gql`
      query hasAccessToSection {
        hasAccessToSection(section: "Account Profile", access: "readAccess")
      }
    `,
    widgetTypes: gql`
      query widgetType($sections: [String!]) {
        widgetTypes(active: true, sections: $sections) {
          id
          name
          section
          icon
        }
      }
    `,
    sessionByToken: gql`
      query sessionByToken($token: String!) {
        sessionByToken(token: $token) {
          id
          userId
          impersonatorSessionId
          impersonatorSession {
            id
            token
            user {
              id
              email
              firstname
              lastname
            }
          }
        }
      }
    `,
    account: gql`
      query account($id: Int!) {
        account(id: $id) {
          id
          name
          logo
          primaryColor
          secondaryColor
          mapping
          mapSystemId
          mapSystem {
            name
          }
          mapSystemApiKey
          geocode
          geocoderApiKey
          geocoder {
            name
          }
          templateScreens {
            id
            title
            icon
            widgets {
              id
              config
              dataGrid
              widgetType {
                id
                name
                icon
              }
            }
          }
        }
      }
    `,
  },
  Mutations: {
    updateAccount: gql`
      mutation updateAccount(
        $id: Int!
        $name: String
        $primaryColor: String
        $secondaryColor: String
        $mapSystemApiKey: String
        $geocoderApiKey: String
        $logo: String
      ) {
        updateAccount(
          id: $id
          name: $name
          primaryColor: $primaryColor
          secondaryColor: $secondaryColor
          mapSystemApiKey: $mapSystemApiKey
          geocoderApiKey: $geocoderApiKey
          logo: $logo
        )
      }
    `,
    changePassword: gql`
      mutation updateUser($id: Int!, $password: String!) {
        updateUser(id: $id, password: $password)
      }
    `,
    updateUser: gql`
      mutation updateUser(
        $id: Int!
        $email: String
        $firstname: String
        $lastname: String
        $avatar: String
        $language: String
      ) {
        updateUser(
          id: $id
          email: $email
          firstname: $firstname
          lastname: $lastname
          avatar: $avatar
          language: $language
        )
      }
    `,
    createWidget: gql`
      mutation createWidget(
        $widgetTypeId: Int!
        $screenId: Int!
        $title: String
        $dataGrid: String
        $config: String
      ) {
        createWidget(
          widgetTypeId: $widgetTypeId
          screenId: $screenId
          title: $title
          dataGrid: $dataGrid
          config: $config
        ) {
          id
          dataGrid
          widgetType {
            id
            icon
            name
          }
        }
      }
    `,
    deleteWidget: gql`
      mutation deleteWidget($id: Int!) {
        deleteWidget(id: $id)
      }
    `,
    updateWidgetDataGrid: gql`
      mutation updateWidget($id: Int!, $dataGrid: String!) {
        updateWidget(id: $id, dataGrid: $dataGrid)
      }
    `,
    updateScreen: gql`
      mutation updateScreen($id: Int!, $title: String, $icon: String) {
        updateScreen(id: $id, title: $title, icon: $icon)
      }
    `,
    deleteScreen: gql`
      mutation deleteScreen($id: Int!) {
        deleteScreen(id: $id)
      }
    `,
    getPresignedUploadUrl: gql`
      mutation getPresignedUploadUrl($filename: String!, $filetype: String!) {
        getPresignedUploadUrl(filename: $filename, filetype: $filetype)
      }
    `,
    createTemplateScreen: gql`
      mutation createTemplateScreen(
        $title: String!
        $icon: String
        $order: Int
      ) {
        createTemplateScreen(title: $title, icon: $icon, order: $order) {
          id
        }
      }
    `,
  },
};
