import React, { useState, useEffect } from "react";
import {
  ButtonGroup,
  Dropdown,
  Form,
  Table,
  Panel,
  Button,
  //Icon,
  IconButton,
  Grid,
  Row,
  Col,
  Loader,
  Progress,
  Whisper,
  Popover,
  Checkbox,
} from "rsuite";

import Icon from "../../components/Icon";
import moment from "moment";
import { useQuery, useMutation } from "@apollo/react-hooks";

import GQL from "./graphql";
import { useApp } from "../../AppStore";

import Details from "./details";
import Actions from "./actions";
import Map from "./map";
import DeviceColorPicker from "../../components/devicecolorpicker";
import LocationPicker from "../../components/locationpicker";
import TransferPopup from "./transferpopup";
import BulkImport from "./bulkimport";
import BulkAction from "./bulkaction";
import AccessDenied from "../../components/accessdenied";

const { Column, HeaderCell, Cell } = Table;
let lang = null;

export default (props) => {
  const isMobile = window.innerWidth < 1024;
  const [showBulkImportModal, setShowBulkImportModal] = useState(false);
  const [showBulkActionModal, setShowBulkActionModal] = useState(false);
  const [bulkActionType, setBulkActionType] = useState(null);
  const [showBulkTransferSelect, setShowBulkTransferSelect] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [
    { user, system, selectedAccount: globalSelectedAccount, cols },
    actions,
  ] = useApp();
  const [selectedAccount, setSelectedAccount] = useState(globalSelectedAccount);
  const [color, setColor] = useState("");
  const [useLocationFilter, setUseLocationFilter] = useState(false);
  const [filterLocations, setFilterLocations] = useState(true);
  const [filteredLocationIds, setFilteredLocationIds] = useState([]);
  const { data: readAccess, loading: loadingReadAccess } = useQuery(
    GQL.Queries.readAccess
  );
  const { data: writeAccess, loading: loadingWriteAccess } = useQuery(
    GQL.Queries.writeAccess
  );
  const { data: deleteAccess, loading: loadingDeleteAccess } = useQuery(
    GQL.Queries.deleteAccess
  );
  const { data: adminAccess, loading: loadingAdminAccess } = useQuery(
    GQL.Queries.adminAccess
  );
  const {
    data: devices,
    loading: loadingDevices,
    refetch: reloadDevices,
  } = useQuery(GQL.Queries.devices, {
    variables: {
      ...(selectedAccount ? { accountId: selectedAccount.id } : {}),
    },
    fetchPolicy: "network-only",
  });
  const [updateDevice] = useMutation(GQL.Mutations.updateDevice);
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [checkedDevices, setCheckedDevices] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const widgetWidth =
    (window.innerWidth / cols) * props.widget.dataGrid.w -
    10 * props.widget.dataGrid.w;

  // Filter devices list every time devices changes
  useEffect(() => {
    // Filter by color is selected
    if (devices && devices.devices && !loadingDevices) {
      // Reset checked devices
      setCheckedDevices([]);

      // Filter by selected color first
      let filteredDevices = color
        ? devices.devices.filter((device) => device.color === color)
        : devices.devices;

      // Filter by filter locations if enabled
      if (useLocationFilter) {
        filteredDevices = filteredDevices.filter((device) => {
          const lastEvent = JSON.parse(device.lastEvent);
          if (filterLocations) {
            // Filter by devices currently inside selected locations
            return (
              filteredLocationIds.filter((id) =>
                lastEvent.locationIds.includes(id)
              ).length > 0
            );
          } else {
            // Filter by devices currently outside selected locations
            return (
              filteredLocationIds.filter(
                (id) => !lastEvent.locationIds.includes(id)
              ).length > 0
            );
          }
        });
      }

      // Set new filtered devices list
      setFilteredDevices(filteredDevices);
    }
  }, [
    devices,
    loadingDevices,
    color,
    filteredLocationIds,
    filterLocations,
    useLocationFilter,
  ]);

  useEffect(() => {
    // Reselect filtered devices if checkedAll is true
    checkedAll === true && setCheckedDevices(filteredDevices.map((d) => d.id));
  }, [filteredDevices, checkedAll]);

  useEffect(() => {
    /** Language File */
    lang =
      user && user.language
        ? require("../../languages/" + user.language + ".js").default
        : lang;
  }, [user]);

  useEffect(() => {
    setSelectedAccount(globalSelectedAccount);
  }, [globalSelectedAccount]);

  const renderContent = () => {
    return loadingDevices ||
      loadingReadAccess ||
      loadingWriteAccess ||
      loadingDeleteAccess ||
      loadingAdminAccess ? (
      <Loader content={lang !== null ? lang.loading : "Loading..."} />
    ) : readAccess.hasAccessToSection === true ? (
      <div
        style={{
          width: "100%",
          position: "relative",
        }}
      >
        <Grid
          fluid
          style={{
            fontSize: "1.1rem",
            fontWeight: "bold",
            width: "100%",
            textAlign: "left",
            padding: "15px",
            paddingBottom: "5px",
            borderBottom: "1px solid #eeeeee",
          }}
        >
          <Row>
            <Col xs={6} style={{ paddingTop: "7px" }}>
              <div>{lang !== null ? lang.devices : "Devices"}</div>

              <div style={{ fontSize: "0.75rem", color: "#aaa" }}>
                <div>
                  {filteredDevices ? filteredDevices.length : 0}/
                  {devices && devices.devices ? devices.devices.length : 0}{" "}
                  {selectedAccount ? (
                    <>
                      {
                        /* Logged in user Account */
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setSelectedAccount(null);
                          }}
                        >
                          {user.account.name}
                        </span>
                      }
                      {
                        /* Account breadcrumb */
                        selectedAccount.parentBreadcrumb &&
                        selectedAccount.parentBreadcrumb.length > 0
                          ? selectedAccount.parentBreadcrumb.map((account) => (
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setSelectedAccount(account);
                                }}
                              >
                                {" "}
                                <Icon icon="faArrowRight" /> {account.name}
                              </span>
                            ))
                          : null
                      }
                      {
                        /* Selected Account */
                        <span>
                          {" "}
                          <Icon icon="faArrowRight" />{" "}
                          {selectedAccount && selectedAccount.name
                            ? selectedAccount.name
                            : "Company"}
                        </span>
                      }
                    </>
                  ) : user && user.account && user.account.name ? (
                    user.account.name
                  ) : lang !== null ? (
                    lang.company
                  ) : (
                    "Company"
                  )}
                </div>
              </div>
            </Col>
            <Col xs={18} style={{ textAlign: "right" }}>
              {system && system === "sm" ? (
                <>
                  {checkedDevices.length > 0 && (
                    <ButtonGroup>
                      <Button
                        appearance="ghost"
                        //className="small-shadow"
                        onClick={() => {
                          //setShowBulkTransferSelect(true);
                          setBulkActionType("transfer");
                          setShowBulkActionModal(true);
                        }}
                        disabled={adminAccess.hasAccessToSection !== true}
                      >
                        <Icon icon="faArrowRightArrowLeft" /> Bulk Transfer
                      </Button>
                      <Dropdown
                        placement="bottomEnd"
                        renderTitle={() => (
                          <IconButton
                            className="small-shadow"
                            icon={<Icon icon="faChevronDown" />}
                          />
                        )}
                      >
                        <Dropdown.Item
                          onClick={() => {
                            setBulkActionType("ota");
                            setShowBulkActionModal(true);
                          }}
                          disabled={adminAccess.hasAccessToSection !== true}
                        >
                          <Icon icon="faVolumeHigh" /> Bulk OTA
                        </Dropdown.Item>
                        <Dropdown.Item divider />
                        <Dropdown.Item
                          onClick={() => {
                            if (deleteAccess.hasAccessToSection === true) {
                              setBulkActionType("delete");
                              setShowBulkActionModal(true);
                            }
                          }}
                          disabled={deleteAccess.hasAccessToSection !== true}
                        >
                          <Icon icon="faTrash" /> Bulk Delete
                        </Dropdown.Item>
                      </Dropdown>
                    </ButtonGroup>
                  )}{" "}
                  {showBulkTransferSelect ? (
                    <div
                      style={{
                        position: "absolute",
                        width: "200px",
                        left: "-100px",
                        top: 0,
                      }}
                    >
                      <TransferPopup
                        selectedAccount={selectedAccount}
                        data={{
                          accountId:
                            selectedAccount && selectedAccount.id
                              ? selectedAccount.id
                              : user.accountId,
                        }}
                        setShowTransferPopup={setShowBulkTransferSelect}
                        onChange={(accountId) => {
                          let bulkMove = [];
                          checkedDevices.forEach((id) =>
                            bulkMove.push(
                              updateDevice({
                                variables: {
                                  id,
                                  accountId,
                                },
                              })
                            )
                          );

                          Promise.all(bulkMove).then((res) => {
                            setShowBulkTransferSelect(false);
                            reloadDevices();
                            setCheckedDevices([]);
                            setCheckedAll(false);
                          });
                        }}
                        refetch={reloadDevices}
                      />
                    </div>
                  ) : null}
                  <Button
                    appearance="ghost"
                    //className="small-shadow"
                    onClick={() => {
                      setShowBulkImportModal(true);
                    }}
                    disabled={adminAccess.hasAccessToSection !== true}
                  >
                    <Icon icon="faFileUpload" /> Bulk Import
                  </Button>{" "}
                  <Button
                    appearance="ghost"
                    //className="small-shadow"
                    onClick={() => {
                      setSelectedDevice(null);
                      setShowDetailsModal(true);
                    }}
                    disabled={writeAccess.hasAccessToSection !== true}
                  >
                    <Icon icon="faSquarePlus" /> New Device
                  </Button>{" "}
                </>
              ) : null}
              <LocationPicker
                useLocationFilter={useLocationFilter}
                setUseLocationFilter={setUseLocationFilter}
                filterLocations={filterLocations}
                setFilterLocations={setFilterLocations}
                filteredLocationIds={filteredLocationIds}
                setFilteredLocationIds={setFilteredLocationIds}
              />{" "}
              <Whisper
                placement="bottomEnd"
                trigger="click"
                speaker={
                  <Popover>
                    <DeviceColorPicker
                      onClick={(color) => setColor(color)}
                      color={color}
                    />
                  </Popover>
                }
              >
                <Button appearance="ghost">
                  {color ? (
                    <Icon icon="faStar" style={{ color }} />
                  ) : (
                    <Icon icon="faFilter" />
                  )}
                </Button>
              </Whisper>
            </Col>
          </Row>
        </Grid>
        <Table
          virtualized={true}
          height={
            isMobile
              ? window.innerHeight - 230
              : (props.baseHeight + 10) * props.h - 125
          }
          data={filteredDevices}
          affixHeader={true}
          rowHeight={75}
          style={{
            borderTop: "2px solid #fafafa",
            borderBottom: "1px dashed #fafafa",
            filter: showDetailsModal && "blur(2px)",
            pointerEvents: showDetailsModal && "none",
          }}
          loading={loadingDevices}
          onRowClick={(rowData) => {
            //setSelectedUser(rowData);
            //setShowDetailsModal(true);
          }}
        >
          {system && system === "sm" && (
            <Column
              //width={Math.max(widgetWidth / 12, 50)}
              flexGrow={1}
            >
              <HeaderCell style={{ position: "relative" }}>
                <Checkbox
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                  checked={checkedAll}
                  onChange={(value, checked) => {
                    if (checked) {
                      setCheckedDevices(devices.devices.map((d) => d.id));
                      setCheckedAll(true);
                    } else {
                      setCheckedDevices([]);
                      setCheckedAll(false);
                    }
                  }}
                  disabled={
                    adminAccess.hasAccessToSection !== true &&
                    deleteAccess.hasAccessToSection !== true
                  }
                />
              </HeaderCell>
              <Cell style={{ position: "relative" }}>
                {(rowData, index) => (
                  <Checkbox
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                    checked={checkedDevices.includes(rowData.id)}
                    onChange={(value, checked) => {
                      if (checked) {
                        setCheckedDevices([...checkedDevices, rowData.id]);
                      } else {
                        setCheckedDevices(
                          checkedDevices.filter((id) => id !== rowData.id)
                        );
                      }
                    }}
                    disabled={
                      adminAccess.hasAccessToSection !== true &&
                      deleteAccess.hasAccessToSection !== true
                    }
                    key={"device-checkbox-" + index}
                  />
                )}
              </Cell>
            </Column>
          )}
          <Column
            //width={Math.max((widgetWidth / 12) * (isMobile ? 8 : 4), 240)}
            flexGrow={3}
          >
            <HeaderCell style={{ textAlign: "left" }}>
              {lang !== null ? lang.device : "Device"}
            </HeaderCell>
            <Cell>
              {(rowData, index) => {
                const lastEvent = rowData.lastEvent
                  ? JSON.parse(rowData.lastEvent)
                  : null;
                const address =
                  lastEvent &&
                  lastEvent.event_data &&
                  lastEvent.event_data.address
                    ? lastEvent.event_data.address.split(",")
                    : null;
                const borderStyle =
                  "6px solid " + (rowData.color ? rowData.color : "white");

                return (
                  <Grid
                    style={{
                      cursor: "pointer",
                    }}
                    fluid
                    onClick={() => {
                      //setSelectedDevice(rowData);
                      //setShowDetailsModal(true);
                      actions.setSelectedLocation(null);
                      actions.setSelectedDevice(rowData);
                    }}
                  >
                    <Row>
                      {widgetWidth > 700 && (
                        <Col xs={6}>
                          <div
                            onClick={() => {
                              //setSelectedDevice(rowData);
                              //setShowDetailsModal(true);
                            }}
                          >
                            <Map
                              rowData={rowData}
                              width="45px"
                              height="45px"
                              icon={rowData.icon}
                              style={{
                                borderTop: borderStyle,
                              }}
                            />
                          </div>
                        </Col>
                      )}
                      <Col xs={18} style={{ textAlign: "left", fontSize: 12 }}>
                        <div>
                          <big>{rowData.name}</big>
                        </div>
                        <div>
                          <small>
                            {address && address.length > 1 ? (
                              <>
                                <div>{address[0]}</div>
                                <div>{address.slice(1).join(", ")}</div>
                              </>
                            ) : lang !== null ? (
                              lang.nA
                            ) : (
                              "N/A"
                            )}
                          </small>
                        </div>
                      </Col>
                    </Row>
                  </Grid>
                );
              }}
            </Cell>
          </Column>
          {!isMobile && widgetWidth > 500 && (
            <Column
              /*
              width={Math.max(
                (widgetWidth / 12) * (system === "sm" ? 5 : 6),
                300
              )}
							*/
              flexGrow={3}
            >
              <HeaderCell style={{ textAlign: "left" }}>
                {lang !== null ? lang.details : "Details"}
              </HeaderCell>
              <Cell>
                {(rowData) => {
                  const lastEvent = rowData.lastEvent
                    ? JSON.parse(rowData.lastEvent)
                    : null;
                  let eventString = "";
                  if (lastEvent) {
                    const secondsAgo = Math.abs(
                      moment
                        .unix(lastEvent.event_data.device_time)
                        .diff(moment(), "seconds")
                    );

                    const timeString =
                      secondsAgo < 60
                        ? secondsAgo +
                          " " +
                          (lang !== null ? lang.secondsAgo : "Second(s) Ago")
                        : secondsAgo < 60 * 60
                        ? parseInt(secondsAgo / 60) +
                          " " +
                          (lang !== null ? lang.minutesAgo : "Minute(s) Ago")
                        : secondsAgo < 60 * 60 * 24
                        ? parseInt(secondsAgo / 60 / 60) +
                          " " +
                          (lang !== null ? lang.hoursAgo : "Hour(s) Ago")
                        : parseInt(secondsAgo / 60 / 60 / 24) +
                          " " +
                          (lang !== null ? lang.daysAgo : "Day(s) Ago");

                    eventString = (
                      <small>
                        <span
                          style={{
                            color:
                              "#" + lastEvent.event_data.conduit_color_code,
                          }}
                        >
                          {lastEvent.event_data.conduit_event_description}
                        </span>{" "}
                        {/*timeString*/}
                        {moment
                          .unix(lastEvent.event_data.device_time)
                          .fromNow(true) +
                          " " +
                          (lang !== null ? lang.ago : "Ago")}
                      </small>
                    );
                  } else {
                    eventString = lang !== null ? lang.nA : "N/A";
                  }

                  return (
                    <Grid fluid>
                      <Row>
                        <Col xs={12} style={{ textAlign: "left" }}>
                          {eventString}
                        </Col>
                        <Col xs={12} style={{ textAlign: "right" }}>
                          <small>
                            {rowData.deviceType &&
                            rowData.deviceType.name &&
                            lastEvent &&
                            lastEvent.event_data &&
                            lastEvent.event_data.battery_percentage &&
                            lastEvent.event_data.battery_percentage >= 0
                              ? rowData.deviceType.name +
                                " " +
                                (lang !== null
                                  ? lang.deviceBattery
                                  : "Device Battery")
                              : rowData.deviceType &&
                                rowData.deviceType.name &&
                                lastEvent &&
                                lastEvent.event_data &&
                                lastEvent.event_data.battery_voltage &&
                                lastEvent.event_data.battery_voltage >= 0
                              ? rowData.deviceType.name +
                                " " +
                                (lang !== null
                                  ? lang.deviceVoltage
                                  : "Device Voltage")
                              : ""}
                          </small>
                        </Col>
                      </Row>
                      {lastEvent &&
                      lastEvent.event_data &&
                      lastEvent.event_data.battery_percentage &&
                      lastEvent.event_data.battery_percentage >= 0 ? (
                        <Row>
                          <Col xs={24}>
                            <Progress.Line
                              strokeColor="#7DB545"
                              percent={parseInt(
                                parseInt(
                                  lastEvent.event_data.battery_percentage
                                )
                              )}
                            />
                          </Col>
                        </Row>
                      ) : lastEvent &&
                        lastEvent.event_data &&
                        lastEvent.event_data.battery_voltage &&
                        lastEvent.event_data.battery_voltage >= 0 ? (
                        <Row>
                          <Col xs={24} style={{ textAlign: "right" }}>
                            <big>{lastEvent.event_data.battery_voltage}</big>
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}
                    </Grid>
                  );
                }}
              </Cell>
            </Column>
          )}
          <Column
            //</Table>width={Math.max((widgetWidth / 12) * 2, 100)}
            flexGrow={2}
          >
            <HeaderCell>{lang !== null ? lang.actions : "Actions"}</HeaderCell>
            <Cell style={{ position: "relative" }}>
              {(rowData) => (
                <Actions
                  data={rowData}
                  setShowDetailsModal={setShowDetailsModal}
                  setSelectedDevice={setSelectedDevice}
                  refetch={reloadDevices}
                  access={{
                    adminAccess: adminAccess.hasAccessToSection,
                    deleteAccess: deleteAccess.hasAccessToSection,
                    writeAccess: writeAccess.hasAccessToSection,
                    readAccess: readAccess.hasAccessToSection,
                  }}
                />
              )}
            </Cell>
          </Column>
        </Table>
        {showDetailsModal && (
          <Details
            selectedDevice={selectedDevice}
            refetch={reloadDevices}
            close={() => {
              setShowDetailsModal(false);
            }}
            selectedAccount={selectedAccount}
          />
        )}
        {showBulkImportModal && (
          <BulkImport
            selectedAccount={selectedAccount}
            refetch={reloadDevices}
            close={() => {
              setShowBulkImportModal(false);
            }}
          />
        )}
        {showBulkActionModal && (
          <BulkAction
            selectedAccount={selectedAccount}
            bulkActionType={bulkActionType}
            refetch={reloadDevices}
            close={() => {
              setShowBulkActionModal(false);
            }}
            selectedDevices={checkedDevices.map(
              (id) => devices.devices.filter((d) => d.id === id)[0]
            )}
          />
        )}
      </div>
    ) : (
      <AccessDenied section="DEVICES" />
    );
  };

  return (
    <div>
      <Panel
        //expanded={props.expanded}
        expanded={false}
        collapsible
        bodyFill
        className="widget-config"
        style={{ borderRadius: 0 }}
      >
        <Form
          style={{ padding: "5px", paddingTop: "15px", paddingBottom: "15px" }}
        ></Form>
      </Panel>
      {renderContent()}
    </div>
  );
};
