import React from "react";
import { Icon } from "@rsuite/icons";

import * as faAt from "@fortawesome/free-solid-svg-icons/faAt";
import * as faKey from "@fortawesome/free-solid-svg-icons/faKey";
import * as faSignIn from "@fortawesome/free-solid-svg-icons/faSignIn";
import * as faEnvelope from "@fortawesome/free-regular-svg-icons/faEnvelope";
import * as faUser from "@fortawesome/free-regular-svg-icons/faUser";
import * as faSliders from "@fortawesome/free-solid-svg-icons/faSliders";
import * as faSquare from "@fortawesome/free-solid-svg-icons/faSquare";
import * as faPencil from "@fortawesome/free-solid-svg-icons/faPencil";
import * as faTag from "@fortawesome/free-solid-svg-icons/faTag";
import * as faGear from "@fortawesome/free-solid-svg-icons/faGear";
import * as faGears from "@fortawesome/free-solid-svg-icons/faGears";
import * as faRightFromBracket from "@fortawesome/free-solid-svg-icons/faRightFromBracket";
import * as faCopy from "@fortawesome/free-solid-svg-icons/faCopy";
import * as faDashboard from "@fortawesome/free-solid-svg-icons/faDashboard";
import * as faShareNodes from "@fortawesome/free-solid-svg-icons/faShareNodes";
import * as faBars from "@fortawesome/free-solid-svg-icons/faBars";
import * as faTv from "@fortawesome/free-solid-svg-icons/faTv";
import * as faHand from "@fortawesome/free-solid-svg-icons/faHand";
import * as faBan from "@fortawesome/free-solid-svg-icons/faBan";
import * as faFloppyDisk from "@fortawesome/free-solid-svg-icons/faFloppyDisk";
import * as faBarChart from "@fortawesome/free-solid-svg-icons/faBarChart";
import * as faMap from "@fortawesome/free-solid-svg-icons/faMap";
import * as faFileText from "@fortawesome/free-solid-svg-icons/faFileText";
import * as faHome from "@fortawesome/free-solid-svg-icons/faHome";
import * as faTablet from "@fortawesome/free-solid-svg-icons/faTablet";
import * as faGlobe from "@fortawesome/free-solid-svg-icons/faGlobe";
import * as faSitemap from "@fortawesome/free-solid-svg-icons/faSitemap";
import * as faMapMarker from "@fortawesome/free-solid-svg-icons/faMapMarker";
import * as faMapMarkerAlt from "@fortawesome/free-solid-svg-icons/faMapMarkerAlt";
import * as faLocationArrow from "@fortawesome/free-solid-svg-icons/faLocationArrow";
import * as faMapSigns from "@fortawesome/free-solid-svg-icons/faMapSigns";
import * as faInfo from "@fortawesome/free-solid-svg-icons/faInfo";
import * as faCircle from "@fortawesome/free-solid-svg-icons/faCircle";
import * as faList from "@fortawesome/free-solid-svg-icons/faList";
import * as faLock from "@fortawesome/free-solid-svg-icons/faLock";
import * as faBell from "@fortawesome/free-solid-svg-icons/faBell";
import * as faUsers from "@fortawesome/free-solid-svg-icons/faUsers";
import * as faSquarePlus from "@fortawesome/free-solid-svg-icons/faSquarePlus";
import * as faChartSimple from "@fortawesome/free-solid-svg-icons/faChartSimple";
import * as faChevronLeft from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import * as faChevronRight from "@fortawesome/free-solid-svg-icons/faChevronRight";
import * as faTrash from "@fortawesome/free-solid-svg-icons/faTrash";
import * as faWindowMaximize from "@fortawesome/free-solid-svg-icons/faWindowMaximize";
import * as faWindowClose from "@fortawesome/free-solid-svg-icons/faWindowClose";
import * as faEye from "@fortawesome/free-solid-svg-icons/faEye";
import * as faEyeSlash from "@fortawesome/free-solid-svg-icons/faEyeSlash";
import * as faRightToBracket from "@fortawesome/free-solid-svg-icons/faRightToBracket";
import * as faCircleXmark from "@fortawesome/free-solid-svg-icons/faCircleXmark";
import * as faWindowRestore from "@fortawesome/free-solid-svg-icons/faWindowRestore";
import * as faBuilding from "@fortawesome/free-solid-svg-icons/faBuilding";
import * as faExclamation from "@fortawesome/free-solid-svg-icons/faExclamation";
import * as faArrowRight from "@fortawesome/free-solid-svg-icons/faArrowRight";
import * as faSortDown from "@fortawesome/free-solid-svg-icons/faSortDown";
import * as faSortUp from "@fortawesome/free-solid-svg-icons/faSortUp";
import * as faArrowRightArrowLeft from "@fortawesome/free-solid-svg-icons/faArrowRightArrowLeft";
import * as faStar from "@fortawesome/free-solid-svg-icons/faStar";
import * as faTerminal from "@fortawesome/free-solid-svg-icons/faTerminal";
import * as faFileUpload from "@fortawesome/free-solid-svg-icons/faFileUpload";
import * as faHashtag from "@fortawesome/free-solid-svg-icons/faHashtag";
import * as faChevronDown from "@fortawesome/free-solid-svg-icons/faChevronDown";
import * as faVolumeHigh from "@fortawesome/free-solid-svg-icons/faVolumeHigh";
import * as faColumns from "@fortawesome/free-solid-svg-icons/faColumns";
import * as faBullseye from "@fortawesome/free-solid-svg-icons/faBullseye";
import * as faSignsPost from "@fortawesome/free-solid-svg-icons/faSignsPost";
import * as faShapes from "@fortawesome/free-solid-svg-icons/faShapes";
import * as faLocationDot from "@fortawesome/free-solid-svg-icons/faLocationDot";
import * as faClock from "@fortawesome/free-solid-svg-icons/faClock";
import * as faCalendarDays from "@fortawesome/free-solid-svg-icons/faCalendarDays";
import * as faCompass from "@fortawesome/free-solid-svg-icons/faCompass";
import * as faRoad from "@fortawesome/free-solid-svg-icons/faRoad";
import * as faTrophy from "@fortawesome/free-solid-svg-icons/faTrophy";
import * as faCar from "@fortawesome/free-solid-svg-icons/faCar";
import * as faTable from "@fortawesome/free-solid-svg-icons/faTable";
import * as faRefresh from "@fortawesome/free-solid-svg-icons/faRefresh";
import * as faCheck from "@fortawesome/free-solid-svg-icons/faCheck";
import * as faUnlock from "@fortawesome/free-solid-svg-icons/faUnlock";
import * as faChartGantt from "@fortawesome/free-solid-svg-icons/faChartGantt";
import * as faFilter from "@fortawesome/free-solid-svg-icons/faFilter";
import * as faFilterCircleXmark from "@fortawesome/free-solid-svg-icons/faFilterCircleXmark";
import * as faChevronUp from "@fortawesome/free-solid-svg-icons/faChevronUp";
import * as faChevronCircleUp from "@fortawesome/free-solid-svg-icons/faChevronCircleUp";

const Icons = {
  faArrowRight,
  faArrowRightArrowLeft,
  faAt,
  faBan,
  faBarChart,
  faBars,
  faBell,
  faBuilding,
  faBullseye,
  faCalendarDays,
  faCar,
  faChartGantt,
  faChartSimple,
  faCheck,
  faChevronCircleUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faClock,
  faColumns,
  faCompass,
  faCopy,
  faCircle,
  faCircleXmark,
  faDashboard,
  faEnvelope,
  faExclamation,
  faEye,
  faEyeSlash,
  faFloppyDisk,
  faFileText,
  faFileUpload,
  faFilter,
  faFilterCircleXmark,
  faGear,
  faGears,
  faGlobe,
  faHand,
  faHashtag,
  faHome,
  faInfo,
  faKey,
  faList,
  faLock,
  faLocationArrow,
  faLocationDot,
  faMap,
  faMapMarker,
  faMapMarkerAlt,
  faMapSigns,
  faPencil,
  faRefresh,
  faRightFromBracket,
  faRightToBracket,
  faRoad,
  faShapes,
  faShareNodes,
  faSignIn,
  faSignsPost,
  faSitemap,
  faSliders,
  faSortDown,
  faSortUp,
  faSquare,
  faSquarePlus,
  faStar,
  faTable,
  faTablet,
  faTag,
  faTerminal,
  faTrash,
  faTrophy,
  faTv,
  faUnlock,
  faUser,
  faUsers,
  faVolumeHigh,
  faWindowClose,
  faWindowMaximize,
  faWindowRestore,
};

const FaSvgIcon = ({ faIcon, ...rest }) => {
  const { width, height, svgPathData } = faIcon;
  return (
    <svg
      {...rest}
      viewBox={`0 0 ${width} ${height}`}
      width="2em"
      height="2em"
      fill="currentColor"
    >
      <path d={svgPathData}></path>
    </svg>
  );
};

export default (props) => {
  const { icon, width, style, ...rest } = props;
  //console.log({ icon });
  if (!Icons[icon]) {
    console.error({ icon });
  }
  return (
    <Icon
      as={FaSvgIcon}
      faIcon={Icons[icon]}
      style={{ width: width || "9pt", ...style }}
      {...rest}
    />
  );
};
