import React, { useState, useEffect } from "react";
import {
  Avatar,
  Button,
  Dropdown,
  //Icon,
  Modal,
  Grid,
  Row,
  Col,
  Input,
  ButtonGroup,
  Whisper,
  Popover,
  Sidenav,
  Nav,
} from "rsuite";
import { useApolloClient, useMutation } from "@apollo/react-hooks";

import Icon from "../../components/Icon";

import { useApp } from "../../AppStore";
//import DropdownMenu from "rsuite/lib/Dropdown/DropdownMenu";

import ScreenIconPicker from "./screeniconpicker";

import GQL from "./graphql";

const { Menu: DropdownMenu } = Dropdown;
let lang = null;

export default (props) => {
  const client = useApolloClient();
  const { screen, index, selectedScreen } = props;
  const [{ user, screens, system }] = useApp();

  /** STATES */
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const primaryColor =
    user && user.account && user.account.primaryColor
      ? user.account.primaryColor
      : "skyblue";
  const [showMenu, setShowMenu] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [title, setTitle] = useState(screen.title);
  const [icon, setIcon] = useState(screen.icon);

  /** GRAPHQL */
  const [updateScreen] = useMutation(GQL.Mutations.updateScreen);
  const [deleteScreen] = useMutation(GQL.Mutations.deleteScreen);

  useEffect(() => {
    /** Language File */
    lang =
      user && user.language
        ? require("../../languages/" + user.language + ".js").default
        : lang;

    //console.log({ lang });
  }, [user]);

  return (
    <div
      style={{
        position: "relative",
        borderRadius: "6px",
        float: "left",
        padding: "18px",
        paddingTop: "10px",
        paddingBottom: "10px",
        cursor: "pointer",
        backgroundColor: index === selectedScreen ? primaryColor : "inherit",
      }}
      onMouseEnter={() => {
        setShowMenu(true);
      }}
      onMouseLeave={() => {
        setShowMenu(false);
      }}
      className={
        index === selectedScreen
          ? system && system === "sm"
            ? "sm-menu-item-active"
            : "menu-item-active"
          : null
      }
    >
      <div>
        <Icon icon={screen.icon} /> {screen.title}
      </div>
      {showMenu && (
        <div
          style={{
            position: "absolute",
            left: "-5px",
            top: "9px",
            backgroundColor: "white",
            zIndex: 9999,
            borderRadius: "6px",
          }}
          className="small-shadow"
          onMouseLeave={() => {
            setShowMenu(false);
          }}
        >
          <div
            className="menu-dropdown-header"
            style={{
              padding: "18px",
              paddingTop: "10px",
              paddingBottom: "10px",
              borderRadius: "6px",
            }}
            onClick={() => {
              if (selectedScreen !== index) {
                props.setSelectedScreen(index);
              }
            }}
          >
            <div>
              <Icon icon={screen.icon} /> {screen.title}
            </div>
          </div>
          <Grid
            style={{ position: "absolute", top: "-5px", width: "100%" }}
            onClick={() => {
              if (selectedScreen !== index) {
                props.setSelectedScreen(index);
              }
            }}
          >
            <Row>
              <Col
                xs={12}
                style={{
                  textAlign: "left",
                  paddingRight: "5px",
                }}
              >
                {index === 0 ? null : (
                  <Avatar
                    className={
                      index === 0 ? "action-button-disabled" : "action-button"
                    }
                    circle
                    size="sm"
                    style={{ position: "relative", top: "10px", left: "-15px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (index > 0) {
                        props.swapScreens(index, index - 1, client);
                      }
                    }}
                  >
                    <Icon icon="faChevronLeft" />
                  </Avatar>
                )}
              </Col>
              <Col
                xs={12}
                style={{
                  textAlign: "right",
                  paddingLeft: "5px",
                }}
              >
                {index === screens.length - 1 ? null : (
                  <Avatar
                    className={
                      index === screens.length - 1
                        ? "action-button-disabled"
                        : "action-button"
                    }
                    circle
                    size="sm"
                    style={{ position: "relative", top: "10px", left: "15px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (index < screens.length - 1) {
                        props.swapScreens(index, index + 1, client);
                      }
                    }}
                  >
                    <Icon icon="faChevronRight" />
                  </Avatar>
                )}
              </Col>
            </Row>
          </Grid>
          <div>
            {showDetails ? (
              <div
                style={{
                  position: "absolute",
                  color: "gray",
                  padding: "15px",
                  backgroundColor: "white",
                  borderRadius: "6px",
                }}
                className="small-shadow"
              >
                <div>
                  <Input value={title} onChange={setTitle} />
                </div>
                <div style={{ paddingTop: "10px" }}>
                  <Whisper
                    placement="bottom"
                    trigger="click"
                    speaker={
                      <Popover style={{ zIndex: 99999 }}>
                        <ScreenIconPicker onClick={(icon) => setIcon(icon)} />
                      </Popover>
                    }
                  >
                    <Avatar size="xs" circle className="action-button">
                      <Icon icon={icon} />
                    </Avatar>
                  </Whisper>{" "}
                  <Avatar
                    size="xs"
                    circle
                    className="action-button"
                    style={{ backgroundColor: "#7DB545" }}
                    onClick={() => {
                      updateScreen({
                        variables: {
                          id: screen.id,
                          title,
                          icon,
                        },
                      }).then((res) => {
                        props.refetchAccount && props.refetchAccount();
                      });
                    }}
                  >
                    <Icon icon="faFloppyDisk" />
                  </Avatar>{" "}
                  <Avatar
                    size="xs"
                    circle
                    className="action-button"
                    style={{ backgroundColor: "#D64545" }}
                    onClick={() => setShowDetails(false)}
                  >
                    <Icon icon="faBan" width={15} />
                  </Avatar>
                </div>
              </div>
            ) : null}
            {!showDetails && (
              <Sidenav style={{ width: "100%" }}>
                <Sidenav.Body>
                  <Nav style={{ width: "100%" }}>
                    <Nav.Item
                      icon={<Icon icon="faGear" />}
                      style={{ width: "100%" }}
                      onClick={() => setShowDetails(true)}
                    >
                      {lang !== null ? lang.settings : "Settings"}
                    </Nav.Item>
                    <Nav.Item
                      icon={<Icon icon="faTrash" />}
                      style={{ width: "100%" }}
                      onClick={() => setShowConfirmDelete(true)}
                    >
                      {lang !== null ? lang.delete : "Delete"}
                    </Nav.Item>
                  </Nav>
                </Sidenav.Body>
              </Sidenav>
            )}
            {/*
            <DropdownMenu>
              <Dropdown.Item onClick={() => setShowDetails(true)}>
                <span>
                  <Icon icon="faGear" />{" "}
                  {lang !== null ? lang.settings : "Settings"}
                </span>
              </Dropdown.Item>
              <Dropdown.Item divider />
              <Dropdown.Item onClick={() => setShowConfirmDelete(true)}>
                <span>
                  <Icon icon="faTrash" />{" "}
                  {lang !== null ? lang.delete : "Delete"}
                </span>
              </Dropdown.Item>
            </DropdownMenu>
									*/}
            <Modal open={showConfirmDelete}>
              <Modal.Header closeButton={false}>
                <Modal.Title>
                  {lang !== null ? lang.deleteScreen : "Delete Screen"}?
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {lang !== null
                  ? lang.deleteConfirm[0]
                  : "Are you sure you want to delete"}{" "}
                ({screen.title})?{" "}
                {lang !== null
                  ? lang.deleteConfirm[1]
                  : "This CANNOT be undone."}
              </Modal.Body>
              <Modal.Footer>
                <ButtonGroup
                  style={{ borderRadius: "6px" }}
                  className="small-shadow"
                >
                  <Button
                    appearance="primary"
                    color="red"
                    onClick={() => {
                      setShowConfirmDelete(false);
                      deleteScreen({
                        variables: {
                          id: screen.id,
                        },
                      }).then((res) => {
                        props.refetchAccount && props.refetchAccount();
                      });
                      //actions.removeScreen(index, client);

                      if (index === selectedScreen) props.setSelectedScreen(0);
                    }}
                    disabled={props.saveDisabled}
                  >
                    <span>
                      <Icon icon="faCheck" /> {lang !== null ? lang.yes : "Yes"}
                    </span>
                  </Button>
                  <Button
                    appearance="primary"
                    color="orange"
                    onClick={() => {
                      setShowConfirmDelete(false);
                    }}
                  >
                    <span>
                      <Icon icon="faBan" width={15} />{" "}
                      {lang !== null ? lang.cancel : "Cancel"}
                    </span>
                  </Button>
                </ButtonGroup>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      )}
    </div>
  );
};
