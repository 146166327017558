import React, { useState, useEffect } from "react";
import {
  Avatar,
  Form,
  //FormGroup,
  InputGroup,
  Input,
  CheckPicker,
  Button,
  //Icon,
  Grid,
  Row,
  Col,
  ButtonGroup,
  Popover,
  Whisper,
} from "rsuite";

import Icon from "../../components/Icon";
import { useMutation, useQuery } from "@apollo/react-hooks";
import sha1 from "sha1";

import GQL from "./graphql";
import { useApp } from "../../AppStore";

import AvatarPicker from "../../components/avatarpicker";
import TemplateScreenSelector from "../../components/templatescreenselector";

let lang = null;
const { Group: FormGroup } = Form;

export default (props) => {
  const { selectedUser, selectedAccount, access } = props;
  const [{ user }] = useApp();
  const { data: roles } = useQuery(GQL.Queries.roles, {
    variables: {
      accountId: selectedAccount ? selectedAccount.id : user.account.id,
    },
  });
  const [createUser] = useMutation(GQL.Mutations.createUser);
  const [updateUser] = useMutation(GQL.Mutations.updateUser);
  const [avatar, setAvatar] = useState(
    selectedUser && selectedUser.avatar ? selectedUser.avatar : ""
  );
  const [firstname, setFirstname] = useState(
    selectedUser && selectedUser.firstname ? selectedUser.firstname : ""
  );
  const [lastname, setLastname] = useState(
    selectedUser && selectedUser.lastname ? selectedUser.lastname : ""
  );
  const [email, setEmail] = useState(
    selectedUser && selectedUser.email ? selectedUser.email : ""
  );
  const [password, setPassword] = useState("");
  const [roleIds, setRoleIds] = useState(
    selectedUser && selectedUser.roles
      ? selectedUser.roles.map((role) => role.id)
      : []
  );
  const [templateScreenIds, setTemplateScreenIds] = useState([]);

  useEffect(() => {
    /** Language File */
    lang =
      user && user.language
        ? require("../../languages/" + user.language + ".js").default
        : lang;
  }, [user]);

  return (
    <div
      className="shadow"
      style={{
        animation: "0.15s ease-out 0s 1 fadeAndSlideIn",
        borderRadius: "1px",
        position: "absolute",
        padding: 0,
        backgroundColor: "white",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 9999,
        width: "95%",
        height: "95%",
        margin: "auto",
        overflowY: "scroll",
      }}
    >
      <div
        style={{
          padding: "10px",
          color: "white",
          position: "relative",
          width: "100%",
        }}
        className="small-shadow primary-gradient"
      >
        <div style={{ width: "100%", textAlign: "left" }}>
          <Grid fluid>
            <Row>
              <Col xs={12} style={{ textAlign: "left" }}>
                <big>
                  {lang !== null ? lang.userDetailsPlaceholder : "User Details"}
                </big>
              </Col>
              <Col xs={12} style={{ textAlign: "right" }}>
                <Avatar
                  size="xs"
                  style={{ backgroundColor: "#D64545" }}
                  className="action-button"
                  onClick={() => {
                    props.close && props.close();
                  }}
                >
                  <Icon icon="faCircleXmark" />
                </Avatar>
              </Col>
            </Row>
          </Grid>
        </div>
        <div>
          <Whisper
            trigger="click"
            placement="bottom"
            speaker={
              <Popover>
                <AvatarPicker
                  onClick={(avatar) => {
                    setAvatar(avatar);
                    props.refetch();
                  }}
                />
              </Popover>
            }
          >
            <Avatar
              onClick={() => {
                //console.log("avatar")
              }}
              circle
              className="small-shadow"
              src={avatar && avatar.length > 0 ? avatar : "images/256_1.png"}
              size="lg"
            />
          </Whisper>
        </div>
        <div>
          <big>
            {firstname && firstname.length > 0
              ? firstname
              : lang !== null
              ? lang.new
              : "New"}{" "}
            {lastname && lastname.length > 0
              ? lastname
              : lang !== null
              ? lang.user
              : "User"}
          </big>
        </div>
        <div>
          <small>
            {email && email.length > 0
              ? email
              : lang !== null
              ? lang.emailPlaceholder
              : "email@domain.com"}
          </small>
        </div>
      </div>
      <div style={{ padding: "15px" }}>
        <Form fluid>
          <FormGroup>
            <InputGroup style={{ width: "100%" }}>
              <InputGroup.Addon>
                <Icon icon="faUser" width={15} />
              </InputGroup.Addon>
              <Input
                style={{ width: "50%" }}
                placeholder={
                  lang !== null ? lang.firstNamePlaceholder : "First Name"
                }
                value={firstname}
                onChange={setFirstname}
              />
              <Input
                style={{ width: "50%" }}
                placeholder={
                  lang !== null ? lang.lastNamePlaceholder : "Last Name"
                }
                value={lastname}
                onChange={setLastname}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup style={{ width: "100%" }}>
              <InputGroup.Addon>
                <Icon icon="faAt" width={15} />
              </InputGroup.Addon>
              <Input
                placeholder={lang !== null ? lang.email : "Email"}
                value={email}
                onChange={setEmail}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup style={{ width: "100%" }}>
              <InputGroup.Addon>
                <Icon icon="faKey" width={15} />
              </InputGroup.Addon>
              <Input
                placeholder={lang !== null ? lang.password : "Password"}
                value={password}
                onChange={setPassword}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup style={{ width: "100%" }}>
              <InputGroup.Addon>
                <Icon icon="faLock" />
              </InputGroup.Addon>
              <CheckPicker
                style={{ width: "100%" }}
                data={
                  roles && roles.roles
                    ? roles.roles.map((role) => ({
                        value: role.id,
                        label: role.name,
                      }))
                    : []
                }
                value={roleIds}
                onChange={setRoleIds}
                disabled={access.adminAccess !== true}
              />
            </InputGroup>
          </FormGroup>
          {!selectedUser ? (
            <FormGroup>
              <TemplateScreenSelector onChange={setTemplateScreenIds} />
            </FormGroup>
          ) : null}
          <FormGroup>
            <ButtonGroup>
              <Button
                appearance="primary"
                className="small-shadow"
                color="green"
                onClick={() => {
                  if (selectedUser) {
                    //console.log("saving");
                    updateUser({
                      variables: {
                        id: selectedUser.id,
                        firstname,
                        lastname,
                        email,
                        avatar,
                        roleIds,
                        ...(password && password.length > 0
                          ? { password: sha1(password) }
                          : null),
                      },
                    }).then((res) => {
                      props.close();
                      props.refetch();
                    });
                  } else {
                    createUser({
                      variables: {
                        ...(selectedAccount
                          ? { accountId: selectedAccount.id }
                          : null),
                        firstname,
                        lastname,
                        email,
                        password: sha1(password),
                        roleIds,
                        avatar,
                        templateScreenIds,
                      },
                    }).then((res) => {
                      //console.log("Create User Result:", res);
                      if (
                        res &&
                        res.data &&
                        res.data.createUser &&
                        res.data.createUser.id
                      ) {
                        props.close();
                        props.refetch();
                      }
                    });
                  }
                }}
              >
                {selectedUser ? (
                  <span>
                    <Icon icon="faFloppyDisk" />{" "}
                    {lang !== null ? lang.save : "Save"}
                  </span>
                ) : (
                  <span>
                    <Icon icon="faFloppyDisk" />{" "}
                    {lang !== null ? lang.create : "Create"}
                  </span>
                )}
              </Button>
              <Button
                appearance="primary"
                className="small-shadow"
                color="orange"
                onClick={() => {
                  props.close && props.close();
                }}
              >
                <Icon icon="faBan" width={15} />{" "}
                {lang !== null ? lang.cancel : "Cancel"}
              </Button>
            </ButtonGroup>
          </FormGroup>
        </Form>
      </div>
    </div>
  );
};
