import React from "react";
import globalHook from "use-global-hook";
import { Message, toaster } from "rsuite";
import Cookie from "js-cookie";
import gql from "graphql-tag";

const rows = 4;
const cols = 8;
const minW = 1;
const minH = 1;

//const heightOffset = 97;
const heightOffset = 110;
const rowHeight = parseInt((window.innerHeight - heightOffset) / rows);

//const impersonationstate = Cookie.get("atles-geofleetos-impersonationstate");
//const localState = Cookie.get("atles-geofleetos-state");

const initialState = {
  showBlankWidgets: true,
  loggedIn: false,
  cols,
  rows,
  minW,
  minH,
  rowHeight,
  widgetCount: 0,
  selectedScreen: 0,
  screens: null,
  showOverlay: true,
  showScreenOverlay: true,
  fullscreenToggle: false,
  filters: [],
  selectedAccount: null,
  selectedDevice: null,
  selectedLocation: null,
  system: "atles",
  /*
  ...(localState && impersonationstate === "false"
    ? JSON.parse(localState)
    : {})
  */
};

const saveState = (state, client) => {
  //window.localStorage.setItem(localStorageKey, JSON.stringify(state));
  if (
    client &&
    state &&
    state.screens &&
    state.screens.length > 0 &&
    state.selectedScreen != null
  ) {
    //console.log("saving screen:", state.screens[state.selectedScreen]);
    if (
      state.screens[state.selectedScreen] &&
      state.screens[state.selectedScreen].widgets &&
      state.screens[state.selectedScreen].widgets.length > 0
    ) {
      const mutation = gql`
        mutation updateWidget($id: Int!, $dataGrid: String) {
          updateWidget(id: $id, dataGrid: $dataGrid)
        }
      `;
      state.screens[state.selectedScreen].widgets.forEach((widget) => {
        //console.log("Saving widget:", { widget });
        client
          .mutate({
            mutation,
            variables: {
              id: widget.id,
              dataGrid: JSON.stringify(widget.dataGrid),
            },
          })
          .then((res) => {
            //console.log("done saving:", { res });
          });
      });
    }
  }
};

const saveScreen = (screen, client) => {
  const mutation = gql`
    mutation updateWidget($id: Int!, $dataGrid: String) {
      updateWidget(id: $id, dataGrid: $dataGrid)
    }
  `;
  screen.widgets.forEach((widget) => {
    //console.log("Saving widget:", { widget });
    client
      .mutate({
        mutation,
        variables: {
          id: widget.id,
          dataGrid: JSON.stringify(widget.dataGrid),
        },
      })
      .then((res) => {
        //console.log("done saving:", { res });
      });
  });
};

const nextOpenSpot = (widgets, w, h, maxX, maxY) => {
  for (let y = 0; y <= maxY - h; y++) {
    for (let x = 0; x <= maxX - w; x++) {
      if (
        !widgets.reduce((accum, widget) => {
          if (
            x < widget.dataGrid.x + widget.dataGrid.w &&
            x + w > widget.dataGrid.x &&
            y < widget.dataGrid.y + widget.dataGrid.h &&
            y + h > widget.dataGrid.y
          ) {
            return true;
          } else {
            return accum;
          }
        }, false)
      ) {
        return { x, y };
      }
    }
  }

  console.log("No more room.");
  return null;
};

const actions = {
  setSystem: (store, system) => {
    store.setState({ system });
  },
  swapScreens: (store, fromIndex, toIndex, client) => {
    const screens = store.state.screens.concat();
    const toScreen = Object.assign({}, screens[toIndex]);
    screens.splice(toIndex, 1, screens[fromIndex]);
    screens.splice(fromIndex, 1, toScreen);
    store.setState({ screens });

    screens.forEach(({ id }, order) => {
      const mutation = gql`
        mutation updateScreen($id: Int!, $order: Int!) {
          updateScreen(id: $id, order: $order)
        }
      `;
      const variables = { id, order };
      client
        .mutate({ mutation, variables })
        .catch((err) => console.error({ err }));
    });
  },
  setScreen: (store, index, screen) => {
    const screens = store.state.screens.concat();
    screens.splice(index, 1, screen);
    store.setState({ screens });
  },
  setSelectedAccount: (store, selectedAccount) => {
    store.setState({ selectedAccount });
  },
  setSelectedDevice: (store, selectedDevice) => {
    store.setState({ selectedDevice });
  },
  setSelectedLocation: (store, selectedLocation) => {
    store.setState({ selectedLocation });
  },
  addFilter: (store, filter) => {
    //console.log("Add Filter:", filter);
    const filters = store.state.filters.concat(filter);
    store.setState({ filters });
  },
  removeFilter: (store, index) => {
    const filters = store.state.filters;
    filters.splice(index, 1);
    store.setState({ filters });
  },
  setShowOverlay: (store, showOverlay) => {
    store.setState({ showOverlay });
  },
  setShowScreenOverlay: (store, showScreenOverlay) => {
    store.setState({ showScreenOverlay });
  },
  getOpenSpots: (store, widgets) => {
    // BROKEN DO NOT USE
    let openSpots = [];
    let spotFound = null;
    let count = 0;
    let w = widgets;

    spotFound = nextOpenSpot(w, 1, 1, cols - 1, rows - 1);

    while (spotFound && count < rows * cols) {
      openSpots.push(spotFound);
      w.push({ dataGrid: { ...spotFound, w: 1, y: 1 } });
      spotFound = nextOpenSpot(w, 1, 1, cols - 1, rows - 1);
      count++;
      //console.log({ spotFound });
    }

    //console.log({ openSpots });

    return openSpots;
  },
  showBlankWidgets: (store) => {
    store.setState({
      showBlankWidgets: true,
    });
  },
  hideBlankWidgets: (store) => {
    store.setState({
      showBlankWidgets: false,
    });
  },
  windowResize: (store) => {
    store.setState({
      rowHeight: parseInt((window.innerHeight - heightOffset) / rows),
    });
  },
  setUser: (store, user) => {
    store.setState({
      user: {
        ...store.state.user,
        ...user,
      },
    });
  },
  login: (store, user) => {
    store.setState({
      isLoggedIn: true,
      user,
      company: {
        name: "MC3 Solutions",
      },
    });
    saveState(store.state);
  },
  logout: (store) => {
    store.setState({
      isLoggedIn: false,
      user: null,
    });
    saveState(store.state);

    Cookie.set("atles-geofleetos-token", null);
    window.location = "/login";
  },
  setScreens: (store, screens) => {
    store.setState({ screens });
  },
  reloadUser: (store, client) => {
    const query = gql`
      {
        user {
          id
          firstname
          lastname
          language
          email
          avatar
          accountId
          account {
            id
            name
            smAccess
            primaryColor
            secondaryColor
            logo
            mapSystemId
            mapSystemApiKey
          }
        }
      }
    `;
    client.query({ query }).then((res) => {
      //console.log("reload user:", res);
      store.setState({ user: res.data.user });
      saveState(store.state);
    });
  },
  addScreen: (store, { name, description, icon, system, order }, client) => {
    client
      .mutate({
        mutation: gql`
          mutation addUserScreen(
            $title: String!
            $description: String
            $icon: String
            $system: String
            $order: Int
          ) {
            addUserScreen(
              title: $title
              description: $description
              icon: $icon
              system: $system
              order: $order
            ) {
              id
              title
              description
              icon
              order
            }
          }
        `,
        variables: { title: name, description, icon, system, order },
      })
      .then((res) => {
        //console.log("new screen created:", res);
        const newScreen = res.data.addUserScreen;
        store.setState({
          selectedScreen: store.state.screens.length,
          screens: [...store.state.screens, { ...newScreen, widgets: [] }],
        });
      })
      .catch((error) => console.error("Could not create screen:", error));
  },
  removeScreen: (store, index, client) => {
    const screens = store.state.screens;
    const [deletedScreen] = screens.splice(index, 1);

    store.setState({ screens });
    client
      .mutate({
        mutation: gql`
          mutation deleteScreen($id: Int!) {
            deleteScreen(id: $id)
          }
        `,
        variables: {
          id: deletedScreen.id,
        },
      })
      .then((res) => {
        //console.log("screen deleted");
      })
      .catch((error) => console.error("could not delete screen", error));
  },
  setSelectedScreen: (store, selectedScreen) => {
    store.setState({ selectedScreen });
    //saveState(store.state);
  },
  handleLayoutChange: (store, layout, client) => {
    if (
      store &&
      store.state &&
      store.state.screens &&
      store.state.screens.length > 0 &&
      store.state.screens[store.state.selectedScreen].fullscreenWidget ==
        null &&
      window.innerWidth > 1024
    ) {
      /*
      const { screens, selectedScreen } = store.state;

      screens.splice(selectedScreen, 1, {
        screen: {
          ...screens[selectedScreen],
          widgets: layout.map((dataGrid, index) => ({
            ...screens[selectedScreen].widgets[index],
            dataGrid
          }))
        }
      });

      store.setState({ screens });
      saveScreen(screens[selectedScreen], client);
      */
      const screens = store.state.screens;
      const screen = screens[store.state.selectedScreen];
      const widgets = layout.map((dataGrid, index) => ({
        ...screen.widgets[index],
        dataGrid,
      }));
      const newScreen = { ...screen, widgets };

      screens.splice(store.state.selectedScreen, 1, newScreen);

      setTimeout(() => {
        //store.setState({ screens });
      }, 0);

      //console.log("screen:", { screen });
      //console.log("newScreen", { newScreen });

      saveScreen(newScreen, client);
      //saveState(store.state, client);
      //console.log("saved state");
    } else {
      //console.log("nothing done");
    }
  },
  addWidget: async (store, widget, client) => {
    //title, type, i, w, h) => {
    let screens = store.state.screens;
    let screen = screens[store.state.selectedScreen];

    const newPosition = nextOpenSpot(
      screen.widgets,
      widget.w,
      widget.h,
      cols,
      rows
    );

    if (newPosition !== null) {
      const newWidget = {
        id: widget.id,
        title: widget.title,
        icon: widget.icon,
        type: widget.type,
        locked: false,
        dataGrid: {
          i: widget.i,
          w: widget.w,
          h: widget.h,
          x: newPosition.x,
          y: newPosition.y,
        },
      };
      // SAVE IN DB
      await client.mutate({
        mutation: gql`
          mutation updateWidget($id: Int!, $dataGrid: String!) {
            updateWidget(id: $id, dataGrid: $dataGrid)
          }
        `,
        variables: {
          id: widget.id,
          dataGrid: JSON.stringify(newWidget.dataGrid),
        },
      });
      screen.widgets = [...screen.widgets, newWidget];
      screens.splice(store.state.selectedScreen, 1, screen);
      store.setState({
        widgetCount: ++store.state.widgetCount,
        screens,
      });
      saveState(store.state);
      toaster.push(
        <Message type="success" closable>
          Widget added!
        </Message>
      );
      //Alert.success("Widget added!");
    } else {
      toaster.push(
        <Message type="error" closable>
          No room for widget!
        </Message>
      );
      //Alert.error("No room for widget!");

      //Blank widget must be deleted from DB or else it'll cause issues
      await client.mutate({
        mutation: gql`
          mutation deleteWidget($id: Int!) {
            deleteWidget(id: $id)
          }
        `,
        variables: {
          id: widget.id,
        },
      });
    }
  },
  removeWidget: (store, index, client) => {
    let screens = store.state.screens;
    let screen = screens[store.state.selectedScreen];

    const [widget] = screen.widgets.splice(index, 1);
    client.mutate({
      mutation: gql`
        mutation deleteWidget($id: Int!) {
          deleteWidget(id: $id)
        }
      `,
      variables: {
        id: widget.id,
      },
    });
    screens.splice(store.state.selectedScreen, 1, screen);
    store.setState({ screens, widgetCount: --store.state.widgetCount });
    saveState(store.state);
    toaster.push(
      <Message type="info" closable>
        Widget deleted!
      </Message>
    );
    //Alert.info("Widget deleted!");
  },
  maximizeWidget: (store, index) => {
    const widget =
      store.state.screens[store.state.selectedScreen].widgets[index];
    let screens = store.state.screens;

    screens.splice(store.state.selectedScreen, 1, {
      ...screens[store.state.selectedScreen],
      fullscreenWidget: {
        ...widget,
        isFlipped: false,
        dataGrid: {
          ...widget.dataGrid,
          i: "fullscreen-widget-" + store.state.selectedScreen,
          x: 0,
          y: 0,
          w: cols,
          h: rows,
          static: true,
        },
      },
    });
    store.setState({ screens, fullscreenToggle: true });
    saveState(store.state);
  },
  minimizeWidget: (store) => {
    let screens = store.state.screens;

    screens.splice(store.state.selectedScreen, 1, {
      ...screens[store.state.selectedScreen],
      fullscreenWidget: null,
    });
    store.setState({ screens, fullscreenToggle: false });
  },
  toggleWidgetActive: (store, index) => {
    let screens = store.state.screens;
    let screen = screens[store.state.selectedScreen];
    let widget = screen.widgets[index];

    widget.active = !widget.active;
    screen.widgets.splice(index, 1, widget);
    screens.splice(store.state.selectedScreen, 1, screen);
    store.setState({ screens });
    saveState(store.state);
  },
  toggleLockedWidget: (store, index) => {
    let screens = store.state.screens;
    let screen = screens[store.state.selectedScreen];
    let widget = screen.widgets[index];

    widget.dataGrid = {
      ...widget.dataGrid,
      isDraggable: !widget.locked,
    };

    widget.locked = !widget.locked;
    screen.widgets.splice(index, 1, widget);
    screens.splice(store.state.selectedScreen, 1, screen);
    store.setState({ screens });
    saveState(store.state);
  },
  toggleCardFlipped: (store, index) => {
    let screens = store.state.screens;
    let screen = screens[store.state.selectedScreen];
    let widget = screen.widgets[index];

    widget.isFlipped = !widget.isFlipped;
    screen.widgets.splice(index, 1, widget);
    screens.splice(store.state.selectedScreen, 1, screen);
    store.setState({ screens });
    saveState(store.state);
  },
};

const useApp = globalHook(React, initialState, actions);

export { useApp };
