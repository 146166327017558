import React from "react";
import { TileLayer } from "react-leaflet";

import { useApp } from "../../AppStore";

export default (props) => {
  const [{ user }] = useApp();
  //console.log({ user });
  if (
    user &&
    user.account &&
    user.account.mapSystemId &&
    user.account.mapSystemApiKey &&
    user.account.mapSystemApiKey.length > 0
  ) {
    switch (user.account.mapSystemId) {
      case 2:
        return (
          <TileLayer
            url={
              "https://api.mapbox.com/styles/v1/mapbox/streets-v9/tiles/{z}/{x}/{y}?access_token=" +
              user.account.mapSystemApiKey
            }
          />
        );
      case 3:
        return (
          <TileLayer
            attribution=""
            url="//mt.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
          />
        );
      case 1:
      default:
        return (
          <TileLayer attribution="" url="//{s}.tile.osm.org/{z}/{x}/{y}.png" />
        );
    }
  } else {
    return (
      <TileLayer attribution="" url="//{s}.tile.osm.org/{z}/{x}/{y}.png" />
    );
  }
};
