import React, { useState, useEffect } from "react";
import {
  Avatar,
  Form,
  //FormGroup,
  InputGroup,
  Input,
  Button,
  //Icon,
  Grid,
  Row,
  Col,
  ButtonGroup,
  Toggle,
} from "rsuite";

import Icon from "../../components/Icon";

import { useMutation, useQuery } from "@apollo/react-hooks";

import GQL from "./graphql";
import { useApp } from "../../AppStore";

let lang = null;
const { Group: FormGroup } = Form;

export default (props) => {
  const { selectedRole, selectedAccount } = props;
  const [{ user }] = useApp();
  const { data: sections } = useQuery(GQL.Queries.sections);
  const [createRole] = useMutation(GQL.Mutations.createRole);
  const [updateRole] = useMutation(GQL.Mutations.updateRole);
  const [name, setName] = useState(
    selectedRole && selectedRole.name ? selectedRole.name : ""
  );
  const [sectionAccess, setSectionAccess] = useState([]);

  useEffect(() => {
    /** Language File */
    lang =
      user && user.language
        ? require("../../languages/" + user.language + ".js").default
        : lang;
  }, [user]);

  useEffect(() => {
    sections &&
      sections.sections &&
      sections.sections.length > 0 &&
      setSectionAccess(
        sections.sections.map((section) => {
          const matchingRoleSection = selectedRole
            ? selectedRole.roleSections.filter(
                (rs) => rs.section.id === section.id
              )[0]
            : null;

          return {
            id: section.id,
            name: section.name,
            readAccess: matchingRoleSection
              ? matchingRoleSection.readAccess
              : false,
            writeAccess: matchingRoleSection
              ? matchingRoleSection.writeAccess
              : false,
            deleteAccess: matchingRoleSection
              ? matchingRoleSection.deleteAccess
              : false,
            adminAccess: matchingRoleSection
              ? matchingRoleSection.adminAccess
              : false,
          };
        })
      );
  }, [sections, selectedRole]);

  const setSectionAccessToggle = (index, access, checked) => {
    const newSectionAccess = sectionAccess.concat();
    let section = sectionAccess[index];
    section[access] = checked;
    newSectionAccess.splice(index, 1, section);
    setSectionAccess(newSectionAccess);
  };

  return (
    <div
      className="shadow"
      style={{
        animation: "0.15s ease-out 0s 1 fadeAndSlideIn",
        borderRadius: "1px",
        position: "absolute",
        padding: 0,
        backgroundColor: "white",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 9999,
        width: "95%",
        height: "95%",
        margin: "auto",
        overflowY: "scroll",
      }}
    >
      <div
        style={{
          padding: "10px",
          color: "white",
          position: "relative",
          width: "100%",
        }}
        className="small-shadow primary-gradient"
      >
        <div style={{ width: "100%", textAlign: "left" }}>
          <Grid fluid>
            <Row>
              <Col xs={12} style={{ textAlign: "left" }}>
                <big>
                  {name} : {lang !== null ? lang.roleSections : "Role Sections"}
                </big>
              </Col>
              <Col xs={12} style={{ textAlign: "right" }}>
                <Avatar
                  size="xs"
                  style={{ backgroundColor: "#D64545" }}
                  className="action-button"
                  onClick={() => {
                    props.close && props.close();
                  }}
                >
                  <Icon icon="faCircleXmark" />
                </Avatar>
              </Col>
            </Row>
          </Grid>
        </div>
      </div>
      <div style={{ padding: "15px" }}>
        <Form fluid>
          <FormGroup>
            <InputGroup>
              <InputGroup.Addon>
                <Icon icon="faTag" width={15} />
              </InputGroup.Addon>
              <Input placeholder="Name" value={name} onChange={setName} />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <Grid fluid>
              <Row
                style={{
                  paddingBottom: "5px",
                  borderBottom: "1px solid #F7F7F7",
                }}
              >
                <Col xs={4}>
                  <small>Section</small>
                </Col>
                <Col xs={5}>
                  <small>Read Access</small>
                </Col>
                <Col xs={5}>
                  <small>Write Access</small>
                </Col>
                <Col xs={5}>
                  <small>Delete Access</small>
                </Col>
                <Col xs={5}>
                  <small>Admin Access</small>
                </Col>
              </Row>
            </Grid>
          </FormGroup>
          {sectionAccess
            ? sectionAccess.map((section, index) => (
                <FormGroup>
                  <Grid fluid>
                    <Row>
                      <Col xs={4}>{section.name}</Col>
                      <Col xs={5}>
                        <Toggle
                          checked={section.readAccess}
                          //className="inside-shadow"
                          onChange={(checked) =>
                            setSectionAccessToggle(index, "readAccess", checked)
                          }
                          checkedChildren={<Icon icon="faCheck" />}
                          unCheckedChildren={<Icon icon="faBan" />}
                          style={
                            {
                              //backgroundColor: section.readAccess && "#8AC054",
                              //borderRadius: "50%",
                            }
                          }
                          //size="lg"
                        />
                      </Col>
                      <Col xs={5}>
                        <Toggle
                          checked={section.writeAccess}
                          //className="inside-shadow"
                          onChange={(checked) =>
                            setSectionAccessToggle(
                              index,
                              "writeAccess",
                              checked
                            )
                          }
                          checkedChildren={<Icon icon="faCheck" />}
                          unCheckedChildren={<Icon icon="faBan" />}
                          style={
                            {
                              //backgroundColor: section.writeAccess && "#4B89DA",
                            }
                          }
                        />
                      </Col>
                      <Col xs={5}>
                        <Toggle
                          checked={section.deleteAccess}
                          //className="inside-shadow"
                          onChange={(checked) =>
                            setSectionAccessToggle(
                              index,
                              "deleteAccess",
                              checked
                            )
                          }
                          checkedChildren={<Icon icon="faCheck" />}
                          unCheckedChildren={<Icon icon="faBan" />}
                          style={
                            {
                              //backgroundColor: section.deleteAccess && "#D94452",
                            }
                          }
                        />
                      </Col>
                      <Col xs={5}>
                        <Toggle
                          checked={section.adminAccess}
                          //className="inside-shadow"
                          onChange={(checked) =>
                            setSectionAccessToggle(
                              index,
                              "adminAccess",
                              checked
                            )
                          }
                          checkedChildren={<Icon icon="faCheck" />}
                          unCheckedChildren={<Icon icon="faBan" />}
                          style={
                            {
                              //backgroundColor: section.adminAccess && "#967ADA",
                            }
                          }
                        />
                      </Col>
                    </Row>
                  </Grid>
                </FormGroup>
              ))
            : null}
          <FormGroup>
            <ButtonGroup>
              <Button
                appearance="primary"
                className="small-shadow"
                color="green"
                onClick={() => {
                  if (selectedRole) {
                    //console.log("saving");
                    updateRole({
                      variables: {
                        id: selectedRole.id,
                        name,
                        sectionIds: sectionAccess.map(({ id }) => id),
                        sectionAccess: sectionAccess.map(
                          ({
                            readAccess,
                            writeAccess,
                            deleteAccess,
                            adminAccess,
                          }) => [
                            readAccess,
                            writeAccess,
                            deleteAccess,
                            adminAccess,
                          ]
                        ),
                      },
                    }).then((res) => {
                      props.close();
                      props.refetch();
                    });
                  } else {
                    createRole({
                      variables: {
                        name,
                        accountId: selectedAccount
                          ? selectedAccount.id
                          : user.account.id,
                        sectionIds: sectionAccess.map(({ id }) => id),
                        sectionAccess: sectionAccess.map(
                          ({
                            readAccess,
                            writeAccess,
                            deleteAccess,
                            adminAccess,
                          }) => [
                            readAccess,
                            writeAccess,
                            deleteAccess,
                            adminAccess,
                          ]
                        ),
                      },
                    }).then((res) => {
                      //console.log("Create User Result:", res);
                      if (
                        res &&
                        res.data &&
                        res.data.createRole &&
                        res.data.createRole.id
                      ) {
                        props.close();
                        props.refetch();
                      }
                    });
                  }
                }}
              >
                {selectedRole ? (
                  <span>
                    <Icon icon="faFloppyDisk" />{" "}
                    {lang !== null ? lang.save : "Save"}
                  </span>
                ) : (
                  <span>
                    <Icon icon="faFloppyDisk" />{" "}
                    {lang !== null ? lang.create : "Create"}
                  </span>
                )}
              </Button>
              <Button
                appearance="primary"
                className="small-shadow"
                color="orange"
                onClick={() => {
                  props.close && props.close();
                }}
              >
                <Icon icon="faBan" width={15} />{" "}
                {lang !== null ? lang.cancel : "Cancel"}
              </Button>
            </ButtonGroup>
          </FormGroup>
        </Form>
      </div>
    </div>
  );
};
