import gql from "graphql-tag";

export default {
  Queries: {
    deviceCount: gql`
      {
        deviceCount
      }
    `,
    userCount: gql`
      {
        userCount
      }
    `,
    accountCount: gql`
      {
        accountCount
      }
    `,
    reportedDevicesCount: gql`
      query reportedDevicesCount($start: String!) {
        reportedDevicesCount(start: $start)
      }
    `
  },
  Mutations: {}
};
