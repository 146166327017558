import React, { useEffect } from "react";
import { Button, ButtonGroup } from "rsuite";

import Icon from "../Icon";
import { useApp } from "../../AppStore";

let lang = null;

export default (props) => {
  const { size } = props;
  const [{ user }] = useApp();

  useEffect(() => {
    /** Language File */
    lang =
      user && user.language
        ? require("../../languages/" + user.language + ".js").default
        : lang;
  }, [user]);

  return (
    <ButtonGroup style={{ borderRadius: "6px" }}>
      <Button
        size={size || "md"}
        appearance="primary"
        color="green"
        onClick={() => {
          props.onSave && props.onSave();
        }}
        disabled={props.saveDisabled}
      >
        <span>
          <Icon icon="faFloppyDisk" /> {lang !== null ? lang.save : "Save"}
        </span>
      </Button>
      <Button
        size={size || "md"}
        appearance="primary"
        color="orange"
        onClick={() => {
          props.onCancel && props.onCancel();
        }}
      >
        <span>
          <Icon icon="faBan" width={15} />{" "}
          {lang !== null ? lang.cancel : "Cancel"}
        </span>
      </Button>
    </ButtonGroup>
  );
};
